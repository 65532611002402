<template>

  <div>
    <Form @submit="submitData" ref="invoiceAddressForm" id="invoiceAddressForm" class="checkout-form checkout-event"
          :initialErrors="null" :initialValues="initialValues" :validation-schema="schema" v-slot="{ values, errors }">
      <div class="d-flex flex-wrap pt-2">

        <div class="col-12 mb-2 d-flex">

          <div class="form-group form-floating w-100" v-if="authStore.isAuthenticated()">
            <select class="form-select" id="saved_addresses" @change="fillData">

              <option selected disabled>{{ getTranslation("invoice_address_placeholder", "Bitte wähle eine Adresse") }}</option>

              <optgroup label="Firmenadressen" v-if="companyAddresses.length >= 1">
                <option v-for="address in companyAddresses" :value="address.id">{{ address.company }}
                  {{ address.firstname }} {{ address.lastname }}, {{ address.street }}
                </option>
              </optgroup>

              <optgroup label="Adressen" v-if="userInfos || addresses.length">
                <option v-for="address in addresses" :value="address.id">{{ address.firstname }} {{ address.lastname }},
                  {{ address.street }}
                </option>
                <option value="userAddress" v-if="userInfos">{{ userInfos.firstname }} {{ userInfos.lastname }},
                  {{ userInfos.street }}
                </option>
              </optgroup>

            </select>
            <label for="saved_addresses">{{ getTranslation("invoice_address_label", "Gespeicherte Adressen") }}</label>
          </div>
          <button class="btn-default btn-small btn-border" v-else @click="fillData">{{ getTranslation('apply_button_text', 'übernehmen') }}</button>
        </div>

        <div class="col-12 px-md-2 d-flex form-group">
          <div class="form-check form-check-inline ps-0 pe-3">
            <Field as="input" class="form-check-input" type="radio" name="title" value="Herr"
                   @click="showCompanyField = false" id="InvoiceAddressTitle1"
                   :class="errors.title ? 'is-invalid':''"></Field>
            <label class="form-check-label" for="InvoiceAddressTitle1">{{ getTranslation('gender_männlich_formal', 'Herr') }}</label>
          </div>

          <div class="form-check form-check-inline ps-0 pe-3">
            <Field as="input" class="form-check-input" type="radio" name="title" value="Frau"
                   @click="showCompanyField = false" id="InvoiceAddressTitle2"
                   :class="errors.title ? 'is-invalid':''"></Field>
            <label class="form-check-label" for="InvoiceAddressTitle2">{{ getTranslation('gender_weiblich_formal', 'Frau') }}</label>
          </div>

          <div class="form-check form-check-inline ps-0 pe-3">
            <Field as="input" class="form-check-input" type="radio" name="title" value="Firma"
                   @click="showCompanyField = true" id="InvoiceAddressTitle3"
                   :class="errors.title ? 'is-invalid':''"></Field>
            <label class="form-check-label" for="InvoiceAddressTitle3">{{ getTranslation('title_company', 'Firma') }}</label>
          </div>
        </div>

        <div class="col-12 px-md-2">
          <div class="form-floating form-group w-100" v-if="showCompanyField">
            <Field as="input" name="company" class="form-control" id="company" type="text" :placeholder="getTranslation('company_label', 'Firmenname')"
                   :class="errors.company ? 'is-invalid':''"/>
            <label for="company">{{ getTranslation('company_label', 'Firmenname') }}</label>
            <small class="text-danger" v-if="errors.company">{{ errors.company }}</small>
          </div>
        </div>

        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="firstname" class="form-control" id="firstname" type="text" :placeholder="getTranslation('firstname_label', 'Vorname')"
                   :class="errors.firstname ? 'is-invalid':''"/>
            <label for="firstname">{{ getTranslation('firstname_label', 'Vorname') }}</label>
            <small class="text-danger" v-if="errors.firstname">{{ errors.firstname }}</small>
          </div>
        </div>

        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="lastname" class="form-control" id="lastname" type="text" :placeholder="getTranslation('lastname_label', 'Nachname')"
                   :class="errors.lastname ? 'is-invalid':''"/>
            <label for="lastname">{{ getTranslation('lastname_label', 'Nachname') }}</label>
            <small class="text-danger" v-if="errors.lastname">{{ errors.lastname }}</small>
          </div>
        </div>

      </div>

      <div class="d-flex flex-wrap pt-2">

        <div class="col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="street" class="form-control" id="street" type="text" :placeholder="getTranslation('street_label', 'Straße & Hausnummer')"
                   :class="errors.street ? 'is-invalid':''"/>
            <label for="street">{{ getTranslation('street_label', 'Straße & Hausnummer') }}</label>
            <small class="text-danger" v-if="errors.street">{{ errors.street }}</small>
          </div>
        </div>

        <div class="col-md-4 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="zip" class="form-control" id="zip" type="text" :placeholder="getTranslation('zip_label', 'Postleitzahl')"
                   :class="errors.zip ? 'is-invalid':''"/>
            <label for="zip">{{ getTranslation('zip_label', 'Postleitzahl') }}</label>
            <small class="text-danger" v-if="errors.zip">{{ errors.zip }}</small>
          </div>
        </div>

        <div class="col-md-8 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="place" class="form-control" id="place" type="text" :placeholder="getTranslation('place_label', 'Stadt')"
                   :class="errors.place ? 'is-invalid':''"/>
            <label for="place">{{ getTranslation('place_label', 'Stadt') }}</label>
            <small class="text-danger" v-if="errors.place">{{ errors.place }}</small>
          </div>
        </div>

        <div class="col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <!--          <select class="form-select" id="country_picker" @change="countrySelected" :value="'de'">-->
            <!--            <option v-for="country in countries" :value="country.alpha2">{{ country.name }}</option>-->
            <!--          </select>-->

            <Field as="select" name="country" id="country_picker" class="form-select">
              <option v-for="country in countries" :key="country" :value="country.alpha2"
                      v-text="country.name"></option>
            </Field>
            <label for="country_picker">{{ getTranslation('country_label', 'Land') }}</label>
          </div>
        </div>

      </div>

    </Form>
  </div>

</template>

<script>
import { Field, Form } from "vee-validate";

import * as yup from "yup";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";
import { useUserStore } from "../../../../store/user_store";

import { countries } from "../../../../data/static/countries";

export default {
  name: "invoiceAddress",
  components: {
    Form,
    Field,
  },
  data() {
    const initialValues = {
      title: "Herr",
      country: "de",
    };

    return {
      countries,
      initialValues,
      isLoading: false,
      showCompanyField: false,
    };
  },
  inject: ["getTranslation"],
  computed: {
    schema() {
      const schema = {
        firstname: yup
          .string()
          .required(
            this.getTranslation(
              "error_firstname_missing",
              "Vorname wird benötigt",
            ),
          )
          .trim(),
        lastname: yup
          .string()
          .required(
            this.getTranslation(
              "error_lastname_missing",
              "Nachname wird benötigt",
            ),
          )
          .trim(),
        street: yup
          .string()
          .required(
            this.getTranslation("error_street_missing", "Straße wird benötigt"),
          )
          .trim(),
        zip: yup
          .string()
          .required(
            this.getTranslation(
              "error_zip_missing",
              "Postleitzahl wird benötigt",
            ),
          )
          .trim(),
        place: yup
          .string()
          .required(
            this.getTranslation("error_place_missing", "Stadt wird benötigt"),
          )
          .trim(),
      };

      if (this.showCompanyField) {
        schema["company"] = yup
          .string()
          .required(
            this.getTranslation(
              "error_company_missing",
              "Firmenname wird benötigt",
            ),
          )
          .trim();
      }

      return schema;
    },
    addresses() {
      if (this.userInfos && this.userInfos.invoice_addresses) {
        return this.userInfos.invoice_addresses.filter(
          (address) => !address.company,
        );
      }
      return [];
    },
    companyAddresses() {
      if (this.userInfos && this.userInfos.invoice_addresses) {
        return this.userInfos.invoice_addresses.filter(
          (address) => address.company,
        );
      }
      return [];
    },
    userInfos() {
      return this.userStore.userInfosRef
        ? JSON.parse(this.userStore.userInfosRef)
        : this.userStore.userInfosRef;
    },
    ...mapStores(useAuthStore, useUserStore),
  },
  methods: {
    fillData(event) {
      if (this.authStore.isAuthenticated()) {
        if (event.target.value == "userAddress") {
          const userData = this.userInfos;
          this.$refs.invoiceAddressForm.setValues({
            title:
              userData.gender == "männlich"
                ? this.getTranslation("gender_männlich_formal", "Herr")
                : this.getTranslation("gender_weiblich_formal", "Frau"),
            firstname: userData.firstname,
            lastname: userData.lastname,
            street: userData.street,
            zip: userData.zip,
            place: userData.place,
            country: "de",
          });
          $(
            `input[name=title][value=${userData.gender == "männlich" ? this.getTranslation("gender_männlich_formal", "Herr") : this.getTranslation("gender_weiblich_formal", "Frau")}]`,
          ).click();
        } else {
          let userData = this.userInfos.invoice_addresses;
          userData = userData.filter(
            (address) => event.target.value == address.id,
          )[0];
          this.$refs.invoiceAddressForm.setValues({
            title: userData.title,
            firstname: userData.firstname,
            lastname: userData.lastname,
            street: userData.street,
            zip: userData.zip,
            place: userData.place,
            country: userData.country,
            company: userData.company,
          });

          if (userData.title == "Firma") {
            $("input[name=title][value=Firma]").click();
          } else {
            $(`input[name=title][value=${userData.title}]`).click();
          }
        }
      } else {
        const checkoutRegisterForm = document.getElementById(
          "checkout-register-form",
        );
        if (checkoutRegisterForm) {
          const formData = new FormData(checkoutRegisterForm);
          this.$refs.invoiceAddressForm.setValues({
            title: this.getTranslation("gender_männlich_formal", "Herr"),
            firstname: formData.get("firstname"),
            lastname: formData.get("lastname"),
            street: formData.get("street"),
            zip: formData.get("zip"),
            place: formData.get("place"),
            country: "de",
          });
        }
      }
    },
    submitData() {
      return;
    },
  },
};
</script>
