<template>
  <ErrorMessage
    :id="formMessageId"
    :name="name"
    class="text-danger"
    v-slot="{ message }"
    as="small"
  >
    {{ message }}
  </ErrorMessage>
</template>

<script>
import { ErrorMessage } from "vee-validate";
import { useFormField } from "./useFormField";

export default {
  name: "FormMessage",
  components: {
    ErrorMessage,
  },
  setup() {
    const { name, formMessageId } = useFormField();
    return { name, formMessageId };
  },
};
</script>