import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import area_api from "../api/area_api";

/**
 * Pinia data store for areas
 */
export const useAreasStore = defineStore("areas", () => {
  /**
   * List of areas
   * @type {Ref<[]>}
   */
  const areas = ref([]);

  /**
   * Fetch areas from the API
   * @param force {boolean} whether to force the fetch even if areas are already loaded
   * @returns {Promise<void>}
   */
  async function fetchAreas(force = false) {
    if (areas.value.length > 0) {
      return;
    }
    areas.value = await area_api.fetchAreas();
  }

  return {
    areas,
    fetchAreas,
  };
});
