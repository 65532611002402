import axios from "axios";

const area_api = {
  fetchAreas() {
    return axios.get(`/api/areas`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data.areas;
    });
  },
};

export default area_api;
