import axios from "axios";
import { createPinia } from "pinia";
import { createApp } from "vue";
import "../styles/shop.scss";

import App from "./src";

import settings_api from "./api/settings_api";
import { createCookieBanner } from "./src/utils/utils";

import Swal from "sweetalert2";
import { useDataStore } from "./store/data_store";

try {
  axios.defaults.headers.common["Accept-Language"] =
    window.disco2app.currentLanguage;
  settings_api.logSession();
  const config = window.disco2app.config;

  const app = createApp(App);
  app.use(createPinia());
  const app_vm = app.mount("#shop");

  const dataStore = useDataStore();
  dataStore.setConfig(config);
  app.config.devtools = config.env === "dev";

  // Headline ausblenden
  if (document.getElementById("instagram-header")) {
    document.getElementById("instagram-header").style.display = "none";
  }

  if (config.cookie_banner_active) {
    createCookieBanner(
      config,
      (config, statistics_accepted, marketing_accepted) => {
        console.log("cookies accepted");
        if (config.scripts.essential) {
          config.scripts.essential.forEach((script) => {
            if (script.type === "js") {
              const script_tag = document.createElement("script");
              script_tag.setAttribute("src", script.src);
              document.head.appendChild(script_tag);
            } else if (script.type === "css") {
              const link_tag = document.createElement("link");
              link_tag.setAttribute("rel", "stylesheet");
              link_tag.setAttribute("href", script.src);
              document.head.appendChild(link_tag);
            }
          });
        }

        if (statistics_accepted) {
          console.log("statistics accepted");
          if (config.scripts.statistics) {
            config.scripts.statistics.forEach((script) => {
              if (script.type === "js") {
                const script_tag = document.createElement("script");
                script_tag.setAttribute("src", script.src);
                document.head.appendChild(script_tag);
              } else if (script.type === "css") {
                const link_tag = document.createElement("link");
                link_tag.setAttribute("rel", "stylesheet");
                link_tag.setAttribute("href", script.src);
                document.head.appendChild(link_tag);
              }
            });
          }
        }

        if (marketing_accepted) {
          console.log("marketing accepted");
          if (config.scripts.marketing) {
            config.scripts.marketing.forEach((script) => {
              if (script.type === "js") {
                const script_tag = document.createElement("script");
                script_tag.setAttribute("src", script.src);
                document.head.appendChild(script_tag);
              } else if (script.type === "css") {
                const link_tag = document.createElement("link");
                link_tag.setAttribute("rel", "stylesheet");
                link_tag.setAttribute("href", script.src);
                document.head.appendChild(link_tag);
              }
            });
          }
        }
      },
    );
  }
} catch (e) {
  console.error(e);
  if (e.name === "SecurityError") {
    Swal.fire({
      title: "Achtung!",
      html: "<span><b>Dein Browser blockiert alle Cookies!</b><br><br>Diese Webseite benutzt <b>essenzielle Cookies</b>, ohne diese wird die Seite nicht ordnungsgemäß funktionieren.<br>Passe deine Browsereinstellungen an und rufe dann die Seite erneut auf.</span>",
      icon: "error",
    });
  } else {
    Swal.fire({
      title: "Whoops!",
      html: "<span><b>Ein unbekannter Fehler ist aufgetreten</b><br>Versuche diese Seite erneut zu laden.</span>",
      icon: "error",
    });
  }

  const params = new URLSearchParams({ name: e.name, message: e.message });
  axios.post("/error/report", params);
}
