<template>
  <div class="d-flex w-100 mt-2 justify-content-center checkout">
    <div class="p-lg-0 p-4 col-12 col-lg-9">
      <h4 class="mb-2">Event:</h4>
      <div class="checkout-event p-4 col-12 d-flex flex-wrap">
        <div class="col-9 pe-2">
          <h5 class="m-0">{{ event.name }}</h5>
          <span v-if="event.start">{{ formatDate(event.start, 'dddd, DD.MM.YYYY HH:mm') }}</span>
          <span v-if="event.start_time">{{ formatDate(event.start_time, 'dddd, DD.MM.YYYY HH:mm') }}</span>
        </div>

        <div class="col-3 event-image" :style="'background-image: url(' + event.image + ')'"></div>
      </div>

      <h4 class="mt-4 mb-2">{{ settings?.lounges?.alternatives?.single?.singular }}:</h4>
      <div class="checkout-event p-4 col-12 d-flex flex-wrap">
        <div class="col-9">
          <h5 class="m-0">{{ selectedLounge.name }} - {{ persons }} {{
              persons > 1 ? useTranslation('module_lounges_persons', 'Personen') : useTranslation('module_lounges_person', 'Person')
            }}</h5>
          <span v-if="config.lounges?.latest_arrival">{{
              useTranslation('module_shop_component_lounges_latest_arrival', 'Ankunft bis spätestens: ')
            }} {{ arriveTime }}&nbsp;</span>
          <span v-else>{{
              useTranslation('module_shop_component_lounges_arrive_time_prefix', 'Ankunft gegen')
            }} {{ arriveTime }}&nbsp;</span>
          <i class="feather-clock" style="font-size: 12px;"></i>
        </div>
        <div class="col-3 event-image" :style="'background-image: url(' + selectedLounge.image + ')'"></div>
      </div>


      <h4 class="mt-4 mb-2">{{ useTranslation('module_shop_component_lounges_request_title', 'Anfrage') }}:</h4>
      <div class="checkout-event p-4 col-12">
        <div class="w-100 form-group m-0 form-floating">
          <textarea 
            ref="messageInput"
            v-model="message"
            @input="resizeTextarea"
            class="form-control"
            :placeholder="useTranslation('module_shop_component_lounge_request_message_placeholder')"
          />
          <label>{{ useTranslation('module_shop_component_lounge_request_note_label') }}</label>
          <small class="text-danger" v-if="messageError">{{ messageError }}</small>
        </div>
      </div>

      <div v-if="!authStore.isAuthenticated()">
        <h4 class="mb-2 mt-4">
          {{ useTranslation('module_shop_component_lounge_request_register_title') }}:
        </h4>
        <div class="checkout-event p-4">
          <CheckoutForm />
        </div>
      </div>

      <div class="d-flex justify-content-center mt-4 mb-2">
        <button 
          type="button" 
          @click="handleSubmit" 
          class="btn w-75"
          :disabled="isLoading"
        >
          <span>{{ useTranslation('module_shop_component_lounge_request_submit_button_text') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";
import { useCartStore } from "../../../../store/cart_store";
import { useDataStore } from "../../../../store/data_store";
import { useCheckoutStore } from "../../../features/checkout/checkout.store";
import { useTranslation } from "../../../composables/useTranslation";
import CheckoutForm from "../../checkout/CheckoutForm.vue";
import moment from "moment";

const authStore = useAuthStore();
const cartStore = useCartStore();
const dataStore = useDataStore();
const checkoutStore = useCheckoutStore();

const { event, settings, config } = storeToRefs(dataStore);
const { selectedLounge, persons, arriveTime } = storeToRefs(cartStore);

const message = ref("");
const messageInput = ref(null);
const messageError = ref("");
const isLoading = ref(false);

const emit = defineEmits(['changeComponent']);

// Initialize the checkout store
checkoutStore.setType('lounges');

const handleSubmit = async () => {
  if (!message.value) {
    messageError.value = useTranslation(
      "module_shop_component_lounge_request_message_error",
      "Bitte gib eine Nachricht ein"
    );
    isLoading.value = false;
    messageInput.value.focus();
    return;
  }

  try {
    isLoading.value = true;
    
    // The CheckoutForm component handles its own validation and updates the store
    // We just need to check if there are any validation errors
    if (!authStore.isAuthenticated() && Object.keys(checkoutStore.validationErrors).length > 0) {
      return;
    }
    
    const response = await checkoutStore.sendReservationRequest(event.value, message.value);
    
    if (response.success) {
      await cartStore.setStatus("paymentCompleted");
      emit("changeComponent", {
        module: "Shop",
        component: "paymentCompleted",
        params: {
          type: "loungeReservationRequest"
        }
      });
    }
  } catch (error) {
    console.error(error);
    messageError.value = error.message;
  } finally {
    isLoading.value = false;
  }
};

const resizeTextarea = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
};

const formatDate = (date, format = "DD.MM.YYYY") => moment(date).format(format);
</script>


<style scoped>
  .form-group textarea {
    resize: both !important;
    overflow: hidden !important;
    width: 100% !important;
    min-height: 3em !important;
    max-height: unset !important;
  }
</style>



