<template>

  <div class="d-flex justify-content-center align-items-center" id="layout-pan" style="position: relative;">

    <div v-if="isLoading">
      <span class="spinner-border"></span>
    </div>

    <img v-show="!isLoading" ref="layout" :src="area.layout.image" loading="eager" class="layout-plan"
         :usemap="'#layout_map_' + area.layout.id"
         :alt="getTranslation('module_shop_component_lounges_areas_layout_button_text', 'Lageplan') + ' - ' + area.name">
    <div v-html="area.layout.map"></div>

  </div>

</template>

<script>
import {
  useDebounceFn,
  useImage,
  useMouseInElement,
  useResizeObserver,
} from "@vueuse/core";

const panzoom = require("panzoom");

export default {
  name: "area_layout_component",
  props: ["area", "event_id", "event", "area_id"],
  inject: ["getTranslation"],
  data() {
    const { isLoading } = useImage({
      src: this.area.layout ? this.area.layout.image : null,
    });
    return {
      originalCoords: {},
      currentCoords: {},
      mouseOver: null,
      isLoading: isLoading,
      pan: null,
    };
  },
  emits: ["startBooking", "startReservationRequest"],
  computed: {
    layout_areas() {
      return document.querySelectorAll(
        `#area_${this.area_id} area.lounge-area`,
      );
    },
  },
  methods: {
    calcMapCoords() {
      return new Promise((resolve) => {
        const layout = this.$refs.layout;
        const originalWidth = layout.naturalWidth;
        const originalHeight = layout.naturalHeight;

        const renderedHeight = layout.height;
        const renderedWidth = layout.width;

        const diffByWidth = originalWidth / renderedWidth;
        const diffByHeight = originalHeight / renderedHeight;

        if (this.layout_areas.length) {
          for (const [id, area] of Object.entries(this.originalCoords)) {
            this.currentCoords[id] = {
              left: Math.round(this.originalCoords[id].left / diffByWidth),
              top: Math.round(this.originalCoords[id].top / diffByHeight),
              right: Math.round(this.originalCoords[id].right / diffByWidth),
              bottom: Math.round(this.originalCoords[id].bottom / diffByHeight),
            };
            const newCoords = [];
            for (const [side, value] of Object.entries(area)) {
              // coords x1,y1,x2,y2

              if (side === "top" || side === "bottom") {
                newCoords[side === "top" ? 1 : 3] = Math.round(
                  value / diffByHeight,
                );
              } else {
                newCoords[side === "left" ? 0 : 2] = Math.round(
                  value / diffByWidth,
                );
              }
            }
            const map_area = document.getElementById(`${id}`);
            map_area.coords = newCoords.join(",");
          }
        }
        resolve();
      });
    },
    markBlockedLounges() {
      const coords = Object.entries(this.currentCoords);

      coords.forEach((coord) => {
        const id = coord[0];
        if (this.event) {
          const lounge = this.event.lounges.find((lounge) => lounge.id == id);
          if (
            lounge?.booked ||
            (lounge?.blocked && !lounge?.reservation_requests)
          ) {
            coord = this.currentCoords[coord[0]];

            const width = coord.right - coord.left;
            const height = coord.bottom - coord.top;

            const layout = document.querySelector(".layout-plan");
            if (
              !this.area_hover &&
              !document.getElementById("area-hover-" + id)
            ) {
              const area_hover = document.createElement("div");
              area_hover.style.top = coord.top + layout.offsetTop + "px";
              area_hover.style.left = coord.left + layout.offsetLeft + "px";
              area_hover.style.height = height + "px";
              area_hover.style.width = width + "px";
              area_hover.style.position = "absolute";
              area_hover.style.backgroundColor = "var(--color-darker)";
              area_hover.classList.add("area-hover");
              area_hover.classList.add("blocker");
              area_hover.id = "area-hover-" + id;
              area_hover.dataset.id = id;

              area_hover.classList.add("d-flex");
              area_hover.classList.add("align-items-center");
              const span = document.createElement("span");
              span.classList.add("text-danger");
              span.classList.add("text-center");
              span.classList.add("w-100");

              span.innerHTML = "bereits<br>reserviert";
              span.style.fontSize = "6px";
              if (window.innerWidth <= 500) {
                span.style.fontSize = "2px";
              }
              area_hover.append(span);

              this.$refs.layout.parentElement.append(area_hover);

              area_hover.style.border = "4px solid var(--color-danger)";
            }
          }
        }
      });
    },
  },
  watch: {
    isLoading: {
      handler(value, oldValue) {
        if (!value) {
          setTimeout(() => {
            if (this.$refs.layout) {
              this.calcMapCoords().then(this.markBlockedLounges);
            }
          }, 100);
        }
      },
      immediate: false,
    },
    mouseOver: {
      handler(value, oldValue) {
        const coords = Object.entries(this.currentCoords);
        if (!value.isOutside && coords && coords.length) {
          coords.forEach((coord) => {
            const id = coord[0];
            const lounge = this.event?.lounges.find(
              (lounge) => lounge.id === id,
            );
            coord = this.currentCoords[coord[0]];

            let scale = 1.0;
            if (this.pan) {
              const transform = this.pan.getTransform();
              scale = transform.scale;
            }

            if (
              coord.left <= (value.x - value.elementPositionX) / scale &&
              coord.right >= (value.x - value.elementPositionX) / scale &&
              coord.top <= (value.y - value.elementPositionY) / scale &&
              coord.bottom >= (value.y - value.elementPositionY) / scale
            ) {
              // hover über area
              const width = coord.right - coord.left;
              const height = coord.bottom - coord.top;

              const layout = document.querySelector(".layout-plan");
              if (
                !this.area_hover &&
                !document.getElementById("area-hover-" + id)
              ) {
                const area_hover = document.createElement("div");
                area_hover.style.top = coord.top + layout.offsetTop + "px";
                area_hover.style.left = coord.left + layout.offsetLeft + "px";
                area_hover.style.height = height + "px";
                area_hover.style.width = width + "px";
                area_hover.style.position = "absolute";
                area_hover.style.cursor = "pointer";
                area_hover.classList.add("area-hover");
                area_hover.id = "area-hover-" + id;
                area_hover.dataset.id = id;

                this.$refs.layout.parentElement.append(area_hover);
                this.area_hover = area_hover;

                let lounge_blocked = false;
                let lounge_reservations = false;

                if (this.event) {
                  if (!lounge?.booked && !lounge?.blocked) {
                    area_hover.style.border = "4px solid var(--color-success)";
                  } else if (lounge?.reservation_requests && lounge?.blocked) {
                    area_hover.style.border = "4px solid var(--color-warning)";
                    lounge_reservations = true;
                    lounge_blocked = true;
                  } else {
                    area_hover.style.border = "4px solid var(--color-danger)";
                    lounge_blocked = true;
                  }
                } else {
                  area_hover.style.border = "4px solid var(--color-primary)";
                }

                if (!lounge_blocked) {
                  area_hover.addEventListener("click", (e) => {
                    this.$emit("startBooking", {
                      lounge: id,
                      event: this.event_id,
                    });
                  });
                }
                if (lounge_blocked && lounge_reservations) {
                  area_hover.addEventListener("click", (e) => {
                    this.$emit("startReservationRequest", {
                      lounge: id,
                      event: this.event_id,
                    });
                  });
                }
              }
            } else {
              if (
                document.getElementById("area-hover-" + id) &&
                !document
                  .getElementById("area-hover-" + id)
                  .classList.contains("blocker")
              ) {
                document.getElementById("area-hover-" + id).remove();
              }
              this.area_hover = null;
            }
          });
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    if (
      (!this.area || !this.area.layout) &&
      document.getElementById("layouts-container-div")
    ) {
      document.getElementById("layouts-container-div").style.display = "none";
    } else {
      if (this.layout_areas.length) {
        this.layout_areas.forEach((area) => {
          const coords = area.coords.split(",");
          this.originalCoords[area.id] = {
            left: Number.parseInt(coords[0]),
            top: Number.parseInt(coords[1]),
            right: Number.parseInt(coords[2]),
            bottom: Number.parseInt(coords[3]),
          };
          this.currentCoords[area.id] = {
            left: Number.parseInt(coords[0]),
            top: Number.parseInt(coords[1]),
            right: Number.parseInt(coords[2]),
            bottom: Number.parseInt(coords[3]),
          };
        });
      }

      if (window.innerWidth >= 992) {
        this.mouseOver = useMouseInElement(this.$refs.layout);
      } else {
        this.layout_areas.forEach((area) => {
          area.addEventListener("click", (e) => {
            e.preventDefault();
            if (this.event) {
              const lounge = this.event.lounges.find(
                (lounge) => lounge.id === area.id,
              );
              if (!lounge?.booked || !lounge?.blocked) {
                this.$emit("startBooking", {
                  lounge: area.id,
                  event: this.event_id,
                });
              } else if (lounge.blocked && lounge.reservation_requests) {
                this.$emit("startReservationRequest", {
                  lounge: area.id,
                  event: this.event_id,
                });
              }
            } else {
              this.$emit("startBooking", {
                lounge: area.id,
                event: this.event_id,
              });
            }
          });
        });
      }

      useResizeObserver(this.$refs.layout, (entries) => {
        this.calcMapCoords();
      });

      setTimeout(() => {
        // this.pan = panzoom(document.getElementById('layout-pan'));
        this.pan = panzoom(document.getElementById("layout-pan"), {
          // transformOrigin: {x: 0.5, y: 0.5},
          maxZoom: window.innerWidth <= 992 ? 4.0 : 2.5,
          minZoom: 1,
          bounds: true,
          boundsPadding: 1,
          onTouch: (e) =>
            !(
              e.target.classList.contains("area-hover") ||
              e.target.localName === "area"
            ),
          beforeWheel: (e) => {
            // allow wheel-zoom only if altKey is down. Otherwise - ignore
            const shouldIgnore = !e.altKey;

            if (!e.altKey && this.$refs.layout) {
              this.$refs.layout.style.transition = "";
              this.$refs.layout.style.filter = "brightness(.4)";
              this.$refs.layout.style.webkitFilter = "brightness(.4)";
              this.$refs.layout.style.transition = "filter 500ms linear";
              this.$refs.layout.style.webkitTransition =
                "-webkit-filter 500ms linear";
              if (!document.getElementById("scrollOverlay")) {
                const overlay = document.createElement("div");
                overlay.id = "scrollOverlay";
                overlay.style.position = "absolute";
                overlay.style.left = "26%";
                overlay.style.top = "50%";
                overlay.style.fontSize =
                  window.innerWidth <= 992 ? "13px" : "20px";
                overlay.innerHTML = `Halte die Taste ${window.navigator.platform.toLowerCase().indexOf("mac") !== -1 ? "&#8997;" : "ALT"} beim Scrollen gerückt um den Plan zu vergrößern`;
                this.$refs.layout.parentElement.append(overlay);

                setTimeout(() => {
                  this.$refs.layout.style.filter = "";
                  this.$refs.layout.style.webkitFilter = "";
                  overlay.remove();
                }, 1000);
              }
            }

            return shouldIgnore;
          },
        })
          .on("panstart", (e) => {
            const transform = this.pan.getTransform();
            if (transform.scale === 1.0) {
              e.preventDefault();
              e.stopPropagation();
            }
          })
          .on(
            "zoom",
            useDebounceFn(() => {
              // console.log('zoom')
              this.calcMapCoords();
            }),
            500,
          );
      }, 250);
    }
  },
};
</script>

<style scoped lang="scss">
.layout-plan {
  position: relative;
  width: 1000px;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.area-hover {
  position: absolute;
  border: 2px solid var(--color-primary);
}


</style>