import { defineStore } from "pinia";
import { ref } from "vue";

export const useTranslationStore = defineStore("translation", () => {
  const current_locale = window.disco2app.currentLanguage || "de";

  /**
   * A map of translation keys to their translated values
   * @type {Object.<string, string>}
   */
  const translations = ref(window.disco2app.translations);
  moment.locale(current_locale);

  /**
   * Get all available translations
   * @returns {Object.<string, string>} Map of translation keys to their translated values
   */
  const getTranslations = () => {
    return translations.value;
  };

  /**
   * Get a translation for a specific key
   * @param {string} key - The translation key to look up
   * @param {string} [default_text=""] - Fallback text if key is not found
   * @returns {string} The translated text or default_text if key not found
   */
  const getTranslation = (key, default_text = "") => {
    return translations.value[key] || default_text;
  };

  /**
   * Replaces placeholders in the translation string with corresponding values.
   *
   * @param {string} key - The key to look up the translation in the `translations` object.
   * @param {string} [default_text=""] - The default text to use if the translation key is not found.
   * @param {Object} [placeholder={}] - An object containing key-value pairs where keys are placeholder names (without % symbols),
   *                                    and values are the values to replace the placeholders with.
   * @returns {string} - The translated text with all placeholders replaced by their corresponding values.
   *
   * @example
   * const translated = getTranslationWithPlaceholder('greeting', 'Hello %name%', { name: 'John' });
   * console.log(translated); // Output: "Hello John"
   */
  const getTranslationWithPlaceholder = (
    key,
    default_text = "",
    placeholder = {},
  ) => {
    let translatedText = translations.value[key] || default_text;

    Object.keys(placeholder).forEach((placeholderKey) => {
      const placeholderPattern = new RegExp(`%${placeholderKey}%`, "g");
      translatedText = translatedText.replace(
        placeholderPattern,
        placeholder[placeholderKey],
      );
    });

    return translatedText;
  };

  const getTranslatedTitleForComponent = (component) => {
    if (translations.value && translations.value["translations"]) {
      switch (component) {
        case "lounges":
          return translations.value[
            "module_shop_component_lounges_select_lounge_title"
          ];
        case "events":
          return translations.value[
            "module_shop_component_lounges_select_event_title"
          ];
        case "drinks":
          return translations.value[
            "module_shop_component_drinks_select_drinks_title"
          ];
        case "checkout":
          return translations.value["module_shop_component_checkout_title"];
        case "paymentCompleted":
          return translations.value[
            "module_shop_component_payment_completed_title"
          ];
        case "register":
          return translations.value["module_shop_component_register_title"];
        case "verify":
          return translations.value["module_shop_component_verify_title"];
        case "missingInfos":
          return translations.value["module_shop_component_missinginfos_title"];
        case "forgotPassword":
          return translations.value["module_shop_component_missinginfos_title"];
        case "tickets":
          return translations.value[
            "module_shop_component_tickets_select_tickets_title"
          ];
        case "drinkMenuSelection":
          return translations.value[
            "module_shop_component_drinks_select_drinkmenu_title"
          ];
        case "validateBooking":
          return translations.value[
            "module_shop_component_lounges_validate_booking_title"
          ];
        case "listDrinks":
          return translations.value[
            "module_shop_component_drinks_select_drinks_title"
          ];
        case "verifyEmail":
          return translations.value["module_shop_component_verify_email_title"];
        case "verifyPhone":
          return translations.value["module_shop_component_verify_phone_title"];
        case "reservationRequest":
          return translations.value[
            "module_shop_component_lounges_reservation_request_title"
          ];
      }
    }
  };

  return {
    translations,
    getTranslations,
    getTranslatedTitleForComponent,
    getTranslation,
    getTranslationWithPlaceholder,
  };
});
