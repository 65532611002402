<template>
  <div class="lounge-container p-4 d-flex justify-content-center flex-wrap" v-if="lounges">
    <div :class="{ 'col-lg-6 col-12 mb-4 pe-4': isLoungeVisible(lounge.id) }" v-for="lounge in lounges">
      <div v-if="isLoungeVisible(lounge.id)" class="inner h-100 lounge-card rn-card box-card-style-default"
           :class="loungeStatusClass(lounge.id)"
           @click="handleLoungeClick(lounge.id)">
        <div class="thumbnail">
          <img :src="lounge.image" :alt="lounge.name">
          <div class="lounge-name">{{ lounge.name }}</div>
          <div class="lounge-status"
               @click="handleLoungeClick(lounge.id)">
            {{ getLoungeStatusText(lounge.id) }}
          </div>
        </div>
        <div v-if="!hideDetails" class="content">
          <div class="d-flex flex-wrap align-items-center lounge-infos h-100"
               v-if="!isReservationRequestAvailable(lounge.id)">
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">{{ lounge.available_places }}</div>
              {{ useTranslation('module_lounges_persons', "Personen") }}
            </div>
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">{{ format(lounge.minimum_consumption) }}</div>
              {{ useTranslation('module_lounges_minimum_consumption', "Mindestverzehr") }}
            </div>
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">{{
                  format((lounge.minimum_consumption) / lounge.available_places)
                }}
              </div>
              {{ useTranslation('module_lounges_per_persons', "pro Person") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useDataStore } from "../../../../store/data_store";
import { useTranslation } from "../../../composables/useTranslation";

const props = defineProps({
  lounges: {
    type: Array,
    required: true
  },
  event_id: {
    type: [String, Number],
    required: true
  },
  hideDetails: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['startBooking', 'startReservationRequest']);

const store = useDataStore();
const { event, isLoungeBlocked, isLoungeAvailable, settings, isLoungeAvailableForReservationRequest, isLoungeReservationRequestsOnly } = storeToRefs(store);

// Computed properties
const hasEvent = computed(() => !!event.value);

const isLoungeVisible = computed(() => (id) => {
  if (!hasEvent.value) return true;

  const lounge = props.lounges.find(l => l.id === id);
  if (!lounge) return false;

  // Show if either:
  // 1. Lounge is available
  // 2. Lounge is blocked/booked but show_when_blocked is true
  // 3. show_locked_lounge setting is enabled
  return isLoungeAvailable.value(id) ||
         ((lounge.show_when_blocked || lounge.booked) && isBlocked.value(id)) ||
         settings.value?.lounges.booking.show_locked_lounge;
});

const isBlocked = computed(() => (id) => {
  if (!hasEvent.value) return false;

  const lounge = props.lounges.find(l => l.id === id);
  if (!lounge) return false;

  return isLoungeBlocked.value(id) || !isLoungeAvailable.value(id) || lounge.booked;
});

const isReservationRequestAvailable = computed(() => (id) => {
  return isLoungeAvailableForReservationRequest.value(id);
});

const isOnlyReservationRequestsAvailable = computed(() => (id) => {
  return isLoungeReservationRequestsOnly.value(id);
});

const loungeStatusClass = computed(() => (id) => ({
  'lounge-status-requests': isReservationRequestAvailable.value(id),
  'blocked lounge-status-blocked': isBlocked.value(id)
}));

const getLoungeStatusText = computed(() => (id) => {
  if (isReservationRequestAvailable.value(id)) {
    return useTranslation('module_lounges_lounge_status_reservation_requests', "Nur auf Anfrage");
  }
  if (!isBlocked.value(id)) {
    return useTranslation('module_lounges_lounge_status_free', "Jetzt reservieren");
  }
  return useTranslation('module_lounges_lounge_status_blocked', "Leider schon reserviert");
});

// Methods
const format = (price) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(price);
};

const handleLoungeClick = (id) => {
  if (isReservationRequestAvailable.value(id) && !isOnlyReservationRequestsAvailable.value(id)) {
    startReservationRequest(id);
  } else if (!isBlocked.value(id)) {
    startBooking(id);
  }
};

const startBooking = (id) => {
  emit('startBooking', { lounge: id, event: props.event_id });
};

const startReservationRequest = (id) => {
  emit('startReservationRequest', {
    lounge: id,
    event: props.event_id,
  });
};
</script>

<style scoped>

</style>
