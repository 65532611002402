<template>

  <div>
    <div class="register-step-info">
      <h4 class="mb-1">{{ getTranslation('module_shop_component_register_step4_title', 'Deine Anschrift..') }}</h4>
      {{ getTranslation('module_shop_component_register_step4_text', 'Wer ist Inhaber der Tickets, Reservierungen etc.?') }}
    </div>

    <div class="d-flex flex-wrap pt-2"  v-if="target_settings?.register?.addressRequired || target_settings?.register?.addressRequired === undefined">

      <div class="col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" :placeholder="getTranslation('street_placeholder', 'Straße & Hausnummer')" name="street" class="form-control" id="street" :class="errors.street ? 'is-invalid':''"/>
          <label for="street">{{ getTranslation('street_label', 'Straße & Hausnummer') }}</label>
          <small class="text-danger" v-if="errors.street">{{ errors.street }}</small>
        </div>
      </div>

      <div class="col-md-4 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" :placeholder="getTranslation('zip_label', 'PLZ')" name="zip" class="form-control" id="zip" :class="errors.zip ? 'is-invalid':''"/>
          <label for="zip">{{ getTranslation('zip_label', 'PLZ') }}</label>
          <small class="text-danger" v-if="errors.zip">{{ errors.zip }}</small>
        </div>
      </div>

      <div class="col-md-8 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" :placeholder="getTranslation('place_label', 'Stadt')" name="place" class="form-control" id="place" :class="errors.place ? 'is-invalid':''"/>
          <label for="place">{{ getTranslation('place_label', 'Stadt') }}</label>
          <small class="text-danger" v-if="errors.place">{{ errors.place }}</small>
        </div>
      </div>

    </div>

    <div class="d-flex flex-wrap pt-2 pt-2">

      <div class="col-12 px-2 text-center">
        <div class="form-group mb-0">
          <div class="form-check w-100 p-0">
            <Field as="input" name="privacy" class="form-check-input m-0 p-0" type="checkbox" :value="true"
                   id="privacy_check" style="height: 24px;" :class="errors.privacy ? 'is-invalid':''"/>
            <label class="form-check-label" for="privacy_check" v-html="getTranslation('module_shop_component_register_privacy_text', privacy_text)">
            </label>
          </div>
          <small class="text-danger" v-if="errors.privacy">{{ errors.privacy }}</small>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { Field } from "vee-validate";

let target_settings;
try {
  target_settings = require("../../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "step4",
  props: ["errors", "asGuest"],
  inject: ["getTranslation"],
  emits: [],
  components: {
    Field,
  },
  data() {
    return {
      privacy_text:
        'Ich habe die <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere diese.',
    };
  },
};
</script>

<style scoped>

</style>