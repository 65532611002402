import axios from "axios";

const lounge_api = {
  fetchLounges() {
    return axios.get(`/api/lounges`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  fetchLoungePackages(lounge) {
    return axios.get(`/api/lounges/${lounge}/packages`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  getLoungeBooking(payload) {
    const params = new URLSearchParams(payload);
    return axios.get("/api/user/booking", { params }).then((resp) => {
      return resp.data;
    });
  },
  getLoungeBookings(payload) {
    const params = new URLSearchParams(payload);
    return axios.get("/api/user/bookings", { params }).then((resp) => {
      return resp.data;
    });
  },
  calculateLoungeFee(payload) {
    const params = new URLSearchParams(payload);
    return axios
      .get("/api/lounges/calculate/fees/", { params })
      .then((resp) => {
        return resp.data;
      });
  },
  checkForDiscounts(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/lounges/check/discounts", params).then((resp) => {
      return resp.data;
    });
  },
  validateBookingNumber(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/validate/lounge-booking", params).then((resp) => {
      return resp.data;
    });
  },
  sendReservationRequest(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/lounges/reservation", params).then((resp) => {
      return resp.data;
    });
  },
};

export default lounge_api;
