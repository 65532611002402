<template>
  <p
    :id="formDescriptionId"
    :class="['form-text', props.class]"
  >
    <slot />
  </p>
</template>

<script>
import { useFormField } from "./useFormField";

export default {
  name: "FormDescription",
  props: {
    class: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { formDescriptionId } = useFormField();
    return { formDescriptionId };
  },
};
</script>