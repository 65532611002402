<template>

  <div class="col-lg-9 col-12 verify-form">

    <div class="col-12">
      <!--      <h4 class="mb-2">Deine Handynummer:</h4>-->

      <h5 class="m-0 mb-2">
        {{
          getTranslation('module_shop_component_verifyphone_subtitle', 'Wir müssen deine Handynummer bestätigen. Dazu senden wir dir beim Klick auf "Code senden" einen Code per SMS an die angegebene Nummer, mit welchem du deine Nummer verifizieren kannst.')
        }}
      </h5>

      <div class="verify-phone-box">
        <div class="col-12">

          <select class="form-select" id="country_picker" @change="handleCountrySelect" :value="countrySelectorValue">
            <option v-for="country in countries" :value="country.alpha2">{{ country.name }}</option>
          </select>


          <Form @submit="sendVerificationCode" id="phone-form">
            <div class="col-12 mt-2">
              <div class="form-floating input-group w-100">

                <input name="country_dialing_code" id="country_dialing_code" class="form-control" :class="countryDialError ? 'is-invalid':''"
                       type="text"
                       :placeholder="getTranslation('country_dialing_code_label', 'Vorwahl')"
                       :value="country_dialing_code"
                       @focusout="verifyCountryDialingCode"
                       @change="handleDialingCodeChange"
                       style="max-width: 11.5rem;"
                />

                <input name="phone" class="form-control verify-form-phonenumber" :class="phoneError ? 'is-invalid':''" id="phone"
                       type="tel"
                       :placeholder="getTranslation('phone_label', 'Telefonnummer')"
                       @focusout="verifyPhone"
                />

                <label for="country_dialing_code">{{
                    getTranslation('country_dialing_code_label', 'Vorwahl')
                  }}</label>
                <label for="phone" style="margin-left: 11.5rem;">{{
                    getTranslation('phone_label', 'Telefonnummer')
                  }}</label>

              </div>
              <div class="d-flex justify-content-center">
                <small class="text-danger">{{ countryDialError }}</small>
                <small class="text-danger">{{ phoneError }}</small>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-center mt-4">
              <button type="submit" class="btn btn-primary px-4" :disabled="(timer > 0 || phoneError || countryDialError)" data-umami-event="verify-phone-request-button">
                <span v-if="timer > 0">{{ timer }} s</span>
                <span v-if="timer === 0">{{
                    getTranslation('module_shop_component_verifyphone_send_button_text', 'Code senden')
                  }}</span>
              </button>
            </div>
          </Form>

        </div>
      </div>

      <div v-if="codeSent" class="col-12 animate__animated animate__fast animate__fadeInUp">
        <h4 class="mt-4 mb-2">{{ getTranslation('enter_sms_code', 'Gib den erhaltenen SMS-Code ein:') }}</h4>

        <Form @submit="verifyCode" :validation-schema="schema" id="verify-code-form">

          <div class="col-12">
            <div class="form-floating form-group w-100">
              <Field as="input" name="code" id="code" type="text" :placeholder="getTranslation('code_label', 'Code')"
                     autocomplete="code"
                     class="form-control"/>
              <label for="code">{{ getTranslation('code_label', 'Code') }}</label>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-center mt-4">
            <button type="submit" :disabled="isLoading" class="btn btn-primary px-4">
              <span v-if="!isLoading">{{
                  getTranslation('verify_phone_button_text', 'Telefonnummer jetzt bestätigen')
                }}</span>
              <span v-else class="spinner-border spinner-border-sm"></span>
            </button>
          </div>

        </Form>

      </div>

    </div>

  </div>

</template>


<script>
import "animate.css";
import { Field, Form } from "vee-validate";

import Cookies from "js-cookie";
import Swal from "sweetalert2";

import { countries } from "../../../../data/static/countries";
import { country_dialing_codes } from "../../../../data/static/country_dialing_codes";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";
import { useUserStore } from "../../../../store/user_store";

export default {
  name: "verifyPhone",
  components: {
    Form,
    Field,
  },
  props: ["registerSuccessful", "loadedFrom"],
  computed: {
    ...mapStores(useAuthStore, useUserStore),
  },
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  data() {
    // module_shop_component_verify_phone_title
    const schema = {
      code: (value) => {
        if (value === undefined || value === "") {
          return this.getTranslation(
            "error_code_missing",
            "Der Code wird benötigt",
          );
        }
        if (value.length < 6) {
          return this.getTranslation(
            "error_code_invalid",
            "Der Code ist ungültig",
          );
        }
        return true;
      },
    };

    const timer = 120;
    let verificationSent = Cookies.get("verificationSent");
    if (verificationSent === undefined) {
      verificationSent = false;
    }

    // get language from cookie
    let language = window.disco2app.currentLanguage ?? "de";

    if (language && language.indexOf("-") !== -1) {
      language = language.indexOf("en")
        ? language.split("-")[1]
        : language.split("-")[0];
    }
    // get country from language
    const initialCountry = countries.find(
      (country) => country.alpha2.toUpperCase() === language.toUpperCase(),
    );

    return {
      verificationSent: verificationSent,
      timer: timer,
      phoneError: null,
      countryDialError: null,
      codeError: null,
      isLoading: false,
      codeSent: false,
      schema: schema,
      country_dialing_code: initialCountry
        ? "+" + country_dialing_codes[initialCountry.alpha2.toUpperCase()]
        : "+49",
      country_dialing_codes: country_dialing_codes,
      countries: countries,
      countrySelectorValue: initialCountry ? initialCountry.alpha2 : "de",
    };
  },
  methods: {
    handleDialingCodeChange(e) {
      this.country_dialing_code = e.target.value;
      const country = Object.keys(this.country_dialing_codes).find(
        (key) =>
          this.country_dialing_codes[key] === e.target.value.replace("+", ""),
      );
      if (country) {
        this.countrySelectorValue = country.toLowerCase();
      }
    },
    handleCountrySelect(e) {
      this.country_dialing_code =
        "+" + this.country_dialing_codes[e.target.value.toUpperCase()];
    },
    verifyPhone: function (e) {
      const val = e.target.value;
      if (val.length === 0) {
        this.phoneError = this.getTranslation(
          "error_phone_required",
          "Telefonnummer wird benötigt.",
        );
      } else if (val.length <= 6) {
        this.phoneError = this.getTranslation(
          "error_phone_invalid",
          "Telefonnummer ist ungültig.",
        );
      } else {
        this.phoneError = null;
      }
    },
    verifyCountryDialingCode: function (e) {
      const val = e.target.value;
      if (val.length === 0) {
        this.countryDialError = this.getTranslation(
          "error_country_dialing_code_required",
          "Landesvorwahl wird benötigt.",
        );
      } else if (val.indexOf("+") === -1) {
        this.countryDialError = this.getTranslation(
          "error_country_dialing_code_invalid",
          'Bitte die Vorwahl überprüfen. (z.B. "+49" für DE)',
        );
      } else {
        this.countryDialError = null;
      }
    },
    sendVerificationCode() {
      if (!document.getElementById("phone").value) {
        this.phoneError = this.getTranslation(
          "error_phone_required",
          "Telefonnummer wird benötigt.",
        );
        return;
      }
      if (!document.getElementById("country_dialing_code").value) {
        this.countryDialError = this.getTranslation(
          "error_country_dialing_code_required",
          "Landesvorwahl wird benötigt.",
        );
        return;
      }
      if (this.timer === 0) {
        const payload = {
          phone: document.getElementById("phone").value,
          country: document.getElementById("country_dialing_code").value,
        };

        this.authStore
          .sendVerificationCode(payload)
          .then((data) => {
            this.codeSent = true;
            Cookies.set("verificationSent", new Date().toISOString());
            this.verificationSent = new Date().toISOString();
            this.timer = 120;
          })
          .then(() => {
            document.getElementById("code").focus();
          })
          .catch((error) => {
            this.codeSent = false;
            Swal.fire({
              title: this.getTranslation(
                "error_unknown",
                "Ein Fehler ist aufgetreten",
              ),
              icon: "error",
              text: error.message,
            });
          });
      }
    },
    verifyCode(values) {
      if (document.getElementById("code").value) {
        this.codeError = null;
        this.isLoading = true;

        this.authStore
          .verifyCode(values)
          .then((data) => {
            Swal.fire({
              title: this.getTranslation(
                "success_message_title",
                "Erfolgreich!",
              ),
              icon: "success",
              text: this.getTranslation(
                "module_shop_component_verifyphone_success_text",
                "Telefonnummer wurde bestätigt.",
              ),
            }).then(() => {
              setTimeout(() => {
                this.userStore.refreshUserInfos().then((userInfos) => {
                  document.getElementById("code").value = "";
                  this.checkValidation();
                });
              }, 1000);
              this.isLoading = false;
            });
          })
          .catch((error) => {
            Swal.fire({
              title: this.getTranslation(
                "error_unknown",
                "Ein Fehler ist aufgetreten",
              ),
              icon: "error",
              text: error.message,
            });
            this.isLoading = false;
          });
      } else {
        this.codeError = this.getTranslation(
          "error_code_required",
          "Code wird benötigt.",
        );
      }
    },
    emits: ["phoneVerified", "setModalTitle"],
    checkValidation() {
      this.isLoading = true;

      this.userStore
        .refreshUserInfos()
        .then((userInfos) => {
          if (userInfos.phone_verified) {
            this.$emit("phoneVerified");
          }
          this.isLoading = false;
        })
        .catch(function (error) {
          this.isLoading = false;
        });
    },
  },
  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            if (this.timer > 0) {
              this.timer--;
            }
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$emit(
      "setModalTitle",
      this.getTranslatedTitleForComponent("verifyPhone"),
    );
  },
  beforeMount: function () {
    this.timer = 0;
  },
  activated() {
    this.$emit(
      "setModalTitle",
      this.getTranslatedTitleForComponent("verifyPhone"),
    );
  },
};
</script>

<style scoped>

</style>