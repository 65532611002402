<template>
  <div class="col-lg-6 col-12 offset-lg-3 p-4 offset-md-0 d-flex flex-wrap mt-2 lounge-persons-container"
       v-if="selectedLounge">
    <div class="col-12 text-center">
      <h3>{{ getTranslation('module_shop_component_lounges_persons_title', 'Okay, los gehts...') }}</h3>
      <h4>{{ getTranslation('module_shop_component_lounges_persons_subtitle', 'Wie viele Personen seid ihr?') }}</h4>
      <div class="persons-picker mt-2 d-flex justify-content-center">
        <div class="minus disable-dbl-tap-zoom"
             :class="persons > 1 ? 'minusable':''"
             @click="cartStore.decrementPersonsCount(lounge)"
        >
          <svg width="14px" height="14px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z"
                fill="currentColor" fill-rule="evenodd"/>
          </svg>
        </div>

        <div class="value">
          <input disabled class="text-center person-count" :value="persons">
        </div>

        <div class="plus disable-dbl-tap-zoom"
             :class="persons < selectedLounge.available_places ? 'plusable':''"
             @click="cartStore.incrementPersonsCount()"
        >
          <svg width="14px" height="14px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m44.969 14.441h10.062c2.3984 0 4.3594 1.9609 4.3594 4.3594v21.805h21.805c2.3984 0 4.3594 1.9609 4.3594 4.3594v10.062c0 2.3984-1.9609 4.3594-4.3594 4.3594l-21.805 0.003906v21.805c0 2.3984-1.9609 4.3594-4.3594 4.3594l-10.062 0.003906c-2.3984 0-4.3594-1.9609-4.3594-4.3594l-0.003906-21.809h-21.801c-2.3984 0-4.3594-1.9609-4.3594-4.3594v-10.062c0-2.3984 1.9609-4.3594 4.3594-4.3594h21.805l-0.003906-21.805c0.003906-2.3984 1.9648-4.3633 4.3633-4.3633z"
                fill="currentColor" fill-rule="evenodd"/>
          </svg>
        </div>
      </div>
    </div>

    <div class="row times mt-4 text-center" v-if="getButtonTimes && !config.lounges?.latest_arrival">
      <h4>{{
          getTranslation('module_shop_component_lounges_persons_subtitle2', 'Und wann plant ihr ca. zu kommen?')
        }}</h4>
      <div class="col-4 mx-0 px-0" v-for="time in getButtonTimes">
        <div class="time-btn m-2" @click="changeTime(time)" :class="arriveTime === time ? 'active':''">
          <span>{{ time }}</span>
          <!--          <br>-->
          <!--          <small>Uhr</small>-->

        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap w-100 justify-content-center mt-4">

      <button class="btn btn-greensuccess mt-4 disable-dbl-tap-zoom" @click="next">
        {{ getTranslation('module_shop_component_lounges_persons_confirm_button_text', 'Perfekt, weiter gehts') }}
      </button>

    </div>

  </div>


</template>


<script>
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../../store/cart_store";
import { useDataStore } from "../../../../store/data_store";

export default {
  name: "persons",
  emits: ["changeComponent", "setModalTitle"],
  data() {
    return {};
  },
  props: ["params"],
  inject: ["getTranslation"],
  mounted() {
    if (this.config.lounges?.latest_arrival) {
      this.changeTime(this.config.lounges?.latest_arrival);
    }
  },
  computed: {
    ...mapStores(useDataStore, useCartStore),
    ...mapState(useCartStore, [
      "persons",
      "arriveTime",
      "selectedLounge",
      "selectedPackages",
    ]),
    ...mapState(useDataStore, ["event", "config"]),
    getButtonTimes() {
      let eventStart = null;
      let eventEnd = null;
      if (this.event.start) {
        eventStart = moment(this.event.start);
        eventEnd = moment(this.event.end);
      } else if (this.event.start_time) {
        eventStart = moment(this.event.start_time);
        eventEnd = moment(this.event.end_time);
      } else {
        return;
      }

      const diff = (eventEnd.unix() - eventStart.unix()) / 60;

      let interval = 30; // minutes
      if (interval * 9 > diff) {
        interval = diff / 9;
      }

      const times = [];

      times.push(eventStart.format("HH:mm"));

      for (let i = 0; i < 8; i++) {
        eventStart = eventStart.add(interval, "minute");
        times.push(eventStart.format("HH:mm"));
      }

      return times;
    },
  },
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    changeComponent: function (component) {
      this.$emit("changeComponent", {
        module: "Shop",
        component: "lounges",
        params: {
          activeComponent: component,
        },
      });
    },
    gotoCheckout() {
      this.$emit("changeComponent", {
        module: "Shop",
        component: "checkout",
        params: {},
      });
    },
    incrementPersonsCount: function () {
      this.cartStore.incrementPersonsCount();
    },
    decrementPersonsCount: function () {
      this.cartStore.decrementPersonsCount();
    },
    changeTime: function (time) {
      this.cartStore.setArriveTime(time);
    },
    next: function () {
      if (this.params?.reservationRequest) {
        this.changeComponent("reservationRequest");
        return;
      }
      const downPaymentPackage = this.selectedLounge?.packages?.find(
        (pkg) => pkg.type === "down_payment",
      );
      if (downPaymentPackage) {
        if (this.selectedPackages.length === 0) {
          this.cartStore.addPackage(downPaymentPackage.id).then(() => {
            return document.body.dispatchEvent(
              new CustomEvent("EVENT_NAME_ADDED_TO_CART", {
                detail: {
                  id: downPaymentPackage.id,
                  name: downPaymentPackage.name,
                  price: downPaymentPackage.price,
                  amount: 1,
                  type: "package",
                },
              }),
            );
          });
        }
        this.gotoCheckout();
        return;
      }

      this.changeComponent("listPackages");
    },
    setArriveTime: function () {
      let eventStart = null;
      if (this.event.start) {
        eventStart = moment(this.event.start);
      } else if (this.event.start_time) {
        eventStart = moment(this.event.start_time);
      } else {
        return;
      }
      const start_time = eventStart.format("HH:mm");
      this.cartStore.setArriveTime(start_time);
    },
  },
  activated() {
    if (this.cartStore.arriveTime === null) {
      this.setArriveTime();
    }
    this.$emit("setModalTitle", this.selectedLounge.name);
  },
  beforeMount() {
    this.setArriveTime();
    this.$emit("setModalTitle", this.selectedLounge.name);
  },
};
</script>


<style scoped>

</style>