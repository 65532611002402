<template>

  <div class="h-100 animate__animated animate__fadeIn">
    <keep-alive>
      <component
          :is="childComponent"
          :type="type"
          :params="params"
          @startLoungeBookingFromEvent="startLoungeBookingFromEvent"
          @startReservationRequestFromEvent="startReservationRequestFromEvent"
          @changeComponent="changeComponent"
          @setModalTitle="setModalTitle"
      ></component>
    </keep-alive>

    <keep-alive>
      <shopFooter v-if="isFooterVisible"
                   @changeComponent="changeComponent"
                   :type="type"
                   :params="params"
                   class="animate__animated animate__fadeIn animate__fast"
      ></shopFooter>
    </keep-alive>
  </div>

</template>


<script>
import "animate.css";

import checkout from "../components/checkout";
import drinks from "../components/drinks";
import events from "../components/events";
import footer from "../components/footer";
import lounges from "../components/lounges";
import paymentCompleted from "../components/payment/paymentCompleted";
import tickets from "../components/tickets";

import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../store/auth_store";
import { useDataStore } from "../../store/data_store";
import { useTranslationStore } from "../../store/translation_store";

let components;

try {
  components = require("../../data/child_strings");
} catch (e) {
  components = require("../../data/default_strings");
}
components = components.default;

export default {
  name: "Shop",
  components: {
    events,
    tickets,
    lounges,
    drinks,
    checkout,
    paymentCompleted,
    shopFooter: footer,
  },
  emits: ["changeComponent", "setModalTitle"],
  props: ["type", "childComponent", "params"],
  data() {
    return {
      bookingFromEvents: null,
      components: components,
    };
  },
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  computed: {
    ...mapStores(useAuthStore, useDataStore),
    ...mapState(useDataStore, ["settings"]),
    isFooterVisible: function () {
      return !!(
        this.childComponent === "tickets" ||
        (this.childComponent === "lounges" &&
          this.params.activeComponent &&
          this.params.activeComponent === "listPackages") ||
        (this.childComponent === "drinks" &&
          this.params.activeComponent === "listDrinks")
      );
    },
  },
  methods: {
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
    changeComponent(payload) {
      this.$emit("changeComponent", {
        module: {
          new: payload.module,
          old: "Shop",
        },
        component: {
          new: payload.component,
          old: this.childComponent,
        },
        title: {
          new:
            payload?.params && this.components[payload.params.activeComponent]
              ? this.getTranslatedTitleForComponent(
                  payload.params.activeComponent,
                )
              : payload.params.childComponent
                ? this.getTranslatedTitleForComponent(
                    payload.params.childComponent,
                  )
                : this.getTranslatedTitleForComponent(this.childComponent),
          old:
            this.params && this.components[this.params.activeComponent]
              ? this.getTranslatedTitleForComponent(this.params.activeComponent)
              : this.getTranslatedTitleForComponent(this.childComponent),
        },
        params: {
          new: payload.params,
          old: this.params,
        },
      });
    },
    startLoungeBookingFromEvent(id) {
      this.dataStore.loadData(id).then(() => {
        this.$emit("changeComponent", {
          module: {
            new: "Shop",
            old: "Shop",
          },
          component: {
            new: "lounges",
            old: this.childComponent,
          },
          title: {
            new: this.getTranslatedTitleForComponent("lounges"),
            old: this.getTranslatedTitleForComponent(this.childComponent),
          },
          params: {
            new: {
              activeComponent: "persons",
            },
            old: this.params,
          },
        });
      });
    },
    startReservationRequestFromEvent(payload) {
      this.dataStore.loadData(payload.event).then(() => {
        this.$emit("changeComponent", {
          module: {
            new: "Shop",
            old: "Shop",
          },
          component: {
            new: "lounges",
            old: this.childComponent,
          },
          title: {
            new: this.getTranslatedTitleForComponent("lounges"),
            old: this.getTranslatedTitleForComponent(this.childComponent),
          },
          params: {
            new: {
              activeComponent: "persons",
              reservationRequest: true,
            },
            old: this.params,
          },
        });
      });
    },
  },
};
</script>


<style scoped>

</style>