<template>

  <div>
    <ul id="progressbar" class="ps-0" v-if="params.step > 0">
      <li :class="params.step >= 0 ? 'active' : ''" id="account"><strong>{{ getTranslation('module_shop_component_register_step1_name', 'Account') }}</strong>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      </li>
      <li :class="params.step >= 1 ? 'active' : ''" id="personal"><strong>{{ getTranslation('module_shop_component_register_step2_name', 'Über dich') }}</strong><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></li>
      <li :class="params.step >= 2 ? 'active' : ''" id="image"><strong>{{ getTranslation('module_shop_component_register_step3_name', 'Profilbild') }}</strong><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg></li>
      <li :class="params.step >= 3 ? 'active' : ''" id="confirm"><strong>{{ getTranslation('module_shop_component_register_step4_name', 'Abschluss') }}</strong><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></li>
    </ul>
    <div class="p-4 animate__animated animate__fadeIn animate__fast auth-form">


      <Form @submit="nextStep" :validation-schema="schemas[params.step]" id="register-form" v-slot="{ errors }" :initialValues="initialValues">


        <KeepAlive>
          <component :is="currentComponent" :ref="currentComponent" :errors="errors"></component>
        </KeepAlive>

        <div class="d-flex flex-wrap justify-content-between register-step-buttons " >


            <button type="button" class="btn-default btn-small btn-border text-center px-4" v-if="params.step !== 0" @click="lastStep" data-umami-event="register-back-button" :data-umami-event-step="params.step">
              <span>{{ getTranslation('back_button_text', 'Zurück') }}</span>
            </button>
            <div v-if="params.step === 0"></div>
            <button type="button" class="btn-default btn-small btn-border text-center px-4" v-if="params.step === 2" @click="nextStep" data-umami-event="register-skip-button" :data-umami-event-step="params.step">
              <span>{{ getTranslation('skip_button_text', 'Überspringen') }}</span>
            </button>
            <button type="submit" class="btn btn-primary text-center px-4" :data-umami-event="params.step === 3 ? 'register-submit-button' : 'register-next-button'" :data-umami-event-step="params.step">
              <span v-if="!isLoading">{{ params.step === 3 ? getTranslation('module_shop_component_register_submit_button_text', 'Registrieren'):getTranslation('module_shop_next_button_text', 'Weiter') }}</span>
              <span v-else class="spinner-border spinner-border-sm"></span>
            </button>
        </div>

      </Form>

    </div>
  </div>

</template>


<script>
import { Form } from "vee-validate";
import { toRaw } from "vue";

import step1 from "./step1";
import step2 from "./step2";
import step3 from "./step3";
import step4 from "./step4";

import * as yup from "yup";

import Cookies from "js-cookie";
import Swal from "sweetalert2";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";

let components;

try {
  components = require("../../../../data/child_strings");
} catch (e) {
  components = require("../../../../data/default_strings");
}
components = components.default;

let target_settings;
try {
  target_settings = require("../../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "Register",
  components: {
    Form,
    step1,
    step2,
    step3,
    step4,
  },
  props: ["params"],
  inject: ["getTranslation"],
  emits: ["changeComponent"],
  data() {
    const registerSteps = ["step1", "step2", "step3", "step4"];
    // NEU
    const password_message = this.getTranslation(
      "error_password_missing",
      "Passwort wird benötigt",
    );
    const password_message_2short = this.getTranslation(
      "error_password_2short",
      "Das Passwort muss mind. 6 Zeichen lang sein.",
    );
    const password_dont_matches = this.getTranslation(
      "error_password_mismatch",
      "Die Passwörter stimmen nicht überein.",
    );
    const password_length = this.getTranslation(
      "error_password_length",
      "Mindestens 8 Zeichen",
    );
    const password_spec_char = this.getTranslation(
      "error_password_spec_char",
      "Mindestens eins dieser Sonderzeichen !#$%^&*()+-=[]{}§~;':|,.<>/?",
    );
    const password_number = this.getTranslation(
      "error_password_number",
      "Mindestens eine Zahl",
    );
    const password_lowercase = this.getTranslation(
      "error_password_lowercase",
      "Mindestens ein Kleinbuchstabe",
    );
    const password_uppercase = this.getTranslation(
      "error_password_uppercase",
      "Mindestens ein Großbuchstabe",
    );
    const specialCharRegex = /[!#$%^&*()+\-=\[\]{}§~;':|,.<>\/?]+/;
    const numberRegex = /[0-9]+/;
    const lowercaseRegex = /[a-z]+/;
    const uppercaseRegex = /[A-Z]+/;
    // NEU ENDE

    const schemas = [
      {
        username: yup
          .string()
          .required(
            this.getTranslation(
              "error_username_missing",
              "Username wird benötigt",
            ),
          )
          .trim()
          .min(
            3,
            this.getTranslation(
              "error_username_2short",
              "Dein Benutzername muss mind. 3 Zeichen lang sein.",
            ),
          ),
        email: yup
          .string()
          .required(
            this.getTranslation("error_email_missing", "E-Mail wird benötigt"),
          )
          .trim()
          .email(
            this.getTranslation(
              "error_email_invalid",
              "Das ist keine gültige E-Mail Adresse.",
            ),
          ),
        // NEU
        password: (value, data) => {
          if (value === undefined || value === "") {
            return password_message;
          }
          if (!specialCharRegex.test(value)) {
            return password_spec_char;
          }
          if (!numberRegex.test(value)) {
            return password_number;
          }
          if (!lowercaseRegex.test(value)) {
            return password_lowercase;
          }
          if (!uppercaseRegex.test(value)) {
            return password_uppercase;
          }
          if (value.length < 8) {
            return password_length;
          }
          return true;
        },
        // NEU ENDE
        confirmPassword: (value, data) => {
          if (value === undefined || value === "") {
            return password_message;
          }
          data = toRaw(data.form);
          if (value !== data.password) {
            return password_dont_matches;
          }
          return true;
        },
      },
      {
        firstname: yup
          .string()
          .required(
            this.getTranslation(
              "error_firstname_missing",
              "Vorname wird benötigt",
            ),
          )
          .trim(),
        lastname: yup
          .string()
          .required(
            this.getTranslation(
              "error_lastname_missing",
              "Nachname wird benötigt",
            ),
          )
          .trim(),
        birthday: (value) => {
          if (value === undefined || value === "") {
            return this.getTranslation(
              "error_birthday_missing",
              "Geburtsdatum wird benötigt",
            );
          }

          const date = new Date(value);
          const now = new Date();

          if (!date) {
            return this.getTranslation(
              "error_birthday_missing",
              "Geburtsdatum wird benötigt",
            );
          }

          if (now < date) {
            return this.getTranslation(
              "error_birthday_in_future",
              "Dein Geburtsdatum kann nicht in der Zukunft liegen.",
            );
          }

          if (date < new Date("1900-01-01")) {
            return this.getTranslation(
              "error_birthday_invalid",
              "Bitte gib ein valides Geburtsdatum an",
            );
          }

          // NEU
          const age = now.getFullYear() - date.getFullYear();
          if (
            age > 16 ||
            (age === 16 && now.getMonth() > now.getMonth()) ||
            (age === 16 &&
              now.getMonth() === now.getMonth() &&
              now.getDate() >= now.getDate())
          ) {
            return true;
          } else {
            return this.getTranslation(
              "error_birthday_age_condition",
              "Du musst mindestens 16 Jahre alt sein.",
            );
          }
          // NEU ENDE

          return true;
        },
      },
      {
        // profile_image: yup.mixed().test("fileSize", "Das Foto ist leider zu groß", (value) => {
        //   if (!value.length) return true // attachment is optional
        //   return value[0].size <= 2000000
        // })
      },
      {
        privacy: (value) => {
          if (!value) {
            return this.getTranslation(
              "error_privacy_missing",
              "Bitte akzeptiere die Datenschutzerklärung.",
            );
          }
          return true;
        },
        // NEU
        street: yup
          .string()
          .required(
            this.getTranslation("error_street_missing", "Straße wird benötigt"),
          )
          .trim(),
        zip: yup
          .string()
          .required(
            this.getTranslation(
              "error_zip_missing",
              "Postleitzahl wird benötigt",
            ),
          )
          .trim(),
        place: yup
          .string()
          .required(
            this.getTranslation("error_place_missing", "Stadt wird benötigt"),
          )
          .trim(),
        // NEU ENDE
      },
    ];

    if (
      this.target_settings &&
      (this.target_settings.register.addressRequired ||
        target_settings?.register?.addressRequired === undefined)
    ) {
      schemas[3] = {
        ...schemas[3],
        street: yup
          .string()
          .required(
            this.getTranslation("error_street_missing", "Straße wird benötigt"),
          )
          .trim(),
        zip: yup
          .string()
          .required(
            this.getTranslation(
              "error_zip_missing",
              "Postleitzahl wird benötigt",
            ),
          )
          .trim(),
        place: yup
          .string()
          .required(
            this.getTranslation("error_place_missing", "Stadt wird benötigt"),
          )
          .trim(),
      };
    }

    let initialValues = {
      gender: "männlich",
    };
    let fb = Cookies.get("facebook_login");
    if (fb) {
      fb = JSON.parse(fb);
      initialValues = {
        gender: "männlich",
        firstname: fb.firstname,
        lastname: fb.lastname,
        email: fb.email,
      };
      Cookies.remove("facebook_login", { path: "/" });
    }
    let apple = Cookies.get("apple_login");
    if (apple) {
      apple = JSON.parse(apple);
      initialValues = {
        gender: "männlich",
        email: apple.email,
      };
      Cookies.remove("apple_login", { path: "/" });
    }

    return {
      schemas,
      initialValues,
      fbUserId: fb ? fb.id : null,
      isLoading: false,
      steps: registerSteps,
      cropperCanvas: null,
      fileName: "uploadedFile",
    };
  },
  computed: {
    ...mapStores(useAuthStore),
    currentComponent() {
      return this.steps[this.params.step];
    },
  },
  methods: {
    submitData(values) {
      this.isLoading = true;

      values["guest"] = this.guest;

      if (this.fbUserId) {
        values["fbUserID"] = this.fbUserId;
      }

      values["birthday"] = moment(values["birthday"]).toISOString(true);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (this.cropperCanvas.canvas && this.fileName != "uploadedFile") {
        this.cropperCanvas.canvas.toBlob((blob) => {
          formData.append(
            "file",
            new File([blob], this.fileName, { type: "image/jpeg" }),
            this.fileName,
          );

          this.authStore
            .register(formData)
            .then(() => {
              this.changeComponent("verify", "Auth", {
                registerSuccessful: true,
              });
              this.isLoading = false;
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                title: this.getTranslation(
                  "error_register_failed",
                  "Registrierung fehlgeschlagen",
                ),
                icon: "error",
                text: error.message,
              });
              this.isLoading = false;
            });
        });
      } else {
        this.authStore
          .register(formData)
          .then(() => {
            this.changeComponent("verify", "Auth", {
              registerSuccessful: true,
            });
            this.isLoading = false;
          })
          .catch((error) => {
            Swal.fire({
              title: this.getTranslation(
                "error_register_failed",
                "Registrierung fehlgeschlagen",
              ),
              icon: "error",
              text: error.message,
            });
            this.isLoading = false;
          });
      }
    },
    changeComponent(component, module = "Auth", params = {}) {
      this.$emit("changeComponent", {
        component: component,
        module: module,
        params: params,
      });
    },
    nextStep(values) {
      new Promise((resolve) => {
        if (this.params.step === 2) {
          this.cropperCanvas = this.$refs["step3"].$refs.cropper.getResult();
          this.fileName = this.$refs["step3"].fileName;
        }
        resolve();
      }).then(() => {
        if (this.params.step < 3) {
          const nextStep = this.params.step + 1;
          this.changeComponent("register", "Auth", { step: nextStep });
        } else {
          this.submitData(values);
        }
      });
    },
    lastStep() {
      if (this.params.step >= 1) {
        const lastStep = this.params.step - 1;
        this.changeComponent("register", "Auth", { step: lastStep });
      }
    },
  },
};
</script>

<style scoped>
/* purgecss start ignore */
.register-step-buttons {
  margin: 20px 0px;
}
#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}


#progressbar .active {
    color: green;
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
}

#progressbar #account:before {
    content: ""
}

#progressbar #personal:before {
    content: ""
}

#progressbar #image:before {
    content: ""
}

#progressbar #confirm:before {
    content: ""
}

#progressbar li svg {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  color: white;
  margin: 0 auto 10px auto;
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #673AB7
}


@media only screen and (max-width: 767px) {
 #progressbar {
     margin-bottom: 0px;
 }
  #progressbar li:before {
      width: 40px;
      height: 40px;
      line-height: 35px;
      font-size: 18px;
  }
  #progressbar li svg {
   top:10px;
   width:19px;
   height:19px; 
  }
  #progressbar li:after {
    top: 19px;
  }
}

/* purgecss end ignore */
</style>