<script lang="js" setup>
import { toTypedSchema } from "@vee-validate/zod";
import { storeToRefs } from "pinia";
import { useFieldError, useIsFieldTouched } from "vee-validate";
import { useForm } from "vee-validate";
import { useField } from "vee-validate";
import { computed, watch } from "vue";
import { useTranslation } from "../../composables/useTranslation";
import { useCheckoutFields } from "../../features/checkout/checkout.config";
import { useCheckoutSchema } from "../../features/checkout/checkout.schemas";
import { useCheckoutStore } from "../../features/checkout/checkout.store";
import { countries } from "../../../data/static/countries";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import Input from "../ui/input/Input.vue";

const {
  toggleShowGuest,
  setValidationErrors,
  setValidateForm,
  setCheckoutData,
} = useCheckoutStore();
const { paymentMethod, isGuestEnabled, isShowGuest, checkoutData } = storeToRefs(
  useCheckoutStore(),
);
const fields = useCheckoutFields();
const schema = useCheckoutSchema();

const { errors, validate, values, setValues } = useForm({
  validationSchema: computed(() => toTypedSchema(schema.value)),
});

if (checkoutData.value) {
  setValues(checkoutData.value);
}

watch(values, (newVal) => {
  setCheckoutData(newVal);
});

setValidateForm(validate);

// Pass validation errors to the store
watch(
  errors,
  (newVal) => {
    setValidationErrors(newVal);
  },
  {
    immediate: true,
  },
);

const shouldShowField = (fieldName) => {
  return (
    !!fields.value[fieldName] ||
    (paymentMethod.value === "sofort" && fieldName === "phone")
  );
};

const getField = (fieldName) => {
  return fields.value[fieldName];
};

const getFieldLabel = (fieldName) => {
  return useTranslation(`checkout_${fieldName}_label`);
};

const isBirthdayTouched = useIsFieldTouched("birthday");
const birthdayError = useFieldError("birthday");
const genderError = useFieldError("gender");

const { value: privacyAccepted, errorMessage: privacyAcceptedError } =
  useField("privacy_accepted");
</script>

<template>
  <form>
    <!-- Gender Radio Group -->
    <div v-if="shouldShowField('gender')" class="d-flex flex-wrap col-12 px-md-2 text-left form-group">
      <div class="form-check form-check-inline ps-0 pe-3" v-for="option in getField('gender').options" :key="option.value">
        <FormField as="input" class="form-check-input" type="radio" name="gender" :id="option"
          :value="option" />
        <label class="form-check-label" :for="option">{{ getFieldLabel(`gender_${option}`) }}</label>
      </div>
      <small v-if="genderError" class="col-12 text-danger">{{ errors?.gender }}</small>
    </div>

    <!-- Name Fields -->
    <div class="d-flex flex-wrap pt-2">
      <div v-if="shouldShowField('firstname')" class="col-lg-6 col-12">
        <FormField v-slot="{ componentField }" name="firstname">
          <FormItem>
            <FormControl>
                <Input type="text" v-bind="componentField" :placeholder="getFieldLabel('firstname')"
                  autocomplete="given-name" />
                <FormLabel>{{ getFieldLabel('firstname') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
      <div v-if="shouldShowField('lastname')" class="col-lg-6 col-12">
        <FormField v-slot="{ componentField }" name="lastname">
          <FormItem>
            <FormControl>
                <Input type="text" v-bind="componentField" :placeholder="getFieldLabel('lastname')"
                  autocomplete="family-name" />
                <FormLabel>{{ getFieldLabel('lastname') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
        </FormField>
      </div>
    </div>

    <!-- Other Fields -->
    <div class="d-flex flex-wrap">
      <!-- Birthday field -->
      <div v-if="shouldShowField('birthday')" class="col-12">
        <FormField v-slot="{ componentField }" name="birthday">
            <FormItem>
              <FormControl>
                <Input
                  type="date"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('birthday')"
                  autocomplete="bday"
                  :class="{ 'is-valid': !birthdayError && isBirthdayTouched }"
                  required
                />
                <FormLabel>{{ getFieldLabel('birthday') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
      </div>

      <!-- Country field -->
      <div v-if="shouldShowField('country')" class="col-12">
        <FormField v-slot="{ componentField }" name="country">
            <FormItem>
              <FormControl>
                <select
                  class="form-select"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('country')"
                  autocomplete="country"
                  required
                >
                  <option value="" disabled selected>{{ getFieldLabel('country') }}</option>
                  <option v-for="country in countries" :key="country.alpha2" :value="country.alpha2">
                    {{ country.name }}
                  </option>
                </select>
                <FormLabel>{{ getFieldLabel('country') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
        </FormField>
      </div>

      <!-- ZIP and Place in one row -->
      <div class="d-flex flex-wrap w-100">
        <div v-if="shouldShowField('zip')" class="col-lg-6 col-12">
          <FormField v-slot="{ componentField }" name="zip">
              <FormItem>
                <FormControl>
                  <Input
                    type="text"
                    v-bind="componentField"
                    :placeholder="getFieldLabel('zip')"
                    autocomplete="postal-code"
                  />
                  <FormLabel>{{ getFieldLabel('zip') }}</FormLabel>
                  <FormMessage />
                </FormControl>
              </FormItem>
            </FormField>
        </div>

        <div v-if="shouldShowField('place')" class="col-lg-6 col-12">
            <FormField v-slot="{ componentField }" name="place">
              <FormItem>
                <FormControl>
                  <Input
                    type="text"
                    v-bind="componentField"
                    :placeholder="getFieldLabel('place')"
                    autocomplete="address-level2"
                  />
                  <FormLabel>{{ getFieldLabel('place') }}</FormLabel>
                  <FormMessage />
                </FormControl>
              </FormItem>
          </FormField>
        </div>
      </div>

      <!-- Street field -->
      <div v-if="shouldShowField('street')" class="col-12">
        <FormField v-slot="{ componentField }" name="street">
            <FormItem>
              <FormControl>
                <Input
                  type="text"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('street')"
                  autocomplete="street-address"
                />
                <FormLabel>{{ getFieldLabel('street') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
        </FormField>
      </div>

      <div v-if="shouldShowField('email')" class="col-12">
          <FormField v-slot="{ componentField }" name="email">
            <FormItem>
              <FormControl>
                <Input
                  type="email"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('email')"
                  autocomplete="email"
                />
                <FormLabel>{{ getFieldLabel('email') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
        <div v-if="shouldShowField('phone')" class="col-12">
          <FormField v-slot="{ componentField }" name="phone">
            <FormItem>
              <FormControl>
                <Input
                  type="tel"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('phone')"
                  autocomplete="tel"
                />
                <FormLabel>{{ getFieldLabel('phone') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
    </div>

    <!-- Account Creation (only shown if guest checkout is allowed) -->
    <div v-if="isGuestEnabled" class="d-flex justify-content-center align-items-center">
      <FormField v-slot="{ field }" name="is_registration">
        <FormItem>
          <FormControl>
            <div class="form-group mb-0">
              <div class="w-100 p-0">
                <input class="m-0 p-0" type="checkbox" v-bind="field" style="height: 24px;" id="is_registration" :value="isGuestEnabled && isShowGuest"
                  @change="toggleShowGuest()" />
                <label for="is_registration">
                  {{ getFieldLabel('is_registration') }}
                </label>
                <FormMessage />
              </div>
            </div>
          </FormControl>
        </FormItem>
      </FormField>
    </div>

    <!-- Password Field (shown by default if !allowGuest, or when registerNew is checked) -->
    <div v-if="shouldShowField('password')" class="col-12">
      <FormField v-slot="{ componentField }" name="password">
        <FormItem>
          <FormControl>
          <Input type="password" v-bind="componentField" :placeholder="getFieldLabel('password')"
            autocomplete="new-password" />
          <FormLabel>{{ getFieldLabel('password') }}</FormLabel>
            <FormMessage />
          </FormControl>
        </FormItem>
      </FormField>
    </div>

    <!-- Privacy Accepted Checkbox -->
    <div class="form-check">
      <input
        id="privacyAccepted"
        class="form-check-input"
        type="checkbox"
        v-model="privacyAccepted"
      />
      <label class="form-check-label" for="privacyAccepted">
        {{ getFieldLabel('privacy_accepted') }}
      </label>
      <small v-if="privacyAcceptedError" class="text-danger"><br />{{ privacyAcceptedError }}</small>
    </div>
  </form>
</template>

<style scoped>
.form-field {
  margin-bottom: 1rem;
}

.col-lg-6:not(:last-child) {
  padding-right: 1rem;
}

@media (max-width: 991.98px) {
  .col-lg-6:not(:last-child) {
    padding-right: 0;
  }
}

.form-check-input {
  margin-right: 0.5rem;
}

.text-danger {
  color: var(--color-danger);
}

.is-valid {
  border-color: var(--color-success);
}
</style>
