<template>

  <div class="d-flex flex-wrap" id="UserDetails">

    <div class="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1 offset-0">
      <div class="d-flex w-100 justify-content-center mt-2">
        <div class="user-image" @click="openCropper" :style="userImageBackground">
          <div class="image-edit-btn">
            <svg width="24px" height="24px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <g fill="#fff">
                <path
                  d="m98.578 10.621c-0.89844-1.9414-2.1914-3.7617-3.7383-5.3281-1.5508-1.5586-3.3594-2.8789-5.3086-3.8008-4.9883-2.3594-10.84-2.2188-15.512 2.5312l-4.8281 4.9219-5.1797 5.2695-42.473 43.207-0.070312 0.070313v0.011718l-0.023438 0.023438v0.011718l-0.13672 0.13672v0.011719l-0.023438 0.023438v0.011718l-0.070312 0.070313-0.023438 0.039062-0.011718 0.011719-0.023438 0.039062v0.023438l-0.019531 0.03125v0.011719l-0.011719 0.011719-0.023438 0.039062h0.046876l-0.011719 0.019531-0.011719 0.011719-0.023438 0.039062v0.011719l-0.011718 0.03125v0.023438l-0.023438 0.039062v0.011719l-0.023437 0.039062v0.20703l-7.4297 27.102c-0.28125 1.0391 0.32812 2.1211 1.3789 2.3984 0.37109 0.10156 0.73828 0.089844 1.0898-0.019532v0.011719l26.762-8.2383c0.37109-0.10938 0.67969-0.32031 0.91016-0.58984l42.359-43.102 0.011719-0.011719 5.1719-5.2695 4.8281-4.9219c4.5859-4.4844 4.7461-10.215 2.4531-15.172zm-74.488 70.719-4.1797-4.1016 4.0312-14.699 14.578 14.32zm18.129-6.3398-16.5-16.219 39.719-40.41 16.5 16.219zm42.461-43.211-14.711-14.461-1.8008-1.7617 2.4297-2.4688 16.5 16.219zm8.6797-8.8281-0.039063 0.039062-3.4609 3.5195-16.508-16.219 3.4609-3.5195c3.3008-3.3594 7.4688-3.4297 11.059-1.7383 1.5312 0.71875 2.9609 1.7617 4.1914 3s2.25 2.6797 2.9609 4.2188c1.6367 3.5586 1.5469 7.6172-1.6641 10.699z" />
                <path
                  d="m82.84 92.25c0 0.46094-0.19141 0.89062-0.5 1.1992-0.30859 0.30859-0.73047 0.5-1.1992 0.5h-73.41c-0.46094 0-0.89062-0.19141-1.1992-0.5-0.30859-0.30859-0.5-0.73047-0.5-1.1992v-73.422c0-0.46094 0.19141-0.89062 0.5-1.1992s0.73047-0.5 1.1992-0.5h39.711l5.6797-6.0586h-45.391c-2.1406 0-4.0703 0.87109-5.4805 2.2812-1.3984 1.3984-2.2812 3.3398-2.2812 5.4805v73.422c0 2.1406 0.87109 4.0703 2.2812 5.4805 1.3984 1.3984 3.3398 2.2695 5.4805 2.2695h73.422c2.1406 0 4.0703-0.87109 5.4805-2.2695 1.3984-1.3984 2.2812-3.3398 2.2812-5.4805l-0.003906-44.375-6.0586 6.1992v38.172z" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex w-100 justify-content-center mt-2">
      <h2 class="m-0">{{ userStore.userInfos.username }}</h2>
    </div>

    <div class="d-flex w-100 justify-content-center mb-2">
      <small>{{ getTranslation('settings_visited_events', 'Events besucht:') }} {{
        userStore.userInfos.amount_checkins
        }}</small>
    </div>

    <div
      class="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1 offset-0 d-flex flex-wrap mt-2 justify-content-center">

      <Form @submit="submitData" :validation-schema="schema" :initial-values="userStore.userInfos" id="user-info-form"
        v-slot="{ errors }">

        <div class="d-flex flex-wrap pt-2">

          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" :placeholder="getTranslation('firstname_label', 'Vorname')" name="firstname"
                class="form-control" id="firstname" :class="errors.firstname ? 'is-invalid' : ''" />
              <label for="firstname">{{ getTranslation('firstname_label', 'Vorname') }}</label>
              <small class="text-danger" v-if="errors.firstname">{{ errors.firstname }}</small>
            </div>
          </div>


          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" :placeholder="getTranslation('lastname_label', 'Nachname')" name="lastname"
                class="form-control" id="lastname" :class="errors.lastname ? 'is-invalid' : ''" />
              <label for="lastname">{{ getTranslation('lastname_label', 'Nachname') }}</label>
              <small class="text-danger" v-if="errors.lastname">{{ errors.lastname }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap pt-2">

          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" placeholder="Username" name="username" class="form-control" id="username"
                :class="errors.username ? 'is-invalid' : ''" />
              <label for="username">Username</label>
              <small class="text-danger" v-if="errors.username">{{ errors.username }}</small>
            </div>
          </div>

          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="email" :placeholder="getTranslation('email_placeholder', 'E-Mail')" name="email"
                autocomplete="username" class="form-control" :class="errors.email ? 'is-invalid' : ''" />
              <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
              <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap pt-2">

          <div class="col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" :placeholder="getTranslation('street_placeholder', 'Straße & Hausnummer')"
                name="street" class="form-control" id="street" :class="errors.street ? 'is-invalid' : ''" />
              <label for="street">{{ getTranslation('street_label', 'Straße & Hausnummer') }}</label>
              <small class="text-danger" v-if="errors.street">{{ errors.street }}</small>
            </div>
          </div>

          <div class="col-md-4 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" :placeholder="getTranslation('zip_label', 'PLZ')" name="zip"
                class="form-control" id="zip" :class="errors.zip ? 'is-invalid' : ''" />
              <label for="zip">{{ getTranslation('zip_label', 'PLZ') }}</label>
              <small class="text-danger" v-if="errors.zip">{{ errors.zip }}</small>
            </div>
          </div>

          <div class="col-md-8 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="text" :placeholder="getTranslation('place_label', 'Stadt')" name="place"
                class="form-control" id="place" :class="errors.place ? 'is-invalid' : ''" />
              <label for="place">{{ getTranslation('place_label', 'Stadt') }}</label>
              <small class="text-danger" v-if="errors.place">{{ errors.place }}</small>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap pt-2">

          <div class=" col-12 px-2">
            <div class="form-floating form-group w-100">

              <div class="input-group">
                <Field name="phone" type="tel" :placeholder="getTranslation('phone_placeholder', 'Telefonnummer')"
                  :class="(!userStore.userInfos.phone || !userStore.userInfos.phone_verified) ? 'is-invalid' : ''"
                  class="form-control" disabled style="border-right: 0; background: none;" />
                <span class="phone-verified-status text-success px-2"
                  v-if="userStore.userInfos.phone && userStore.userInfos.phone_verified"><svg
                    xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-check-circle">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></span>


                <div class="input-group-append" style="height: 50px">
                  <button class="btn bg-white text-black bg-theme-gradient" type="button"
                    style="border-radius: var(--radius);border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding: 0 28px;height: 100%;"
                    @click="startVerify('verifyPhone')" data-umami-event="verify-phone-button">
                    <svg width="16px" height="16px" version="1.1" viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg">
                      <g fill="currentColor">
                        <path
                          d="m98.578 10.621c-0.89844-1.9414-2.1914-3.7617-3.7383-5.3281-1.5508-1.5586-3.3594-2.8789-5.3086-3.8008-4.9883-2.3594-10.84-2.2188-15.512 2.5312l-4.8281 4.9219-5.1797 5.2695-42.473 43.207-0.070312 0.070313v0.011718l-0.023438 0.023438v0.011718l-0.13672 0.13672v0.011719l-0.023438 0.023438v0.011718l-0.070312 0.070313-0.023438 0.039062-0.011718 0.011719-0.023438 0.039062v0.023438l-0.019531 0.03125v0.011719l-0.011719 0.011719-0.023438 0.039062h0.046876l-0.011719 0.019531-0.011719 0.011719-0.023438 0.039062v0.011719l-0.011718 0.03125v0.023438l-0.023438 0.039062v0.011719l-0.023437 0.039062v0.20703l-7.4297 27.102c-0.28125 1.0391 0.32812 2.1211 1.3789 2.3984 0.37109 0.10156 0.73828 0.089844 1.0898-0.019532v0.011719l26.762-8.2383c0.37109-0.10938 0.67969-0.32031 0.91016-0.58984l42.359-43.102 0.011719-0.011719 5.1719-5.2695 4.8281-4.9219c4.5859-4.4844 4.7461-10.215 2.4531-15.172zm-74.488 70.719-4.1797-4.1016 4.0312-14.699 14.578 14.32zm18.129-6.3398-16.5-16.219 39.719-40.41 16.5 16.219zm42.461-43.211-14.711-14.461-1.8008-1.7617 2.4297-2.4688 16.5 16.219zm8.6797-8.8281-0.039063 0.039062-3.4609 3.5195-16.508-16.219 3.4609-3.5195c3.3008-3.3594 7.4688-3.4297 11.059-1.7383 1.5312 0.71875 2.9609 1.7617 4.1914 3s2.25 2.6797 2.9609 4.2188c1.6367 3.5586 1.5469 7.6172-1.6641 10.699z" />
                        <path
                          d="m82.84 92.25c0 0.46094-0.19141 0.89062-0.5 1.1992-0.30859 0.30859-0.73047 0.5-1.1992 0.5h-73.41c-0.46094 0-0.89062-0.19141-1.1992-0.5-0.30859-0.30859-0.5-0.73047-0.5-1.1992v-73.422c0-0.46094 0.19141-0.89062 0.5-1.1992s0.73047-0.5 1.1992-0.5h39.711l5.6797-6.0586h-45.391c-2.1406 0-4.0703 0.87109-5.4805 2.2812-1.3984 1.3984-2.2812 3.3398-2.2812 5.4805v73.422c0 2.1406 0.87109 4.0703 2.2812 5.4805 1.3984 1.3984 3.3398 2.2695 5.4805 2.2695h73.422c2.1406 0 4.0703-0.87109 5.4805-2.2695 1.3984-1.3984 2.2812-3.3398 2.2812-5.4805l-0.003906-44.375-6.0586 6.1992v38.172z" />
                      </g>
                    </svg>

                  </button>
                </div>
              </div>

              <a @click="startVerify('verifyPhone')" v-if="!userStore.userInfos.phone_verified" href="#">
                <small>
                  {{ getTranslation('module_user_component_profile_verifyphone_button_text', 'Telefonnummer jetzt bestätigen') }}
                </small>
              </a>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap pt-2 pt-2">

          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="select" name="gender" id="gender" class="form-select"
                :class="errors.gender ? 'is-invalid' : ''">
                <option value="" disabled>{{ getTranslation('gender_label', 'Geschlecht') }}</option>
                <option v-for="gender in genders" :key="gender" :value="gender"
                  v-text="getTranslation('gender_' + gender, gender)"></option>
              </Field>
              <label for="gender">{{ getTranslation('gender_label', 'Geschlecht') }}</label>
              <small class="text-danger" v-if="errors.gender">
                {{ getTranslation('error_gender_missing', 'Bitte wähle ein Geschlecht') }}
              </small>
            </div>
          </div>

          <div class="col-md-6 col-12 px-2">
            <div class="form-floating form-group w-100">
              <Field as="input" id="birthday" :placeholder="getTranslation('birthday_placeholder', 'Geburtsdatum')"
                name="birthday" class="form-control" type="date" :class="errors.birthday ? 'is-invalid' : ''"></Field>
              <label for="birthday">{{ getTranslation('birthday_label', 'Geburtsdatum') }}</label>
              <small class="text-danger" v-if="errors.birthday">{{ errors.birthday }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap justify-content-center mt-4">
          <button type="submit" class="btn text-center px-4" :class="isSaved ? 'bg-success' : ''"
            :disabled="isSaved || isLoading">
            <span v-if="!isLoading && !isSaved">{{
              getTranslation('module_user_component_profile_save_button_text', 'Speichern')
              }}</span>
            <span v-else-if="isLoading && !isSaved" class="spinner-border spinner-border-sm"></span>
            <span v-else-if="isSaved"><i class="fa-solid fa-check"></i></span>
          </button>
        </div>

      </Form>

    </div>


  </div>

  <div class="modal animate__animated animate__fadeInUpBig animate__faster" id="cropper-modal" data-bs-backdrop="static"
    data-bs-keyboard="true" tabindex="-1" aria-hidden="true">

    <div class="modal-dialog modal-lg modal-fullscreen-md-down">
      <div class="modal-content">

        <div class="modal-header">
          <button type="button" class="btn-back" aria-label="Back" style="background: none;"><i
              class="feather-arrow-left fs-20"></i></button>
          <span class="h4 modal-title">{{
            getTranslation('module_user_component_profile_change_image_title', 'Profilbild bearbeiten')
            }}</span>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="background:none;"><i
              class="feather-x fs-20"></i></button>
        </div>

        <div class="modal-body p-4">
          <div class="upload">
            <cropper ref="cropper" class="upload-cropper" :src="image ? image : userImage" default-boundaries="fit"
              imageRestriction="stencil" :stencil-size="{
                width: 300,
                height: 300
              }" :stencil-props="{
                previewClass: 'upload-cropper__preview',
                movable: false,
                resizable: false,
                lines: {
                  north: false,
                  west: false,
                  south: false,
                  east: false,
                },
                handlers: {
                  eastNorth: false,
                  north: false,
                  westNorth: false,
                  west: false,
                  westSouth: false,
                  south: false,
                  eastSouth: false,
                  east: false,
                }
              }" :stencil-component="$options.components.CircleStencil" :canvas="{
                maxArea: 1096 * 1096
              }" />
            <div class="button-wrapper">
              <a class="button btn-default btn-small btn-border" @click="$refs.file.click()">
                <input type="file" ref="file" @change="loadImage($event)"
                  :accept="`image/png, image/gif, image/jpeg ${isSafari() ? ', image/heic, image/heif' : ''}`" />
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-image">
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                  <polyline points="21 15 16 10 5 21"></polyline>
                </svg>
                {{ getTranslation('image_label', 'Bild hochladen') }}
              </a>
            </div>

            <div class="button-wrapper">
              <a class="btn mb-4" @click="uploadImage">
                {{ getTranslation('module_user_component_profile_save_button_text', 'Speichern') }}
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { Field, Form } from "vee-validate";
import { CircleStencil, Cropper } from "vue-advanced-cropper";

import axios from "axios";
import { mapStores } from "pinia";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAuthStore } from "../../../../store/auth_store";
import { useUserStore } from "../../../../store/user_store";

export default {
  name: "UserDetails",
  components: {
    Form,
    Field,
    CircleStencil,
    Cropper,
  },
  inject: ["getTranslation"],
  emits: ["openVerify"],
  data() {
    return {
      schema: {
        firstname: yup
          .string()
          .required(
            this.getTranslation(
              "error_firstname_missing",
              "Vorname wird benötigt",
            ),
          )
          .trim(),
        lastname: yup
          .string()
          .required(
            this.getTranslation(
              "error_lastname_missing",
              "Nachname wird benötigt",
            ),
          )
          .trim(),
        username: yup
          .string()
          .required(
            this.getTranslation(
              "error_username_missing",
              "Username wird benötigt",
            ),
          )
          .trim()
          .min(3),
        email: yup
          .string()
          .required(
            this.getTranslation("error_email_missing", "E-Mail wird benötigt"),
          )
          .trim()
          .email(
            this.getTranslation(
              "error_email_invalid",
              "Das ist keine gültige E-Mail Adresse.",
            ),
          ),
        street: yup
          .string()
          .required(
            this.getTranslation("error_street_missing", "Straße wird benötigt"),
          )
          .trim(),
        zip: yup
          .string()
          .required(
            this.getTranslation(
              "error_zip_missing",
              "Postleitzahl wird benötigt",
            ),
          )
          .trim(),
        place: yup
          .string()
          .required(
            this.getTranslation("error_place_missing", "Stadt wird benötigt"),
          )
          .trim(),
        birthday: (value, data) => {
          if (value === undefined || value === "") {
            return this.getTranslation(
              "error_birthday_missing",
              "Geburtsdatum wird benötigt",
            );
          }

          const date = new Date(value);
          const now = new Date();

          if (!date) {
            return this.getTranslation(
              "error_birthday_missing",
              "Geburtsdatum wird benötigt",
            );
          }

          if (now < date) {
            return this.getTranslation(
              "error_birthday_in_future",
              "Dein Geburtsdatum kann nicht in der Zukunft liegen.",
            );
          }

          if (date < new Date("1900-01-01")) {
            return this.getTranslation(
              "error_birthday_invalid",
              "Bitte gib ein valides Geburtsdatum an",
            );
          }

          return true;
        },
        gender: yup
          .string()
          .nullable()
          .required(
            this.getTranslation(
              "error_gender_missing",
              "Bitte wähle ein Geschlecht",
            ),
          ),
        // nationality: yup.string().nullable().required('Bitte wähle eine Nationalität.'),
      },
      genders: ["männlich", "weiblich", "divers"],
      isLoading: false,
      isSaved: false,
      image: null,
      fileName: null,
      userImageBackground: "",
    };
  },
  computed: {
    ...mapStores(useAuthStore, useUserStore),
    // userImageBackground: function () {
    //   let infos = this.userStore.userInfos;
    //
    //   if (infos !== null && infos.image) {
    //     return "background-image: url('/api/user/image/?src=" + infos.image + "')";
    //   }
    //   return "background-image: url('/images/user_image_placeholder.png')";
    // },
    userImage: function () {
      const infos = this.userStore.userInfos;

      if (infos !== null && infos.image) {
        return "/api/user/image/?src=" + infos.image;
      }
      return "/images/user_image_placeholder.png";
    },
  },
  methods: {
    submitData(values) {
      const translation = this.getTranslation;
      this.isLoading = true;
      const that = this;
      if (this.userStore.userInfos.email !== values.email) {
        values.email_unconfirmed = true;
      }

      this.userStore
        .updateUserInfos(values)
        .then(() => {
          this.userStore.refreshUserInfos().then((userInfos) => {
            that.isLoading = false;

            if (values.email_unconfirmed === true) {
              that.startVerify("verifyEmail");
            }

            that.isSaved = true;
            setTimeout(() => {
              that.isSaved = false;
            }, 2000);
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: translation(
              "error_unknown",
              "Ein Fehler ist aufgetreten",
            ),
            icon: "error",
            text: error.message,
          });
          that.isLoading = false;
        });
    },
    isSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return /safari/.test(ua) && !/chrome/.test(ua) && !/android/.test(ua);
    },
    startVerify: function (type) {
      this.$emit("openVerify", type);
    },
    openCropper(event) {
      new bootstrap.Modal(document.getElementById("cropper-modal"), {
        keyboard: true,
      }).show();
      this.$refs.cropper.refresh();
    },
    loadImage(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
          this.fileName = input.files[0].name;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();

      if (canvas) {
        canvas.toBlob((blob) => {
          const userId = this.userStore.userInfos.id;
          const file = new File(
            [blob],
            this.fileName ? this.fileName : "user_" + userId + "_image.jpg",
            { type: "image/jpeg" },
          );
          const form = new FormData();

          form.append("file", file);

          form.append("id", userId);
          form.append("token", this.authStore.token);

          axios({
            method: "post",
            url: "/api/user/image/update",
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then((resp) => {
              if (!resp.data.success) {
                Swal.fire(
                  this.getTranslation(
                    "error_unknown",
                    "Ein Fehler ist aufgetreten",
                  ),
                  resp.data.message,
                  "error",
                );
              } else {
                Swal.fire(
                  this.getTranslation("saved", "Gespeichert"),
                  this.getTranslation(
                    "image_changed_message",
                    "Das Bild wurde gespeichert.",
                  ),
                  "success",
                )
                  .then(() => {
                    document.querySelector("#cropper-modal .btn-close").click();
                  })
                  .then((res) => {
                    this.userStore.refreshUserInfos().then((userInfos) => {
                      this.userImageBackground =
                        "background-image: url('" + userInfos.image + "')";
                    });
                  });
              }
            })
            .catch((response) => {
              //handle error
              console.error(response);
            });
        }, "image/jpeg");
      }
    },
  },
  beforeMount() {
    this.userStore.refreshUserInfos().then((userInfos) => {
      if (userInfos !== null && userInfos.image) {
        this.userImageBackground =
          "background-image: url('" + userInfos.image + "')";
      } else {
        this.userImageBackground =
          "background-image: url('/images/user_image_placeholder.png')";
      }
    });
  },
};
</script>

<style scoped lang="scss">
/*! purgecss start ignore */
.vue-advanced-cropper {
  text-align: center;
  position: relative;
  user-select: none;
  max-height: 100%;
  max-width: 100%;
  direction: ltr;
}

.vue-advanced-cropper__stretcher {
  pointer-events: none;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.vue-advanced-cropper__image {
  user-select: none;
  position: absolute;
  transform-origin: center;
  max-width: none !important;
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  opacity: 1;
  background: black;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.vue-advanced-cropper__foreground {
  opacity: 0.5;
}

.vue-advanced-cropper__boundaries {
  opacity: 1;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.vue-advanced-cropper__cropper-wrapper {
  width: 100%;
  height: 100%;
}

.vue-advanced-cropper__image-wrapper {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.vue-advanced-cropper__stencil-wrapper {
  position: absolute;
}

.vue-bounding-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.vue-bounding-box__handler {
  position: absolute;
}

.vue-bounding-box__handler--west-north {
  left: 0;
  top: 0;
}

.vue-bounding-box__handler--north {
  left: 50%;
  top: 0;
}

.vue-bounding-box__handler--east-north {
  left: 100%;
  top: 0;
}

.vue-bounding-box__handler--east {
  left: 100%;
  top: 50%;
}

.vue-bounding-box__handler--east-south {
  left: 100%;
  top: 100%;
}

.vue-bounding-box__handler--south {
  left: 50%;
  top: 100%;
}

.vue-bounding-box__handler--west-south {
  left: 0;
  top: 100%;
}

.vue-bounding-box__handler--west {
  left: 0;
  top: 50%;
}

.vue-handler-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.vue-handler-wrapper__draggable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vue-handler-wrapper--west-north {
  cursor: nw-resize;
}

.vue-handler-wrapper--north {
  cursor: n-resize;
}

.vue-handler-wrapper--east-north {
  cursor: ne-resize;
}

.vue-handler-wrapper--east {
  cursor: e-resize;
}

.vue-handler-wrapper--east-south {
  cursor: se-resize;
}

.vue-handler-wrapper--south {
  cursor: s-resize;
}

.vue-handler-wrapper--west-south {
  cursor: sw-resize;
}

.vue-handler-wrapper--west {
  cursor: w-resize;
}

.vue-handler-wrapper--disabled {
  cursor: auto;
}

.vue-draggable-area {
  position: relative;
}

.vue-preview-result {
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
}

.vue-preview-result__wrapper {
  position: absolute;
}

.vue-preview-result__image {
  pointer-events: none;
  position: relative;
  user-select: none;
  transform-origin: center;
  max-width: none !important;
}

.vue-preview {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.vue-preview--fill {
  width: 100%;
  height: 100%;
  position: absolute;
}

.vue-preview__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.vue-preview__image {
  pointer-events: none;
  position: absolute;
  user-select: none;
  transform-origin: center;
  max-width: none !important;
}

.vue-simple-line {
  background: none;
  transition: border 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-width: 0;
  border-style: solid;
}

.vue-simple-line--south,
.vue-simple-line--north {
  height: 0;
  width: 100%;
}

.vue-simple-line--east,
.vue-simple-line--west {
  height: 100%;
  width: 0;
}

.vue-simple-line--east {
  border-right-width: 1px;
}

.vue-simple-line--west {
  border-left-width: 1px;
}

.vue-simple-line--south {
  border-bottom-width: 1px;
}

.vue-simple-line--north {
  border-top-width: 1px;
}

.vue-simple-line--hover {
  opacity: 1;
  border-color: white;
}

.vue-line-wrapper {
  background: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vue-line-wrapper--north,
.vue-line-wrapper--south {
  height: 12px;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}

.vue-line-wrapper--north {
  top: 0;
  cursor: n-resize;
}

.vue-line-wrapper--south {
  top: 100%;
  cursor: s-resize;
}

.vue-line-wrapper--east,
.vue-line-wrapper--west {
  width: 12px;
  height: 100%;
  transform: translateX(-50%);
  top: 0;
}

.vue-line-wrapper--east {
  left: 100%;
  cursor: e-resize;
}

.vue-line-wrapper--west {
  left: 0;
  cursor: w-resize;
}

.vue-line-wrapper--disabled {
  cursor: auto;
}

.vue-simple-handler {
  display: block;
  background: white;
  height: 10px;
  width: 10px;
}

.vue-rectangle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.vue-rectangle-stencil__preview {
  position: absolute;
  width: 100%;
  height: 100%;
}

.vue-rectangle-stencil--movable {
  cursor: move;
}

.vue-circle-stencil {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  cursor: move;
}

.vue-circle-stencil__preview {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
}

.vue-circle-stencil--movable {
  cursor: move;
}

/*! purgecss end ignore */

/*! purgecss start ignore */
.upload-cropper {
  border: solid 1px;
  min-height: 300px;
  min-width: 300px;
  max-height: 300px;

  &__preview {
    border: solid 1px rgba(white, 0.15);
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  cursor: pointer;
}

.button input {
  display: none;
}

/*! purgecss end ignore */
</style>