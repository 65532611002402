import { computed } from "vue";
import { z } from "zod";
import { useTranslation } from "../../composables/useTranslation";
import { useCheckoutFields } from "./checkout.config";

/**
 * Schema definition for a checkout field
 * @type {import('zod').ZodObject}
 */
export const schemaCheckoutField = z.object({
  type: z.enum(["text", "checkbox", "date", "radio"]),
  rules: z
    .array(
      z.object({
        regex: z.string().optional(),
        minLength: z.number().optional(),
        maxLength: z.number().optional(),
        min: z.number().optional(),
        max: z.number().optional(),
        minDate: z.date().optional(),
        maxDate: z.date().optional(),
      }),
    )
    .optional(),
  options: z.array(z.string()).optional(),
});

/**
 * Schema configuration for checkout fields
 * Defines the structure for both default and guest checkout field arrays
 * @type {import('zod').ZodObject}
 */
export const schemaFieldConfig = z.object({
  default: z.array(schemaCheckoutField),
  guest: z.array(schemaCheckoutField),
});

/**
 * Schema definition for invoice address fields
 * @type {import('zod').ZodObject}
 */
export function useInvoiceAddressSchema() {
  return computed(() => {
    const requiredString = (field) =>
      z.string({
        required_error: useTranslation(`checkout_invoice_${field}_required`),
      });

    return z.object({
      company: z.string().optional(),
      firstname: requiredString("firstname").min(
        2,
        useTranslation("checkout_invoice_firstname_too_short"),
      ),
      lastname: requiredString("lastname").min(
        2,
        useTranslation("checkout_invoice_lastname_too_short"),
      ),
      street: requiredString("street").min(
        5,
        useTranslation("checkout_invoice_street_too_short"),
      ),
      zip: requiredString("zip").min(
        4,
        useTranslation("checkout_invoice_zip_too_short"),
      ),
      place: requiredString("place").min(
        2,
        useTranslation("checkout_invoice_place_too_short"),
      ),
      country: requiredString("country").min(
        2,
        useTranslation("checkout_invoice_country_too_short"),
      ),
    });
  });
}

/**
 * Composable that creates and returns a dynamic Zod schema for checkout form validation
 * based on the configured checkout fields
 *
 * @returns {import('vue').ComputedRef<import('zod').ZodObject>} Computed ref containing the generated checkout validation schema
 */
export const useCheckoutSchema = () => {
  const fields = useCheckoutFields();

  const createBaseSchema = (key) =>
    z.string({
      required_error: useTranslation(`checkout_${key}_required`),
      invalid_type_error: useTranslation(`checkout_${key}_invalid`),
    });

  const addTextRules = (schema, field, key) => {
    const { rules } = field;
    if (!rules) return schema;

    if (rules.minLength) {
      schema = schema.min(
        rules.minLength,
        useTranslation(`checkout_${key}_too_short`),
      );
    }
    if (rules.maxLength) {
      schema = schema.max(
        rules.maxLength,
        useTranslation(`checkout_${key}_too_long`),
      );
    }
    if (rules.regex) {
      console.log("RULES: ", rules.regex);
      schema = schema.regex(
        new RegExp(rules.regex),
        useTranslation(`checkout_${key}_invalid`),
      );
    }
    if (rules.min) {
      schema = schema.min(
        rules.min,
        useTranslation(`checkout_${key}_too_small`),
      );
    }
    if (rules.max) {
      schema = schema.max(rules.max, useTranslation(`checkout_${key}_too_big`));
    }
    return schema;
  };

  const addDateRules = (schema, field, key) => {
    const { rules } = field;
    if (!rules) return schema;

    if (rules.minDate) {
      schema = schema.min(
        rules.minDate,
        useTranslation(`checkout_${key}_too_early`),
      );
    }
    if (rules.maxDate) {
      schema = schema.max(
        rules.maxDate,
        useTranslation(`checkout_${key}_too_late`),
      );
    }
    return schema;
  };

  return computed(() => {
    let checkoutSchema = z.object({
      privacy_accepted: z.coerce
        .boolean({
          required_error: useTranslation("checkout_privacy_required"),
          invalid_type_error: useTranslation("checkout_privacy_invalid"),
        })
        .refine((val) => val === true, {
          message: useTranslation("checkout_privacy_required"),
        }),
    });

    Object.entries(fields.value).forEach(([key, field]) => {
      let fieldSchema = null;

      switch (true) {
        case field.type === "text":
          fieldSchema = addTextRules(createBaseSchema(key), field, key);
          break;

        case field.type === "email" && key === "email":
          fieldSchema = createBaseSchema(key).email(
            useTranslation(`checkout_${key}_invalid`),
          );
          break;

        case key === "phone":
          fieldSchema = createBaseSchema(key).regex(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
            useTranslation(`checkout_${key}_invalid`),
          );
          break;

        case field.type === "boolean":
          fieldSchema = z.boolean({ 
            required_error: useTranslation(`checkout_${key}_required`),
            invalid_type_error: useTranslation(`checkout_${key}_invalid`),
          });
          break;

        case field.type === "date":
          fieldSchema = addDateRules(
            z.coerce.date({
              required_error: useTranslation(`checkout_${key}_required`),
              invalid_type_error: useTranslation(`checkout_${key}_invalid`),
            }),
            field,
            key,
          );
          break;

        case field.type === "radio":
          if (!field.options) {
            throw new Error(`Field ${key} has no options`);
          }
          fieldSchema = createBaseSchema(key).refine(
            (value) => field.options.includes(value),
            useTranslation(`checkout_${key}_invalid`),
          );
          break;
      }

      checkoutSchema = checkoutSchema.extend({
        [key]: fieldSchema,
      });
    });

    return checkoutSchema;
  });
};
