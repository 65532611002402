<script setup>
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import { useTranslation } from "../../composables/useTranslation";

const props = defineProps({
  areas: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(["update:selectedAreas"]);

const selectedAreas = ref([]);
const isOpen = ref(false);

const selectAreaText = useTranslation(
  "module_shop_component_lounges_select_areas_select_area",
  "Select areas",
);
const selectAllText = useTranslation(
  "module_shop_component_lounges_select_areas_select_all",
  "Select all areas",
);
const singularAreasSelectedText = useTranslation(
  "module_shop_component_lounges_select_areas_singular_areas_selected",
  "Area selected",
);
const pluralAreasSelectedText = useTranslation(
  "module_shop_component_lounges_select_areas_plural_selected",
  "Areas selected",
);
const allAreasSelectedText = useTranslation(
  "module_shop_component_lounges_select_areas_all_areas_selected",
  "All areas selected",
);

const selectedAreasText = computed(() => {
  switch (true) {
    case selectedAreas.value.length === 0:
      return selectAreaText;
    case selectedAreas.value.length === props.areas.length:
      return allAreasSelectedText;
    case selectedAreas.value.length === 1:
      return `${selectedAreas.value.length} ${singularAreasSelectedText}`;
    default:
      return `${selectedAreas.value.length} ${pluralAreasSelectedText}`;
  }
});

const toggleArea = (areaId) => {
  const index = selectedAreas.value.indexOf(areaId);
  if (index === -1) {
    selectedAreas.value.push(areaId);
  } else {
    selectedAreas.value.splice(index, 1);
  }
};

const toggleAll = () => {
  if (selectedAreas.value.length === props.areas.length) {
    selectedAreas.value = [];
  } else {
    selectedAreas.value = props.areas.map((area) => area.id);
  }
};

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

watch(
  selectedAreas,
  (newValue) => {
    emit("update:selectedAreas", newValue);
  },
  { deep: true },
);
</script>

<template>
  <div class="area-filter dropdown" :class="{ 'show': isOpen }">
    <button class="dropdown-toggle" @click="toggleDropdown" type="button" id="areaFilterDropdown" aria-expanded="false">
      {{ selectedAreasText }}
    </button>
    <div class="dropdown-menu" :class="{ 'show': isOpen }" aria-labelledby="areaFilterDropdown">
      <button class="dropdown-item" @click="toggleAll">
        <span class="checkmark" v-if="selectedAreas.length === areas.length">✓</span>
        <span class="area-name">{{ selectAllText }}</span>
      </button>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item-list">
        <button v-for="area in areas" :key="area.id" class="dropdown-item" @click="toggleArea(area.id)"
          :aria-checked="selectedAreas.includes(area.id)">
          <span class="checkmark" v-if="selectedAreas.includes(area.id)">✓</span>
          <span class="area-name">{{ area.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.area-filter {
  position: relative;

  .dropdown-toggle {
    background: transparent;
    color: var(--color-white);
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1.5rem;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    padding: 6px 12px;

    &::after {
      margin-left: .5em;
      vertical-align: 0.2em;
    }
  }

  .dropdown-menu {
    width: 300px;
    max-height: 300px;
    overflow-y: auto;
    background: var(--color-black);
    border: var(--border-width) solid var(--color-lighter);
  }

  .dropdown-item {
    color: var(--color-white);
    cursor: pointer;
    padding: 0.75rem 1rem;
    position: relative;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      background-color: var(--color-primary);
    }

    .checkmark {
      position: absolute;
      left: 1rem;
      font-size: 1.75rem;
    }

    .area-name {
      margin-left: 2rem;
    }
  }

  .dropdown-item-list {
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>