<template>

  <div class="verify-container p-4 d-flex justify-content-center flex-wrap" v-if="!isLoading">
    <component :is="componentName" @emailVerified="emailVerified" @phoneVerified="phoneVerified" @setModalTitle="setModalTitle"></component>
  </div>

  <div class="col-12" v-else>
    <h3 class="spinner-border"></h3>
  </div>
</template>


<script>
import verifyEmail from "./verifyEmail";
import verifyPhone from "./verifyPhone";

import { mapState, mapStores } from "pinia";
import { useDataStore } from "../../../../store/data_store";
import { useUserStore } from "../../../../store/user_store";

export default {
  name: "Verify",
  components: {
    verifyEmail,
    verifyPhone,
  },
  props: ["loadedFrom", "params"],
  computed: {
    ...mapStores(useUserStore, useDataStore),
  },
  emits: [
    "changeComponent",
    "loginSuccessful",
    "setModalTitle",
    "setRegisterSuccessful",
  ],
  data() {
    const userStore = useUserStore();
    return {
      isLoading: true,
      componentName: null,
      userInfos: JSON.parse(userStore.userInfosRef),
    };
  },
  methods: {
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
    changeComponent(component, module = "Auth") {
      this.$emit("changeComponent", { component: component, module: module });
    },
    emailVerified() {
      this.userInfos = JSON.parse(this.userStore.userInfosRef);
      if (this.verifyType) {
        this.changeComponent("checkout", "Shop");
      } else {
        if (!this.userInfos.phone_verified) {
          if (
            this.dataStore.settings["verification"].includes("lounges") ||
            this.dataStore.settings["verification"].includes("tickets")
          ) {
            this.componentName = "verifyPhone";
          } else {
            this.$emit("loginSuccessful");
          }
        } else {
          // if(!this.params.registerSuccessful) {
          //   this.changeComponent('checkout', 'Shop');
          // }
          this.$emit("loginSuccessful");
        }
      }
      if (this.params.registerSuccessful) {
        this.$emit("setRegisterSuccessful", false);
      }
    },
    phoneVerified() {
      this.userInfos = JSON.parse(this.userStore.userInfosRef);
      if (this.verifyType) {
        this.changeComponent("checkout", "Shop");
      } else {
        if (this.userInfos.unconfirmed_email !== "") {
          this.componentName = "verifyEmail";
        } else {
          // if(!this.params.registerSuccessful) {
          //   this.changeComponent('checkout', 'Shop');
          // }
          this.$emit("loginSuccessful");
        }
      }
      if (this.params.registerSuccessful) {
        this.$emit("setRegisterSuccessful", false);
      }
    },
  },
  activated() {
    this.userStore
      .refreshUserInfos()
      .then((userInfos) => {
        this.userInfos = userInfos;
        if (this.params.verifyType) {
          this.componentName = this.params.verifyType;
        } else {
          if (this.userInfos.unconfirmed_email !== "")
            this.componentName = "verifyEmail";
          else if (!this.userInfos.phone_verified)
            this.componentName = "verifyPhone";
        }
      })
      .then(() => {
        this.isLoading = false;
      });

    if (document.querySelector(".btn-close")) {
      document.querySelector(".btn-close").style.display = "none";
    }
  },
  deactivated() {
    if (document.querySelector(".btn-close")) {
      document.querySelector(".btn-close").style.display = "inline";
    }
  },
  beforeMount: function () {
    this.userStore
      .refreshUserInfos()
      .then((userInfos) => {
        this.userInfos = userInfos;
        if (this.params.verifyType) {
          this.componentName = this.params.verifyType;
        } else {
          if (this.userInfos.unconfirmed_email !== "")
            this.componentName = "verifyEmail";
          else if (!this.userInfos.phone_verified)
            this.componentName = "verifyPhone";
        }
      })
      .then(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>

</style>