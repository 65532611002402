import Crypto from "crypto-js";
import Cookies from "js-cookie";
import { v4 } from "uuid";

import { useLocalStorage, useShare } from "@vueuse/core";

function createCookieBanner(config, callback) {
  return new Promise((resolve, reject) => {
    let text = config.cookie_banner_text;
    if (text == undefined || text == "") {
      text = `Wir und unsere Partner erheben Daten und verwenden Cookies. Essenzielle Cookies werden für die Funktionalität der Seite gebraucht; andere werden für personalisierte Anzeigen und analytische Zwecke verwendet. Klickst du auf "Alles akzeptieren", stimmst du der Verwendung deiner Daten zu. Weitere Informationen zur Erhebung und Verwendung von Daten findest du in unserer <a href="/datenschutz" id="cookies-eu-more">Datenschutzerklärung</a>.`;
    }

    const html = `
        <div id="cookies-eu-banner" class="d-none" style="position: fixed;bottom: 0; left: 0; right: 0; height: auto;">
            <div class="d-flex flex-wrap cookies-eu-info" style="font-size: 1.25rem;text-align: left;padding: 16px 20px;max-width: 1000px;margin: 0 auto;">
                <div class="col-lg-12">
                    <span>${text}</span>
                </div>

                <div class="col-lg-6 cookies-links">
                    <a href="/cookie-richtlinie/" target="_blank">Cookie-Richtlinie</a> |
                    <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> |
                    <a href="/impressum/" target="_blank">Impressum</a>
                </div>

                <div class="col-lg-6 d-flex flex-wrap">
                    <div class="col-lg-6 col-12 d-flex justify-content-end" style="padding-right: .5rem;"><button id="open-cookie-modal" class="btn btn-outline-primary text-white">Einstellungen</button></div>
                    <div class="col-lg-6 col-12" style="padding-left: .5rem;"><button id="cookies-eu-accept" class="btn btn-primary">Alle akzeptieren</button></div>
                </div>

            </div>
        </div>`;
    const banner = document.createElement("div");
    banner.innerHTML = html;
    document.body.append(banner);

    const statistic_cookies_accepted = Cookies.get(
      "statistic-cookies-accepted",
    );
    const marketing_cookies_accepted = Cookies.get(
      "marketing-cookies-accepted",
    );

    const modal = document.createElement("div");
    modal.innerHTML = `
            <div class="modal fade" id="cookie-modal" tabindex="-1" aria-labelledby="cookie-modalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-fullscreen-md-down">
                    <div class="modal-content bg-dark-transparent animate__animated animate__fadeIn">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cookie-modalLabel">Cookie Einstellungen</h5>
                            <button type="button" class="btn-close" id="close-cookie-modal"><i class="feather-x fs-20" data-v-dc79ecc0=""></i></button>
                        </div>

                        <div class="modal-body" style="padding-left: 4rem;padding-right: 4rem;padding-bottom: calc(1rem + 20px);">

                            <div class="col-12 d-flex flex-wrap">
                                <span class="w-100">
                                    ${text}
                                </span>

                                <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a>
                            </div>

                            <div class="col-12 d-flex flex-wrap justify-content-center" style="margin-top: 2rem;">
                                <div class="d-flex flex-wrap w-75">
                                    <h5 class="mb-0">Essenzielle Cookies (Pflicht)</h5>
                                    <span class="w-100">
                                    Essenzielle-Cookies helfen uns dabei, Ihnen Funktionen zur Verfügung zu stellen, die für die Nutzung unseres Angebots essenziell sind.
                                    </span>
                                </div>
                                <div class="">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="1" name="essential-cookies" id="essential-cookies-accepted" checked disabled style="opacity: 0!important;">
                                      <label class="form-check-label" for="essential-cookies-accepted"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 d-flex flex-wrap justify-content-center" style="margin-top: 1.5rem;">
                                <div class="d-flex flex-wrap w-75">
                                    <h5 class="mb-0">Statistik Cookies</h5>
                                    <span class="w-100">
                                    Statistik-Cookies helfen uns zu verstehen, wie Besucher mit unserem Angebot interagieren, indem Informationen anonym gesammelt und gemeldet werden.
                                    </span>
                                </div>
                                <div class="">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="1" name="statistics-cookies" id="statistics-cookies-accepted" ${statistic_cookies_accepted ? "checked" : ""}>
                                      <label class="form-check-label" for="statistics-cookies-accepted"></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 d-flex flex-wrap justify-content-center" style="margin-top: 1.5rem;">
                                <div class="d-flex flex-wrap w-75">
                                    <h5 class="mb-0">Marketing Cookies</h5>
                                    <span class="w-100">
                                        Marketing-Cookies verwenden unsere Vermarktungspartner, um Besuchern auf Webseiten zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für Sie sind und daher wertvoller für Publisher und werbetreibende Drittparteien.
                                    </span>
                                </div>
                                <div class="">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="1" name="marketing-cookies" id="marketing-cookies-accepted" ${marketing_cookies_accepted ? "checked" : ""}>
                                      <label class="form-check-label" for="marketing-cookies-accepted"></label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer d-flex flex-wrap justify-content-end">
                            <button id="modal-cookies-save" class="btn btn-outline-primary text-white">Speichern</button>
                            <button id="modal-cookies-eu-accept" class="btn btn-primary">Alle akzeptieren</button>
                        </div>
                   </div>
               </div>
            </div>`;

    document.body.append(modal);

    resolve();
  }).then(() => {
    const cookies_accepted = Cookies.get("cookies-accepted");
    if (cookies_accepted) {
      return callback(config, true, true);
    } else {
      const backdrop = document.createElement("div");
      backdrop.id = "cookies-eu-backdrop";
      backdrop.classList.add("modal-backdrop", "fade", "show");
      document.body.append(backdrop);
    }

    const modal = new bootstrap.Modal(document.getElementById("cookie-modal"));
    document.getElementById("cookies-eu-banner").classList.remove("d-none");

    document
      .getElementById("open-cookie-modal")
      .addEventListener("click", (e) => {
        e.preventDefault();
        modal.show();
      });

    document
      .getElementById("close-cookie-modal")
      .addEventListener("click", (e) => {
        e.preventDefault();
        Cookies.set("cookies-accepted", true, { expires: 365 });
        document.getElementById("cookies-eu-backdrop").remove();
        modal.hide();

        if (config.cookie_banner_page_reload) {
          location.reload();
        }
      });

    document
      .getElementById("cookies-eu-accept")
      .addEventListener("click", (e) => {
        // accept all cookies
        e.preventDefault();
        Cookies.set("cookies-accepted", true, { expires: 365 });
        Cookies.set("statistic-cookies-accepted", true, { expires: 365 });
        Cookies.set("marketing-cookies-accepted", true, { expires: 365 });
        document.getElementById("cookies-eu-banner").parentElement.remove();
        document.getElementById("cookies-eu-backdrop").remove();

        if (config.cookie_banner_page_reload) {
          location.reload();
        }

        callback(config, true, true);
      });

    document
      .getElementById("modal-cookies-eu-accept")
      .addEventListener("click", (e) => {
        // accept all cookies
        e.preventDefault();
        Cookies.set("cookies-accepted", true, { expires: 365 });
        Cookies.set("statistic-cookies-accepted", true, { expires: 365 });
        Cookies.set("marketing-cookies-accepted", true, { expires: 365 });

        modal.hide();

        document.getElementById("cookies-eu-banner").parentElement.remove();
        document.getElementById("cookies-eu-backdrop").remove();

        if (config.cookie_banner_page_reload) {
          location.reload();
        }

        callback(config, true, true);
      });

    document
      .getElementById("modal-cookies-save")
      .addEventListener("click", (e) => {
        // accept selected cookies
        e.preventDefault();

        Cookies.set("cookies-accepted", true, { expires: 365 });

        const acceptStatistics = document.querySelector(
          '#cookie-modal input[name="statistics-cookies"]',
        ).checked;
        const acceptMarketing = document.querySelector(
          '#cookie-modal input[name="marketing-cookies"]',
        ).checked;

        Cookies.set("statistic-cookies-accepted", acceptStatistics, {
          expires: 365,
        });
        Cookies.set("marketing-cookies-accepted", acceptMarketing, {
          expires: 365,
        });

        modal.hide();
        document.getElementById("cookies-eu-banner").parentElement.remove();
        document.getElementById("cookies-eu-backdrop").remove();

        if (config.cookie_banner_page_reload) {
          location.reload();
        }

        callback(config, acceptStatistics, acceptMarketing);
      });
  });
}

const name = "_eid";
const key = useLocalStorage(name, v4());

const serializer = {
  read: (value) => {
    // Get the encryption token from cookie or generate a new one.
    const encryptionToken = key.value;

    try {
      const bytes = Crypto.AES.decrypt(value, encryptionToken);
      const str = bytes.toString(Crypto.enc.Utf8);

      return str;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  write: (value) => {
    // Encrypt the store using our encryption token stored in cookies.
    let encryptionToken = key.value;

    if (!encryptionToken) {
      key.value = v4();
      encryptionToken = key.value;
    }

    return value
      ? Crypto.AES.encrypt(value, encryptionToken).toString()
      : value;
  },
};

function migrateStorage() {
  if (window.localStorage.vuex) {
    const oldData = JSON.parse(window.localStorage.vuex);

    if (oldData) {
      const authData = oldData.auth;

      if (authData) {
        setTimeout(() => {
          console.log("remove V1 auth data");
          window.localStorage.removeItem("vuex");
        }, 500);

        return authData;
      }
    }
  }
}

export { createCookieBanner, migrateStorage, serializer };
