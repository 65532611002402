export function formatCurrency(price) {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(price);
}

export function formatDate(date, format) {
  return moment(date).format(format);
}
