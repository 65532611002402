<script setup>
import {
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
  DrawerTrigger,
} from "vaul-vue";
import { defineEmits, defineProps, ref } from "vue";

const props = defineProps({
  areas: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["selectArea"]);

const selectedAreas = ref([]);
const handleSelectArea = (areaId) => {
  if (selectedAreas.value.includes(areaId)) {
    selectedAreas.value = selectedAreas.value.filter((id) => id !== areaId);
  } else {
    selectedAreas.value.push(areaId);
  }

  emit("selectArea", selectedAreas.value);
};
</script>

<template>
  <div>
    <DrawerRoot should-scale-background>
      <DrawerTrigger class="drawer-trigger">
        Lounges filtern
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerOverlay class="drawer-overlay" />
        <DrawerContent class="drawer-content">
          <div class="drawer-inner">
            <div class="drawer-handle"></div>
            <div class="drawer-content-wrapper">
              <div class="areas-grid">
                <button type="button" v-for="area in areas" :key="area.id" :class="['areas-grid-item', {
                  'selected': selectedAreas.includes(area.id)
                }]" @click="handleSelectArea(area.id)" :aria-checked="selectedAreas.includes(area.id)">
                  <img class="area-image" :src="area.image" :alt="area.name" />
                  <span class="area-name">{{ area.name }}</span>
                </button>
              </div>
            </div>
          </div>
        </DrawerContent>
      </DrawerPortal>
    </DrawerRoot>
  </div>
</template>

<style lang="scss" scoped>
.areas-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  overflow-y: scroll;
}

.areas-grid-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: var(--border-width) solid var(--color-lighter);
  border-radius: var(--bs-border-radius-lg);
  background: var(--color-black);

  &.selected {
    border: var(--border-width) solid var(--color-primary);
  }
}

.area-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.area-name {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
  color: var(--color-white);
}

.drawer-trigger {
  border-radius: var(--bs-border-radius-lg);
  background-color: transparent;
  padding: 0.625rem 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-white);
  border: none;
}

.drawer-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1060;
}

.drawer-content {
  background-color: var(--color-black);
  display: flex;
  flex-direction: column;
  border-top-left-radius: var(--bs-modal-border-radius-lg);
  border-top-right-radius: var(--bs-modal-border-radius-lg);
  height: 80%;
  margin-top: 6rem;
  max-height: 96%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1061;
}

.drawer-inner {
  padding: var(--bs-modal-padding-y) var(--bs-modal-padding-x);
  background-color: var(--color-black);
  border-top-left-radius: var(--bs-modal-border-radius-lg);
  border-top-right-radius: var(--bs-modal-border-radius-lg);
  flex: 1;
  overflow: scroll;
  height: 100%;
}

.drawer-handle {
  margin: 0 auto;
  width: 3rem;
  height: 0.375rem;
  flex-shrink: 0;
  border-radius: 9999px;
  background-color: var(--bs-gray-300);
  margin-bottom: 2rem;
}

.drawer-content-wrapper {
  padding: 1rem;
  margin: 0 auto;
}
</style>