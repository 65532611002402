<template>

  <div class="container" v-if="authStore.isAuthenticated()">

    <div>

      <div class="text-white">
<!--        <KeepAlive>-->
          <component :is="currentComponent" @openVerify="openVerify"></component>
<!--        </KeepAlive>-->
      </div>

    </div>

  </div>

</template>

<script>
import UserDetails from "../components/user/details";
import UserSettings from "../components/user/settings";

import { mapStores } from "pinia";
import { useAuthStore } from "../../store/auth_store";

export default {
  name: "UserModule",
  emits: ["openVerify"],
  components: {
    UserDetails,
    UserSettings,
  },
  data() {
    return {
      currentComponent: "UserDetails",
    };
  },
  watch: {
    "authStore.token": {
      handler: (value) => {
        if (!value) {
          window.location = "/";
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    openVerify: function (type) {
      this.$emit("openVerify", type);
    },
    changeComponent: function (componentName) {
      this.currentComponent = componentName;
    },
  },
  mounted() {
    this.currentComponent = document.getElementById("user").dataset.component
      ? document.getElementById("user").dataset.component
      : "UserDetails";
  },
};
</script>

<style scoped>

</style>