import axios from "axios";

const user_api = {
  refreshUserInfos(token) {
    const params = new URLSearchParams({ token });

    return axios.get("/api/user/infos", { params }).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data.data;
    });
  },
  updateUserInfos(token, payload) {
    const params = new URLSearchParams(Object.assign({ token }, payload));

    return axios.post("/api/user/infos/update", params).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return params;
    });
  },
};

export default user_api;
