<template>
  
  <div>
    <div class="register-step-info">
      <h4 class="mb-1">{{ getTranslation('module_shop_component_register_step3_title', 'Dein Profilbild..') }}</h4>
      {{ getTranslation('module_shop_component_register_step3_text', 'Möchtest du ein Foto von dir zeigen?') }}
    </div>

    <div class="col-12 pt-2">


      <div class="upload">
        <cropper ref="cropper" class="upload-cropper" :src="image"
        default-boundaries="fit"
        imageRestriction="stencil"
        :stencil-size="{
          width: 300,
          height: 300
        }"
        :stencil-props="{
          previewClass: 'upload-cropper__preview',
          movable: false,
          resizable: false,
          lines: {
            north: false,
            west: false,
            south: false,
            east: false,
          },
          handlers: {
            eastNorth: false,
            north: false,
            westNorth: false,
            west: false,
            westSouth: false,
            south: false,
            eastSouth: false,
            east: false,
          }
        }"
        :canvas="{
          maxArea: 1096 * 1096
        }"
       :stencil-component="$options.components.CircleStencil"
        />
      <div class="button-wrapper">
        <a class="button btn-default btn-small btn-border" @click="$refs.file.click()">
          <input
            type="file"
            ref="file"
            @change="loadImage($event)"
            :accept="`image/png, image/gif, image/jpeg ${isSafari() ? ', image/heic, image/heif' : ''}`"
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
          {{ getTranslation('image_label', 'Bild hochladen') }}
        </a>
      </div>
    </div>

    </div>
  </div>

</template>

<script>
import { Field } from "vee-validate";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "step3",
  props: ["errors", "asGuest"],
  emits: [],
  inject: ["getTranslation"],
  components: {
    Field,
    Cropper,
    CircleStencil,
  },
  data() {
    return {
      image: "/images/user_image_placeholder.png",
      fileName: "uploadedFile",
    };
  },
  methods: {
    isSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return /safari/.test(ua) && !/chrome/.test(ua) && !/android/.test(ua);
    },
    loadImage(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
          this.fileName = input.files[0].name;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    // getCroppingResult(){
    //   const { canvas } = this.$refs.cropper.getResult();
    //   return canvas.toDataURL("image/jpeg", 0.9);
    //
    // },
    // uploadImage() {
    //   let that = this;
    //   const { canvas } = this.$refs.cropper.getResult();
    //
    //   if (canvas) {
    //     const form = new FormData();
    //
    //     const b64 = canvas.toDataURL("image/jpeg", 0.9);
    //
    //     console.log(b64)
    //
    //     form.append("file", b64);
    //     form.append('id', this.userInfos.id);
    //     form.append('token', this.token);
    //
    //     axios({
    //       method: "post",
    //       url: "/update-user-image",
    //       data: form,
    //       headers: { "Content-Type": "multipart/form-data" },
    //     }).then(function (resp) {
    //       if(!resp.data.success){
    //         Swal.fire('Fehlgeschlagen', resp.data.message);
    //       } else {
    //         that.refreshUserInfos();
    //       }
    //     })
    //     .catch(function (response) {
    //       //handle error
    //       console.log(response);
    //     });
    //
    //     // canvas.toBlob((blob) => {
    //     //
    //     //
    //     //   // You can use axios, superagent and other libraries instead here
    //     //
    //     //   // Perhaps you should add the checking file format
    //     // }, this.mime);
    //   }
    // },
  },
};
</script>


<style scoped lang="scss">

/* purgecss start ignore */
  .vue-advanced-cropper {
    text-align: center;
    position: relative;
    user-select: none;
    max-height: 100%;
    max-width: 100%;
    direction: ltr;
  }
  .vue-advanced-cropper__stretcher {
    pointer-events: none;
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
  .vue-advanced-cropper__image {
    user-select: none;
    position: absolute;
    transform-origin: center;
    max-width: none !important;
  }
  .vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
    opacity: 1;
    background: black;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .vue-advanced-cropper__foreground {
    opacity: 0.5;
  }
  .vue-advanced-cropper__boundaries {
    opacity: 1;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .vue-advanced-cropper__cropper-wrapper {
    width: 100%;
    height: 100%;
  }
  .vue-advanced-cropper__image-wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .vue-advanced-cropper__stencil-wrapper {
    position: absolute;
  }

  .vue-bounding-box {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .vue-bounding-box__handler {
    position: absolute;
  }
  .vue-bounding-box__handler--west-north {
    left: 0;
    top: 0;
  }
  .vue-bounding-box__handler--north {
    left: 50%;
    top: 0;
  }
  .vue-bounding-box__handler--east-north {
    left: 100%;
    top: 0;
  }
  .vue-bounding-box__handler--east {
    left: 100%;
    top: 50%;
  }
  .vue-bounding-box__handler--east-south {
    left: 100%;
    top: 100%;
  }
  .vue-bounding-box__handler--south {
    left: 50%;
    top: 100%;
  }
  .vue-bounding-box__handler--west-south {
    left: 0;
    top: 100%;
  }
  .vue-bounding-box__handler--west {
    left: 0;
    top: 50%;
  }

  .vue-handler-wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
  }
  .vue-handler-wrapper__draggable {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vue-handler-wrapper--west-north {
    cursor: nw-resize;
  }
  .vue-handler-wrapper--north {
    cursor: n-resize;
  }
  .vue-handler-wrapper--east-north {
    cursor: ne-resize;
  }
  .vue-handler-wrapper--east {
    cursor: e-resize;
  }
  .vue-handler-wrapper--east-south {
    cursor: se-resize;
  }
  .vue-handler-wrapper--south {
    cursor: s-resize;
  }
  .vue-handler-wrapper--west-south {
    cursor: sw-resize;
  }
  .vue-handler-wrapper--west {
    cursor: w-resize;
  }
  .vue-handler-wrapper--disabled {
    cursor: auto;
  }

  .vue-draggable-area {
    position: relative;
  }

  .vue-preview-result {
    overflow: hidden;
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .vue-preview-result__wrapper {
    position: absolute;
  }
  .vue-preview-result__image {
    pointer-events: none;
    position: relative;
    user-select: none;
    transform-origin: center;
    max-width: none !important;
  }

  .vue-preview {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
  }
  .vue-preview--fill {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .vue-preview__wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .vue-preview__image {
    pointer-events: none;
    position: absolute;
    user-select: none;
    transform-origin: center;
    max-width: none !important;
  }

  .vue-simple-line {
    background: none;
    transition: border 0.5s;
    border-color: rgba(255, 255, 255, 0.3);
    border-width: 0;
    border-style: solid;
  }
  .vue-simple-line--south, .vue-simple-line--north {
    height: 0;
    width: 100%;
  }
  .vue-simple-line--east, .vue-simple-line--west {
    height: 100%;
    width: 0;
  }
  .vue-simple-line--east {
    border-right-width: 1px;
  }
  .vue-simple-line--west {
    border-left-width: 1px;
  }
  .vue-simple-line--south {
    border-bottom-width: 1px;
  }
  .vue-simple-line--north {
    border-top-width: 1px;
  }
  .vue-simple-line--hover {
    opacity: 1;
    border-color: white;
  }

  .vue-line-wrapper {
    background: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vue-line-wrapper--north, .vue-line-wrapper--south {
    height: 12px;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
  }
  .vue-line-wrapper--north {
    top: 0;
    cursor: n-resize;
  }
  .vue-line-wrapper--south {
    top: 100%;
    cursor: s-resize;
  }
  .vue-line-wrapper--east, .vue-line-wrapper--west {
    width: 12px;
    height: 100%;
    transform: translateX(-50%);
    top: 0;
  }
  .vue-line-wrapper--east {
    left: 100%;
    cursor: e-resize;
  }
  .vue-line-wrapper--west {
    left: 0;
    cursor: w-resize;
  }
  .vue-line-wrapper--disabled {
    cursor: auto;
  }

  .vue-simple-handler {
    display: block;
    background: white;
    height: 10px;
    width: 10px;
  }

  .vue-rectangle-stencil {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .vue-rectangle-stencil__preview {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .vue-rectangle-stencil--movable {
    cursor: move;
  }

  .vue-circle-stencil {
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: content-box;
    cursor: move;
  }
  .vue-circle-stencil__preview {
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .vue-circle-stencil--movable {
    cursor: move;
  }

  .upload-cropper {
    border: solid 1px;
    min-height: 300px;
    min-width: 300px;
    max-height: 300px;

    &__preview {
      border: solid 1px rgba(white, 0.15);
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }

  .button {
    cursor: pointer;
  }

  .button input {
    display: none;
  }
/* purgecss end ignore */
</style>