<template>

  <div class="">

    <div class="thumbnail">
      <img :src="selectedLounge.image" :alt="selectedLounge.name">
      <div v-if="!isReservationRequest()" class="content">
        <div class="d-flex flex-wrap align-items-center lounge-infos w-100">
          <div class="col-4">
            <div class="lounge-info-title h4 mb--0">{{ selectedLounge.available_places }}</div>
            {{ getTranslation('module_areas_lounges_preview_persons', 'Personen') }}
          </div>
          <div class="col-4">
            <div class="lounge-info-title h4 mb--0">{{ format(selectedLounge.minimum_consumption) }}</div>
            {{ getTranslation('module_areas_lounges_preview_minimum_consumption', 'Mindestverzehr') }}
          </div>
          <div class="col-4">
            <div class="lounge-info-title h4 mb--0">
              {{ format((selectedLounge.minimum_consumption) / selectedLounge.available_places) }}
            </div>
            {{ getTranslation('module_areas_lounges_preview_per_person', 'pro Person') }}
          </div>

        </div>
      </div>
    </div>


  </div>

</template>

<script>
import { mapState } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

let components;

try {
  components = require("../../../data/child_strings");
} catch (e) {
  // console.log('fallback to default');
  components = require("../../../data/default_strings");
}
components = components.default;
export default {
  name: "preview",
  data() {
    return {
      components,
    };
  },
  props: ["hideDetails"],
  inject: ["getTranslation"],
  computed: {
    ...mapState(useCartStore, ["selectedLounge"]),
    ...mapState(useDataStore, ["isLoungeReservationRequestsOnly"]),
  },
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    formatDate(date, format) {
      return moment(date).format(format);
    },
    isReservationRequest() {
      return this.isLoungeReservationRequestsOnly(this.selectedLounge.id);
    },
  },
};
</script>

<style scoped lang="scss">

.thumbnail {
  position: relative;
}

.lounge-infos {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--color-darker);
  padding: 1rem;
}
</style>