<template>
  <div class="animate__animated animate__fadeIn animate__fast d-flex flex-wrap justify-content-center">

    <div class="p-4 pt-2 d-flex justify-content-center flex-wrap" v-if="params.activeComponent === 'validateBooking'">
      <validateBooking :params="params"
      @setModalTitle="setModalTitle"
      @changeComponent="changeComponent"/>
    </div>

    <div class="ticket-container col-12 col-lg-9 p-4 pt-2 d-flex justify-content-center flex-wrap" v-else-if="params.activeComponent === 'listDrinks'">

      <render-drinks
          @setModalTitle="setModalTitle"
          :params="params"
          :selected_drink_menu="selected_drink_menu"
          :selected_drink_category="selected_drink_category" />

    </div>

    <div class="col-8" v-else-if="params.activeComponent === 'drinkMenuSelection'">
      <drink-menu-selection
          @setModalTitle="setModalTitle"
          @selectDrinkMenu="selectDrinkMenu"
          :selected_drink_menu="selected_drink_menu"
          :selected_drink_category="selected_drink_category"
          :params="params">
      </drink-menu-selection>
    </div>


    <div class="shop-footer-spacing"></div>

  </div>
</template>


<script >
import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import DrinkMenuSelection from "./drinkMenuSelection.vue";
import RenderDrinks from "./renderDrinks.vue";
import validateBooking from "./validateBooking.vue";

let components;

try {
  components = require("../../../data/child_strings");
} catch (e) {
  components = require("../../../data/default_strings");
}
components = components.default;
export default {
  name: "Drinks",
  components: {
    DrinkMenuSelection,
    RenderDrinks,
    validateBooking,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drinks: [],
      codeVisible: false,
      codeIsLoading: false,
      code: null,
      _selected_drink_menu: null,
      _selected_drink_category: null,
    };
  },
  emits: ["changeComponent", "setModalTitle"],
  computed: {
    ...mapStores(useAuthStore, useDataStore, useCartStore),
    ...mapState(useDataStore, [
      "event",
      "settings",
      "drink_menus",
      "drink_categories",
    ]),
    selected_drink_menu() {
      return this._selected_drink_menu;
    },
    selected_drink_category() {
      return this._selected_drink_category;
    },
  },
  methods: {
    changeComponent(payload) {
      const params = {
        module: payload.module,
        component: payload.component,
        params: payload.params,
      };
      this.$emit("changeComponent", params);
    },
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
    selectDrinkMenu(drink_menu) {
      this.setModalTitle(drink_menu?.title);
      this._selected_drink_menu = drink_menu;
      this._selected_drink_category = drink_menu?.categories?.length
        ? drink_menu?.categories[0]
        : null;
      this.changeComponent({
        module: "Shop",
        component: "drinks",
        params: {
          activeComponent: "listDrinks",
        },
      });
    },
  },
  // activated() {
  //   this.setModalTitle(components[this.params.activeComponent].title);
  // }
};
</script>

<style lang="scss" scoped>
.form-select {
  width: 100%;
  color: #fff;
}
</style>
