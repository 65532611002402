import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { toRaw } from "vue";
import drink_api from "../api/drink_api";
import event_api from "../api/event_api";
import language_api from "../api/language_api";
import lounge_api from "../api/lounge_api";
import settings_api from "../api/settings_api";
import { useAreasStore } from "./areas.store";
import { useCartStore } from "./cart_store";

export const useDataStore = defineStore({
  id: "data",
  state: () => ({
    _events: useSessionStorage("data_events", []),
    _currentEvent: useSessionStorage("data_currentEvent", null),
    _currentEventId: useSessionStorage("data_currentEventId", null),
    _tickets: useSessionStorage("data_tickets", []),
    _lounges: useSessionStorage("data_lounges", []),
    _drink_menus: useSessionStorage("data_drink_menus", []),
    _app_settings: useSessionStorage("data_app_settings", null),
    _language: useSessionStorage("data_language", null),
    _config: useSessionStorage("data_config", null),
  }),
  getters: {
    config: (state) => state._config,
    settings: (state) => state._app_settings,
    events: (state) => state._events,
    event: (state) =>
      state._events.find((item) => {
        console.log("item", item);
        return item.id == state._currentEventId;
      }),
    currentEventId: (state) => state._currentEventId,
    tickets: (state) => state._tickets,
    lounges: (state) => state._lounges,
    drink_menus: (state) => state._drink_menus,
    ticketsAvailable: (state) =>
      state._currentEvent?.ticket_status == "available",
    getLounge: (state) => {
      return (lounge_id) => {
        if (!state._lounges || !state._lounges.length) {
          throw new Error("Keine Lounges Verfügbar");
        }
        let lounge = state._lounges.find((item) => item.id == lounge_id);
        if (state._currentEvent?.lounges?.length) {
          const _lounge = state._currentEvent.lounges.find(
            (item) => item.id == lounge_id
          );
          if (_lounge) {
            // merge lounge data from event and overwrite lounge data from lounge
            lounge = { ...lounge, ..._lounge };
          }
        }
        return lounge;
      };
    },
    getTicket: (state) => {
      return (ticket_id) => {
        if (!state._tickets || !state._tickets.length) {
          throw new Error("Keine Tickets Verfügbar");
        }
        return state._tickets.find((item) => item.id == ticket_id);
      };
    },
    isLoungeBlocked: (state) => {
      return (lounge_id) => {
        if (!state._lounges || !state._lounges.length) {
          console.warn("Keine Lounges Verfügbar");
          return false;
        }
        let lounge = state._lounges.find((lounge) => {
          return lounge.id === lounge_id;
        });

        if (state._currentEvent?.lounges?.length) {
          const _lounge = state._currentEvent.lounges.find((lounge) => {
            return lounge.id === lounge_id;
          });
          if (lounge) {
            lounge = { ..._lounge, ...lounge };
          }
        }

        return lounge.blocked && !lounge.show_when_blocked;
      };
    },
    isLoungeAvailable: (state) => {
      return (lounge_id) => {
        if (!state._lounges || !state._lounges.length) {
          console.warn("Keine Lounges Verfügbar");
          return false;
        }
        let lounge = state._lounges.find((lounge) => {
          return lounge.id === lounge_id;
        });

        if (state._currentEvent?.lounges?.length) {
          const _lounge = state._currentEvent.lounges.find((lounge) => {
            return lounge.id === lounge_id;
          });
          if (lounge) {
            lounge = { ..._lounge, ...lounge };
          }
        }

        return !lounge.booked && !lounge.blocked;
      };
    },
    isLoungeAvailableForReservationRequest: (state) => {
      return (lounge_id) => {
        const areasStore = useAreasStore();

        if (!state._lounges || !state._lounges.length) {
          console.warn("Keine Lounges Verfügbar");
          return false;
        }
        let lounge = state._lounges.find((lounge) => {
          return lounge.id === lounge_id;
        });
        if (
          areasStore.areas.find((area) => area.id === lounge.area?.id)
            ?.reservations_requests_active
        ) {
          return true;
        }

        if (!lounge) {
          return false;
        }

        if (state._currentEvent?.lounges?.length) {
          const _lounge = state._currentEvent.lounges.find((lounge) => {
            return lounge.id === lounge_id;
          });
          if (lounge) {
            lounge = { ..._lounge, ...lounge };
          }
        }
        return lounge.reservation_requests && lounge.blocked;
      };
    },
    isLoungeReservationRequestsOnly: (state) => {
      return (lounge_id) => {
        if (!state._lounges || !state._lounges.length) {
          console.warn("Keine Lounges Verfügbar");
          return false;
        }
        const lounge = state._lounges.find((lounge) => {
          return lounge.id === lounge_id;
        });

        // Check if every event has a reservation request for this lounge
        const eventsLounges =
          state._events
            ?.map((event) => event.lounges)
            .map((lounges) =>
              lounges.filter((lounge) => lounge.id === lounge_id)
            ) ?? [];
        return (
          eventsLounges.flat().filter((l) => l.reservation_requests).length ===
          eventsLounges.flat().length
        );
      };
    },
    isLoungeAvailableForReservationRequestForEvent: (state) => {
      return (lounge_id, event_id) => {
        const event = state._events.find((item) => item.id === event_id);
        if (!event) {
          console.warn("Kein Event Verfügbar");
          return false;
        }
        const lounge = event.lounges.find((_lounge) => {
          return _lounge.id === lounge_id;
        });
        return lounge.reservation_requests && lounge.blocked;
      };
    },
    isLoungeBlockedForEvent: (state) => {
      // check if lounge is blocked for event
      return (lounge_id, event_id) => {
        const event = state._events.find((item) => item.id == event_id);
        if (!event) {
          console.warn("Kein Event Verfügbar");
          return { blocked: false, show_when_blocked: false };
        }
        const lounge = event.lounges.find((_lounge) => {
          return _lounge.id == lounge_id;
        });

        return {
          blocked: lounge?.blocked,
          show_when_blocked: lounge?.show_when_blocked,
        };
      };
    },
    isLoungeAvailableForEvent: (state) => {
      // check if lounge is booked for event
      return (lounge_id, event_id) => {
        const event = state._events.find((item) => item.id == event_id);
        if (!event) {
          console.warn("Keine Events Verfügbar");
          return false;
        }
        const booked =
          event.lounges.filter((_lounge) => {
            return (
              _lounge.id == lounge_id && !_lounge.booked && !_lounge.blocked
            );
          }).length > 0;
        return booked;
      };
    },
    getPackage: (state) => {
      return (lounge_id, package_id) => {
        if (!state._lounges?.length) {
          throw new Error("Keine Lounges im Store");
        }

        const lounge = state._lounges.find(
          (lounge) => lounge.id === Number.parseInt(lounge_id)
        );
        return (
          lounge?.packages?.find(
            (item) => item.id === Number.parseInt(package_id)
          ) || null
        );
      };
    },
    getEvent: (state) => {
      return (event_id) => {
        if (state._events === null) {
          throw new Error("Keine Events im Store");
        }
        return state._events.find(
          (event_id) => event.id === Number.parseInt(event_id)
        );
      };
    },
    getDrink(state) {
      return (drink_id) => {
        if (state._drink_menus === null || state._drink_menus?.length === 0) {
          throw new Error("Keine Drink Menus im Store");
        }
        // find drink by id in drink menus[] -> categories[] -> drinks[]
        for (let i = 0; i < state._drink_menus.length; i++) {
          const drink_menu = state._drink_menus[i];
          for (let j = 0; j < drink_menu.categories.length; j++) {
            const category = drink_menu.categories[j];
            for (let k = 0; k < category.drinks.length; k++) {
              const drink = category.drinks[k];
              if (drink.id === Number.parseInt(drink_id)) {
                return drink;
              }
            }
          }
        }
      };
    },
    getDrinkMenus: (state) => {
      return state._drink_menus;
    },
    getDrinkMenu: (state) => {
      return (drink_menu_id) => {
        if (state._drink_menus === null) {
          throw new Error("Keine Drink Menus im Store");
        }
        return state._drink_menus.find(
          (drink_menu) => drink_menu.id === Number.parseInt(drink_menu_id)
        );
      };
    },
    getLanguage: (state) => {
      return state._language;
    },
    getLanguageStringForKey: (state) => {
      return (key) => {
        if (!state._language) {
          throw new Error("Keine Sprache im Store");
        }
        return state._language[key];
      };
    },
  },
  actions: {
    loadEvents() {
      return event_api.fetchEvents().then((data) => {
        this._events = [];
        data.events.forEach((event) => {
          this._events.push({
            id: event.id,
            name: event.name,
            desc: event.details,
            start_time: event.start_time,
            end_time: event.end_time,
            image: event.image,
            box_office: event.box_office_available,
            vip_factor: event.vip_factor,
            ticket_status: event.ticket_status,
            invoice_address_possible: event.invoice_address_possible,
            personalized_tickets: event.personalized_tickets,
            vat: event.vat,
            lounges: event.lounges,
            has_free_tickets: event.has_free_tickets,
          });
        });
      });
    },
    loadLounges() {
      return lounge_api.fetchLounges().then((data) => {
        return (this._lounges = data.lounges);
      });
    },
    loadData(event) {
      console.log("loadData", event);
      this._currentEventId = event;
      return event_api
        .fetchData(event)
        .then((data) => {
          this._currentEvent = {
            id: data.event.id,
            name: data.event.name,
            desc: data.event.details,
            start_time: data.event.start_time,
            end_time: data.event.end_time,
            image: data.event.image,
            box_office: data.event.box_office_available,
            vip_factor: data.event.vip_factor,
            ticket_status: data.event.ticket_status,
            invoice_address_possible: data.event.invoice_address_possible,
            personalized_tickets: data.event.personalized_tickets,
            vat: data.event.vat,
            lounges: data.event.lounges,
            has_free_tickets: data.event.has_free_tickets,
          };

          if (!this._events.find((item) => item.id == data.event.id)) {
            const events = this._events;
            events.push(this._currentEvent);
            this._events = events;
          }

          this._tickets = data.event.tickets;
        })
        .then(() => {
          const cartStore = useCartStore();
          if (cartStore._selectedLounge) {
            return this.loadLoungePackages(cartStore._selectedLounge);
          }
        });
    },
    loadDrinkMenus() {
      return drink_api.fetchDrinkMenus().then((data) => {
        this._drink_menus = data.drink_menus;
        return this._drink_menus;
      });
    },
    loadLoungePackages(lounge_id) {
      if (!this._lounges?.length) {
        console.warn("Keine Lounges im Store");
        return;
      }

      return lounge_api.fetchLoungePackages(lounge_id).then((data) => {
        const loungeIndex = this._lounges.findIndex(
          (item) => item.id == lounge_id
        );
        if (loungeIndex !== -1) {
          // Create a new object to ensure reactivity
          this._lounges[loungeIndex] = {
            ...this._lounges[loungeIndex],
            packages: data.lounge.packages,
          };
        }
        return this._lounges[loungeIndex];
      });
    },
    loadSettings() {
      this._app_settings = window.disco2app.settings;
      return this._app_settings;
    },
    setLanguage(lang_code) {
      return language_api.getLanguage(lang_code).then((data) => {
        return (this._language = data);
      });
    },
    setConfig(config) {
      this._config = config;
    },
  },
});
