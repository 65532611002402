<script setup>
import { computed, ref, watch } from "vue";
import { useTranslation } from "../../composables/useTranslation";

const emit = defineEmits(["update:selectedStatuses"]);

const selectedStatuses = ref([]);
const isOpen = ref(false);

const selectStatusText = useTranslation(
  "module_shop_component_lounges_select_statuses_select_status",
  "Select status",
);
const selectAllText = useTranslation(
  "module_shop_component_lounges_select_statuses_select_all",
  "Select all statuses",
);
const nStatusesSelectedText = useTranslation(
  "module_shop_component_lounges_select_statuses_n_statuses_selected",
  "Status(es) selected",
);

const statuses = [
  {
    id: "booked",
    name: useTranslation(
      "module_shop_component_lounges_status_booked",
      "Booked",
    ),
  },
  {
    id: "free",
    name: useTranslation("module_shop_component_lounges_status_free", "Free"),
  },
  {
    id: "on_request",
    name: useTranslation(
      "module_shop_component_lounges_status_on_request",
      "On Request",
    ),
  },
];

const selectedStatusesText = computed(() => {
  if (selectedStatuses.value.length === 0) return selectStatusText;
  if (selectedStatuses.value.length === statuses.length) return selectAllText;
  return `${selectedStatuses.value.length} ${nStatusesSelectedText}`;
});

const toggleStatus = (statusId) => {
  const index = selectedStatuses.value.indexOf(statusId);
  if (index === -1) {
    selectedStatuses.value.push(statusId);
  } else {
    selectedStatuses.value.splice(index, 1);
  }
};

const toggleAll = () => {
  if (selectedStatuses.value.length === statuses.length) {
    selectedStatuses.value = [];
  } else {
    selectedStatuses.value = statuses.map((status) => status.id);
  }
};

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

watch(
  selectedStatuses,
  (newValue) => {
    emit("update:selectedStatuses", newValue);
  },
  { deep: true },
);
</script>

<template>
  <div class="status-filter dropdown" :class="{ 'show': isOpen }">
    <button class="dropdown-toggle" @click="toggleDropdown" type="button" id="statusFilterDropdown"
      aria-expanded="false">
      {{ selectedStatusesText }}
    </button>
    <div class="dropdown-menu dropdown-menu-start" :class="{ 'show': isOpen }" aria-labelledby="statusFilterDropdown">
      <button class="dropdown-item" @click="toggleAll">
        <span class="checkmark" v-if="selectedStatuses.length === statuses.length">✓</span>
        <span class="status-name">{{ selectAllText }}</span>
      </button>
      <div class="dropdown-divider"></div>
      <div class="dropdown-item-list">
        <button v-for="status in statuses" :key="status.id" class="dropdown-item" @click="toggleStatus(status.id)"
          :aria-checked="selectedStatuses.includes(status.id)">
          <span class="checkmark" v-if="selectedStatuses.includes(status.id)">✓</span>
          <span class="status-name">{{ status.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.status-filter {
  position: relative;

  .dropdown-toggle {
    background: transparent;
    color: var(--color-white);
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1.5rem;

    &::after {
      margin-left: .5em;
      vertical-align: 0.2em;
    }
  }

  .dropdown-menu {
    max-width: 300px;
    max-height: 300px;
    overflow-y: auto;
    background: var(--color-black);
    border: var(--border-width) solid var(--color-lighter);
  }

  .dropdown-item {
    color: var(--color-white);
    cursor: pointer;
    padding: 0.75rem 1rem;
    position: relative;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      background-color: var(--color-primary);
    }

    .checkmark {
      position: absolute;
      left: 1rem;
      font-size: 1.75rem;
    }

    .status-name {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .dropdown-item-list {
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
