import axios from "axios";

const settings_api = {
  getSettings() {
    return axios.get("/api/app/settings/?context=web", {}).then((resp) => {
      return resp.data;
    });
  },
  getConfig() {
    return axios.get("/api/app/config", {}).then((resp) => {
      return resp.data;
    });
  },
  logSession(fromLogin = false) {
    var url = "/api/log/session";
    if (fromLogin) {
      url += "?fromlogin";
    }

    return axios.post(url, {}).then((resp) => {
      return resp.data;
    });
  },
};

export default settings_api;
