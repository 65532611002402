import { defineStore } from "pinia";

import { useSessionStorage } from "@vueuse/core";

import { ref, watch } from "vue";
import user_api from "../api/user_api";
import { useAuthStore } from "./auth_store";

import lounge_api from "../api/lounge_api";
import { serializer } from "../src/utils/utils";

let target_settings;
try {
  target_settings = require("../settings/target_settings");
} catch (e) {
  console.log("fallback to default");
  target_settings = require("../settings/default_settings");
}
target_settings = target_settings.target_settings;

export const useUserStore = defineStore("user", () => {
  const auth = useAuthStore();

  // STATE
  const userInfos = ref(useSessionStorage("userInfos", null, { serializer }));

  const bookings = ref(null);

  //ACTIONS
  // TODO(flo): we can probably delete this
  function isUserMissingInfos() {
    const infos = JSON.parse(userInfos.value);
    if (!infos.firstname) return true;
    if (!infos.lastname) return true;
    // if (!infos.username) return true;
    if (!infos.email) return true;
    if (!infos.birthday) return true;
    if (!infos.gender) return true;

    if (
      target_settings?.register?.addressRequired ||
      target_settings?.register?.addressRequired === undefined
    ) {
      // TODO(flo): street only if not paypal: https://www.loom.com/share/2d99480e27aa40cf937a63e1f561a233?sid=95574f6a-452a-48a9-bb46-225825a986e9
      if (!infos.street) return true;
      if (!infos.place) return true;
      if (!infos.zip) return true;
    }
    return false;
  }

  function refreshUserInfos(token = null) {
    if (auth.isAuthenticated) {
      return user_api
        .refreshUserInfos(token === null ? auth.token : token)
        .then((infos) => {
          userInfos.value = JSON.stringify(infos);
          return infos;
          // console.log(JSON.parse(userInfos.value))
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // throw new Error('Nicht eingeloggt');
  }

  function updateUserInfos(payload) {
    const auth = useAuthStore();
    if (auth.isAuthenticated) {
      return user_api
        .updateUserInfos(auth.token, payload)
        .then((infos) => {
          userInfos.value = JSON.stringify(infos);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    }
    throw new Error("Nicht eingeloggt");
  }

  function clear() {
    userInfos.value = null;
    bookings.value = null;
  }

  function loadBookings() {
    const auth = useAuthStore();
    if (auth.isAuthenticated()) {
      return lounge_api
        .getLoungeBookings({ token: auth.token })
        .then((_bookings) => {
          bookings.value = _bookings;
          return _bookings;
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    }
    throw new Error("Nicht eingeloggt");
  }

  function getBookingForEvent(event) {
    return bookings.value.find((booking) => {
      return booking.event_id === event.id && booking.status === "SUCCEEDED";
    });
  }

  function hasUserValidBookingForEvent(event) {
    // do not resolve on failure
    if (!bookings?.value) {
      return loadBookings().then((bookings) => {
        return hasUserValidBookingForEvent(event);
      });
    }
    return new Promise((resolve, reject) => {
      const booking = getBookingForEvent(event);
      if (booking) {
        resolve(booking);
      }
    });
  }

  return {
    userInfos: userInfos.value ? JSON.parse(userInfos.value) : userInfos.value,
    userInfosRef: userInfos,
    bookings: bookings.value,
    bookingsRef: bookings,
    isUserMissingInfos,
    refreshUserInfos,
    updateUserInfos,
    loadBookings,
    hasUserValidBookingForEvent,
    clear,
  };
});
