import axios from "axios";

const auth_api = {
  register(payload) {
    return axios({
      method: "post",
      url: "/api/auth/register",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  login(payload) {
    const params = new URLSearchParams(payload);

    return axios.post("/api/auth/login", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data.token;
    });
  },
  resetPassword(payload) {
    const params = new URLSearchParams(payload);

    return axios.post("/api/user/password/reset", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data.token;
    });
  },
  changePassword(payload, token) {
    const params = new URLSearchParams({ ...payload, token });

    return axios.post("/api/user/password/change", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  deleteAccount(token) {
    const params = new URLSearchParams({ token });

    return axios.post("/api/user/delete/account", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  sendVerificationEmail(token) {
    const params = new URLSearchParams({ token });

    return axios.post("/api/auth/verification/email", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  sendVerificationCode(token, payload) {
    const params = new URLSearchParams(Object.assign({ token }, payload));

    return axios.post("/api/auth/verification/code", params).then((resp) => {
      if (resp.data.error !== undefined || !resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  verifyCode(token, payload) {
    const params = new URLSearchParams(Object.assign({ token }, payload));
    return axios
      .post("/api/auth/verification/verify/code", params)
      .then((resp) => {
        if (resp.data.error !== undefined || !resp.data.success) {
          throw new Error(resp.data.message);
        }

        return resp.data;
      });
  },
};

export default auth_api;
