
<template>
  <div class="drink-menu-container" v-if="drink_menus?.length > 0 && !inCheckout">

    <div class="drink drink-menu mb-4 d-flex flex-wrap w-100 animate__animated animate__fadeIn animate__fast"
         v-for="drink_menu in drink_menus" :class="!inCheckout ? 'mb-4':''"
        @click="selectDrinkMenu(drink_menu)"
    >

      <div class="col-12 w-100 d-flex justify-content-center align-items-center">
        <div v-if="drink_menu.image" class="drink-menu-image" :style="'background-image: url(' + drink_menu.image + ')'"></div>
        <div v-else class="drink-menu-image logo" :style="'background-image: url(' + logo + ')'"></div>
      </div>

      <div class="col-12 w-100 d-flex flex-wrap mt-4 justify-content-center align-items-center" style="width: fit-content;">

        <div class="w-100 text-center">
          <h4 class="title m-0">{{ drink_menu.title }}</h4>
        </div>

        <div class="text-center mt-2 description">
          <p class="m-0">{{ drink_menu.description }}</p>
        </div>

      </div>

    </div>
  </div>
</template>


<script>
import { mapState, mapStores } from "pinia";

import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

const light_logos = document.querySelector("header a img.logo-light").srcset;
const dark_logos = document.querySelector("header a img.logo-dark").srcset;
const light_logo = light_logos.split(", ")[1];
const dark_logo = dark_logos.split(", ")[1];
export default {
  name: "drinkMenuSelection",
  components: {},
  props: {
    params: {
      type: Object,
      required: true,
    },
    inCheckout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapStores(useDataStore, useCartStore),
    ...mapState(useDataStore, ["event", "settings", "drink_menus"]),
    logo() {
      const logo = document.body.classList.contains("active-dark-mode")
        ? light_logo
        : dark_logo;
      return logo ? logo.split(" ")[0] : "";
    },
  },
  data() {
    return {};
  },
  inject: ["getTranslatedTitleForComponent"],
  emits: ["selectDrinkMenu", "setModalTitle"],
  methods: {
    selectDrinkMenu(drink_menu) {
      this.cartStore.clearSelectedDrinks();
      this.$emit("selectDrinkMenu", drink_menu);
    },
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
  },
  activated() {
    this.setModalTitle(
      this.getTranslatedTitleForComponent("drinkMenuSelection"),
    );
  },
};
</script>


<style scoped>

</style>