import axios from "axios";

const ticket_api = {
  reedemTicket(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/tickets/redeem", params).then((resp) => {
      return resp.data;
    });
  },
  getTicketOrdering(payload) {
    const params = new URLSearchParams(payload);
    return axios.get("/api/user/order", { params }).then((resp) => {
      if (resp.status === 200) {
        return resp.data;
      } else {
        throw new Error("Ein Fehler ist aufgetreten");
      }
    });
  },
  checkForDiscounts(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/tickets/check/discounts", params).then((resp) => {
      return resp.data;
    });
  },
};

export default ticket_api;
