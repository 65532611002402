<script setup>
import { useVModel } from "@vueuse/core";

const props = defineProps({
  defaultValue: {
    type: [String, Number],
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  class: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["update:modelValue"]);

const modelValue = useVModel(props, "modelValue", emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <input v-model="modelValue" :class="['form-control', props.class]">
</template>