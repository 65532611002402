<template>

  <div>
    <div class="register-step-info">
      <h4 class="mb-1">{{ getTranslation('module_shop_component_register_step2_title', 'Weiter gehts..') }}</h4>
      {{ getTranslation('module_shop_component_register_step2_text', 'Wie heißt du und wer bist du?') }}
    </div>

    <div class="d-flex flex-wrap pt-2">

      <div class="col-12 px-md-2 text-left form-group w-100">
        <div class="form-check form-check-inline ps-0 pe-3" v-for="(gender, key) in genders">
          <Field as="input" class="form-check-input" type="radio" name="gender" :id="key" :value="key"
                 :class="errors.gender?'is-invalid':''"></Field>
          <label class="form-check-label" :for="key">{{ gender }}</label>
        </div>
      </div>

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" :placeholder="getTranslation('firstname_label', 'Vorname')" name="firstname" class="form-control" id="firstname" :class="errors.firstname ? 'is-invalid':''"/>
          <label for="firstname">{{ getTranslation('firstname_label', 'Vorname') }}</label>
          <small class="text-danger" v-if="errors.firstname">{{ errors.firstname }}</small>
        </div>
      </div>

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" :placeholder="getTranslation('lastname_label', 'Nachname')" name="lastname" class="form-control" id="lastname" :class="errors.lastname ? 'is-invalid':''"/>
          <label for="lastname">{{ getTranslation('lastname_label', 'Nachname') }}</label>
          <small class="text-danger" v-if="errors.lastname">{{ errors.lastname }}</small>
        </div>
      </div>

    </div>

    <div class="d-flex flex-wrap pt-2 pt-2">

      <div class="col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <!-- NOTE(flo): start value is '' so we can add some CSS for date fields -->
          <Field as="input" type="date" :placeholder="getTranslation('birthday_placeholder', 'Geburtsdatum')" name="birthday" class="form-control" id="birthday" :class="errors.birthday ? 'is-invalid':''" required></Field>
          <label for="birthday">{{ getTranslation('birthday_label', 'Geburtsdatum') }}</label>
          <small class="text-danger" v-if="errors.birthday">{{ errors.birthday }}</small>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { Field } from "vee-validate";

export default {
  name: "step2",
  props: ["errors"],
  inject: ["getTranslation"],
  emits: [],
  components: {
    Field,
  },
  data() {
    return {
      genders: {
        männlich: this.getTranslation("gender_männlich_formal", "Herr"),
        weiblich: this.getTranslation("gender_weiblich_formal", "Frau"),
        divers: this.getTranslation("gender_divers", "Divers"),
      },
    };
  },
};
</script>

<style scoped>

</style>