<template>

  <div class="col-lg-9 col-12 mt-4">

    <Form @submit="sendForgotPasswordEmail" :class="formClasses" id="login-form" :validation-schema="schema" v-slot="{ errors }">
      <div class="d-flex flex-column flex-wrap pt-2 justify-content-center align-items-center">


        <h5 class="m-0 text-center">{{ getTranslation('module_shop_component_forgotpassword_text', 'Gib bitte deine E-Mail Adresse ein, damit wir dein Passwort zurücksetzen können.') }}</h5>

        <div class="col-sm-8 col-lg-4 col-12 px-2 mt-4">

          <div class="form-floating form-group w-100">
            <Field as="input" type="email" :placeholder="getTranslation('email_placeholder', 'E-Mail')" name="email" autocomplete="username" class="form-control" :class="errors.email ? 'is-invalid':''"/>
            <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
            <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
          </div>
        </div>

      </div>

      <div class="d-flex justify-content-center w-100 mt-2 mb-4">
        <button type="submit" class="btn btn-primary mt-2 px-4 mb-4" :disabled="emailTimer > 0" data-umami-event="forgot-password-button">
          <span v-if="emailTimer > 0 && !isLoading">{{ emailTimer }} s</span>
          <span v-if="emailTimer === 0 && !isLoading">{{ getTranslation('module_shop_component_password_sendmail_button_text', 'E-Mail anfordern') }}</span>
          <span v-if="isLoading" class="spinner-border-sm"></span>
        </button>
      </div>

    </Form>

  </div>

</template>

<script>
import Swal from "sweetalert2";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";

let components;

try {
  components = require("../../../data/child_strings");
} catch (e) {
  components = require("../../../data/default_strings");
}
components = components.default;

export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
  },
  props: ["formClasses"],
  inject: ["getTranslation"],
  data() {
    const schema = {
      email: yup
        .string()
        .required(
          this.getTranslation("error_email_missing", "E-Mail wird benötigt"),
        )
        .trim()
        .email(
          this.getTranslation(
            "error_email_invalid",
            "Das ist keine gültige E-Mail Adresse.",
          ),
        ),
    };
    return {
      schema,
      components,
      isLoading: false,
      emailTimer: 0,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  watch: {
    emailTimer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            if (this.emailTimer > 0) {
              this.emailTimer--;
            }
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  emits: ["changeComponent"],
  methods: {
    sendForgotPasswordEmail(values) {
      if (this.emailTimer === 0) {
        this.isLoading = true;

        this.authStore.resetPassword(values).then((res) => {
          this.emailTimer = 60;

          Swal.fire({
            title: this.getTranslation("success_message_title", "Erfolgreich!"),
            icon: "success",
            text: this.getTranslation(
              "module_shop_component_password_sendmail_success_text",
              "E-Mail wurde versendet.",
            ),
          }).then(() => {
            this.changeComponent("login");
          });
          this.isLoading = false;
        }).catch((error) => {
          Swal.fire({
            title: this.getTranslation("error_unknown", "Ein Fehler ist aufgetreten"),
            icon: "error",
            text: error.message,
          });
          throw error;
        });
      }
    },
    changeComponent(component, module = "Auth") {
      this.$emit("changeComponent", { component: component, module: module });
    },
  },
};
</script>

<style scoped>

</style>