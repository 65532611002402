<template>

  <div class="drink d-flex w-100 flex-wrap animate__animated animate__fadeIn animate__fast" v-for="drink in drinks" :class="!inCheckout ? 'mb-4':''">

    <div class="d-flex w-100">
      <div class="d-flex flex-wrap flex-shrink-1 pe-4" >

        <div class="package-image" :style="drink.image ? 'background-image: url(' + drink.image + ')' : ''"></div>

      </div>

      <div class="d-flex flex-wrap flex-grow-1" style="width: fit-content;">
        <div class="col-12">
          <div class="d-flex flex-shrink-1 justify-content-between">
            <h5 class="m-0">{{ drink.name }}</h5>
            <i class="feather feather-info"
               style="cursor: pointer;font-size: 20px;"
               @click="showAdditives(drink)"
            ></i>
          </div>
        </div>

        <div class="col-12">
          <span v-html="drink.description"></span>
        </div>

        <div class="col-12">
          <span v-if="drink.content">{{ drink.content }}</span>
        </div>

        <div class="col-12 d-flex justify-content-between">

          <div class="d-flex flex-wrap flex-grow-1">
            <Transition name="slide-fade-left">
              <div class="col-12 ticket-pricing mt-2">
                <span class="ticket-price">{{ format(drink.price) }}</span>
                <div class="w-100" v-if="drink.discounted && this.inCheckout && discountCode">
                  <button class="btn btn-sm btn-default me-2" style="width: 16px; height: 16px;padding: .25rem;position:relative;" @click="cartStore.removeDiscountCode('drinks')" data-bs-toggle="tooltip" data-bs-placement="top" title="Rabatt entfernen">
                    <i class="ticket-fee feather-x" style="position:absolute;top:2px;left:2px; font-size: 12px;"></i>
                  </button>
                  <small v-if="showDiscount">{{ discountCode.description }} <span v-if="discountCode.code != ''">({{ discountCode.code }})</span></small>
                </div>
              </div>
            </Transition>
          </div>

          <div class=" d-flex flex-shrink-1 item-picker align-items-center">
            <div class="minus disable-dbl-tap-zoom"
                 :class="isDecrementable(drink.id, drink.price) ? 'minusable':''"
                 @click="_decrementDrinkCount(drink.id, drink.price)"
            >
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z" fill="currentColor" fill-rule="evenodd"/>
              </svg>

            </div>

            <div class="value disable-dbl-tap-zoom">
              <input disabled class="text-center ticket-count" :value="countDrink(drink.id)">
            </div>

            <div class="plus disable-dbl-tap-zoom" @click="_incrementDrinkCount(drink.id, drink.price)"
            :class="isIncrementable(drink.id, drink.price) ? 'plusable':''">
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m44.969 14.441h10.062c2.3984 0 4.3594 1.9609 4.3594 4.3594v21.805h21.805c2.3984 0 4.3594 1.9609 4.3594 4.3594v10.062c0 2.3984-1.9609 4.3594-4.3594 4.3594l-21.805 0.003906v21.805c0 2.3984-1.9609 4.3594-4.3594 4.3594l-10.062 0.003906c-2.3984 0-4.3594-1.9609-4.3594-4.3594l-0.003906-21.809h-21.801c-2.3984 0-4.3594-1.9609-4.3594-4.3594v-10.062c0-2.3984 1.9609-4.3594 4.3594-4.3594h21.805l-0.003906-21.805c0.003906-2.3984 1.9648-4.3633 4.3633-4.3633z" fill="currentColor" fill-rule="evenodd"/>
              </svg>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="d-flex w-100" v-if="additivesShortText(drink)">
      <small style="font-size: 13px;">Inhaltsstoffe: {{ additivesShortText(drink) }}</small>
    </div>

  </div>

</template>


<script >
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

import Swal from "sweetalert2";

export default {
  name: "renderDrink",
  components: {},
  props: {
    drinks: {
      type: Array,
      required: true,
    },
    inCheckout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  inject: ["getTranslation"],
  data() {
    return {};
  },
  computed: {
    ...mapStores(useCartStore),
    ...mapState(useDataStore, ["settings"]),
    ...mapState(useCartStore, [
      "countDrink",
      "discountCode",
      "incrementDrinkCount",
      "decrementDrinkCount",
      "cart_sums",
    ]),
    showDiscount: function () {
      if (
        this.settings["discount-codes"] &&
        this.settings["discount-codes"].active
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    isIncrementable: function (drink_id, price) {
      return (
        Number.parseInt(this.cart_sums.sum) + Number.parseFloat(price) <=
        Number.parseFloat(this.settings.lounges.drink_orderings.limit)
      );
    },
    isDecrementable: function (drink_id, price) {
      return (
        Number.parseInt(this.cart_sums.sum) - Number.parseFloat(price) >=
        Number.parseFloat(this.settings.lounges.drink_orderings.min_consumption)
      );
    },
    _incrementDrinkCount: function (drink_id, price) {
      if (
        Number.parseInt(this.cart_sums.sum) + Number.parseFloat(price) <=
        Number.parseFloat(this.settings.lounges.drink_orderings.limit)
      ) {
        this.cartStore.incrementDrinkCount(drink_id);
      }
    },
    _decrementDrinkCount: function (drink_id, price = "0") {
      if (this.inCheckout) {
        if (
          Number.parseInt(this.cart_sums.sum) - Number.parseFloat(price) >=
          Number.parseFloat(
            this.settings.lounges.drink_orderings.min_consumption,
          )
        ) {
          this.cartStore.decrementDrinkCount(drink_id);
        }
      } else {
        this.cartStore.decrementDrinkCount(drink_id);
      }
    },
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    additivesShortText: (drink) => {
      const additives = [];
      if (drink.additives) {
        drink.additives.forEach((additive) => {
          if (!additives.includes(additive)) {
            additives.push(additive.acronym);
          }
        });
      }
      return additives.join(", ");
    },
    showAdditives: function (drink) {
      const additives = [];
      if (drink.additives) {
        drink.additives.forEach((additive) => {
          if (!additives.includes(additive)) {
            additives.push(additive.name + " (" + additive.acronym + ")");
          }
        });
      }
      const additive_text = additives.join(", ");
      let html = `<div class="d-flex flex-wrap align-items-center">
        <div class="col-12">
          <h5 class="m-0">${drink.name}</h5>
        </div>
        <div class="col-12 d-flex" >
            <div class="d-flex flex-shrink-1" style="min-width: fit-content;">
                <h6 class="m-0 pe-1">Beschreibung:</h6>
            </div>
            <small>${drink.description}</small>
        </div>
        <div class="col-12 d-flex">
            <div class="d-flex flex-shrink-1" style="min-width: fit-content;">
                <h6 class="m-0 pe-1">Inhalt:</h6>
            </div>
          <small>${drink.content}</small>
        </div>`;

      if (additive_text) {
        html += `<div class="col-12 d-flex">
            <div class="d-flex flex-shrink-1" style="min-width: fit-content;">
                <h6 class="m-0 pe-1">Inhaltsstoffe:</h6>
            </div>
          <small>${additive_text}</small>
        </div>`;
      }
      html += `</div>`;
      Swal.fire({
        // title: 'Inhaltsstoffe',
        html: html,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: this.getTranslation(
          "close_button_text",
          "Schließen",
        ),
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>

</style>