<template>

  <div class="drink-search-bar">
    <input type="text" class="form-control" :placeholder="getTranslation('search_placeholder', 'Suche...')" v-model="searchValue" />
  </div>

  <Transition name="slide-fade-left">
    <div class="drink-categories-tabs mb-4" v-if="selected_drink_menu?.categories?.length && !inCheckout && !searchValue">

      <div v-for="drink_category in selected_drink_menu.categories">
        <div class="drink-categories-tab-item" :class="selected_drink_category?.id === drink_category?.id ? 'active':''" :key="drink_category?.id" @click="selected_drink_category = drink_category">
          <div class="drink-categories-tab-item-inner" :class="{'active': selected_drink_category?.id === drink_category?.id}">
            <div class="drink-categories-tab-item-inner-title">
              <h5 class="m-0">{{ drink_category?.name }}</h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Transition>
  <div class="d-flex flex-wrap justify-content-center w-100">

    <render-drink :drinks="drinks_for_category" />

  </div>

</template>


<script >
import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useUserStore } from "../../../store/user_store";
import renderDrink from "./renderDrink.vue";

export default {
  name: "renderDrinks",
  components: {
    renderDrink,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    inCheckout: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected_drink_menu: {
      type: Object,
      required: true,
    },
    selected_drink_category: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      searchValue: "",
    };
  },
  emits: ["setModalTitle"],
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  computed: {
    ...mapStores(useAuthStore, useDataStore, useCartStore, useUserStore),
    ...mapState(useDataStore, ["event", "settings", "drink_menus"]),
    selectedDrinks: function () {
      return this.cartStore.selectedDrinks;
    },
    drinks: function () {
      let drinks = [];
      this.selected_drink_menu?.categories?.forEach((category) => {
        drinks = drinks.concat(category.drinks);
      });
      return drinks;
    },
    drinks_for_category: function () {
      if (this.searchValue) {
        const filteredDrinks = this.drinks.filter((drink) => {
          if (
            drink.name.toLowerCase().includes(this.searchValue.toLowerCase())
          ) {
            return drink;
          }

          if (
            drink.description
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          ) {
            return drink;
          }
        });
        const uniqueNames = new Set(
          filteredDrinks.map((drink) => drink.name.toLowerCase()),
        );
        return Array.from(uniqueNames).map((name) =>
          filteredDrinks.find((drink) => drink.name.toLowerCase() === name),
        );
      }
      if (!this.selected_drink_category?.id) {
        return [];
      }
      return this.selected_drink_menu?.categories?.find(
        (category) => category.id === this.selected_drink_category?.id,
      )?.drinks;
    },
  },
  methods: {
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
  },
  activated() {
    this.setModalTitle(this.getTranslatedTitleForComponent("listDrinks"));
  },
};
</script>

<style scoped>
</style>