<script lang="js" setup>
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { useField } from "vee-validate";
import { computed, watch } from "vue";
import { useTranslation } from "../../composables/useTranslation";
import { useInvoiceAddressSchema } from "../../features/checkout/checkout.schemas";
import { useCheckoutStore } from "../../features/checkout/checkout.store";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import Input from "../ui/input/Input.vue";

const checkoutStore = useCheckoutStore();
const schemaInvoiceAddress = useInvoiceAddressSchema();
const { errors, values, validate } = useForm({
  validationSchema: computed(() => toTypedSchema(schemaInvoiceAddress.value)),
});
checkoutStore.setValidateInvoiceAddress(validate);

watch(values, (newVal) => {
  checkoutStore.setInvoiceAddressData(newVal);
});

watch(errors, (newVal) => {
  checkoutStore.setInvoiceAddressValidationErrors(newVal);
});

const getFieldLabel = (fieldName) => {
  return useTranslation(`checkout_invoice_${fieldName}_label`);
};

const TITLE_MALE = "männlich";
const TITLE_FEMALE = "weiblich";
const TITLE_COMPANY = "firma";

const titleOptions = [TITLE_MALE, TITLE_FEMALE, TITLE_COMPANY];

const { value: titleValue, errorMessage: titleErrorMessage } =
  useField("title");

const showCompanyField = computed(() => titleValue.value === TITLE_COMPANY);
</script>

<template>
  <form @submit.prevent="handleSubmit" class="checkout-form checkout-event">
     <div class="d-flex flex-wrap col-12 px-md-2 text-left form-group">
      <div class="form-check form-check-inline ps-0 pe-3" v-for="option in titleOptions" :key="option">
        <FormField as="input" class="form-check-input" type="radio" name="title" :id="`invoice_title_${option}`"
          :value="option" />
        <label class="form-check-label" :for="`invoice_title_${option}`">{{ getFieldLabel(`title_${option}`) }}</label>
      </div>
      <small v-if="titleErrorMessage" class="col-12 text-danger">{{ titleErrorMessage }}</small>
    </div>

    <!-- Company Field -->
    <div class="col-12" v-if="showCompanyField">
      <FormField v-slot="{ componentField }" name="company">
        <FormItem>
          <FormControl>
            <Input 
              type="text"
              v-bind="componentField"
              :placeholder="getFieldLabel('company')"
              autocomplete="organization"
            />
            <FormLabel>{{ getFieldLabel('company') }}</FormLabel>
            <FormMessage />
          </FormControl>
        </FormItem>
      </FormField>
    </div>

    <!-- Name Fields -->
    <div class="d-flex flex-wrap pt-2">
      <div class="col-lg-6 col-12">
        <FormField v-slot="{ componentField }" name="firstname">
          <FormItem>
            <FormControl>
              <Input 
                type="text"
                v-bind="componentField"
                :placeholder="getFieldLabel('firstname')"
                autocomplete="given-name"
              />
              <FormLabel>{{ getFieldLabel('firstname') }}</FormLabel>
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>
      </div>
      <div class="col-lg-6 col-12">
        <FormField v-slot="{ componentField }" name="lastname">
          <FormItem>
            <FormControl>
              <Input
                type="text"
                v-bind="componentField"
                :placeholder="getFieldLabel('lastname')"
                autocomplete="family-name"
              />
              <FormLabel>{{ getFieldLabel('lastname') }}</FormLabel>
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>
      </div>
    </div>

    <!-- Address Fields -->
    <div class="d-flex flex-wrap">
      <div class="col-12">
        <FormField v-slot="{ componentField }" name="street">
          <FormItem>
            <FormControl>
              <Input 
                type="text"
                v-bind="componentField"
                :placeholder="getFieldLabel('street')"
                autocomplete="street-address"
              />
              <FormLabel>{{ getFieldLabel('street') }}</FormLabel>
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>
      </div>

      <!-- ZIP and place in one row -->
      <div class="d-flex flex-wrap w-100">
        <div class="col-lg-6 col-12">
          <FormField v-slot="{ componentField }" name="zip">
            <FormItem>
              <FormControl>
                <Input 
                  type="text"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('zip')"
                  autocomplete="postal-code"
                />
                <FormLabel>{{ getFieldLabel('zip') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
        </div>

        <div class="col-lg-6 col-12">
          <FormField v-slot="{ componentField }" name="place">
            <FormItem>
              <FormControl>
                <Input 
                  type="text"
                  v-bind="componentField"
                  :placeholder="getFieldLabel('place')"
                  autocomplete="address-level2"
                />
                <FormLabel>{{ getFieldLabel('place') }}</FormLabel>
                <FormMessage />
              </FormControl>
            </FormItem>
          </FormField>
        </div>
      </div>

      <div class="col-12">
        <FormField v-slot="{ componentField }" name="country">
          <FormItem>
            <FormControl>
              <Input 
                type="text"
                v-bind="componentField"
                :placeholder="getFieldLabel('country')"
                autocomplete="country-name"
              />
              <FormLabel>{{ getFieldLabel('country') }}</FormLabel>
              <FormMessage />
            </FormControl>
          </FormItem>
        </FormField>
      </div>
    </div>
  </form>
</template>

<style scoped>
.form-field {
  margin-bottom: 1rem;
}

.col-lg-6:not(:last-child) {
  padding-right: 1rem;
}

@media (max-width: 991.98px) {
  .col-lg-6:not(:last-child) {
    padding-right: 0;
  }
}
</style> 