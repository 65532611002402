<template>
  <div class="input-group">
    <input id="discount-code-input"
           type="text"
           class="form-control"
           :placeholder="getTranslation('module_shop_component_checkout_discount_code', 'Rabattcode')"
           v-model="code"
           :class="classes"
           @keyup.enter="redeemCode"
    />
    <div class="input-group-append" style="height: 50px">
      <button v-if="!codeIsLoading" class="btn" type="button"
              style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;"
              @click="redeemCode"
              data-bs-toggle="tooltip" data-bs-placement="top" :title="getTranslation('redeem_button_text', 'Einlösen')">
        {{ getTranslation('redeem_button_text', 'Einlösen') }}
      </button>
      <button v-if="codeIsLoading" class="btn" type="button"
              style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;"><span
          class="spinner-border spinner-border-sm" style="color: #000;"></span></button>
    </div>
  </div>
</template>

<script>
export default {
  expose: ["code", "classes", "placeholder"],
  emits: ["redeemCode"],
  name: "codeInput",
  inject: ["getTranslation"],
  data() {
    return {
      code: "",
      classes: "",
    };
  },
  props: {
    codeIsLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Rabattcode",
    },
  },
  methods: {
    redeemCode() {
      this.$emit("redeemCode", { code: this.code });
    },
  },
  watch: {
    code: function (val) {
      if (val.length == 0) {
        this.classes = "";
      }
    },
  },
};
</script>

<style scoped>

</style>