import axios from "axios";
import moment from "moment";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { computed, ref } from "vue";
import { useAuthStore } from "../../../store/auth_store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useTranslation } from "../../composables/useTranslation";
import { objectToFormData } from "../../lib/utils/objectToFormData";
import { schemaFieldConfig } from "./checkout.schemas";
import { useSessionStorage } from "@vueuse/core";

/**
 * Helper to merge personalized ticket data with ticket entries
 * @param {Array} tickets - Array of ticket objects
 * @param {Object} personalizedData - Object containing personalized data keyed by ticket ID
 * @returns {Array} Tickets with merged owner data
 */
const mergePersonalizedTicketData = (tickets, personalizedData) => {
  return tickets.map((ticket) => {
    const ticketPersonalizations = [];

    for (let i = 1; i <= Number.parseInt(ticket.amount); i++) {
      const personalization = {
        firstname: personalizedData[`${ticket.id}-${i}-firstname`] || "",
        lastname: personalizedData[`${ticket.id}-${i}-lastname`] || "",
        company: personalizedData[`${ticket.id}-${i}-company`] || "",
      };
      ticketPersonalizations.push(personalization);
    }

    return {
      ...ticket,
      owners: ticketPersonalizations,
    };
  });
};

/**
 * Helper to handle purchase success and redirect
 * @param {Object} data - Response data from purchase request
 * @param {Object} eventDetails - Event details for dispatch
 */
const handlePurchaseSuccess = (data, eventDetails) => {
  if (data.success && data.redirect_url) {
    // Dispatch purchase event
    document.body.dispatchEvent(
      new CustomEvent("EVENT_NAME_PURCHASED", {
        detail: eventDetails,
      })
    );

    // Redirect to payment provider
    window.location = data.redirect_url;
  }
};

/**
 * Helper to make payment requests to the backend
 * @param {string} endpoint - API endpoint
 * @param {Object} params - Request parameters
 * @param {string} paymentMethod - Selected payment method
 * @returns {Promise<Object>} Payment response data
 */
const makePaymentRequest = async (
  endpoint,
  params,
  paymentMethod,
  feesPaymentMethod
) => {
  const authStore = useAuthStore();
  const cartStore = useCartStore();

  try {
    // Convert nested objects to form data format
    const formattedParams = objectToFormData({
      pay_type: paymentMethod,
      pay_type_fees: feesPaymentMethod,
      card_type: "visa",
      token: authStore.token,
      ...params,
      ...(params.discountCode?.code && {
        discount_code: params.discountCode.code,
      }),
      is_registration: params.is_registration ? 1 : 0,
    });

    const requestParams = new URLSearchParams(formattedParams);
    const response = await axios.post(endpoint, requestParams);
    const data = response.data;

    if (!data.success) {
      throw new Error(data.message);
    }

    await cartStore.setStatus("paymentInProgress");
    return data;
  } catch (error) {
    console.log("ERROR", error);
    console.log("ERROR MESSAGE", error.message);
    Swal.fire({
      icon: "error",
      title: useTranslation("error_unknown", "Ein Fehler ist aufgetreten"),
      text: error.message,
    });
    throw error;
  }
};

/**
 * Store for managing checkout state and configuration
 */
export const useCheckoutStore = defineStore("checkout", () => {
  /** @type {import('vue').Ref<boolean>} Whether guest checkout is enabled */
  const isGuestEnabled = computed(() => {
    const dataStore = useDataStore();
    return dataStore.config?.checkout?.enable_guest ?? false;
  });

  /** @type {import('vue').Ref<boolean>} Whether to show guest checkout option */
  const isShowGuest = useSessionStorage("checkout_is_show_guest", true);

  /** @type {import('vue').Ref<boolean>} Whether invoice address is enabled */
  const isInvoiceAddressEnabled = useSessionStorage(
    "checkout_is_invoice_address_enabled",
    false
  );

  /** @type {import('vue').Ref<boolean>} Whether to show invoice address form */
  const isShowInvoiceAddress = useSessionStorage(
    "checkout_is_show_invoice_address",
    true
  );

  /** @type {import('vue').Ref<string|null>} Type of checkout (tickets, lounges, etc) */
  const type = useSessionStorage("checkout_type", null);

  /** @type {import('vue').Ref<boolean>} Whether checkout request is pending */
  const isPending = useSessionStorage("checkout_is_pending", false);

  /** @type {import('vue').Ref<Error|null>} Current error state */
  const error = ref(null);

  /** @type {import('vue').Ref<string|null>} Selected payment method */
  const paymentMethod = useSessionStorage("checkout_payment_method", null);

  /** @type {import('vue').Ref<string|null>} Selected fee payment method */
  const feesPaymentMethod = useSessionStorage(
    "checkout_fees_payment_method",
    null
  );

  /** @type {import('vue').Ref<Object>} Checkout field configuration from settings */
  const fieldConfig = ref(window.disco2app.settings.checkout);

  /** @type {import('vue').Ref<Object>} Form data for checkout */
  const checkoutData = useSessionStorage("checkout_checkout_data", {});

  /** @type {import('vue').Ref<Object>} Validation errors for checkout form */
  const validationErrors = useSessionStorage("checkout_validation_errors", {});

  /** @type {import('vue').Ref<Function|null>} Function to validate main checkout form */
  const validateForm = useSessionStorage("checkout_validate_form", null);

  /** @type {import('vue').Ref<Object>} Form data for invoice address */
  const invoiceAddressData = useSessionStorage(
    "checkout_invoice_address_data",
    {}
  );

  /** @type {import('vue').Ref<Object>} Validation errors for invoice address */
  const invoiceAddressValidationErrors = useSessionStorage(
    "checkout_invoice_address_validation_errors",
    {}
  );

  /** @type {import('vue').Ref<Function|null>} Function to validate invoice address */
  const validateInvoiceAddress = useSessionStorage(
    "checkout_validate_invoice_address",
    null
  );

  /** @type {import('vue').Ref<Object>} Form data for personalized tickets */
  const personalizedTicketsData = useSessionStorage(
    "checkout_personalized_tickets_data",
    {}
  );

  /** @type {import('vue').Ref<Object>} Validation errors for personalized tickets */
  const personalizedTicketsValidationErrors = useSessionStorage(
    "checkout_personalized_tickets_validation_errors",
    {}
  );

  /** @type {import('vue').Ref<Function|null>} Function to validate personalized tickets */
  const validatePersonalizedTickets = useSessionStorage(
    "checkout_validate_personalized_tickets",
    null
  );

  /** @type {import('vue').ComputedRef<Object>} Fields configuration for registration */
  const registrationFields = computed(() => fieldConfig.value?.registration);

  /** @type {import('vue').ComputedRef<Object>} Fields configuration for guest checkout */
  const guestFields = computed(() => fieldConfig.value?.guest);

  /**
   * Validates all required forms based on checkout configuration
   * @throws {Error} If validation fails
   */
  const validateForms = async () => {
    try {
      if (!validateForm.value) {
        throw new Error(useTranslation("checkout_validation_failed"));
      }

      const result = await validateForm.value();

      if (!result.valid) {
        throw new Error(useTranslation("checkout_validation_failed"));
      }

      return result;
    } catch (error) {
      console.error("Validation error:", error);
      Swal.fire({
        title: useTranslation(
          "error_validation_failed",
          "Validierung fehlgeschlagen"
        ),
        icon: "error",
        text: error.message,
      });
      throw error;
    }
  };

  /**
   * Processes ticket purchase
   * @param {Object} event - Event object
   * @returns {Promise<Object>} Purchase response
   */
  const buyTickets = async (event) => {
    const cartStore = useCartStore();
    const authStore = useAuthStore();

    // Process tickets with personalization if needed
    const processedTickets = event.personalized_tickets
      ? mergePersonalizedTicketData(
          cartStore.selectedTickets,
          personalizedTicketsData.value
        )
      : cartStore.selectedTickets;

    const processedFreeTickets =
      event.personalized_tickets && cartStore.selectedFreeTickets.length
        ? mergePersonalizedTicketData(
            cartStore.selectedFreeTickets,
            personalizedTicketsData.value
          )
        : cartStore.selectedFreeTickets;

    const params = {
      ...checkoutData.value,
      event: event.id,
      tickets: processedTickets,
      free_tickets: processedFreeTickets,
      discount_code: cartStore.discountCodeStr,
    };

    if (authStore.isAuthenticated()) {
      params.token = authStore.token;
    }

    if (isInvoiceAddressEnabled.value) {
      params.invoice_address = JSON.stringify(invoiceAddressData.value);
    }

    const data = await makePaymentRequest(
      "/api/tickets/buy",
      params,
      paymentMethod.value,
      feesPaymentMethod.value
    );

    handlePurchaseSuccess(data, {
      event,
      tickets: processedTickets,
      freeTicket: processedFreeTickets,
      sum: data.sum,
    });

    return data;
  };

  /**
   * Processes lounge purchase
   * @param {Object} event - Event object
   * @returns {Promise<Object>} Purchase response
   */
  const buyLounge = async (event) => {
    const cartStore = useCartStore();
    const authStore = useAuthStore();

    if (
      !cartStore.selectedLounge ||
      !cartStore.selectedPackages.length ||
      !cartStore.arriveTime ||
      !cartStore.persons
    ) {
      throw new Error(useTranslation("checkout_missing_lounge_data"));
    }

    const params = {
      ...checkoutData.value,
      event: event.id,
      packages: cartStore.selectedPackagesForCheckout,
      arrive_time: moment(cartStore.arriveTime, "HH:mm").toISOString(true),
      persons: cartStore.persons,
      notes: cartStore.notes,
      discount_code: cartStore.discountCodeStr,
    };

    if (authStore.isAuthenticated()) {
      params.token = authStore.token;
    }

    if (isInvoiceAddressEnabled.value) {
      params.invoice_address = JSON.stringify(invoiceAddressData.value);
    }

    const data = await makePaymentRequest(
      `/api/lounges/${cartStore.selectedLounge.id}/booking`,
      params,
      paymentMethod.value,
      feesPaymentMethod.value
    );

    handlePurchaseSuccess(data, {
      event,
      lounge: cartStore.selectedLounge,
      packages: cartStore.selectedPackages,
      arriveTime: cartStore.arriveTime,
      persons: cartStore.persons,
      notes: cartStore.notes,
      sum: data.sum,
    });

    return data;
  };

  /**
   * Main checkout process handler
   * @returns {Promise<Object>} Purchase response
   */
  const checkout = async () => {
    const dataStore = useDataStore();
    const cartStore = useCartStore();
    const authStore = useAuthStore();

    error.value = null;
    validationErrors.value = {};
    invoiceAddressValidationErrors.value = {};

    try {
      isPending.value = true;

      const event = dataStore.event;
      if (!event) {
        throw new Error(useTranslation("checkout_event_not_found"));
      }

      if (!authStore.isAuthenticated()) {
        await validateForms(event);
      }

      let data;

      // Process purchase based on type
      switch (type.value) {
        case "lounges":
          data = await buyLounge(event);
          break;
        default:
          if (
            !cartStore.selectedTickets.length &&
            !cartStore.selectedFreeTickets.length
          ) {
            throw new Error(useTranslation("checkout_no_tickets_selected"));
          }
          data = await buyTickets(event);
          break;
      }

      if (data.success && data.redirect_url) {
        // Dispatch purchase event with appropriate details
        const eventDetails =
          type.value === "lounges"
            ? {
                event,
                lounge: cartStore.selectedLounge,
                packages: cartStore.selectedPackages,
                arriveTime: cartStore.arriveTime,
                persons: cartStore.persons,
                notes: cartStore.notes,
                sum: data.sum,
              }
            : {
                event,
                tickets: cartStore.selectedTickets,
                freeTicket: cartStore.selectedFreeTickets,
                sum: data.sum,
              };

        document.body.dispatchEvent(
          new CustomEvent("EVENT_NAME_PURCHASED", {
            detail: eventDetails,
          })
        );

        window.location = data.redirect_url;
      }
      if (checkoutData.value.is_registration) {
        authStore.login({
          email: checkoutData.value.email,
          password: checkoutData.value.password,
        });
      }

      return data;
    } catch (err) {
      console.error(err);
      error.value = err.message || err;
      throw err;
    } finally {
      isPending.value = false;
    }
  };

  /**
   * Toggle guest mode display
   */
  const toggleShowGuest = () => {
    isShowGuest.value = !isShowGuest.value;
  };

  /**
   * Toggle invoice address display
   */
  const toggleShowInvoiceAddress = () => {
    isShowInvoiceAddress.value = !isShowInvoiceAddress.value;
  };

  /**
   * Set guest mode enabled state
   * @param {boolean} value - Enable/disable guest mode
   */
  const setIsGuestEnabled = (value) => {
    isGuestEnabled.value = value;
  };

  /**
   * Set invoice address enabled state
   * @param {boolean} value - Enable/disable invoice address
   */
  const setIsInvoiceAddressEnabled = (value) => {
    isInvoiceAddressEnabled.value = value;
  };

  /**
   * Set checkout type
   * @param {string} value - Checkout type (tickets/lounge)
   */
  const setType = (value) => {
    type.value = value;
  };

  /**
   * Set payment method
   * @param {string} value - Payment method
   */
  const setPaymentMethod = (value) => {
    paymentMethod.value = value;
  };

  /**
   * Set fees payment method
   * @param {string|null} value - Payment method
   */
  const setFeesPaymentMethod = (value) => {
    feesPaymentMethod.value = value;
  };

  /**
   * Set field configuration
   * @param {Object} value - Field configuration object
   */
  const setFieldConfig = (value) => {
    const config = schemaFieldConfig.safeParse(value);
    if (!config.success) {
      throw new Error(`Invalid field config: ${config.error.message}`);
    }
    fieldConfig.value = config.data;
  };

  /**
   * Set validate form function
   * @param {Function} value - Validate function
   */
  const setValidateForm = (value) => {
    validateForm.value = value;
  };

  /**
   * Set validate invoice address function
   * @param {Function} value - Validate function
   */
  const setValidateInvoiceAddress = (value) => {
    validateInvoiceAddress.value = value;
  };

  /**
   * Set validate personalized tickets function
   * @param {Function} value - Validate function
   */
  const setValidatePersonalizedTickets = (value) => {
    validatePersonalizedTickets.value = value;
  };

  /**
   * Set checkout form data
   * @param {Object} value - Form data
   */
  const setCheckoutData = (value) => {
    checkoutData.value = value;
  };

  /**
   * Set validation errors
   * @param {Object} value - Validation errors
   */
  const setValidationErrors = (value) => {
    validationErrors.value = value;
  };

  /**
   * Set invoice address data
   * @param {Object} value - Invoice address data
   */
  const setInvoiceAddressData = (value) => {
    invoiceAddressData.value = value;
  };

  /**
   * Set invoice address validation errors
   * @param {Object} value - Validation errors
   */
  const setInvoiceAddressValidationErrors = (value) => {
    invoiceAddressValidationErrors.value = value;
  };

  /**
   * Set personalized tickets data
   * @param {Object} value - Personalized tickets data
   */
  const setPersonalizedTicketsData = (value) => {
    personalizedTicketsData.value = value;
  };

  /**
   * Set personalized tickets validation errors
   * @param {Object} value - Validation errors
   */
  const setPersonalizedTicketsValidationErrors = (value) => {
    personalizedTicketsValidationErrors.value = value;
  };

  /**
   * Processes lounge reservation request
   * @param {Object} event - Event object
   * @param {string} message - Reservation message
   * @returns {Promise<Object>} Reservation response
   */
  const sendReservationRequest = async (event, message) => {
    const cartStore = useCartStore();
    const authStore = useAuthStore();

    if (
      !cartStore.selectedLounge ||
      !cartStore.arriveTime ||
      !cartStore.persons
    ) {
      throw new Error(useTranslation("checkout_missing_lounge_data"));
    }

    const params = {
      ...checkoutData.value,
      event: event.id,
      lounge: cartStore.selectedLounge.id,
      message: message,
      persons: cartStore.persons,
      arrive_time: `${moment(event.start_time).format("YYYY-MM-DD")} ${
        cartStore.arriveTime
      }:00`,
      is_registration: checkoutData.value.is_registration ? 1 : 0,
    };

    if (authStore.isAuthenticated()) {
      params.token = authStore.token;
    }

    try {
      const response = await axios.post(
        "/api/lounges/reservation",
        new URLSearchParams(params)
      );
      const data = response.data;

      if (!data.success) {
        throw new Error(data.message);
      }

      await cartStore.setStatus("paymentCompleted");

      document.body.dispatchEvent(
        new CustomEvent("EVENT_NAME_PURCHASED", {
          detail: {
            event,
            lounge: cartStore.selectedLounge,
            persons: cartStore.persons,
            message: message,
            arriveTime: cartStore.arriveTime,
          },
        })
      );

      return data;
    } catch (error) {
      Swal.fire({
        title: useTranslation("error_unknown", "Ein Fehler ist aufgetreten"),
        icon: "error",
        text: error.message,
      });
      throw error;
    }
  };

  const validate = async () => {
    if (!validateForm.value) {
      console.error("Validate form function not set");
      return { valid: false };
    }

    try {
      return await validateForm.value();
    } catch (error) {
      console.error("Validation error:", error);
      return { valid: false };
    }
  };

  const clear = () => {
    isGuestEnabled.value = true;
    isShowGuest.value = true;
    isInvoiceAddressEnabled.value = false;
    isShowInvoiceAddress.value = true;
    type.value = null;
    isPending.value = false;
    error.value = null;
    paymentMethod.value = null;
    feesPaymentMethod.value = null;
  };

  return {
    // State
    isGuestEnabled,
    isShowGuest,
    isInvoiceAddressEnabled,
    isShowInvoiceAddress,
    type,
    isPending,
    error,
    paymentMethod,
    feesPaymentMethod,
    // Form data
    checkoutData,
    validationErrors,
    invoiceAddressData,
    invoiceAddressValidationErrors,
    personalizedTicketsData,
    personalizedTicketsValidationErrors,

    // Computed
    registrationFields,
    guestFields,

    // Methods
    checkout,
    toggleShowGuest,
    toggleShowInvoiceAddress,
    setIsGuestEnabled,
    setIsInvoiceAddressEnabled,
    setType,
    setPaymentMethod,
    setFeesPaymentMethod,
    setFieldConfig,
    setValidateForm,
    setValidateInvoiceAddress,
    setValidatePersonalizedTickets,
    setCheckoutData,
    setValidationErrors,
    setInvoiceAddressData,
    setInvoiceAddressValidationErrors,
    setPersonalizedTicketsData,
    setPersonalizedTicketsValidationErrors,
    sendReservationRequest,
    validateForms,
    validate,
    clear,
  };
});
