<template>

    <div class="col-lg-9 col-12 mt-4">

      <div class="mt-4">
<!--        <h4 class="mb-2">Verifiziere deine E-Mail-Adresse</h4>-->

        <div class="">

          <div class="col-12 mt-2">
            <h5 class="m-0 mb-2">
              {{ getTranslation('module_shop_component_verifyemail_text_prefix', 'Wir haben dir eine E-Mail an') }}
              <b>"{{ userInfos.unconfirmed_email }}"</b>
              {{ getTranslation('module_shop_component_verifyemail_text_suffix', 'gesendet.') }}
            </h5>
          </div>

          <div class="col-12" style="margin-top: 4rem;">
            <span>
              {{ getTranslation('module_shop_component_verifyemail_nomail', 'Du hast keine E-Mail erhalten?') }}
            </span>
          </div>

          <div class="col-12 mt-2">
            <div class="form-floating form-group w-100 m-0">
              <input class="form-control" type="email" name="email" v-once :value="userInfos.unconfirmed_email" id="email" autocomplete="username" :placeholder="getTranslation('email_placeholder', 'E-Mail')"/>
              <label for="email">
                {{ getTranslation('email_label', 'E-Mail') }}
              </label>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-center mt-4">
            <button type="button" @click="sendVerificationEmail" class="btn btn-primary" :disabled="emailTimer > 0" data-umami-event="verify-email-button">
              <span v-if="emailTimer > 0">{{ emailTimer }} s</span>
              <span v-if="emailTimer === 0">
                {{ getTranslation('module_shop_component_verifyemail_resend_button_text', 'Erneut an diese E-Mail senden') }}
              </span>
            </button>
          </div>

          <div class="col-12 mt-2 d-flex justify-content-center">
            <small style="font-size: 10px">{{ getTranslation('module_shop_component_verifyemail_notice', 'Die Bestätigung deiner E-Mail-Adresse wird automatisch erkannt, dies kann einen moment dauern.') }}</small>
          </div>

        </div>

      </div>
    </div>

</template>

<script>
import { getTransitionDurationFromElement } from "bootstrap/js/src/util";
import { mapStores } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";
import { useUserStore } from "../../../../store/user_store";

export default {
  name: "verifyEmail",
  data() {
    const userStore = useUserStore();
    return {
      missTyped: false,
      emailTimer: 0,
      refreshInterval: null,
      userInfos: JSON.parse(userStore.userInfosRef),
    };
  },
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  props: ["registerSuccessful", "loadedFrom"],
  computed: {
    ...mapStores(useAuthStore, useUserStore),
  },
  methods: {
    getTransitionDurationFromElement,
    sendVerificationEmail() {
      if (this.emailTimer === 0) {
        const newUserInfos = Object.assign(this.userInfos, {
          email: document.getElementById("email").value,
          email_unconfirmed: true,
        });
        if (
          this.authStore.email_sent_at === null ||
          this.authStore.email_sent_at <= moment().unix() - 30
        ) {
          if (
            this.userInfos &&
            this.userInfos.email != document.getElementById("email").value
          ) {
            this.userStore.updateUserInfos(newUserInfos).then(() => {
              this.authStore.setEmailSentAt(moment().unix());
              this.emailTimer = 60;

              clearInterval(this.refreshInterval);
              this.refreshInterval = setInterval(() => {
                this.userStore.refreshUserInfos().then((userInfos) => {
                  this.userInfos = userInfos;
                  if (this.userInfos.unconfirmed_email === "") {
                    clearInterval(this.refreshInterval);
                    this.$emit("emailVerified");
                  }
                });
              }, 15000);
            });
          } else {
            this.authStore.sendVerificationEmail().then(() => {
              this.authStore.setEmailSentAt(moment().unix());
              this.emailTimer = 60;

              clearInterval(this.refreshInterval);
              this.refreshInterval = setInterval(() => {
                this.userStore.refreshUserInfos().then((userInfos) => {
                  this.userInfos = userInfos;
                  if (this.userInfos.unconfirmed_email === "") {
                    clearInterval(this.refreshInterval);
                    this.$emit("emailVerified");
                  }
                });
              }, 15000);
            });
          }
        }
      }
    },
  },
  watch: {
    emailTimer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            if (this.emailTimer > 0) {
              this.emailTimer--;
            }
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  emits: ["emailVerified", "setModalTitle"],
  activated() {
    this.$emit(
      "setModalTitle",
      this.getTranslatedTitleForComponent("verifyEmail"),
    );

    if (this.refreshInterval === null) {
      if (this.userInfos.unconfirmed_email !== "") {
        // this.sendVerificationEmail();
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(() => {
          this.userStore.refreshUserInfos().then((userInfos) => {
            this.userInfos = userInfos;
            if (this.userInfos.unconfirmed_email === "") {
              clearInterval(this.refreshInterval);
              this.$emit("emailVerified");
            }
          });
        }, 15000);
      }
    }
  },
  mounted() {
    this.$emit(
      "setModalTitle",
      this.getTranslatedTitleForComponent("verifyEmail"),
    );
    if (this.userInfos.unconfirmed_email !== "") {
      if (this.refreshInterval === null) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(() => {
          this.userStore.refreshUserInfos().then((userInfos) => {
            this.userInfos = userInfos;
            if (this.userInfos.unconfirmed_email === "") {
              clearInterval(this.refreshInterval);
              this.$emit("emailVerified");
            }
          });
        }, 15000);
      }
    }
  },
  deactivated() {
    clearInterval(this.refreshInterval);
  },
};
</script>

<style scoped>

</style>