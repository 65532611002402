import { useTranslationStore } from "../../store/translation_store";

/**
 * Get a translation for a given key.
 * @param {string} key - The key to get the translation for.
 * @param {string} default_text - The default text to return if the key is not found.
 * @returns {string} The translation for the given key.
 */
export function useTranslation(key, default_text) {
  const translationStore = useTranslationStore();
  return translationStore.getTranslation(key, default_text || key);
}

/**
 * Get all available translations.
 * @returns {Array<{key: string, value: string}>} An array of translation objects, each containing a key and translated text.
 */
export function useTranslations() {
  const translationStore = useTranslationStore();
  return translationStore.getTranslations();
}
