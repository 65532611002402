<template>

  <div class="animate__animated animate__fadeIn animate__fast">
    <div class="col-12 mb-4 text-center">
      <h3>{{ getTranslation('module_shop_component_lounges_validate_booking_text', 'Finde eine Lounge-Buchung') }}</h3>
      <h4>{{ getTranslation('module_shop_component_lounges_validate_booking_text2', 'Damit die Getränke richtig ankommen...') }}</h4>
    </div>

    <div class="px-4 w-100 auth-form d-flex flex-wrap justify-content-center mt-2">

      <div class="col-12 col-lg-9" v-if="lounges.length">

        <div class="form-group w-100" style="z-index: 0;">
          <select class="form-select" id="lounge-picker" v-model="lounge_id" :value="'de'" required>
            <option
                v-for="(lounge, index) in lounges"
                :value="lounge.id"
                :key="index"
            >{{ lounge.name }}</option>
          </select>
        </div>

        <div class="form-group w-100 form-floating" style="z-index: 0;">
            <input id="booking_number"
                   v-model="booking_number"
                   type="text"
                   class="form-control"
                   :placeholder="getTranslation('module_shop_component_lounges_validate_booking_booking_number_placeholder', 'Buchungsnummer eingeben (optional)')"
            />
          <label for="booking_number">{{ getTranslation('module_shop_component_lounges_validate_booking_booking_number_label', 'Buchungsnummer') }}</label>
        </div>

        <div class="form-group w-100 form-floating" style="z-index: 0;">
          <input id="name"
                 v-model="name"
                 type="text"
                 class="form-control"
                 :placeholder="getTranslation('module_shop_component_lounges_validate_booking_name_placeholder', 'Vor- und Nachname o. Nachname o. Username')"
                 required
          />
          <label for="name">{{ getTranslation('module_shop_component_lounges_validate_booking_name_label', 'Name auf der Buchung') }}</label>
        </div>

        <div class="form-group w-100 d-flex justify-content-center">
          <button v-if="!bookingIsLoading" class="btn" type="button" @click="validateBooking">
            Überprüfen
          </button>
          <button v-if="bookingIsLoading" class="btn" type="button"><span class="spinner-border spinner-border-sm"></span></button>
        </div>
      </div>

    </div>
  </div>


</template>


<script >
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

import Swal from "sweetalert2";
import { useAuthStore } from "../../../store/auth_store";
import { useUserStore } from "../../../store/user_store";

export default {
  name: "persons",
  emits: ["changeComponent", "setModalTitle"],
  data() {
    return {
      booking_number: null,
      bookingIsLoading: false,
      name: null,
      lounge_id: null,
    };
  },
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  computed: {
    ...mapStores(useDataStore, useCartStore, useAuthStore, useUserStore),
    ...mapState(useDataStore, ["event", "settings", "lounges", "events"]),
  },
  methods: {
    changeComponent: function (component, module = "Shop", params = {}) {
      document.getElementById("shop-modal").scrollTo(0, 0);
      this.$emit("changeComponent", { module, component, params });
    },
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
    check() {
      if (this.authStore.isAuthenticated()) {
        const now = moment();
        const cur_events = this.events.filter(
          (event) =>
            moment(event.start_time).isBefore(now) &&
            moment(event.end_time).isAfter(now),
        );
        if (cur_events.length) {
          this.bookingIsLoading = true;
          cur_events.forEach((event) => {
            this.userStore
              .hasUserValidBookingForEvent(event.id)
              .then((booking) => {
                if (booking) {
                  this.dataStore
                    .loadData(booking.event.id)
                    .then(() => {
                      this.cartStore.setLounge(booking.lounge.id);
                      return this.cartStore.setLoungeBookingNumber(
                        booking.booking_number,
                      );
                    })
                    .then(() => {
                      return this.changeComponent("checkout", "Shop");
                    });
                }
              });
          });
          this.bookingIsLoading = false;
        }
      } else {
        this.changeComponent("login", "Auth", {
          redirect: {
            module: "Shop",
            component: "drinks",
            params: {
              activeComponent: "validateBooking",
            },
          },
        });
      }
    },
    validateBooking() {
      this.bookingIsLoading = true;
      const params = {
        booking_number: this.booking_number ? this.booking_number : "",
      };

      if (this.lounge_id && this.lounge_id !== "null") {
        params.lounge = this.lounge_id;
      }

      if (this.name && this.name !== "null") {
        params.name = this.name;
      }

      this.cartStore
        .validateBookingNumber(params)
        .then((res) => {
          this.bookingIsLoading = false;
          if (res.success && res.booking) {
            this.dataStore
              .loadData(res.booking.event)
              .then(() => {
                this.cartStore.setLounge(res.booking.lounge);
                this.cartStore.setLoungeBookingNumber(this.booking_number);
              })
              .then(() => {
                this.changeComponent("checkout");
              });
          } else {
            Swal.fire({
              title: this.getTranslation(
                "error_booking_not_found_title",
                "Buchung nicht gefunden",
              ),
              text: res.message
                ? res.message
                : this.getTranslation(
                    "error_booking_not_found_text",
                    "Die Buchung konnte nicht gefunden werden. Bitte überprüfe deine Eingabe.",
                  ),
              icon: "error",
              confirmButtonText: this.getTranslation(
                "close_button_text",
                "Schließen",
              ),
            });
          }
        })
        .catch((error) => {
          this.bookingIsLoading = false;
        });
    },
  },
  mounted() {
    this.check();

    if (this.lounges.length) {
      this.lounge_id = this.lounges[0].id;
    }

    if (!this.dataStore.events?.length) {
      this.dataStore.loadEvents();
    }
  },
  activated() {
    this.check();
    const title = this.getTranslatedTitleForComponent("validateBooking");
    this.setModalTitle(title);
  },
};
</script>


<style scoped>

</style>
