<template>
<div class="animate__animated animate__fadeIn animate__fast">
  <div class="px-4 py-4">
    <h3 class="m-0 text-center">{{ event.name }}</h3>
    <h5 class="m-0 text-center">{{ formatDate(event.start_time, 'dddd, DD.MM.YYYY HH:mm') }}</h5>
  </div>

  <div v-if="target_settings.tickets.codes.position === 'above'">
    <div v-if="event.has_free_tickets" class="d-flex justify-content-center w-100 mt-4">
      <a class="btn-read-more ticket-btn" @click="toggleCodeVisible" style="cursor: pointer;">
        <span>{{ getTranslation('module_shop_component_tickets_free_ticket_code_message', 'Du hast einen Freiticket-Code? Löse ihn hier ein.') }}</span>
      </a>
    </div>
    <div class="px-4 w-100 auth-form d-flex justify-content-center mt-2" v-if="codeVisible">
      <div class="form-group" style="z-index: 0;">
        <div class="input-group">
          <input id="redemptionCode"
                 type="text"
                 class="form-control"
                 placeholder="Code"
          />
          <div class="input-group-append" style="height: 50px">
            <button v-if="!codeIsLoading" class="btn" type="button" style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;" @click="redeemCode" data-umami-event="redeem-code-button">
              {{ getTranslation('redeem_button_text', 'Einlösen') }}
            </button>
            <button v-if="codeIsLoading" class="btn" type="button" style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;"><span class="spinner-border spinner-border-sm"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ticket-container p-4 pt-2 d-flex justify-content-center flex-wrap">

    <renderTickets :data="tickets" :classes="'col-12 col-lg-9 py-2 mb-4 animate__animated animate__fadeIn animate__fast'"></renderTickets>

  </div>

  <div v-if="target_settings.tickets.codes.position === 'underneath'">
    <div v-if="event?.has_free_tickets" class="d-flex justify-content-center w-100">
      <a class="btn-read-more ticket-btn" @click="toggleCodeVisible" style="cursor: pointer;"><span>{{ getTranslation('module_shop_component_tickets_free_ticket_code_message', 'Du hast einen Freiticket-Code? Löse ihn hier ein.') }}</span></a>
    </div>
    <div class="px-4 w-100 auth-form d-flex justify-content-center mt-2" v-if="codeVisible">
      <div class="form-group" style="z-index: 0;">
        <div class="input-group">
          <input id="redemptionCode"
                 type="text"
                 class="form-control"
                 placeholder="Code"
          />
          <div class="input-group-append" style="height: 50px">
            <button v-if="!codeIsLoading" class="btn" type="button" style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;" @click="redeemCode" data-umami-event="redeem-code-button">
              {{ getTranslation('redeem_button_text', 'Einlösen') }}
            </button>
            <button v-if="codeIsLoading" class="btn" type="button" style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;"><span class="spinner-border spinner-border-sm"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="shop-footer-spacing"></div>

</div>
</template>

<script>
import Swal from "sweetalert2";
import renderTickets from "./renderTickets";

import ticket_api from "../../../api/ticket_api";

import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

let target_settings;

try {
  target_settings = require("../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "tickets",
  components: {
    renderTickets: renderTickets,
  },
  emits: [],
  data() {
    return {
      target_settings,
      codeIsLoading: false,
      codeVisible: target_settings.tickets.codes.open,
    };
  },
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useCartStore, useAuthStore),
    ...mapState(useDataStore, ["event", "tickets"]),
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    redeemCode: function () {
      this.codeIsLoading = true;

      const code = document.getElementById("redemptionCode").value;

      if (!code) {
        document.getElementById("redemptionCode").classList.add("is-invalid");
        this.codeIsLoading = false;
        return;
      }
      document.getElementById("redemptionCode").classList.remove("is-invalid");

      const values = {
        event: this.event.id,
        code: code,
      };

      if (this.authStore.isAuthenticated()) {
        values.token = this.authStore.token;
      }

      ticket_api.reedemTicket(values).then((data) => {
        if (!data.success) {
          Swal.fire({
            title: "Fehlgeschlagen",
            icon: "error",
            text: data.message ? data.message : "Ein Fehler ist aufgetreten",
          });
          this.codeIsLoading = false;
          return;
        }

        this.cartStore.addFreeTicket(data.ticket, code);
        document.getElementById("redemptionCode").value = "";
        this.codeIsLoading = false;
      });
    },
    toggleCodeVisible() {
      this.codeVisible = !this.codeVisible;

      setTimeout(() => {
        if (this.codeVisible) {
          document.getElementById("redemptionCode").focus();
          document
            .getElementById("redemptionCode")
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 200);
    },
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal?.has_free_tickets !== oldVal?.has_free_tickets) {
          this.codeVisible = target_settings.tickets.codes.open;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>