<template>

  <div class="w-100">

    <div class="d-flex flex-wrap lounge-reservation">
      <keep-alive>
        <component :is="params.activeComponent" :params="params" @setModalTitle="setModalTitle" @startBooking="startBooking" @startReservationRequest="startReservationRequest" @changeComponent="changeComponent" class="animate__animated animate__fadeIn animate__fast"></component>
      </keep-alive>
    </div>
  </div>

</template>


<script>
import "../../../../../node_modules/animate.css/animate.css";
import listLounges from "./listLounges";
import listPackages from "./packages/listPackages";
import persons from "./persons/persons";
import reservationRequest from "./reservationRequests";

import { mapStores } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

export default {
  name: "Lounges",
  props: ["data", "params"],
  components: {
    listLounges,
    persons,
    listPackages,
    reservationRequest,
  },
  data() {
    return {};
  },
  computed: {
    ...mapStores(useDataStore, useCartStore),
  },
  emits: ["changeComponent", "setModalTitle"],
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    startBooking: function (id) {
      this.cartStore.setLounge(id).then(() => {
        this.changeComponent({
          module: "Shop",
          component: "lounges",
          params: {
            activeComponent: "persons",
            reservationRequest: false,
          },
        });
      });
    },
    startReservationRequest: function (payload) {
      this.cartStore.setLounge(payload.lounge, false).then(() => {
        this.changeComponent({
          module: "Shop",
          component: "lounges",
          params: {
            activeComponent: "persons",
            reservationRequest: true,
          },
        });
      });
    },
    changeComponent: function (payload) {
      this.$emit("changeComponent", {
        component: payload.component,
        module: payload.module,
        params: payload.params,
      });
    },
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
  },
};
</script>


<style scoped>

</style>