<template>
    <div class="payment-methods w-100 d-flex flex-wrap">
        <template v-for="method in availablePaymentMethods" :key="method.id">
            <div class="d-flex flex-wrap p-2"
                 :class="[columnClass, { 'col-6': isWideColumn, 'col-4': !isWideColumn }]">
                <div class="d-flex flex-wrap w-100 pay-option"
                     :class="{ active: paymentMethod === method.id }"
                     @click="changePaymentMethod(method.id)">
                    <div class="w-100 d-flex justify-content-center">
                        <img :srcset="method.srcset"/>
                    </div>
                    <div class="w-100 d-flex justify-content-center text-center" :style="method.style">
                        {{ method.label }}
                    </div>
                </div>
            </div>
        </template>

        <small v-if="status === 'paymentFailed'" class="col-12 text-danger my-2 text-center"
               v-html="paymentFailedMessage"></small>

        <div class="col-12 text-center checkout-legal-text" v-if="showConditionsFor">
            <div v-html="gtcText"></div>
            <div v-if="type === 'lounges'">
                <div v-if="currentLanguage === 'en'" v-html="cancellationTextEn"></div>
                <div v-else v-html="settings.lounges.cancellation_text"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useTranslation } from "../../composables/useTranslation";
import { watch } from "vue";

const props = defineProps({
    paymentMethod: String,
    type: String,
    showConditionsFor: [Boolean, String],
    allowCash: Boolean
});

const emit = defineEmits(['changePaymentMethod']);

const currentLanguage = window.disco2app.currentLanguage;

const cartStore = useCartStore();
const dataStore = useDataStore();
const { status, selectedLounge } = storeToRefs(cartStore);
const { settings } = storeToRefs(dataStore);

const downPaymentPackage = computed(() => {
    return selectedLounge.value?.packages?.find(
      (p) => p.type === "down_payment",
    );
});

watch(selectedLounge, (newVal) => {
    if (newVal && !newVal.packages) {
        dataStore.loadLoungePackages(newVal.id);
    }
}, {
    immediate: true,
});

// Payment method configuration
const PAYMENT_METHODS = {
    bar: {
        id: 'bar',
        label: computed(() => useTranslation('web_payment_methods_cash', 'Bar')),
        srcset: `/images/payment_methods/pay-option-bar.png 1x,
                 /images/payment_methods/pay-option-bar@2x.png 2x,
                 /images/payment_methods/pay-option-bar@3x.png 3x`
    },
    paypal: {
        id: 'paypal',
        label: 'PayPal',
        srcset: `/images/payment_methods/pay-option-paypal.png 1x,
                 /images/payment_methods/pay-option-paypal@2x.png 2x,
                 /images/payment_methods/pay-option-paypal@3x.png 3x`
    },
    sofort: {
        id: 'sofort',
        label: 'Klarna',
        srcset: `/images/payment_methods/pay-option-sofort.png 1x,
                 /images/payment_methods/pay-option-sofort@2x.png 2x,
                 /images/payment_methods/pay-option-sofort@3x.png 3x`
    },
    creditcard: {
        id: 'creditcard',
        label: computed(() => useTranslation('web_payment_methods_creditcard', 'Kreditkarte')),
        srcset: `/images/payment_methods/creditcard.png 1x,
                 /images/payment_methods/creditcard@2x.png 2x,
                 /images/payment_methods/creditcard@3x.png 3x`,
        style: { 'line-height': '18px' }
    }
};

const isMethodAllowed = computed(() => (method) => {
    console.log({
        type: props.type,
        downPaymentPackage: downPaymentPackage.value,
        method: method,
    });
    if (props.type === 'lounges' && downPaymentPackage.value && method === 'bar') return false;
    if (props.type === 'tickets' && method === 'bar') return false;
    
    switch (props.type) {
        case 'lounges':
            return settings.value.lounges.booking.pay[method] === 1;
        case 'tickets':
            return settings.value.tickets.pay[method] === 1;
        case 'drinks':
            return settings.value.lounges.drink_orderings.methods[method] === 1;
        case 'fees':
            return settings.value.lounges.booking.pay_fees === undefined ||
                   settings.value.lounges.booking?.pay_fees[method] === 1;
        default:
            return false;
    }
});

const availablePaymentMethods = computed(() => 
    Object.values(PAYMENT_METHODS).filter(method => isMethodAllowed.value(method.id))
);

const isWideColumn = computed(() => 
    isMethodAllowed.value('bar') && (isMethodAllowed.value('direct') || isMethodAllowed.value('paypal'))
);

const gtcText = computed(() => useTranslation('module_shop_component_checkout_gtc_text', 
    `Es gelten unsere <a href="/agb" rel="nofollow" target="_blank" style="font-weight: bold">AGB</a> und 
     <a style="font-weight: bold" href="/datenschutz" rel="nofollow" target="_blank">Datenschutzerklärungen</a>. `
));

const paymentFailedMessage = computed(() => useTranslation(
    'web_payment_failed_message',
    'Die Zahlung konnte nicht erfolgreich abgeschlossen werden.<br />Bitte probiere es erneut.'
));

const cancellationTextEn = `If a VIP table is canceled up to 48 hours before the event, we charge a 25% cancellation fee. 
    Up to 24 hours before the event, we will charge 50% cancellation fee. Cancellations within 12 hours before the event will
    be charged 100% cancellation fee. Cancellation fees are calculated based on the amount of the deposit.`;

// Methods
const changePaymentMethod = (method) => {
    emit('changePaymentMethod', method);
};
</script>

<style scoped>
.payment-methods {
    .pay-option {
        cursor: pointer;
        &.active {
            /* Add your active styles here */
        }
    }
}
</style>
