<template>

  <div class="d-flex flex-wrap justify-content-center w-100" v-if="lounge_packages">
    <div :class="classes" v-for="lounge_package in lounge_packages">

      <div class="package p-4">

        <div class=" pe-4 my-auto">

          <div class="package-image" :style="'background-image: url(' + lounge_package.image + ')'"></div>

        </div>

        <div class="d-flex flex-wrap flex-grow-1">

          <div class="col-12">
            <h5 class="m-0"><b>{{
                downPaymentPackage === lounge_package.id ? getTranslation('down_payment_package_name', 'Anzahlung') : lounge_package.name
              }}</b></h5>
          </div>

          <div class="col-12">
            <span>{{
                downPaymentPackage === lounge_package.id ? getTranslation('down_payment_package_description', 'Dies ist die Anzahlung deiner Lounge. Der Rest wird vor Ort an der Kasse abgerechnet.') : lounge_package.description
              }}</span>
          </div>

          <div class="col-12 d-flex align-items-end">

            <Transition name="slide-fade-up">
              <div class="d-flex flex-wrap flex-grow-1">
                <span><b>{{ format(lounge_package.price) }}</b></span>

                <div class="w-100" v-if="this.inCheckout && lounge_package.discounted">
                  <button class="btn btn-sm btn-default me-2"
                          style="width: 16px; height: 16px;padding: .25rem;position:relative;"
                          @click="cartStore.removeDiscountCode('lounges')">
                    <i class="ticket-fee feather-x" style="position:absolute;top:2px;left:2px; font-size: 12px;"></i>
                  </button>
                  <small v-if="showDiscount">{{ discountCode.description }} <span
                      v-if="discountCode.code == ''">({{ discountCode.code }})</span></small>
                </div>
              </div>
            </Transition>

            <div v-if="downPaymentPackage !== lounge_package.id"
                 class="d-flex flex-shrink-1 item-picker align-items-center">

              <div class="minus disable-dbl-tap-zoom"
                   v-if="this.inCheckout"
                   :class="(parseFloat(cart_sums.sum) - parseFloat(lounge_package.price)) < parseFloat(selectedLounge.minimum_consumption) ? '':'minusable'"
                   @click="decrementPackageCount(lounge_package.id, lounge_package.price)"
              >
                <svg width="14px" height="14px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z"
                      fill="currentColor" fill-rule="evenodd"/>
                </svg>
              </div>

              <div class="minus disable-dbl-tap-zoom"
                   v-else
                   :class="packageCount(lounge_package.id) <= 0 ? '':'minusable'"
                   @click="decrementPackageCount(lounge_package.id, lounge_package.price)"
              >
                <svg width="14px" height="14px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z"
                      fill="currentColor" fill-rule="evenodd"/>
                </svg>
              </div>


              <div class="value disable-dbl-tap-zoom">
                <input disabled class="ticket-count text-center" :value="packageCount(lounge_package.id)">
              </div>

              <div class="plus disable-dbl-tap-zoom "
                   :class="(parseFloat(cart_sums.sum) + parseFloat(lounge_package.price)) > parseFloat(settings.lounges.booking.limit) ? '':'plusable'"
                   @click="incrementPackageCount(lounge_package.id, lounge_package.price)"
              >
                <svg width="14px" height="14px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m44.969 14.441h10.062c2.3984 0 4.3594 1.9609 4.3594 4.3594v21.805h21.805c2.3984 0 4.3594 1.9609 4.3594 4.3594v10.062c0 2.3984-1.9609 4.3594-4.3594 4.3594l-21.805 0.003906v21.805c0 2.3984-1.9609 4.3594-4.3594 4.3594l-10.062 0.003906c-2.3984 0-4.3594-1.9609-4.3594-4.3594l-0.003906-21.809h-21.801c-2.3984 0-4.3594-1.9609-4.3594-4.3594v-10.062c0-2.3984 1.9609-4.3594 4.3594-4.3594h21.805l-0.003906-21.805c0.003906-2.3984 1.9648-4.3633 4.3633-4.3633z"
                      fill="currentColor" fill-rule="evenodd"/>
                </svg>
              </div>

            </div>

          </div>
        </div>
      </div>


    </div>
    <div v-if="!inCheckout" style="margin-top: 10rem;margin-bottom: 10rem;"></div>
  </div>


</template>

<script>
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../../store/cart_store";
import { useDataStore } from "../../../../store/data_store";

export default {
  name: "renderPackage",
  props: ["classes", "lounge_packages", "inCheckout"],
  emits: [],
  data() {
    return {};
  },
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useCartStore),
    ...mapState(useCartStore, [
      "selectedLounge",
      "packageCount",
      "discountCode",
      "cart_sums",
    ]),
    ...mapState(useDataStore, ["event", "settings", "config"]),
    showDiscount: function () {
      if (
        this.settings["discount-codes"] &&
        this.settings["discount-codes"].active
      ) {
        return true;
      } else {
        return false;
      }
    },
    downPaymentPackage() {
      return this.selectedLounge?.packages?.find(
        (p) => p.type === "down_payment",
      )?.id;
    },
  },
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    incrementPackageCount: function (package_id, price = "0") {
      if (this.cartStore.isDiscounted) {
        if (
          Number.parseFloat(this.cart_sums.sum) + Number.parseFloat(price) <=
          Number.parseFloat(this.settings.lounges.booking.limit)
        ) {
          this.cartStore.addPackage(package_id).then(() => {
            const _package = this.lounge_packages.find(
              (p) => p.id === package_id,
            );
            if (!_package) return;

            return document.body.dispatchEvent(
              new CustomEvent("EVENT_NAME_ADDED_TO_CART", {
                detail: {
                  id: _package.id,
                  name: _package.name,
                  price: _package.price,
                  amount: this.packageCount(package_id),
                  type: "package",
                },
              }),
            );
          });
        }
      } else {
        if (
          Number.parseFloat(this.cart_sums.sum) + Number.parseFloat(price) <=
          Number.parseFloat(this.settings.lounges.booking.limit)
        ) {
          this.cartStore.addPackage(package_id).then(() => {
            const _package = this.lounge_packages.find(
              (p) => p.id === package_id,
            );
            if (!_package) return;

            return document.body.dispatchEvent(
              new CustomEvent("EVENT_NAME_ADDED_TO_CART", {
                detail: {
                  id: _package.id,
                  name: _package.name,
                  price: _package.price,
                  amount: this.packageCount(package_id),
                  type: "package",
                },
              }),
            );
          });
        }
      }
    },
    decrementPackageCount: function (package_id, price = "0") {
      if (this.inCheckout) {
        if (
          Number.parseInt(this.cart_sums.sum) - Number.parseFloat(price) >=
          Number.parseFloat(this.selectedLounge.minimum_consumption)
        ) {
          this.cartStore.removePackage(package_id);
        }
      } else {
        this.cartStore.removePackage(package_id);
      }
    },
  },
  mounted() {
    // this.$store.dispatch('updateLoungePackages');
  },
};
</script>

<style scoped>
.text-crossed {
  color: var(--color-danger);
  text-decoration: line-through;
}
</style>