/**
 * Helper to convert object to form data format
 * @param {Object} obj - Object to convert
 * @param {string} namespace - Namespace for nested objects
 * @returns {Object} Flattened object in form data format
 */
export const objectToFormData = (obj, namespace = '') => {
  const formData = {};

  for (let property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue;
    }

    const value = obj[property];
    const formKey = namespace ? `${namespace}[${property}]` : property;

    if (value instanceof Date) {
      formData[formKey] = value.toISOString();
    }
    else if (value instanceof Array) {
      value.forEach((item, index) => {
        if (typeof item === 'object') {
          Object.assign(formData, objectToFormData(item, `${formKey}[${index}]`));
        } else {
          formData[`${formKey}[${index}]`] = item;
        }
      });
    }
    else if (typeof value === 'object' && value !== null) {
      Object.assign(formData, objectToFormData(value, formKey));
    }
    else {
      formData[formKey] = value;
    }
  }

  return formData;
}; 