import axios from "axios";

const event_api = {
  fetchEvents() {
    return axios.get(`/api/events`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  fetchData(event) {
    return axios.get(`/api/events/${event}`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }
      return resp.data;
    });
  },
};

export default event_api;
