<template>
    <div v-if="event" class="d-flex w-100 mt-2 justify-content-center checkout">
        <div class="p-lg-0 p-4 col-12 col-lg-9">

            <h4 class="mb-2">Event:</h4>
            <div class="checkout-event p-4 col-12 d-flex flex-wrap">
                <div class="col-9 pe-2">
                    <span v-if="event.start">{{ formatDate(event.start, 'dddd, DD.MM.YYYY HH:mm') }}</span>
                    <span v-if="event.start_time">{{ formatDate(event.start_time, 'dddd, DD.MM.YYYY HH:mm') }}</span>
                </div>

                <div class="col-3 event-image" :style="'background-image: url(' + event.image + ')'"></div>
            </div>

            <h4 class="mt-4 mb-2">{{
                    getTranslation('module_shop_component_checkout_ordering_title', 'Deine Bestellung:')
                }}</h4>
            <div v-if="type === 'tickets'">
                <Transition name="slide-fade-left">
                    <renderTickets :data="selectedTickets" :inCheckout="true"
                                   :classes="'col-12 py-0 mb-0'"></renderTickets>
                </Transition>
            </div>

            <div v-else-if="type === 'lounges' && selectedLounge">
                <div class="checkout-event lounge-data-info p-4 col-12 mb-0 d-flex flex-wrap">
                    <div class="col-9">
                        <h5 class="m-0">{{ selectedLounge.name }} - {{ persons }} {{
                                persons > 1 ? getTranslation('module_lounges_persons', 'Personen') :
                                    getTranslation('module_lounges_person', 'Person')
                            }}</h5>
                        <span v-if="config.lounges?.latest_arrival">{{
                                getTranslation('module_shop_component_lounges_latest_arrival', 'Ankunft bis spätestens: ')
                            }} {{ arriveTime }}&nbsp;</span>
                        <span v-else>{{
                                getTranslation('module_shop_component_lounges_arrive_time_prefix', 'Ankunft gegen')
                            }} {{ arriveTime }}&nbsp;</span>
                        <i class="feather-clock" style="font-size: 12px;"></i>
                        <p class="mb--0">{{
                                getTranslation('module_lounges_minimum_consumption', 'Mindestverzehr')
                            }}: {{
                                Intl.NumberFormat('de-DE', {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(selectedLounge.minimum_consumption)
                            }}</p>
                    </div>
                    <div class="col-3 event-image" :style="'background-image: url(' + selectedLounge.image + ')'"></div>
                </div>

                <div class="col-12">
                    <Transition name="slide-fade-left">
                        <renderPackage :lounge_packages="selectedPackages" :inCheckout="true"
                                       :classes="'col-12 py-0 mb-0'">
                        </renderPackage>
                    </Transition>
                </div>
            </div>

            <div v-else-if="type === 'drinks' && selectedLounge">
                <div class="checkout-event lounge-data-info p-4 col-12 mb-0 d-flex flex-wrap">
                    <div class="col-9">
                        <h5 class="m-0">{{ settings.drinks?.alternatives.drink_order.singular }} für
                            {{ selectedLounge.name }}</h5>
                    </div>
                    <div class="col-3 event-image" :style="'background-image: url(' + selectedLounge.image + ')'"></div>
                </div>

                <div class="col-12">
                    <Transition name="slide-fade-left">
                        <renderDrink :drinks="selectedDrinks" :inCheckout="true"
                                     :classes="'col-12 py-0 mb-0'"></renderDrink>
                    </Transition>
                </div>
            </div>

            <div class="checkout-event p-4 col-12 d-flex flex-wrap">
                <div v-if="cartStore.discountOnTotal > 0">
                    <div class="w-100">
                        <button v-if="cartStore.discountCode" class="btn btn-sm btn-default me-2"
                                style="width: 16px; height: 16px;padding: .25rem;position:relative;"
                                @click="cartStore.removeDiscountCode('total')" data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Rabatt entfernen">
                            <i class="ticket-fee feather-x"
                               style="position:absolute;top:2px;left:2px; font-size: 12px;"></i>
                        </button>
                        <small v-if="discountCode">
                            {{ cartStore.discountCode.description }} auf den Gesamtbetrag
                            <span v-if="cartStore.discountCode.code != ''">
                ({{ cartStore.discountCode.code }})
              </span>
                        </small>
                    </div>
                </div>

                <div class="col-12 d-flex">

                    <small class="text-danger">{{ minError }}</small>
                    <div class="d-flex flex-wrap flex-grow-1 justify-content-end pe-2">

                        <div v-if="type === 'tickets'">
                            <span>{{ getTranslation('module_shop_component_checkout_sum_label', 'Summe') }}:</span>
                            <br/>

                            <span v-if="cart_sums.system_fee > 0">{{
                                    getTranslation('module_shop_component_checkout_fee_label', 'Gebühren')
                                }}:</span>
                            <br v-if="cart_sums.system_fee > 0"/>

                            <span v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)">
                {{ getTranslation('module_shop_component_checkout_discount_label', 'Rabatt') }}:
              </span>
                            <br v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)"/>

                            <hr class="my-0"/>
                            <span><b>
                  {{ getTranslation('module_shop_component_checkout_total_label', 'Gesamt inkl. MwSt.') }}:
                </b></span>
                        </div>

                        <div v-else-if="type === 'lounges' || type === 'drinks'">
                            <span>{{ getTranslation('module_shop_component_checkout_sum_label', 'Summe') }}:</span>
                            <br/>
                            <span v-if="settings.lounges.booking.fee_active && (cart_sums.system_fee > 0)">
                {{ getTranslation('module_shop_component_checkout_fee_label', 'Gebühren') }}:
              </span>
                            <br v-if="settings.lounges.booking.fee_active && (cart_sums.system_fee > 0)"/>

                            <span v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)">
                {{ getTranslation('module_shop_component_checkout_discount_label', 'Rabatt') }}:
              </span>
                            <br v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)"/>
                            <hr class="my-0"/>
                            <span><b>
                  {{ getTranslation('module_shop_component_checkout_total_label', 'Gesamt inkl. MwSt.') }}:
                </b></span>
                        </div>

                    </div>

                    <div class="" style="text-align: right; min-width: 70px;" v-if="!price_is_loading">

                        <div>
                            <span>{{ format(cart_sums.sum) }}</span>
                            <br/>

                            <span
                                v-if="(settings?.lounges?.booking?.fee_active || type === 'tickets') && cart_sums.system_fee > 0">{{
                                    format(cart_sums.system_fee)
                                }}</span>
                            <br v-if="(settings?.lounges?.booking?.fee_active || type === 'tickets') && cart_sums.system_fee > 0"/>

                            <span v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)"><b>{{
                                    format(cart_sums.discount)
                                }}</b></span>
                            <br v-if="cartStore.isDiscounted || (cart_sums.discount && cart_sums.discount < 0)"/>

                            <hr class="my-0"/>
                            <span><b>{{ format(cart_sums.total) }}</b></span>
                            <br/>
                        </div>


                    </div>
                    <div v-else class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-white" role="status">
                            <span class="visually-hidden">{{
                                    getTranslation('refresh_label', 'Aktualisiere...')
                                }}</span>
                        </div>
                    </div>

                </div>

                <div v-if="type === 'lounges' && persons > 1 && settings.lounges.show_per_person_price"
                     style="width:100%;text-align: right;">
          <span style="color: #fd7e14;">{{
                  getTranslation('per_person_price_label_prefix', 'Das sind nur')
              }} ~{{ format((cart_sums.total) / persons) }} {{
                  getTranslation('module_lounges_per_person', 'pro Person')
              }}!</span>
                </div>

                <Transition name="slide-fade-left">
                    <div class="col-12 d-flex justify-content-center flex-wrap mt-4" style="display: flex;"
                         v-if="showDiscountCodeField">
                        <a class="btn-read-more ticket-btn" @click="toggleDiscountCodeVisible" style="cursor: pointer;">
              <span>
                {{
                      getTranslation('module_shop_component_checkout_discount_code_label', 'Du hast einen Rabattcode? Löse ihn hier ein.')
                  }}
              </span>
                        </a>
                    </div>
                </Transition>

                <Transition name="slide-fade-left">
                    <div class="col-12 d-flex justify-content-center flex-wrap mt-4" style="display: flex;"
                         v-if="!cartStore.isDiscounted && cart_sums.sum > 0 && (settings['discount-codes'] && settings['discount-codes'].active)">
                        <div v-show="isDiscountCodeVisible">
                            <code-input ref="codeInput" :codeIsLoading="codeIsLoading"
                                        @redeemCode="redeemCode"></code-input>
                            <div
                                v-if="!cartStore.isDiscounted && cartStore.discountCodeStr && cartStore.discountCodeError">
                                <span class="text-danger">{{ cartStore.discountCodeError }}</span>
                            </div>
                        </div>
                    </div>
                </Transition>

                <div class="col-12 d-flex flex-wrap mt-4 checkout-form"
                     v-if="event.personalized_tickets && type === 'tickets'">
                    <PersonalizedTicketsForm ref="personalizedTickets"></PersonalizedTicketsForm>
                </div>

            </div>


            <h4 class="mt-4 mb-2">{{
                    getTranslation('module_shop_component_checkout_userinfos_label', 'Kontaktinfos')
                }}:</h4>

            <div class="col-12 checkout-event" v-if="(!checkoutRegister) && authStore.isAuthenticated() && userInfos">
                <h5 class="mb-0">{{ getTranslation('ticket_ordering_from_user_text', "Bestellt auf") }} {{
                        userInfos.firstname
                    }} {{ userInfos.lastname }} - {{ getTranslation('birthday_short', "geb. am") }}
                    {{ formatDate(userInfos.birthday) }}
                    <a class="" href="#" @click="addMissingInfos">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-edit">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                    </a>
                </h5>
                <div class="checkout-missing-infos" v-if="userStore.isUserMissingInfos()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-alert-triangle">
                        <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                        <line x1="12" y1="9" x2="12" y2="13"></line>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <h6 class="">
                        {{
                            getTranslation('module_shop_component_checkout_missinginfos_text', 'Wichtig: Bei deinem Konto fehlen Angaben, welche für den Bestellprozess notwendig sind!')
                        }}
                    </h6>
                    <a class="btn-link-underlined" href="#" @click="addMissingInfos" data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       title="{{ getTranslation('module_shop_component_checkout_missinginfos_button_text', 'Angaben hier vervollständigen') }}">
            <span>
              {{
                    getTranslation('module_shop_component_checkout_missinginfos_button_text', 'Angaben hier vervollständigen')
                }}
            </span>
                    </a>
                </div>
                <div class="mb-4" v-else>
                    {{ userInfos.street ? userInfos.street + '-' : '' }} {{ userInfos.zip }} {{ userInfos.place }}
                </div>

                <div class="checkout-missing-infos" v-if="userInfos.unconfirmed_email !== ''">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-alert-triangle">
                        <path
                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                        <line x1="12" y1="9" x2="12" y2="13"></line>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <h6 class="">
                        {{
                            getTranslation('module_shop_component_checkout_verify_email_title', 'Wichtig: Du hast deine E-Mail Adresse noch nicht bestätigt!')
                        }}
                    </h6>
                    <span>
            {{ getTranslation('module_shop_component_checkout_missing_mail_prefix', 'Keine Mail auf') }}
            {{ userInfos.unconfirmed_email }}
            {{ getTranslation('module_shop_component_checkout_missing_mail_suffix', 'erhalten?') }}
            <a class="btn-link-underlined" href="#" @click="startVerify('verifyEmail')">
              <span>{{
                      getTranslation('module_shop_component_checkout_missing_mail_resend', 'Bestätigungs-Mail erneut anfordern.')
                  }}</span>
            </a>
          </span>
                </div>


                <h5>{{ getTranslation('phone_label', 'Deine Telefonnummer') }} <small>{{
                        settings['verification'].includes(this.type) ? '' : '(' + getTranslation('optional', 'optional') + ')'
                    }}</small></h5>
                <div class="form-group w-100">
                    <div class="input-group">
                        <input name="phone" type="text"
                               :placeholder="getTranslation('phone_placeholder', 'Telefonnummer')"
                               class="form-control"
                               :class="((!userInfos.phone || !userInfos.phone_verified) && settings['verification'].includes(this.type)) ? 'is-invalid' : ''"
                               id="phone" :value="verifyPhoneInital(userInfos.phone)"
                               style="border-right: 0; background: none;"
                               disabled/>
                        <span class="phone-verified-status text-success px-2"
                              v-if="userInfos.phone && userInfos.phone_verified"><svg xmlns="http://www.w3.org/2000/svg"
                                                                                      width="18"
                                                                                      height="18" viewBox="0 0 24 24"
                                                                                      fill="none" stroke="currentColor"
                                                                                      stroke-width="2"
                                                                                      stroke-linecap="round"
                                                                                      stroke-linejoin="round"
                                                                                      class="feather feather-check-circle">
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                <polyline points="22 4 12 14.01 9 11.01"></polyline>
              </svg></span>


                        <div class="input-group-append" style="height: 50px">
                            <button class="btn bg-white text-black" type="button"
                                    style="border-bottom-left-radius: 0;border-top-left-radius: 0;border:0;padding-bottom:10px;"
                                    @click="startVerify('verifyPhone')" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Telefonnummer ändern">
                                <svg width="16px" height="16px" version="1.1" viewBox="0 0 100 100"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g fill="currentColor">
                                        <path
                                            d="m98.578 10.621c-0.89844-1.9414-2.1914-3.7617-3.7383-5.3281-1.5508-1.5586-3.3594-2.8789-5.3086-3.8008-4.9883-2.3594-10.84-2.2188-15.512 2.5312l-4.8281 4.9219-5.1797 5.2695-42.473 43.207-0.070312 0.070313v0.011718l-0.023438 0.023438v0.011718l-0.13672 0.13672v0.011719l-0.023438 0.023438v0.011718l-0.070312 0.070313-0.023438 0.039062-0.011718 0.011719-0.023438 0.039062v0.023438l-0.019531 0.03125v0.011719l-0.011719 0.011719-0.023438 0.039062h0.046876l-0.011719 0.019531-0.011719 0.011719-0.023438 0.039062v0.011719l-0.011718 0.03125v0.023438l-0.023438 0.039062v0.011719l-0.023437 0.039062v0.20703l-7.4297 27.102c-0.28125 1.0391 0.32812 2.1211 1.3789 2.3984 0.37109 0.10156 0.73828 0.089844 1.0898-0.019532v0.011719l26.762-8.2383c0.37109-0.10938 0.67969-0.32031 0.91016-0.58984l42.359-43.102 0.011719-0.011719 5.1719-5.2695 4.8281-4.9219c4.5859-4.4844 4.7461-10.215 2.4531-15.172zm-74.488 70.719-4.1797-4.1016 4.0312-14.699 14.578 14.32zm18.129-6.3398-16.5-16.219 39.719-40.41 16.5 16.219zm42.461-43.211-14.711-14.461-1.8008-1.7617 2.4297-2.4688 16.5 16.219zm8.6797-8.8281-0.039063 0.039062-3.4609 3.5195-16.508-16.219 3.4609-3.5195c3.3008-3.3594 7.4688-3.4297 11.059-1.7383 1.5312 0.71875 2.9609 1.7617 4.1914 3s2.25 2.6797 2.9609 4.2188c1.6367 3.5586 1.5469 7.6172-1.6641 10.699z"/>
                                        <path
                                            d="m82.84 92.25c0 0.46094-0.19141 0.89062-0.5 1.1992-0.30859 0.30859-0.73047 0.5-1.1992 0.5h-73.41c-0.46094 0-0.89062-0.19141-1.1992-0.5-0.30859-0.30859-0.5-0.73047-0.5-1.1992v-73.422c0-0.46094 0.19141-0.89062 0.5-1.1992s0.73047-0.5 1.1992-0.5h39.711l5.6797-6.0586h-45.391c-2.1406 0-4.0703 0.87109-5.4805 2.2812-1.3984 1.3984-2.2812 3.3398-2.2812 5.4805v73.422c0 2.1406 0.87109 4.0703 2.2812 5.4805 1.3984 1.3984 3.3398 2.2695 5.4805 2.2695h73.422c2.1406 0 4.0703-0.87109 5.4805-2.2695 1.3984-1.3984 2.2812-3.3398 2.2812-5.4805l-0.003906-44.375-6.0586 6.1992v38.172z"/>
                                    </g>
                                </svg>

                            </button>
                        </div>
                    </div>

                    <div class="checkout-missing-infos" v-if="phoneError">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-alert-triangle">
                            <path
                                d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                            <line x1="12" y1="9" x2="12" y2="13"></line>
                            <line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                        <h6 class="">{{ phoneError }}</h6>
                        <a class="btn-link-underlined" href="#" @click="startVerify('verifyPhone')"
                           v-if="!userInfos.phone_verified">
              <span>
                {{ getTranslation('verify_phone_button_text', 'Telefonnummer jetzt bestätigen') }}
              </span>
                        </a>
                    </div>


                </div>

            </div>


            <div class="col-12 checkout-event" v-if="_checkoutRegister && !authStore.isAuthenticated()">
                <h5 class="mb-0">
                    {{ getTranslation('module_shop_component_checkout_login_title', 'Du bist bereits Mitglied?') }}
                </h5>
                <small>
                    {{ getTranslation('module_shop_component_checkout_login_text', 'Dann logge dich jetzt ein…') }}
                </small>

                <div class="d-flex justify-content-center my-2">
                    <button type="button" @click="changeComponent('login', 'Auth');" class="btn w-75">
                        <span>Login</span>
                    </button>
                </div>

                <h5 class="mb-2 mt-4">
                    {{ isGuestEnabled ?
                        getTranslation('module_shop_component_checkout_guest_title', 'Oder bestelle als Gast') :
                        getTranslation('module_shop_component_checkout_create_account_title', 'Oder erstelle einen Account')
                    }}:
                </h5>

                <CheckoutForm/>
            </div>

            <div class="col-12 checkout-event" v-else-if="_checkoutRegister && !authStore.isAuthenticated()">
                <h5 class="mb-0">
                    {{ getTranslation('module_shop_component_checkout_login_required', 'Bitte logge dich ein um fortzufahren') }}
                </h5>
                <div class="d-flex justify-content-center my-2">
                    <button type="button" @click="changeComponent('login', 'Auth');" class="btn w-75">
                        <span>Login</span>
                    </button>
                </div>
            </div>


            <div class="mt-4" v-if="isInvoiceAddressEnabled">
                <div class="d-flex flex-wrap" style="margin-bottom: 25px;" v-if="isInvoiceAddressEnabled">
                    <!--<div class="d-flex flex-wrap" style="margin-bottom: 25px;" v-if="event.invoice_address_possible">-->
                    <div class="col-12 px-2 text-center">
                        <div class="form-group mb-0">
                            <div class="form-check w-100 p-0">
                                <input class="form-check-input m-0 p-0" type="checkbox" id="showInvoiceAddress"
                                       :checked="!isShowInvoiceAddress" @click="toggleInvoiceAddress"
                                       style="height: 24px;"/>
                                <label class="form-check-label" for="showInvoiceAddress">
                                    {{
                                        getTranslation('module_shop_component_checkout_invoice_address_label', 'Rechnungsadresse stimmt mit Lieferadresse überein')
                                    }}:
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-4" v-show="isShowInvoiceAddress">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="mb-2 mt-4">
                                {{
                                    getTranslation('module_shop_component_checkout_invoice_address_title', 'Rechnungsadresse')
                                }}
                            </h5>
                        </div>
                        <InvoiceAddressForm/>
                    </div>
                </div>
            </div>


            <h4 v-if="type === 'lounges'" class="mt-4 mb-2">
                {{ getTranslation('module_shop_component_checkout_notes', 'Hinweise:') }}</h4>
            <div class="col-12 mt-2 checkout-event" v-if="type === 'lounges'">
                <div class="w-100 form-group form-floating">
                    <textarea name="notes" class="form-control" id="notes" v-once :value="notes" @change="setNotes"
                        :placeholder="getTranslation('module_shop_component_checkout_note_placeholder', 'Gerne kannst du uns noch Anmerkungen hinterlassen...')"/>
                    <label for="notes">{{
                            getTranslation('module_shop_component_checkout_note_label', 'Hinweise für uns')
                        }}</label>
                </div>
            </div>


            <div>
                <h4 v-if="hasPayments" class="mt-4 mb-2">
                    {{ getTranslation('module_shop_component_checkout_payment_title', 'Wie möchtest du zahlen?') }}
                </h4>
                <div class="checkout-event2 col-12">

                    <div v-if="hasPayments" class="col-12 mt-2">
                        <payment :type="type" :paymentMethod="paymentMethod"
                                 :showConditionsFor="paymentMethod === 'bar' && settings.lounges.booking.fee_active && settings.lounges.booking.pay_fees_directly_on_bar_booking && type === 'lounges' ? false : type"
                                 @changePaymentMethod="changePaymentMethod"></payment>
                    </div>
                    <div v-if="type == 'lounges' && config.require_lounge_terms === 1" class="mt-4 mb-2">
                        <div class="form-group mb-0">
                            <div class="form-check w-100 p-0">
                                <input name="terms" class="form-check-input m-0 p-0" type="checkbox"
                                       v-model="hasAcceptedTerms"
                                       id="terms_check" style="height: 24px;"/>
                                <label class="form-check-label" for="terms_check"
                                       v-html="getTranslation('module_shop_component_checkout_accept_terms', 'Ich habe die %link% gelesen.').replace('%link%', `<a href='/agb' target='_blank'>${getTranslation('module_shop_component_checkout_accept_terms_terms', 'Reservierungsbedingungen')}</a>`)">
                                </label>
                            </div>
                        </div>
                    </div>

                    <Transition v-if="hasFees" name="slide-fade-right">
                        <div class="col-12 my-4"
                             v-if="paymentMethod === 'bar' && settings.lounges.booking.fee_active && settings.lounges.booking.pay_fees_directly_on_bar_booking && type === 'lounges'">
                            <div>
                                <span style='color: var(--color-warning)'>
                                    {{
                                        getTranslation('module_shop_component_checkout_payment_fee_text_prefix', 'Die Buchungsgebühr in Höhe von')
                                    }}
                                    <b>{{ format(cart_sums.system_fee) }}</b>
                                    {{ getTranslation('module_shop_component_checkout_payment_fee_text_suffix', 'fällt sofort an.') }}
                                </span>
                                                    <br/>
                                                    <span>
                                    {{ getTranslation('method_bar_fees_paid_message', 'Der Rest wird von dir vor Ort in Bar erwartet.') }}
                                </span>
                            </div>
                            <h4 class="m-0">
                                {{ getTranslation('method_bar_pay_fees_message', 'Wie möchtest du diese begleichen?') }}
                            </h4>
                        </div>
                    </Transition>

                    <Transition v-if="hasFees" name="slide-fade-left">
                        <div class="col-12 mt-2"
                             v-if="paymentMethod === 'bar' && settings.lounges.booking.fee_active && settings.lounges.booking.pay_fees_directly_on_bar_booking && type === 'lounges' && hasFees">
                            <payment :type="'fees'" :paymentMethod="feesPaymentMethod" :showConditionsFor="'lounges'"
                                     @changePaymentMethod="changeFeePaymentMethod"></payment>
                        </div>
                    </Transition>

                    <div v-if="checkoutError" class="alert alert-danger" role="alert">
                        {{ checkoutError }}
                    </div>

                    <div class="col-12 d-flex justify-content-center mt-4">
                        <button type="button" class="btn btn-secondary w-100 register"
                                data-umami-event="checkout#click" @click="handleSubmit" :disabled="isCheckoutPending">
                            <span v-if="isCheckoutPending">
                                {{ getTranslation('module_shop_component_checkout_buy_button_text', 'Jetzt bezahlen') }}
                            </span>
                            <span v-else>
                                {{ getTranslation('module_shop_component_checkout_buy_button_text', 'Jetzt bezahlen') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useDebounceFn} from "@vueuse/core";
import {computed, ref} from "vue";
import Login from "../oauth/Login";

import CodeInput from "../../utils/components/codeInput.vue";
import renderDrink from "../drinks/renderDrink";
import renderPackage from "../lounges/packages/renderPackage";
import CheckoutRegister from "../oauth/register/checkoutRegister";
import InvoiceAddress from "../oauth/register/invoiceAddress";
import payment from "../payment";
import PersonalizedTicketsForm from "../tickets/PersonalizedTicketsForm";
import renderTickets from "../tickets/renderTickets";
import CheckoutForm from "./CheckoutForm.vue";

import {mapState, mapStores, storeToRefs} from "pinia";
import {useAuthStore} from "../../../store/auth_store";
import {useCartStore} from "../../../store/cart_store";
import {useDataStore} from "../../../store/data_store";
import {useUserStore} from "../../../store/user_store";

import lounge_api from "../../../api/lounge_api";
import {useCheckoutStore} from "../../features/checkout/checkout.store";
import InvoiceAddressForm from "./InvoiceAddressForm.vue";
import {useTranslation} from "../../composables/useTranslation";

let components;

try {
    components = require("../../../data/child_strings");
} catch (e) {
    components = require("../../../data/default_strings");
}
components = components.default;

export default {
    name: "checkout",
    setup(props) {
        const dataStore = useDataStore();
        const {settings} = storeToRefs(dataStore);
        const isLoading = ref(true);

        return {
            isLoading,
            loungesCancellationText: computed(
                () => settings.value?.lounges?.cancellation_text ?? "",
            ),
            ticketsCancellationText: computed(
                () => settings.value?.tickets?.cancellation_text ?? "",
            ),
            drinksCancellationText: computed(
                () => settings.value?.drinks?.cancellation_text ?? "",
            ),
        };
    },
    data() {
        const authStore = useAuthStore();

        return {
            components,
            minError: null,
            phoneError: null,
            phoneVerified: true,
            paymentFeeMethod: "paypal",
            isLoading: false,
            _checkoutRegister: !authStore.isAuthenticated(),
            invoiceAddressVisible: false,
            loungeFees: 0,
            loungeFeesDiscounted: 0,
            codeIsLoading: false,
            redeemError: null,
            isDiscountCodeVisible: false,
            hasAcceptedTerms: false,
        };
    },
    props: {
        type: {
            type: String,
            required: true,
            validator: (value) => ["lounges", "tickets", "drinks"].includes(value),
        },
    },
    inject: ["getTranslation"],
    components: {
        CodeInput,
        Login,
        renderTickets,
        PersonalizedTicketsForm,
        renderPackage,
        renderDrink,
        CheckoutRegister,
        InvoiceAddress,
        payment,
        CheckoutForm,
        InvoiceAddressForm,
    },
    computed: {
        ...mapStores(
            useAuthStore,
            useDataStore,
            useCartStore,
            useUserStore,
            useCheckoutStore,
        ),
        ...mapState(useCartStore, [
            "status",
            "selectedTickets",
            "cart_sums",
            "countTickets",
            "selectedFreeTickets",
            "selectedLounge",
            "arriveTime",
            "persons",
            "selectedPackages",
            "notes",
            "discountCode",
            "selectedTicketsForCheckout",
            "selectedPackagesForCheckout",
            "countFreeTickets",
            "price_is_loading",
            "selectedDrinks",
            "selectedDrinksForDiscountCheck",
            "cart_sums",
        ]),
        ...mapState(useDataStore, ["event", "settings", "config"]),
        ...mapState(useCheckoutStore, {
            paymentMethod: "paymentMethod",
            feesPaymentMethod: "feesPaymentMethod",
            checkoutError: "error",
            isShowInvoiceAddress: "isShowInvoiceAddress",
            isInvoiceAddressEnabled: "isInvoiceAddressEnabled",
            isCheckoutPending: "isPending",
            isGuestEnabled: "isGuestEnabled",
        }),
        hasPayments() {
            return this.cart_sums.total > 0;
        },
        hasFees() {
            return this.cart_sums.system_fee > 0;
        },
        userInfos() {
            return this.userStore.userInfosRef
                ? JSON.parse(this.userStore.userInfosRef)
                : this.userStore.userInfosRef;
        },
        showDiscountCodeField() {
            return (
                ((!this.cartStore.isDiscounted && this.cart_sums.sum > 0) ||
                    this.discountCode?.automatic) &&
                this.settings["discount-codes"] &&
                this.settings["discount-codes"].active
            );
        },
        buyButtonText() {
            if (this.type === "lounges") {
                if (
                    this.paymentMethod === "bar" &&
                    !this.settings.lounges.booking.fee_active
                ) {
                    if (this.selectedLounge.booking_requests) {
                        return this.getTranslation(
                            "module_shop_component_checkout_lounge_request_button_text",
                            "Jetzt anfragen",
                        );
                    }
                    return this.getTranslation(
                        "module_shop_component_checkout_lounge_reservate_button_text",
                        "Jetzt reservieren",
                    );
                }
            }
            return this.getTranslation(
                "module_shop_component_checkout_buy_button_text",
                "Jetzt bezahlen",
            );
        },
        showGuestCheckoutForm() {
            return !this.authStore.isAuthenticated();
        },
        gtcText: () => useTranslation('module_shop_component_checkout_gtc_text', `Es gelten unsere <a href="/agb" rel="nofollow"
              target="_blank" style="font-weight: bold">AGB</a> und <a style="font-weight: bold" href="/datenschutz"
              rel="nofollow" target="_blank">Datenschutzerklärungen</a>. `)
    },
    emits: ["changeComponent"],
    methods: {
        toggleInvoiceAddress() {
            this.checkoutStore.toggleShowInvoiceAddress();
        },
        changeComponent: function (component, module = "Shop", params = {}) {
            this.$emit("changeComponent", {component, module, params});
            if (component === "login") {
                this._checkoutRegister = false;
            }
        },
        cleanData(data) {
            for (const key in data) {
                if (data[key] === undefined) {
                    data[key] = null;
                } else if (typeof data[key] === "object" && data[key] !== null) {
                    this.cleanData(data[key]);
                }
            }
            return data;
        },
        format: (price) =>
            new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
            }).format(price),
        formatDate: (date, format = "DD.MM.YYYY") => moment(date).format(format),
        calculateLoungeFee: useDebounceFn(
            function () {
                lounge_api
                    .calculateLoungeFee({
                        price: this.cart_sums.total,
                        pay_type: this.paymentMethod,
                        pay_type_fees: this.paymentFeeMethod,
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (this.cartStore.isDiscounted) {
                            this.loungeFeesDiscounted = res.system_fees;
                        } else {
                            this.loungeFees = res.system_fees;
                        }
                    });
            },
            250,
            {maxWait: 5000},
        ),
        changePaymentMethod: function (method) {
            this.checkoutStore.setPaymentMethod(method);

            this.validatePhone();
            // this.calculateLoungeFee();
        },
        changeFeePaymentMethod: function (method) {
            this.checkoutStore.setFeesPaymentMethod(method);
            // this.calculateLoungeFee();
        },
        verifyPhone: function (e) {
            const val = e.target.value;
            if (this.settings.verification.includes(this.type)) {
                if (val === "") {
                    this.phoneError = this.getTranslation(
                        "error_phone_required",
                        "Telefonnummer wird benötigt.",
                    );
                } else if (
                    !this.userInfos.phone_verified ||
                    this.userInfos.phone !== val
                ) {
                    this.phoneError = this.getTranslation(
                        "error_phone_not_verified",
                        "Diese Telefonnummer ist noch nicht bestätigt.",
                    );
                    this.phoneVerified = false;
                } else {
                    this.phoneError = null;
                }
            }
        },
        verifyPhoneInital: function (value) {
            if (this.settings.verification.includes(this.type)) {
                if (value === "") {
                    this.phoneError = this.getTranslation(
                        "error_phone_required",
                        "Telefonnummer wird benötigt.",
                    );
                } else if (
                    !this.userInfos.phone_verified ||
                    this.userInfos.phone !== value
                ) {
                    this.phoneError = this.getTranslation(
                        "error_phone_not_verified",
                        "Diese Telefonnummer ist noch nicht bestätigt.",
                    );
                } else {
                    this.phoneError = null;
                }
            }
            return value;
        },
        startVerify: function (type) {
            this.changeComponent("verify", "Auth", {verifyType: type});
        },
        addMissingInfos: function () {
            this.changeComponent("missingInfos", "Auth");
        },
        validateInvoiceAddress() {
            return new Promise((resolve) => {
                if (
                    this.invoiceAddressVisible &&
                    this.event.invoice_address_possible &&
                    this.$refs.invoiceAddressComponent
                ) {
                    this.$refs.invoiceAddressComponent.validate().then((result) => {
                        if (result.valid) {
                            resolve();
                        } else {
                            this.isLoading = false;
                        }
                    });
                } else {
                    resolve();
                }
            });
        },
        validatePhone() {
            const checkoutForm = this.$refs.checkoutRegisterForm;

            if (!checkoutForm) {
                return;
            }

            if (this.paymentMethod === "sofort") {
                checkoutForm.requirePhone();
                this.isLoading = false;
            } else {
                checkoutForm.unrequirePhone();
            }
        },
        validatePersonalizedTickets() {
            return new Promise((resolve) => {
                if (this.event.personalized_tickets && this.type === "tickets") {
                    if (this.$refs.personalizedTickets.namesComplete()) {
                        resolve();
                    } else {
                        this.isLoading = false;
                    }
                } else {
                    resolve();
                }
            });
        },
        async handleSubmit() {
            this.checkoutStore.checkout();
        },
        setNotes: function (event) {
            this.cartStore.setNotes(event.target.value);
        },
        redeemCode: function (data) {
            this.codeIsLoading = true;
            if (data.code) {
                if (this.$refs.codeInput) {
                    this.$refs.codeInput.classes = "is-valid";
                }
                this.cartStore.clearDiscountErrors();
                this.cartStore
                    .setDiscountCode(data.code, this.type)
                    .then(() => {
                        this.codeIsLoading = false;
                    })
                    .then(() => {
                        this.codeIsLoading = false;
                        if (!this.cartStore.isDiscounted && this.$refs.codeInput) {
                            // this.cartStore.removeDiscountCode();
                            this.$refs.codeInput.classes = "is-invalid";
                        }
                    });
            } else {
                if (this.$refs.codeInput) {
                    this.$refs.codeInput.classes = "is-invalid";
                }
                this.codeIsLoading = false;
            }
        },
        toggleDiscountCodeVisible: function () {
            this.isDiscountCodeVisible = !this.isDiscountCodeVisible;
        },
    },
    watch: {
        packagePriceSum: {
            handler: function (newPrice, oldPrice) {
                if (this.type === "lounges" && this.selectedLounge) {
                    const min = this.selectedLounge.minimum_consumption;
                    if (min > newPrice) {
                        this.minError =
                            `${this.getTranslation(
                                "error_minimum_consumption",
                                "Der Mindestverzehr wurde nicht erreicht.",
                            )} (${this.format(min)})`;
                    } else {
                        this.minError = null;
                    }

                    // this.calculateLoungeFee();
                }
            },
            immediate: true,
        },
        countTickets: {
            handler(amount, oldAmount) {
                if (this.type === "tickets") {
                    if (amount <= 0) {
                        if (this.freeTicket && this.freeTicket.amount >= 0) {
                            this.minError = null;
                        } else {
                            this.minError = this.getTranslation(
                                "error_select_at_least_one_ticket",
                                "Du musst mindestens 1 Ticket wählen.",
                            );
                        }
                    } else {
                        this.minError = null;
                    }
                }
            },
            immediate: true,
        },
        isDiscountCodeVisible: {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    this.$refs.codeInput?.$el.focus();
                }
            },
        },
    },
    beforeMount() {
        this.checkoutStore.setType(this.type);
        this.authStore.$onAction(({name, store, args, after, onError}) => {
            after((result) => {
                if (name === "login") {
                    this._checkoutRegister = false;
                }

                if (name === "logout") {
                    this._checkoutRegister = true;
                }
            });
        });

        if (this.type === "lounges" && !this.settings.lounges.booking.pay.direct) {
            this.checkoutStore.setPaymentMethod("bar");
        }

        if (
            this.type === "drinks" &&
            !this.settings.lounges.drink_orderings.methods.direct
        ) {
            this.checkoutStore.setPaymentMethod("bar");
        }
    },
    beforeUnmount() {
        if (this.status === "paymentFailed") {
            this.cartStore.clearStatus();
        }
    },
    activated() {
        this.minError = null;
        this._checkoutRegister = !this.authStore.isAuthenticated();

        let data = {};
        if (this.type === "tickets") {
            data = {
                event: this.event,
                tickets: this.selectedTickets,
                freeTicket: this.freeTicket,
                sum: this.cart_sums.total,
            };
        } else if (this.type === "lounges") {
            data = {
                event: this.event,
                lounge: this.selectedLounge,
                packages: this.selectedPackages,
                arriveTime: this.arriveTime,
                persons: this.persons,
                notes: this.notes,
                sum: this.cart_sums.total,
            };
        } else if (this.type === "drinks") {
            data = {
                event: this.event,
                drinks: JSON.stringify(this.selectedDrinks),
                sum: this.cart_sums.total,
            };
        }
        document.body.dispatchEvent(
            new CustomEvent("EVENT_NAME_INITIATED_CHECKOUT", {detail: data}),
        );
    },
};
</script>

<style scoped>
.text-crossed {
    color: var(--color-danger);
    text-decoration: line-through;
}
</style>
