import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import drink_api from "../api/drink_api";
import lounge_api from "../api/lounge_api";
import ticket_api from "../api/ticket_api";
import { useAuthStore } from "./auth_store";
import { useDataStore } from "./data_store";

export const useCartStore = defineStore({
  id: "cart",
  state: () => ({
    // status
    _status: useLocalStorage("status", null),
    // lounge params
    _selectedLounge: useLocalStorage("cart_selected_lounge", null),
    _selectedPackages: useLocalStorage("cart_selected_packages", {}), // {id: amount, ...}
    _discountedPackages: useLocalStorage("cart_discounted_packages", null),
    _persons: useLocalStorage("cart_persons", 1),
    _arriveTime: useLocalStorage("cart_arrive_time", null),
    _notes: useLocalStorage("cart_notes", null),
    _loungeBookingNumber: useSessionStorage("cart_lounge_booking_number", null),
    // ticket params
    _ticketOwners: useLocalStorage("cart_ticket_owners", {}), // { ticketId: [{firstname, lastname, company}, ...], ... }
    _selectedTickets: useLocalStorage("cart_selected_tickets", {}), // {id: amount, ...}
    _selectedFreeTickets: useLocalStorage("cart_selected_free_tickets", {}), // {id: amount, ...}
    _discountedTickets: useLocalStorage("cart_discounted_tickets", null),
    // drink params
    _selectedDrinks: useLocalStorage("cart_selected_drinks", {}), // {id: amount, ...}
    _discountedDrinks: useLocalStorage("cart_discounted_drinks", null),

    // cart sums
    _cart_sums: useLocalStorage("cart_sums", {
      sum: 0,
      total: 0,
      discount: 0,
    }),
    // discount code
    _discountCode: useLocalStorage("cart_discount_code", null),
    _discountCodeStr: useLocalStorage("cart_discount_code_str", null),
    _discountCodeError: useLocalStorage("cart_discount_code_error", null),
    _discount_on_total: 0,
    _price_is_loading: useLocalStorage("cart_price_is_loading", false),
  }),
  getters: {
    status: (state) => state._status,
    //LOUNGE
    selectedLounge: (state) => {
      const dataStore = useDataStore();
      if (!state._selectedLounge) return null;
      return dataStore.getLounge(state._selectedLounge);
    },
    price_is_loading: (state) => state._price_is_loading,
    discountedPackages: (state) => state._discountedPackages,
    discountedTickets: (state) => state._discountedTickets,
    cart_sums: (state) => state._cart_sums,
    discountCode: (state) => state._discountCode,
    discountCodeStr: (state) => state._discountCodeStr,
    discountCodeError: (state) => state._discountCodeError,
    discountOnTotal: (state) => state._discount_on_total,
    persons: (state) => state._persons,
    arriveTime: (state) => state._arriveTime,
    notes: (state) => state._notes,
    packageCount: (state) => {
      return (package_id) => {
        if (
          Object.keys(state._selectedPackages).length === 0 ||
          !state._selectedPackages[package_id]
        )
          return 0;
        return state._selectedPackages[package_id];
      };
    },
    countPackages: (state) => {
      if (Object.keys(state._selectedPackages).length === 0) return 0;

      let count = 0;
      Object.keys(state._selectedPackages).forEach((item) => {
        count += state._selectedPackages[item];
      });
      return count;
    },
    selectedPackages: (state) => {
      const dataStore = useDataStore();

      if (Object.keys(state._selectedPackages).length === 0) return [];

      const packages = [];

      Object.keys(state._selectedPackages).forEach((item) => {
        let pack = dataStore.getPackage(state._selectedLounge, item);
        // Add null check here
        if (!pack) return; // Skip if package is null

        // Create a new object to avoid mutating store data
        pack = {
          ...pack,
          amount: state._selectedPackages[item],
          discounted: false,
        };

        if (state._discountedPackages && state._discountedPackages.length) {
          state._discountedPackages.forEach((discounted_package) => {
            if (discounted_package.id == pack.id) {
              pack.discounted = discounted_package.discounted;
              pack.discounted_price = discounted_package.price;
              pack.amount = discounted_package.amount;
            }
          });
        }
        packages.push(pack);
      });
      return packages;
    },
    selectedPackagesForCheckout: (state) => {
      const dataStore = useDataStore();

      if (Object.keys(state._selectedPackages).length === 0) return [];

      const packages = [];

      Object.keys(state._selectedPackages).forEach((item) => {
        const pack = JSON.parse(
          JSON.stringify(dataStore.getPackage(state._selectedLounge, item)),
        ); // removes reference to prevent update of package in dataStore

        pack.amount = state._selectedPackages[item];
        pack.discounted = false;
        if (state._discountedPackages && state._discountedPackages.length) {
          state._discountedPackages.forEach((discounted_package, index) => {
            if (discounted_package.id == pack.id) {
              pack.discounted = discounted_package.discounted;
              pack.price = discounted_package.price;
              pack.amount = discounted_package.amount;
            }
          });
        }
        packages.push(pack);
      });
      return packages;
    },
    selectedPackagesForDiscountCheck: (state) => {
      const packages = [];

      Object.keys(state._selectedPackages).forEach((item) => {
        packages.push({ id: item, amount: state._selectedPackages[item] });
      });
      return packages;
    },
    // TICKETS
    selectedTickets: (state) => {
      const dataStore = useDataStore();

      if (Object.keys(state._selectedTickets).length === 0) return [];

      const tickets = [];

      Object.keys(state._selectedTickets).forEach((item) => {
        const ticket = dataStore.getTicket(item);
        ticket.amount = state._selectedTickets[item];
        ticket.discounted = false;

        console.log({discountedTickets: state._discountedTickets, ticket: ticket});

        if (state._discountedTickets && state._discountedTickets.length) {
          state._discountedTickets.forEach((discounted_ticket) => {
            if (discounted_ticket.id == ticket.id) {
              ticket.discounted = discounted_ticket.discounted;
              ticket.discounted_price = discounted_ticket.price;
              ticket.discounted_fee_per_ticket =
                discounted_ticket.fee_per_ticket;
              ticket.amount = discounted_ticket.amount;
            }
          });
        }

        // Set owners if available
        if (state._ticketOwners[item]) {
          ticket.owners = state._ticketOwners[item];
        } else {
          ticket.owners = [];
        }

        tickets.push(ticket);
      });
      return tickets;
    },
    selectedTicketsForCheckout: (state) => {
      const dataStore = useDataStore();

      if (Object.keys(state._selectedTickets).length === 0) return [];

      const tickets = [];

      Object.keys(state._selectedTickets).forEach((item) => {
        const ticket = JSON.parse(JSON.stringify(dataStore.getTicket(item))); // removes reference to prevent update of ticket in dataStore
        ticket.amount = state._selectedTickets[item];
        ticket.discounted = false;
        if (state._discountedTickets && state._discountedTickets.length) {
          state._discountedTickets.forEach((discounted_ticket, index) => {
            if (discounted_ticket.id == ticket.id) {
              ticket.price = discounted_ticket.price;
              ticket.fee_per_ticket = discounted_ticket.fee_per_ticket;
              ticket.amount = discounted_ticket.amount;
            }
          });
        }

        if (state._ticketOwners && Object.keys(state._ticketOwners).length) {
          Object.keys(state._ticketOwners).forEach((ticket_id, index) => {
            ticket.owners = Object.values(state._ticketOwners[ticket_id]);
          });
        }

        tickets.push(ticket);
      });
      return tickets;
    },
    selectedTicketsForDiscountCheck: (state) => {
      const tickets = [];

      Object.keys(state._selectedTickets).forEach((item) => {
        const ticket = {
          id: item,
          amount: state._selectedTickets[item],
        };
        tickets.push(ticket);
      });
      return tickets;
    },
    selectedFreeTickets: (state) => {
      // if(Object.keys(state._selectedFreeTickets).length === 0) return [];
      const tickets = [];

      Object.keys(state._selectedFreeTickets).forEach((item) => {
        const ticket = state._selectedFreeTickets[item];
        if (Object.keys(state._ticketOwners).length) {
          Object.keys(state._ticketOwners).forEach((ticket_id, index) => {
            ticket.owners = Object.values(state._ticketOwners[ticket_id]);
          });
        }

        tickets.push(ticket);
      });
      return tickets;
    },
    ticketCount: (state) => {
      return (ticket_id) => {
        if (
          Object.keys(state._selectedTickets).length === 0 ||
          !state._selectedTickets[ticket_id]
        )
          return 0;
        return state._selectedTickets[ticket_id];
      };
    },
    countTickets: (state) => {
      if (
        Object.keys(state._selectedTickets).length === 0 &&
        Object.keys(state._selectedFreeTickets).length === 0
      )
        return 0;

      let count = 0;
      if (Object.keys(state._selectedTickets).length >= 0) {
        Object.keys(state._selectedTickets).forEach((item) => {
          count += state._selectedTickets[item];
        });
      }

      if (Object.keys(state._selectedTickets).length >= 0) {
        Object.keys(state._selectedFreeTickets).forEach((item) => {
          count += state._selectedFreeTickets[item].amount;
        });
      }
      return count;
    },
    countFreeTickets: (state) => {
      if (Object.keys(state._selectedFreeTickets).length === 0) return 0;

      let count = 0;
      if (Object.keys(state._selectedFreeTickets).length >= 0) {
        Object.keys(state._selectedFreeTickets).forEach((item) => {
          count += state._selectedFreeTickets[item].amount;
        });
      }

      return count;
    },
    isDiscounted: (state) => {
      if (state._discountedTickets && state._discountedTickets?.length) {
        return true;
      }
      if (state._discountedPackages && state._discountedPackages?.length) {
        return true;
      }
      if (state._discountedDrinks && state._discountedDrinks?.length) {
        return true;
      }
      return state._discount_on_total > 0;
    },
    // DRINKS
    selectedDrinks: (state) => {
      const dataStore = useDataStore();

      if (Object.keys(state._selectedDrinks).length === 0) return [];

      const drinks = [];

      Object.keys(state._selectedDrinks).forEach((item) => {
        const drink = dataStore.getDrink(item);
        drink.amount = state._selectedDrinks[item].amount;

        drink.discounted = false;
        if (state._discountedDrinks && state._discountedDrinks.length) {
          state._discountedDrinks.forEach((discounted_drink, index) => {
            if (discounted_drink.id == drink.id) {
              drink.discounted = discounted_drink.discounted;
            }
          });
        }
        drinks.push(drink);
      });
      return drinks;
    },
    selectedDrinksForDiscountCheck: (state) => {
      const drinks = [];
      if (Object.keys(state._selectedDrinks).length === 0) return drinks;

      Object.keys(state._selectedDrinks).forEach((item) => {
        drinks.push({ id: item, amount: state._selectedDrinks[item].amount });
      });
      return drinks;
    },
    countDrinks: (state) => {
      if (Object.keys(state._selectedDrinks).length === 0) return 0;
      let count = 0;
      Object.keys(state._selectedDrinks).forEach((item) => {
        count += state._selectedDrinks[item].amount;
      });
      return count;
    },
    countDrink: (state) => {
      return (drink_id) => {
        if (
          Object.keys(state._selectedDrinks).length === 0 ||
          !state._selectedDrinks[drink_id]
        )
          return 0;
        return state._selectedDrinks[drink_id].amount;
      };
    },
    bookingNumber: (state) => state._loungeBookingNumber,
  },
  actions: {
    setStatus(status) {
      return new Promise((resolve) => {
        this._status = status;
        resolve();
      });
    },
    clearStatus() {
      return new Promise((resolve) => {
        this._status = null;
        resolve();
      });
    },
    setLounge(lounge_id, loadPackages = true) {
      return new Promise((resolve) => {
        this._selectedLounge = lounge_id;
        const dataStore = useDataStore();
        if (loadPackages) {
          dataStore.loadLoungePackages(lounge_id);
        }
        resolve();
      });
    },
    setArriveTime(time) {
      this._arriveTime = time;
    },
    setPersonsCount(count) {
      if (count < this.selectedLounge.available_places) {
        this._persons = count;
      }
    },
    incrementPersonsCount() {
      if (this._persons < this.selectedLounge.available_places) {
        this._persons++;
      }
    },
    decrementPersonsCount() {
      if (this._persons > 1) {
        this._persons--;
      }
    },
    updateDiscountedTickets(check_code = false) {
      const auth = useAuthStore();
      const dataStore = useDataStore();
      this._price_is_loading = true;
      return ticket_api
        .checkForDiscounts({
          tickets: JSON.stringify(this.selectedTicketsForDiscountCheck),
          event: dataStore.event.id,
          token: auth.token,
          code: this._discountCodeStr,
        })
        .then((data) => {
          data = JSON.parse(data);
          this._price_is_loading = false;
          if (data.success) {
            this._discountedTickets = data.items;
            this._discountCode = data.discount_code;
            this._discountCodeError = null;
            this._discount_on_total = data.discount_on_total
              ? data.discount_on_total
              : 0;
            this._cart_sums = data.cart_sums;
          } else {
            this._discountedTickets = null;
            this._cart_sums = data.cart_sums
              ? data.cart_sums
              : { sum: 0, total: 0, discount: 0 };
            if (check_code) {
              this._discountCodeError = data.message ? data.message : null;
              if (data.code && data.code === 404) {
                this._discountCodeError = "Rabattcode nicht gefunden.";
              }
            }
          }
          return data;
        });
    },
    updateDiscountedPackages(check_code = false) {
      const auth = useAuthStore();
      const dataStore = useDataStore();
      this._price_is_loading = true;
      return lounge_api
        .checkForDiscounts({
          packages: JSON.stringify(this.selectedPackagesForDiscountCheck),
          event: dataStore.event.id,
          token: auth.token,
          code: this._discountCodeStr,
          lounge_id: this.selectedLounge.id,
        })
        .then((data) => {
          data = JSON.parse(data);
          this._price_is_loading = false;
          if (data.success) {
            this._discountedPackages = data.items;
            this._discountCode = data.discount_code;
            this._discountCodeError = null;
            this._discount_on_total = data.discount_on_total
              ? data.discount_on_total
              : 0;
            this._cart_sums = data.cart_sums;
          } else {
            this._discountedPackages = null;

            this._cart_sums = data.cart_sums
              ? data.cart_sums
              : { sum: 0, total: 0, discount: 0 };
            if (check_code) {
              this._discountCodeError = data.message ? data.message : null;
            }
          }
        });
    },
    updateDiscountedDrinks(check_code = false) {
      const auth = useAuthStore();
      const dataStore = useDataStore();
      this._price_is_loading = true;
      return drink_api
        .checkForDiscounts({
          drinks: JSON.stringify(this.selectedDrinksForDiscountCheck),
          event: dataStore.event?.id,
          token: auth.token,
          code: this._discountCodeStr,
        })
        .then((data) => {
          data = JSON.parse(data);
          this._price_is_loading = false;
          if (data.success) {
            this._discountedDrinks = data.items;
            this._discountCode = data.discount_code;
            this._discountCodeError = null;
            this._discount_on_total = data.discount_on_total
              ? data.discount_on_total
              : 0;
            this._cart_sums = data.cart_sums;
          } else {
            this._discountedDrinks = null;
            this._cart_sums = data.cart_sums
              ? data.cart_sums
              : { sum: 0, total: 0, discount: 0 };
            if (check_code) {
              this._discountCodeError = data.message ? data.message : null;
            }
          }
        });
    },
    addPackage(package_id) {
      return new Promise((resolve) => {
        if (this._selectedPackages[package_id]) {
          this._selectedPackages[package_id] += 1;
          return resolve();
        }

        this._selectedPackages[package_id] = 1;
        return resolve();
      }).then(() => {
        const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'].active) {
        this.updateDiscountedPackages();
        // }
      });
    },
    addTicket(ticket_id) {
      return new Promise((resolve) => {
        if (this._selectedTickets[ticket_id]) {
          this._selectedTickets[ticket_id] += 1;
          resolve();
          return;
        }

        this._selectedTickets[ticket_id] = 1;
        return resolve();
      }).then(() => {
        const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'] && dataStore.settings['discount-codes'].active) {
        this.updateDiscountedTickets();
        // }
      });
    },
    removePackage(package_id) {
      return new Promise((resolve) => {
        if (
          this._selectedPackages[package_id] &&
          this._selectedPackages[package_id] > 1
        ) {
          this._selectedPackages[package_id] -= 1;
          resolve();
          return;
        }
        delete this._selectedPackages[package_id];
        resolve();
      }).then(() => {
        const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'].active) {
        this.updateDiscountedPackages();
        // }
      });
    },
    removeTicket(ticket_id) {
      return new Promise((resolve) => {
        if (
          this._selectedTickets[ticket_id] &&
          this._selectedTickets[ticket_id] > 1
        ) {
          this._selectedTickets[ticket_id] -= 1;
          resolve();
          return;
        }
        delete this._selectedTickets[ticket_id];
        resolve();
      }).then(() => {
        const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'] && dataStore.settings['discount-codes'].active) {
        this.updateDiscountedTickets();
        // }
      });
    },
    addFreeTicket(ticket, code) {
      return new Promise((resolve) => {
        if (this._selectedFreeTickets[ticket.id]) {
          this._selectedFreeTickets[ticket.id].amount =
            this._selectedFreeTickets[ticket.id].max;
          return resolve();
        }

        this._selectedFreeTickets[ticket.id] = ticket;
        this._selectedFreeTickets[ticket.id].code = code;
        this._selectedFreeTickets[ticket.id].amount = ticket.tickets_per_user;
        this._selectedFreeTickets[ticket.id].max = ticket.tickets_per_user;

        return resolve();
      });
    },
    incrementFreeTicketCount(ticket_id) {
      if (
        this._selectedFreeTickets[ticket_id] &&
        this._selectedFreeTickets[ticket_id].amount <
        this._selectedFreeTickets[ticket_id].max
      ) {
        this._selectedFreeTickets[ticket_id].amount++;
      }
    },
    decrementFreeTicketCount(ticket_id) {
      if (
        this._selectedFreeTickets[ticket_id] &&
        this._selectedFreeTickets[ticket_id].amount > 1
      ) {
        this._selectedFreeTickets[ticket_id].amount--;
      } else if (this._selectedFreeTickets[ticket_id]) {
        delete this._selectedFreeTickets[ticket_id];
      }
    },
    setNotes(notes) {
      this._notes = notes;
    },
    setTicketOwners(ticketId, owners) {
      this._ticketOwners[ticketId] = owners;
    },
    getTicketOwners(ticketId) {
      return this._ticketOwners[ticketId] || [];
    },
    addTicketOwner(ticketId, owner) {
      if (!this._ticketOwners[ticketId]) {
        this._ticketOwners[ticketId] = [];
      }
      this._ticketOwners[ticketId].push(owner);
    },
    restoreTicketOrdering(id) {
      const dataStore = useDataStore();
      const auth = useAuthStore();
      return new Promise((resolve) => {
        ticket_api
          .getTicketOrdering({ id: id, token: auth.token })
          .then((resp) => {
            return dataStore.loadData(resp.event.id).then((event) => {
              resp.tickets.forEach((ticket) => {
                this.addTicket(ticket.ticket_id);
              });
            });
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    restoreLoungeBooking(id) {
      const dataStore = useDataStore();
      const auth = useAuthStore();
      return new Promise((resolve) => {
        lounge_api
          .getLoungeBooking({ id: id, token: auth.token })
          .then((resp) => {
            return dataStore.loadData(resp.event.id).then((event) => {
              this.setLounge(resp.lounge.id).then(() => {
                const time = new Date(resp.arrive_time)
                  .toLocaleTimeString("de")
                  .slice(0, 5);
                this.setArriveTime(time)
                this.setPersonsCount(resp.persons);
                resp.packages.forEach((_package) => {
                  for (let i = 0; i < _package.amount; i++) {
                    this.addPackage(_package.id);
                  }
                });
              });
            });
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    restoreDrinkOrdering(id) {
      const dataStore = useDataStore();
      const auth = useAuthStore();
      return new Promise((resolve) => {
        drink_api
          .getDrinkOrdering({ id: id, token: auth.token })
          .then((resp) => {
            return dataStore
              .loadData(resp.event.id)
              .then((event) => dataStore.loadDrinkMenus())
              .then(() => {
                return this.setLoungeBookingNumber(resp.lounge.booking_number);
              })
              .then(() => {
                return this.setLounge(resp.lounge.id)
                  .then(() => {
                    resp.drinks?.forEach((drink) => {
                      for (let i = 0; i < drink.amount; i++) {
                        this.incrementDrinkCount(
                          drink.id,
                          i === drink.amount - 1,
                        );
                      }
                    });
                  })
                  .then(() =>
                    this.setDiscountCode(resp.discount_code, "drinks"),
                  );
              });
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
    reset() {
      return new Promise((resolve) => {
        this._status = null;
        this._selectedLounge = null;
        this._selectedPackages = {};
        this._selectedTickets = {};
        this._selectedFreeTickets = {};

        this._selectedDrinks = {};
        this._persons = 1;
        this._arriveTime = null;
        this._notes = null;
        this._loungeBookingNumber = null;

        this._discountCodeStr = null;
        this._discountCode = null;
        this._discountedTickets = null;
        this._cart_sums = { sum: 0, total: 0, discount: 0 };
        this._discountedPackages = null;
        resolve();
      });
    },
    setDiscountCode(code, type) {
      return new Promise((resolve) => {
        this._discountCodeStr = code;
        this._discountCode = null;
        this._discountedTickets = null;
        this._cart_sums = { sum: 0, total: 0, discount: 0 };
        this._discountedPackages = null;
        this.selectedPackages; // durch zugriff -> reactive force update
        this.selectedTickets; // durch zugriff -> reactive force update
        this.selectedDrinks; // durch zugriff -> reactive force update
        resolve();
      }).then(() => {
        if (type == "tickets") {
          return this.updateDiscountedTickets(true);
        } else if (type == "lounges" || type == "fees") {
          return this.updateDiscountedPackages(true);
        } else if (type == "drinks") {
          this.updateDiscountedDrinks(true);
        }
      });
    },
    removeDiscountCode(type) {
      return new Promise((resolve) => {
        this._discountCodeStr = undefined;
        this._discountCode = undefined;
        this._discountedTickets = undefined;
        // this._cart_sums = {total:0};
        this._discountedPackages = undefined;
        this._discount_on_total = 0;
        resolve();
      }).then(() => {
        if (type == "tickets") {
          return this.updateDiscountedTickets(false);
        } else if (type == "lounges" || type == "fees") {
          return this.updateDiscountedPackages(false);
        } else if (type == "drinks") {
          return this.updateDiscountedDrinks(false);
        } else {
          if (this.selectedTicketsForDiscountCheck.length > 0) {
            return this.updateDiscountedTickets(false);
          } else if (this.selectedPackagesForDiscountCheck.length > 0) {
            return this.updateDiscountedPackages(false);
          } else if (this.selectedDrinksForDiscountCheck.length > 0) {
            return this.updateDiscountedDrinks(false);
          }
        }
      });
    },
    clearSelectedPackages() {
      return new Promise((resolve) => {
        this._selectedPackages = {};
        resolve();
      });
    },
    clearDiscountErrors() {
      return new Promise((resolve) => {
        this._discountCodeError = null;
        resolve();
      });
    },
    // DRINKS
    incrementDrinkCount(drink_id, refresh = true) {
      return new Promise((resolve) => {
        if (
          this._selectedDrinks[drink_id] &&
          this._selectedDrinks[drink_id].amount
        ) {
          this._selectedDrinks[drink_id].amount += 1;
          resolve();
          return;
        }
        this._selectedDrinks[drink_id] = {
          id: drink_id,
          amount: 1,
        };
        resolve();
      }).then(() => {
        if (refresh) {
          this.updateDiscountedDrinks();
        }
      });
    },
    decrementDrinkCount(drink_id) {
      return new Promise((resolve) => {
        if (
          this._selectedDrinks[drink_id] &&
          this._selectedDrinks[drink_id].amount > 1
        ) {
          this._selectedDrinks[drink_id].amount -= 1;
          resolve();
          return;
        }
        delete this._selectedDrinks[drink_id];
        resolve();
      }).then(() => {
        // const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'].active) {
        this.updateDiscountedDrinks();
        // }
      });
    },
    removeDrinkFromSelected(drink_id) {
      return new Promise((resolve) => {
        delete this._selectedDrinks[drink_id];
        resolve();
      }).then(() => {
        // const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'].active) {
        this.updateDiscountedDrinks();
        // }
      });
    },
    clearSelectedDrinks() {
      return new Promise((resolve) => {
        this._selectedDrinks = {};
        resolve();
      }).then(() => {
        // const dataStore = useDataStore();
        // if(dataStore.settings['discount-codes'].active) {
        this.updateDiscountedDrinks();
        // }
      });
    },
    validateBookingNumber(params) {
      const auth = useAuthStore();
      const payload = {
        ...params,
        token: auth.token,
      };
      return lounge_api.validateBookingNumber(payload);
    },
    setLoungeBookingNumber(booking_number) {
      return new Promise((resolve) => {
        this._loungeBookingNumber = booking_number;
        resolve();
      });
    },
    sendReservationRequest(params) {
      const auth = useAuthStore();
      const payload = {
        ...params,
        token: auth.token,
      };
      return lounge_api.sendReservationRequest(payload);
    },
  },
});
