<template>
  <div>
    <div v-if="isLoading"><span class="spinner-border"></span></div>
    <div v-else>
      <div v-if="childComponent === 'login'" class="mt-2 text-center">
        <div v-if="logo && config.login?.hide_branding !== 1">
          <img :src="logo" loading="eager" style="width: auto; height: 80px"/>
        </div>
        <h4 v-else-if="config.login?.hide_branding !== 1" class="subtitle"><span>{{ settings.name }}</span></h4>
        <h1 class="auth-title">{{ getTranslation('login_title', 'Jetzt anmelden') }}</h1>
        <h2 class="subtitle">
          {{ getTranslation('login_subtitle', 'Melde dich an und erhalte Zugriff auf weitere Funktionen!') }}</h2>
      </div>
      <div v-else-if="(childComponent === 'register' && params.step === 0)"
           class="mt-2 text-center">
           <h2 class="auth-title" v-if="childComponent === 'register'">
            {{ getTranslation('register_title', 'Jetzt registrieren') }}</h2>
          
        <small>
          {{
            getTranslation('register_login_free', 'register_login_free')
          }}
          <br>
          {{
            getTranslation('register_login_entered_data', 'Alle eingegebenen Daten können NUR von DIR eingesehen werden.')
          }}
        </small>
      </div>
      
      <div v-else-if="(childComponent === 'forgotPassword')"
           class="mt-2 text-center">
        <h2 class="auth-title">
          {{ getTranslation('module_shop_component_forgotpassword_title', 'Passwort zurücksetzen') }}</h2>
      </div>

      <div class="animate__animated animate__fadeIn animate__fast">
        <component class="w-100"
                   :is="childComponent"
                   :params="params"
                   :type="type"
                   @loginSuccessful="loginSuccessful"
                   @changeComponent="changeComponent"
                   @setModalTitle="setModalTitle"
                   :formClasses="'auth-form'"
                   :loadedFrom="loadedFrom"
        ></component>
      </div>

      <div v-if="childComponent === 'login'" class="small-text my-4 text-center">
        <a href="#" @click="changeComponent({component: 'register', module: 'Auth', params: { step: 0 }})"
           class="btn-link-underlined"><span>{{
            getTranslation('register_login_switch_link', 'Du bist noch nicht dabei?')
          }}</span></a><br/>
        {{ getTranslation('register_forgot_switch_text', 'Oder hast du dein') }} <a href="#"
                                                                                    @click="changeComponent({component: 'forgotPassword', module: 'Auth'})"
                                                                                    class="d-block mb-5 btn-link-underlined"><span>{{
          getTranslation('register_forgot_switch_link', 'Passwort vergessen?')
        }}</span></a>
      </div>

    </div>
  </div>

</template>

<script>
import forgotPassword from "../components/oauth/ForgotPassword";
import login from "../components/oauth/Login";
import missingInfos from "../components/oauth/missingInfos";
import register from "../components/oauth/register/Register";
import verify from "../components/oauth/verify/Verify";
import Swal from "sweetalert2";
import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../store/auth_store";
import { useDataStore } from "../../store/data_store";
import { useUserStore } from "../../store/user_store";
import { useTranslation } from "../composables/useTranslation";

let components;

try {
  components = require("../../data/child_strings");
} catch (e) {
  components = require("../../data/default_strings");
}
components = components.default;

export default {
  name: "Auth",
  props: ["loadedFrom", "childComponent", "params", "type"],
  emits: ["changeComponent", "loginSuccessful", "setModalTitle"],
  components: {
    register,
    login,
    verify,
    missingInfos,
    forgotPassword,
  },
  data() {
    const light_logos = document.querySelector(
      "header a img.logo-light",
    ).srcset;
    const dark_logos = document.querySelector("header a img.logo-dark").srcset;
    const light_logo = light_logos.split(", ")[1];
    const dark_logo = dark_logos.split(", ")[1];

    const logo = document.body.classList.contains("active-dark-mode")
      ? light_logo
      : dark_logo;

    return {
      isLoading: false,
      components: components,
      logo: logo ? logo.split(" ")[0] : "",
    };
  },
  inject: ["getTranslation", "getTranslatedTitleForComponent"],
  computed: {
    ...mapStores(useAuthStore, useUserStore),
    ...mapState(useDataStore, ["settings", "config"]),
  },
  methods: {
    changeComponent(payload) {
      const newTitle = this.getTranslatedTitleForComponent(payload.component);
      const oldTitle = this.getTranslatedTitleForComponent(this.childComponent);
      this.$emit("changeComponent", {
        module: {
          new: payload.module,
          old: "Auth",
        },
        component: {
          new: payload.component,
          old: this.childComponent,
        },
        title: {
          new: newTitle ? newTitle : this.components[payload.component].title,
          old: oldTitle ? oldTitle : this.components[this.childComponent].title,
        },
        params: {
          new: payload.params,
          old: this.params,
        },
      });
    },
    loginSuccessful() {
      this.$emit("loginSuccessful");
      document
        .getElementById("login-modal")
        .dispatchEvent(new CustomEvent("loginSuccessful"));
    },
    setModalTitle(title) {
      this.$emit("setModalTitle", title);
    },
  },
  beforeMount() {
    this.authStore.$onAction(({ name, store, args, after, onError }) => {
      after((result) => {
        if (name === "logout") {
          this.changeComponent({
            module: "Auth",
            component: "login",
            params: {},
          });
        }
      });
    });

    // add listener to change component to inital if modal is closed
    document
      .getElementById("login-modal")
      .addEventListener("hidden.bs.modal", (e) => {
        this.changeComponent({
          module: "Auth",
          component: "login",
          params: {},
        });
      });
    document
      .getElementById("login-modal")
      .addEventListener("hide.bs.modal", (e) => {
        if (this.childComponent === "register") {
          e.preventDefault();
          e.stopPropagation();
          Swal.fire({
            icon: "question",
            text: this.getTranslation(
              "register_abort_message",
              "Möchtest du den Registrierungsprozess wirklich abbrechen?",
            ),
            showDenyButton: true,
            denyButtonText: this.getTranslation("deny_button_text", "Nein!"),
            confirmButtonText: this.getTranslation(
              "confirm_button_text",
              "Ja!",
            ),
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeComponent({
                module: "Auth",
                component: "login",
                params: {},
              });
              document
                .getElementById("login-modal")
                .dispatchEvent(new CustomEvent("loginSuccessful"));
            }
          });
        }
      });
  },
  beforeUnmount() {
    // remove listener to change component to inital if modal is closed
    document.getElementById("login-modal").removeEventListener(
      "hidden.bs.modal",
      (e) => {
        this.changeComponent({
          module: "Auth",
          component: "login",
          params: {},
        });
      },
      false,
    );
  },
};
</script>

<style scoped>

.small-text {
  font-size: 15px !important;
  line-height: 22px !important;
}
</style>