<template>

  <div class="d-flex w-100 justify-content-center flex-wrap" v-if="data && data.length">
    <div :class="classes" v-for="ticket in data">

      <div class="ticket p-4">
        <div class="col-12 d-flex">
          <div class="flex-shrink-1">
            <h5 class="m-0">{{ ticket.name }}</h5>
          </div>

          <div class="flex-grow-1 d-flex justify-content-end">
            <small  v-if="!ticket.buyable" style="color: var(--color-danger);">{{ getTranslation('module_shop_component_tickets_sold_out', 'ausverkauft') }}</small>
          </div>
        </div>

        <div class="col-12">
          <span v-html="ticket.description"></span>
          <div v-if="ticket?.platform === 'app'" class="d-flex flex-shrink-1 item-picker align-items-center">
            <small style="color: var(--color-warning);">{{ getTranslation('module_shop_component_tickets_platform_message', target_settings.tickets?.platform_message ? target_settings.tickets.platform_message : "Dieses Ticket gibt's ausschließlich in der App.") }}</small>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-end" v-if="ticket.tickets_left_visible && ticket.buyable && ticket.tickets_left && ticket.tickets_left > 0">
          <small style="color: var(--color-warning);">{{ getTranslationWithPlaceholder('module_shop_component_tickets_sold_count', 'Noch %count%x erhältlich', { count: ticket.tickets_left}) }}</small>
        </div>

        <div class="col-12 d-flex">

          <div class="d-flex flex-wrap flex-grow-1">

            <Transition name="slide-fade-left">
              <div class="col-12 ticket-pricing mt-2">
                <span v-if="ticket.fee_active" class="ticket-price">{{ format(ticket.price - ticket.fee_per_ticket) }}</span>
                <span v-else class="ticket-price">{{ format(ticket.price) }}</span>
                <small v-if="ticket.fee_active" class="ticket-fee">+ {{ format(ticket.discounted ? ticket.discounted_fee_per_ticket : ticket.fee_per_ticket) }} {{ getTranslation('module_shop_component_tickets_fee', 'Gebühr') }}</small>
                <div class="w-100" v-if="ticket.discounted && this.inCheckout">
                  <button class="btn btn-sm btn-default me-2" style="width: 16px; height: 16px;padding: .25rem;position:relative;" @click="cartStore.removeDiscountCode('tickets')" data-bs-toggle="tooltip" data-bs-placement="top" title="Rabatt entfernen">
                    <i class="ticket-fee feather-x" style="position:absolute;top:2px;left:2px; font-size: 12px;"></i>
                  </button>
                  <small v-if="showDiscount">{{ discountCode.description }} <span v-if="discountCode.code != ''">({{ discountCode.code }})</span></small>
                </div>
              </div>
            </Transition>

          </div>

          <div v-if="ticket.buyable && (!ticket.platform || ticket.platform == null || ticket.platform !== 'app')" class="d-flex flex-shrink-1 item-picker align-items-center">

            <div class="minus disable-dbl-tap-zoom"
                 :class="ticketCount(ticket.id) <= 0  || (this.countTickets <= 1 && this.inCheckout) ? '':'minusable'"
                 @click="decrementTicketCount(ticket.id)"
            >
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z" fill="currentColor" fill-rule="evenodd"/>
              </svg>

            </div>

            <div class="value disable-dbl-tap-zoom">
              <input disabled class="text-center ticket-count" :value="ticketCount(ticket.id)">
            </div>

            <div class="plus disable-dbl-tap-zoom"
                 :class="ticketCount(ticket.id) >= ticket.tickets_per_user || ticketsLeftForTicket(ticket) <= 0 ? '':'plusable'"
                 @click="ticketCount(ticket.id) >= ticket.tickets_per_user || ticketsLeftForTicket(ticket) <= 0 ? dummy:incrementTicketCount(ticket.id)"
            >
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m44.969 14.441h10.062c2.3984 0 4.3594 1.9609 4.3594 4.3594v21.805h21.805c2.3984 0 4.3594 1.9609 4.3594 4.3594v10.062c0 2.3984-1.9609 4.3594-4.3594 4.3594l-21.805 0.003906v21.805c0 2.3984-1.9609 4.3594-4.3594 4.3594l-10.062 0.003906c-2.3984 0-4.3594-1.9609-4.3594-4.3594l-0.003906-21.809h-21.801c-2.3984 0-4.3594-1.9609-4.3594-4.3594v-10.062c0-2.3984 1.9609-4.3594 4.3594-4.3594h21.805l-0.003906-21.805c0.003906-2.3984 1.9648-4.3633 4.3633-4.3633z" fill="currentColor" fill-rule="evenodd"/>
              </svg>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="" v-else-if="!inCheckout">
    <span class="text-danger">{{ getTranslation('module_shop_component_tickets_no_tickets_available_message', 'Aktuell sind keine Tickets verfügbar...') }}</span>
  </div>

  <div class="d-flex flex-wrap w-100 justify-content-center" v-if="selectedFreeTickets">
    <div :class="classes" v-for="freeTicket in selectedFreeTickets">
      <div class="ticket free-ticket p-4">
        <div class="col-12 d-flex">
          <div class="flex-shrink-1">
            <h5 class="m-0">{{ freeTicket.name }}</h5>
          </div>
        </div>

        <div class="col-12">
          <span v-html="freeTicket.description"></span>
        </div>

        <div class="col-12 d-flex justify-content-end">

          <div class="d-flex flex-shrink-1 item-picker align-items-center">

            <div class="minus disable-dbl-tap-zoom"
                 :class="freeTicket.amount <= 0 ? '':'minusable'"
                 @click="cartStore.decrementFreeTicketCount(freeTicket.id)"
            >
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m6.9648 35.539h86.07c2.9531 0 5.4297 2.4805 5.4297 5.4297v18.062c0 2.9531-2.4805 5.3125-5.4297 5.3125h-86.07c-3.0703 0-5.4297-2.3594-5.4297-5.3125v-18.062c0-2.9531 2.3594-5.4297 5.4297-5.4297z" fill="currentColor" fill-rule="evenodd"/>
              </svg>

            </div>

            <div class="value disable-dbl-tap-zoom">
              <input disabled class="text-center ticket-count" :value="freeTicket.amount">
            </div>

            <div class="plus disable-dbl-tap-zoom"
                 :class="freeTicket.amount >= freeTicket.max ? '':'plusable'"
                 @click="freeTicket.amount >= freeTicket.max ? dummy:cartStore.incrementFreeTicketCount(freeTicket.id)"
            >
              <svg width="13px" height="13px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path d="m44.969 14.441h10.062c2.3984 0 4.3594 1.9609 4.3594 4.3594v21.805h21.805c2.3984 0 4.3594 1.9609 4.3594 4.3594v10.062c0 2.3984-1.9609 4.3594-4.3594 4.3594l-21.805 0.003906v21.805c0 2.3984-1.9609 4.3594-4.3594 4.3594l-10.062 0.003906c-2.3984 0-4.3594-1.9609-4.3594-4.3594l-0.003906-21.809h-21.801c-2.3984 0-4.3594-1.9609-4.3594-4.3594v-10.062c0-2.3984 1.9609-4.3594 4.3594-4.3594h21.805l-0.003906-21.805c0.003906-2.3984 1.9648-4.3633 4.3633-4.3633z" fill="currentColor" fill-rule="evenodd"/>
              </svg>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

</template> 

<script>
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useTranslationStore } from "../../../store/translation_store";

let target_settings;

try {
  target_settings = require("../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "renderTickets",
  props: ["data", "classes", "inCheckout"],
  emits: [],
  data() {
    return {
      target_settings,
    };
  },
  computed: {
    ...mapStores(useCartStore),
    ...mapState(useTranslationStore, [
      "getTranslation",
      "getTranslatedTitleForComponent",
      "getTranslationWithPlaceholder",
    ]),
    ...mapState(useDataStore, ["event", "tickets", "settings"]),
    ...mapState(useCartStore, [
      "discountCode",
      "ticketCount",
      "countTickets",
      "selectedFreeTickets",
    ]),
    showDiscount: function () {
      if (
        this.settings["discount-codes"] &&
        this.settings["discount-codes"].active
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    incrementTicketCount: function (id) {
      this.cartStore.addTicket(id).then(() => {
        const ticket = this.tickets.find((ticket) => ticket.id === id);
        if (!ticket) return;
        const ticketsLeft = parseInt(ticket.tickets_left);
        if (ticketsLeft <= 0) {
          return;
        }

        return document.body.dispatchEvent(
          new CustomEvent("EVENT_NAME_ADDED_TO_CART", {
            detail: {
              id: ticket.id,
              name: ticket.name,
              price: ticket.price,
              amount: this.ticketCount(id),
              type: "ticket",
            },
          }),
        );
      });
    },
    decrementTicketCount: function (id) {
      if (this.countTickets > 1 || !this.inCheckout) {
        this.cartStore.removeTicket(id);
      }
    },
    incrementFreeTicketCount: function (id) {
      this.cartStore.incrementFreeTicketCount(id);
    },
    decrementFreeTicketCount: function (id) {
      if (this.countTickets > 1 || !this.inCheckout) {
        this.cartStore.decrementFreeTicketCount(id);
      }
    },
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
      ticketsLeftForTicket: function (ticket) {
        const count = parseInt(ticket.tickets_left);
        return count - this.ticketCount(ticket.id);
    },
    dummy: () => {
      return;
    },
  },
};
</script>

<style scoped>
.text-crossed {
  color: var(--color-danger);
  text-decoration: line-through;
}
</style>