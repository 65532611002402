<template>

  <Teleport to="#areas" v-if="render && dataLoaded">

    <div class="d-flex flex-wrap">
      <ul class="nav nav-tabs d-flex flex-nowrap" role="tablist" id="area-tabs">
        <li class="nav-item" role="presentation" :id="`area_${key}_tab`" v-for="(area, key) in areas"
            @click="changeArea(key)">
          <button class="nav-link" :class="activeAreaIndex === key ? 'active':''" :id="`area_${key}_tab`"
                  data-bs-toggle="tab" :data-bs-target="`#area_${key}`" type="button" role="tab"
                  :aria-controls="area.name" aria-selected="true">

            <img v-if="area.image" class="area-logo" loading="eager" :src="area.image" :alt="area.name">
            <span v-else>{{ area.name }}</span>
          </button>
        </li>
      </ul>
      <div class="indicators mt-2">
        <div class="indicator mx-2" :class="activeAreaIndex === key ? 'active':''" v-for="(area, key) in areas"
             @click="changeArea(key)"></div>
      </div>

      <div class="tab-content mt-4 w-100">
        <div class="tab-pane fade" :class="activeAreaIndex === key ? 'show active':''" :id="`area_${key}`"
             role="tabpanel" :aria-labelledby="`area_${key}_tab`" v-for="(area, key) in areas">
          <div class="d-flex flex-wrap justify-content-center">
            <div :class="activeArea.photos.length ? 'col-12':'col-12'">

              <div class="d-flex flex-wrap pr--20">

                <div class="col-12">
                  <h3 class="title">{{ JSON.parse(area.title) }}</h3>
                </div>

                <div class="col-12" v-if="getDescription(area)">
                  <p class="description" v-html="getDescription(area)"></p>
                </div>

                <!-- TODO(flo): if we only want the tabbed-layout for praterdome, we should add a config value here instead of commenting out etc
                <div
                    class="col-12 d-flex flex-wrap align-content-center justify-content-center justify-content-sm-start gap-4">

                  <div class=" d-flex justify-content-center">
                    <button v-if="areas[key].layout" class="btn-default"
                            @click="scrollTo(`layout_${key}`)"
                            style="background: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);min-width: 75%;"
                    >
                      {{ getTranslation('module_shop_component_lounges_areas_layout_button_text', 'Lageplan') }}
                    </button>
                  </div>

                  <div class="d-flex justify-content-center">
                    <button v-if="areas[key].lounges && areas[key].lounges.length" class="btn-default"
                            @click="scrollTo(`lounges_${key}`)"
                            style="background: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);min-width: 75%;"
                    >{{ settings?.lounges?.alternatives?.single?.plural }}
                    </button>
                  </div>

                </div>
                -->
              </div>
            </div>

            <!-- Tabbed layout when config.lounges.tabbed is true -->
            <template v-if="config?.lounges?.tabbed">
              <ul class="nav nav-tabs d-flex flex-wrap align-items-center justify-content-center gap-4" role="tablist" id="area-sub-tabs">
                <li class="nav-item d-flex justify-content-center" role="presentation" :id="`area_${sub_key}_sub_tab`" v-for="(area_sub_tab, sub_key) in area_sub_tabs"
                    @click="changeAreaSubTab(area_sub_tab)">
                  <button class="btn-default" :class="activeAreaSubTab?.name === area_sub_tab.name ? 'active':''" :id="`area_${sub_key}_sub_tab`"
                          data-bs-toggle="tab" :data-bs-target="`#area_${sub_key}_sub`" type="button" role="tab"
                          :aria-controls="area_sub_tab.name" aria-selected="true" @click="scrollTo(`layout_${sub_key}`)" style="background: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);min-width: 75%;">
                    <span>{{ area_sub_tab.name }}</span>
                  </button>
                </li>
              </ul>

              <template v-if="!activeAreaSubTab"></template>

              <div class="col-12 mt--30" v-else-if="activeArea.photos.length && activeAreaSubTab?.name === getTranslation('module_lounges_component_areas_photos_title', 'Fotos')">
                <div class="d-flex flex-wrap justify-content-evenly">
                  <div class="area-photo mb-4"
                      :style="`background-image: url('${activeArea.photos[activePhoto].path}?context=web')`">
                  </div>
                </div>

                <div class="d-flex flex-wrap justify-content-evenly">
                  <a v-for="(photo, idx_photo) in areas[key].photos" class="area-photo-thumb mb-4"
                    :class="idx_photo === activePhoto ? 'active':''"
                    @click="activePhoto = idx_photo"
                    style="overflow:hidden;cursor: pointer;"
                    :style="`background-image: url('${photo.path.replace('.jpg', '.w256.h256.jpg?context=web')}')`"
                  ></a>
                </div>
              </div>

              <div class="col-12 mt--30" v-else-if="areas[key] && areas[key].layout && activeAreaSubTab?.name === getTranslation('module_lounges_component_areas_layout_title', 'Lageplan')" :id="`layout_${key}`">
                <div v-if="area.reservations_requests_active !== 1" class="col-12">
                  <div class="section-title text-center">
                    <h4 class="subtitle"><span class="theme-gradient">{{
                        getTranslation('module_lounges_component_areas_layout_title', 'Lageplan')
                      }}</span></h4>
                  </div>
                </div>

                <div v-if="area.reservations_requests_active !== 1" class="col-12" style="overflow: hidden;">
                  <area_layout :area="areas[key]" :area_id="key" :event="event" :event_id="event_id"
                              v-if="activeAreaIndex == key"
                              @startBooking="startBooking"
                              @startReservationRequest="startReservationRequest"
                  ></area_layout>
                </div>
              </div>

              <div class="col-12 mt--30" v-else-if="areas[key] && areas[key].lounges && areas[key].lounges.length && activeAreaSubTab?.name === this.activeArea?.lounges.length > 1 ? this.settings?.lounges?.alternatives?.single?.plural : this.settings?.lounges?.alternatives?.single?.singular"
                  :id="`lounges_${key}`">
                <div class="col-12">
                  <div class="section-title text-center">
                    <h4 class="subtitle"><span class="theme-gradient">{{
                        areas[key]?.lounges.length > 1 ? this.settings?.lounges?.alternatives?.single?.plural : this.settings?.lounges?.alternatives?.single?.singular
                      }}</span></h4>
                  </div>
                </div>

                <div class="col-12">
                  <list-lounges-areas :lounges="areas[key].lounges" :event_id="event_id" @startBooking="startBooking"
                                      :hide-details="area.reservations_requests_active === 1"
                                      @startReservationRequest="startReservationRequest">
                  </list-lounges-areas>
                </div>
              </div>
            </template>

            <!-- Column layout when config.lounges.tabbed is false -->
            <template v-else>
              <!-- Photos Section -->
              <div class="col-12 mt--30" v-if="activeArea.photos.length">
                <div class="section-title text-center">
                  <h4 class="subtitle"><span class="theme-gradient">{{
                      getTranslation('module_lounges_component_areas_photos_title', 'Fotos')
                    }}</span></h4>
                </div>
                <div class="d-flex flex-wrap justify-content-evenly">
                  <div class="area-photo mb-4"
                      :style="`background-image: url('${activeArea.photos[activePhoto].path}?context=web')`">
                  </div>
                </div>

                <div class="d-flex flex-wrap justify-content-evenly">
                  <a v-for="(photo, idx_photo) in areas[key].photos" class="area-photo-thumb mb-4"
                    :class="idx_photo === activePhoto ? 'active':''"
                    @click="activePhoto = idx_photo"
                    style="overflow:hidden;cursor: pointer;"
                    :style="`background-image: url('${photo.path.replace('.jpg', '.w256.h256.jpg?context=web')}')`"
                  ></a>
                </div>
              </div>

              <!-- Layout Section -->
              <div class="col-12 mt--30" v-if="areas[key] && areas[key].layout" :id="`layout_${key}`">
                <div v-if="area.reservations_requests_active !== 1" class="col-12">
                  <div class="section-title text-center">
                    <h4 class="subtitle"><span class="theme-gradient">{{
                        getTranslation('module_lounges_component_areas_layout_title', 'Lageplan')
                      }}</span></h4>
                  </div>
                </div>

                <div v-if="area.reservations_requests_active !== 1" class="col-12" style="overflow: hidden;">
                  <area_layout :area="areas[key]" :area_id="key" :event="event" :event_id="event_id"
                              v-if="activeAreaIndex == key"
                              @startBooking="startBooking"
                              @startReservationRequest="startReservationRequest"
                  ></area_layout>
                </div>
              </div>

              <!-- Lounges Section -->
              <div class="col-12 mt--30" v-if="areas[key] && areas[key].lounges && areas[key].lounges.length"
                  :id="`lounges_${key}`">
                <div class="col-12">
                  <div class="section-title text-center">
                    <h4 class="subtitle"><span class="theme-gradient">{{
                        areas[key]?.lounges.length > 1 ? this.settings?.lounges?.alternatives?.single?.plural : this.settings?.lounges?.alternatives?.single?.singular
                      }}</span></h4>
                  </div>
                </div>

                <div class="col-12">
                  <list-lounges-areas :lounges="areas[key].lounges" :event_id="event_id" @startBooking="startBooking"
                                      :hide-details="area.reservations_requests_active === 1"
                                      @startReservationRequest="startReservationRequest">
                  </list-lounges-areas>
                </div>
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>

  </Teleport>


</template>

<script>
import { mapState, mapStores } from "pinia";
import { useAreasStore } from "../../../../store/areas.store";
import { useDataStore } from "../../../../store/data_store";
import settings from "../../user/settings/index.vue";
import area_layout from "./area_layout";
import ListLoungesAreas from "./area_listLounges";

export default {
  name: "Area",
  components: {
    ListLoungesAreas,
    area_layout,
  },
  inject: ["getTranslation"],
  emits: ["startBooking", "startReservationRequest"],
  data() {
    const type = document.getElementById("areas")
      ? document.getElementById("areas").dataset.type
      : "areas";
    const event_id = document.getElementById("areas")
      ? document.getElementById("areas").dataset.event
      : null;
    return {
      type,
      event_id,
      activeAreaIndex: 0,
      activePhoto: 0,
      activeAreaSubTab: null,
      render: !!document.getElementById("areas"),
      dataLoaded: false,
    };
  },
  computed: {
    settings() {
      return settings;
    },
    ...mapStores(useDataStore, useAreasStore),
    ...mapState(useDataStore, ["event", "settings", "config"]),
    ...mapState(useAreasStore, ["areas"]),
    activeArea() {
      if (this.areas[this.activeAreaIndex]) {
        return this.areas[this.activeAreaIndex];
      }
      return null;
    },
    area_sub_tabs() {
      const tabs = [];
      if (this.activeArea?.photos) {
        // TODO(flo): translation anlegen
        tabs.push({ name: this.getTranslation('module_lounges_component_areas_photos_title', 'Fotos') });
      }
      if (this.activeArea?.layout) {
        tabs.push({ name: this.getTranslation('module_lounges_component_areas_layout_title', 'Lageplan') });
      }
      if (this.activeArea?.lounges) {
        tabs.push({ name: this.activeArea?.lounges.length > 1 ? this.settings?.lounges?.alternatives?.single?.plural : this.settings?.lounges?.alternatives?.single?.singular });
      }
      return tabs;
    },
  },
  methods: {
    startBooking(payload) {
      this.$emit("startBooking", payload);
    },
    startReservationRequest(payload) {
      this.$emit("startReservationRequest", payload);
    },
    getDescription(area) {
      const currentLocale = window.locale || "";

      if (
        area?.translations?.[currentLocale]?.description
      ) {
        return area.translations[currentLocale].description;
      }

      return area.description;
    },
    changeArea(key) {
      this.activePhoto = 0;
      this.activeAreaIndex = key;
      const tab = document.getElementById(`area_${key}_tab`);
      if (tab) {
        tab.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
          inline: "center",
        });
      }
    },
    changeAreaSubTab(sub_tab) {
      this.activeAreaSubTab = sub_tab;
      const tab = document.getElementById(`area_${sub_tab.name}_sub_tab`);
      if (tab) {
        tab.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
          inline: "center",
        });
      }
    },
    scrollTo(id) {
      if (document.getElementById(id)) {
        document
          .getElementById(id)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },
  },
  beforeMount: async function () {
    this.areasStore.fetchAreas();
    if (this.event_id) {
      await this.dataStore.loadLounges();
      await this.dataStore.loadData(this.event_id);
    }
    // Set default tab if tabbed layout is enabled
    if (this.config?.lounges?.tabbed) {
      this.activeAreaSubTab = { name: this.getTranslation('module_lounges_component_areas_photos_title', 'Fotos') };
    }
    this.dataLoaded = true;
  },
};
</script>

<style scoped>
  .btn-default.active {
    outline: solid var(--color-white);
  }
</style>
