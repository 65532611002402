import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useCheckoutStore } from "./checkout.store";

/**
 * Composable that returns the appropriate checkout fields based on guest mode status
 *
 * @returns {import('vue').ComputedRef<Array>} Computed array of checkout fields
 */
export const useCheckoutFields = () => {
  const {
    isGuestEnabled,
    isShowGuest,
    registrationFields,
    guestFields,
    paymentMethod,
  } = storeToRefs(useCheckoutStore());

  return computed(() => {
    let fields = {};
    if (!isGuestEnabled.value || !isShowGuest.value) {
      fields = registrationFields.value;
    } else {
      fields = guestFields.value || registrationFields.value;
    }

    // Filter out fields that are not required
    fields = Object.fromEntries(
      Object.entries(fields).filter(([_, field]) => field.required)
    );

    console.log("FIELDS: ", fields);

    if (paymentMethod.value === "sofort") {
      fields.phone = {
        type: "text",
        required: true,
      };
      fields.street = {
        type: "text",
        required: true,
      };
      fields.zip = {
        type: "text",
        required: true,
      };
      fields.place = {
        type: "text",
        required: true,
      };
      fields.country = {
        type: "text",
        required: true,
      };
    }

    return fields;
  });
};
