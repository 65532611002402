<script setup>
import { storeToRefs } from "pinia";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import { useTranslation } from "../../composables/useTranslation";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useCheckoutStore } from "../../features/checkout/checkout.store";
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import Input from "../ui/input/Input.vue";

const names = ref({});

const cartStore = useCartStore();
const { selectedTickets, selectedFreeTickets } = storeToRefs(cartStore);
const checkoutStore = useCheckoutStore();
const dataStore = useDataStore();
const { event } = storeToRefs(dataStore);

const tickets = computed(() => {
  return selectedFreeTickets.value
    ? [...selectedTickets.value, ...selectedFreeTickets.value]
    : selectedTickets.value;
});

const initializeTicketNames = (ticket) => {
  if (!names.value[ticket.id]) {
    names.value[ticket.id] = Array(Number.parseInt(ticket.amount))
      .fill(null)
      .map(() => ({
        firstname: "",
        lastname: "",
        company: "",
      }));
  }
};

watch(
  tickets,
  () => {
    tickets.value.forEach(initializeTicketNames);
  },
  {
    deep: true,
    immediate: true,
  },
);

const createTicketSchema = computed(() => {
  const schema = {};
  
  tickets.value.forEach((ticket) => {
    for (let i = 1; i <= parseInt(ticket.amount); i++) {
      schema[`${ticket.id}-${i}-firstname`] = z.string().min(1, 'First name is required');
      schema[`${ticket.id}-${i}-lastname`] = z.string().min(1, 'Last name is required');
      if (event.value.invoice_address_possible) {
        schema[`${ticket.id}-${i}-company`] = z.string().optional();
      }
    }
  });

  return z.object(schema);
});

const { errors, values, validate } = useForm({
  validationSchema: computed(() => toTypedSchema(createTicketSchema.value)),
});

checkoutStore.setValidatePersonalizedTickets(validate);

watch(values, (newVal) => {
  checkoutStore.setPersonalizedTicketsData(newVal);
});

watch(errors, (newVal) => {
  checkoutStore.setPersonalizedTicketsValidationErrors(newVal);
});

const validateTicketOwners = async () => {
  const result = await validate();
  if (result.valid) {
    tickets.value.forEach((ticket) => {
      cartStore.setTicketOwners(ticket.id, names.value[ticket.id]);
    });
  }
  return result.valid;
};

defineExpose({
  validateTicketOwners,
});
</script>

<template>
  <section class="personalized-tickets">
    <h5>{{ useTranslation('web_personalized_tickets_title', 'Vergebe Namen für deine Tickets') }}</h5>

    <form @submit.prevent>
      <div 
        v-for="ticket in tickets" 
        :key="ticket.id"
        class="ticket-group"
      >
        <div 
          v-for="index in parseInt(ticket.amount)" 
          :key="index"
          class="ticket-form"
        >
          <div class="ticket-header">
            <h6>{{ ticket.name }} #{{ index }}</h6>
          </div>

          <div class="ticket-fields">
            <div class="field-group">
              <FormField 
                v-slot="{ componentField }" 
                :name="`${ticket.id}-${index}-firstname`"
                v-model="names[ticket.id][index - 1].firstname"
              >
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      v-bind="componentField"
                      :placeholder="useTranslation('firstname_label', 'Vorname')"
                      autocomplete="given-name"
                    />
                    <FormLabel>{{ useTranslation('firstname_label', 'Vorname') }}*</FormLabel>
                    <FormMessage />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>

            <div class="field-group">
              <FormField 
                v-slot="{ componentField }" 
                :name="`${ticket.id}-${index}-lastname`"
                v-model="names[ticket.id][index - 1].lastname"
              >
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      v-bind="componentField"
                      :placeholder="useTranslation('lastname_label', 'Nachname')"
                      autocomplete="family-name"
                    />
                    <FormLabel>{{ useTranslation('lastname_label', 'Nachname') }}*</FormLabel>
                    <FormMessage />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>

            <div v-if="event.invoice_address_possible" class="field-group">
              <FormField 
                v-slot="{ componentField }" 
                :name="`${ticket.id}-${index}-company`"
                v-model="names[ticket.id][index - 1].company"
              >
                <FormItem>
                  <FormControl>
                    <Input
                      type="text"
                      v-bind="componentField"
                      :placeholder="useTranslation('company_label', 'Firmenname')"
                      autocomplete="organization"
                    />
                    <FormLabel>{{ useTranslation('company_label', 'Firmenname') }}</FormLabel>
                    <FormMessage />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<style scoped lang="scss">
.personalized-tickets {
  .ticket-group {
    margin-bottom: 2rem;
  }

  .ticket-form {
    margin-bottom: 1.5rem;
  }

  .ticket-header {
    margin-bottom: 1rem;
  }

  .ticket-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .field-group {
    flex: 1 1 calc(50% - 0.5rem);
    min-width: 250px;

    &:last-child {
      flex: 1 1 100%;
    }
  }
}
</style>