<template>

  <div>
    <Form @submit="submitData" ref="checkoutRegisterForm" id="checkout-register-form" class="checkout-form"
      :initialValues="initialValues" :validation-schema="schema" v-slot="{ values, errors, validate }">
      <template v-if="storeValidateFunction(validate)"></template>
      <div class="d-flex flex-wrap pt-2">

        <div class="col-12 px-md-2 text-left form-group">
          <div class="form-check form-check-inline ps-0 pe-3" v-for="(gender, key) in genders">
            <Field as="input" class="form-check-input" type="radio" name="gender" :id="key" :value="key"
              :class="errors.gender ? 'is-invalid' : ''"></Field>
            <label class="form-check-label" :for="key">{{ gender }}</label>
          </div>
        </div>

        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="text" :placeholder="getTranslation('firstname_label', 'Vorname')" name="firstname"
              class="form-control" id="firstname" :class="errors.firstname ? 'is-invalid' : ''" />
            <label for="firstname">{{ getTranslation('firstname_label', 'Vorname') }}</label>
            <small class="text-danger" v-if="errors.firstname">{{ errors.firstname }}</small>
          </div>
        </div>


        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="text" :placeholder="getTranslation('lastname_label', 'Nachname')" name="lastname"
              class="form-control" id="lastname" :class="errors.lastname ? 'is-invalid' : ''" />
            <label for="lastname">{{ getTranslation('lastname_label', 'Nachname') }}</label>
            <small class="text-danger" v-if="errors.lastname">{{ errors.lastname }}</small>
          </div>
        </div>

      </div>

      <div class="d-flex flex-wrap pt-2"
        v-if="(target_settings?.register?.addressRequired || target_settings?.register?.addressRequired === undefined)">

        <div class="col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="text" :placeholder="getTranslation('street_placeholder', 'Straße & Hausnummer')"
              name="street" class="form-control" id="street" :class="errors.street ? 'is-invalid' : ''" />
            <label for="street">{{ getTranslation('street_label', 'Straße & Hausnummer') }}</label>
            <small class="text-danger" v-if="errors.street">{{ errors.street }}</small>
          </div>
        </div>

        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="text" :placeholder="getTranslation('zip_label', 'PLZ')" name="zip"
              class="form-control" id="zip" :class="errors.zip ? 'is-invalid' : ''" />
            <label for="zip">{{ getTranslation('zip_label', 'PLZ') }}</label>
            <small class="text-danger" v-if="errors.zip">{{ errors.zip }}</small>
          </div>
        </div>

        <div class="col-md-6 col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="text" :placeholder="getTranslation('place_label', 'Stadt')" name="place"
              class="form-control" id="place" :class="errors.place ? 'is-invalid' : ''" />
            <label for="place">{{ getTranslation('place_label', 'Stadt') }}</label>
            <small class="text-danger" v-if="errors.place">{{ errors.place }}</small>
          </div>
        </div>

      </div>

      <div class="d-flex flex-wrap pt-2" v-if="showPhone">
        <div class="col-12 px-md-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="phone" class="form-control" id="phone" type="text"
              :placeholder="getTranslation('phone_label', 'Deine Telefonnummer')"
              :class="errors.phone ? 'is-invalid' : ''" />
            <label for="phone">{{ getTranslation('phone_label', 'Deine Telefonnummer') }}</label>
            <small class="text-danger" v-if="errors.phone">{{ errors.phone }}</small>
          </div>
        </div>
      </div>



      <div class="col-12 px-md-2 mt-2">
        <div class="form-floating form-group w-100">
          <Field as="input" id="birthday" :placeholder="getTranslation('birthday_placeholder', 'Geburtsdatum')"
            name="birthday" class="form-control" type="date" :class="errors.birthday ? 'is-invalid' : ''"
            :value="birthday">
          </Field>
          <label for="birthday">{{ getTranslation('birthday_label', 'Geburtsdatum') }}</label>
          <small class="text-danger" v-if="errors.birthday">{{ errors.birthday }}</small>
        </div>
      </div>


      <div class="col-12 px-md-2 mt-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="email" :placeholder="getTranslation('email_label', 'E-Mail')" name="email"
            autocomplete="username" class="form-control" id="email" :class="errors.email ? 'is-invalid' : ''" />
          <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
          <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
        </div>
      </div>

      <div class="d-flex flex-wrap pt-2"
        v-if="params.type == 'checkout' || (params.type == 'loungeReservationRequest' && !authStore.isAuthenticated())">

        <div class="col-12 px-2 text-center">
          <div class="form-group mb-0">
            <div class="form-check w-100 p-0">
              <Field as="input" name="privacy" class="form-check-input m-0 p-0" type="checkbox" :value="true"
                id="privacy_check" style="height: 24px;" :class="errors.privacy ? 'is-invalid' : ''" />
              <label class="form-check-label" for="privacy_check"
                v-html="getTranslation('module_shop_component_register_privacy_text', privacy_text)">
              </label>
            </div>
            <small class="text-danger" v-if="errors.privacy">{{ errors.privacy }}</small>
          </div>
        </div>

      </div>

      <button id="submitCheckoutForm" type="submit" style="display:none;" data-umami-event="checkout-register-button"></button>

    </Form>
  </div>

</template>

<script>
import { Field, Form } from "vee-validate";

import Cookies from "js-cookie";
import { mapState, mapStores } from "pinia";
import { toRaw } from "vue";
import * as yup from "yup";

import { useAuthStore } from "../../../../store/auth_store";
import { useUserStore } from "../../../../store/user_store";

let target_settings;
try {
  target_settings = require("../../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "checkoutRegister",
  components: {
    Form,
    Field,
  },
  props: {
    params: {
      type: Object,
      required: false,
      default: () => {
        return {
          type: "checkout",
          prefill: false,
        };
      },
    },
  },
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useUserStore, useAuthStore),
    ...mapState(useAuthStore, ["isAuthenticated"]),
    initialValues() {
      const values =
        this.params.prefill && this.isAuthenticated()
          ? this.userStore.userInfosRef
            ? JSON.parse(this.userStore.userInfosRef)
            : { gender: "männlich" }
          : { gender: "männlich" };
      if (values.birthday) {
        values.birthday = moment(values["birthday"]).format("DD.MM.YYYY");
        return values;
      }
      return values;
    },
    birthday() {
      if (this.initialValues.birthday) {
        return moment(this.initialValues.birthday).format("YYYY-MM-DD");
      }
      return this.now;
    },
  },
  data() {
    // NEU
    const password_message = this.getTranslation(
      "error_password_missing",
      "Passwort wird benötigt",
    );
    const password_message_2short = this.getTranslation(
      "error_password_2short",
      "Das Passwort muss mind. 6 Zeichen lang sein.",
    );
    const password_dont_matches = this.getTranslation(
      "error_password_mismatch",
      "Die Passwörter stimmen nicht überein.",
    );
    const password_length = this.getTranslation(
      "error_password_length",
      "Mindestens 8 Zeichen",
    );
    const password_spec_char = this.getTranslation(
      "error_password_spec_char",
      "Mindestens eins dieser Sonderzeichen !#$%^&*()+-=[]{}§~;':|,.<>/?",
    );
    const password_number = this.getTranslation(
      "error_password_number",
      "Mindestens eine Zahl",
    );
    const password_lowercase = this.getTranslation(
      "error_password_lowercase",
      "Mindestens ein Kleinbuchstabe",
    );
    const password_uppercase = this.getTranslation(
      "error_password_uppercase",
      "Mindestens ein Großbuchstabe",
    );
    const specialCharRegex = /[!#$%^&*()+\-=\[\]{}§~;':|,.<>\/?]+/;
    const numberRegex = /[0-9]+/;
    const lowercaseRegex = /[a-z]+/;
    const uppercaseRegex = /[A-Z]+/;
    // NEU ENDE

    const schema = {
      firstname: yup
        .string()
        .required(
          this.getTranslation(
            "error_firstname_missing",
            "Vorname wird benötigt",
          ),
        )
        .trim(),
      lastname: yup
        .string()
        .required(
          this.getTranslation(
            "error_lastname_missing",
            "Nachname wird benötigt",
          ),
        )
        .trim(),
      email: yup
        .string()
        .required(
          this.getTranslation("error_email_missing", "E-Mail wird benötigt"),
        )
        .trim()
        .email(
          this.getTranslation(
            "error_email_invalid",
            "Das ist keine gültige E-Mail Adresse.",
          ),
        ),
      birthday: (value) => {
        if (value === undefined || value === "") {
          return this.getTranslation(
            "error_birthday_missing",
            "Geburtsdatum wird benötigt",
          );
        }

        const date = new Date(value);
        const now = new Date();

        if (!date) {
          return this.getTranslation(
            "error_birthday_missing",
            "Geburtsdatum wird benötigt",
          );
        }

        if (now < date) {
          return this.getTranslation(
            "error_birthday_in_future",
            "Dein Geburtsdatum kann nicht in der Zukunft liegen.",
          );
        }

        if (date < new Date("1900-01-01")) {
          return this.getTranslation(
            "error_birthday_invalid",
            "Bitte gib ein valides Geburtsdatum an",
          );
        }

        // NEU
        const age = now.getFullYear() - date.getFullYear();
        if (
          age > 16 ||
          (age === 16 && now.getMonth() > now.getMonth()) ||
          (age === 16 &&
            now.getMonth() === now.getMonth() &&
            now.getDate() >= now.getDate())
        ) {
          return true;
        } else {
          return this.getTranslation(
            "error_birthday_age_condition",
            "Du musst mindestens 16 Jahre alt sein.",
          );
        }
        // NEU ENDE

        return true;
      },
    };

    const register_schema = {
      // NEU
      password: (value, data) => {
        if (value === undefined || value === "") {
          return password_message;
        }
        if (!specialCharRegex.test(value)) {
          return password_spec_char;
        }
        if (!numberRegex.test(value)) {
          return password_number;
        }
        if (!lowercaseRegex.test(value)) {
          return password_lowercase;
        }
        if (!uppercaseRegex.test(value)) {
          return password_uppercase;
        }
        if (value.length < 8) {
          return password_length;
        }
        return true;
      },
      // NEU ENDE
      confirmPassword: (value, data) => {
        if (value === undefined || value === "") {
          return password_message;
        }

        data = toRaw(data.form);
        if (value !== data.password) {
          return password_dont_matches;
        }
        return true;
      },
      privacy: function (value) {
        if (!value) {
          return this.getTranslation(
            "error_privacy_missing",
            "Bitte akzeptiere die Datenschutzerklärung.",
          );
        }
        return true;
      }.bind(this),
      // NEU
      street: yup
        .string()
        .required(
          this.getTranslation("error_street_missing", "Straße wird benötigt"),
        )
        .trim(),
      zip: yup
        .string()
        .required(
          this.getTranslation(
            "error_zip_missing",
            "Postleitzahl wird benötigt",
          ),
        )
        .trim(),
      place: yup
        .string()
        .required(
          this.getTranslation("error_place_missing", "Stadt wird benötigt"),
        )
        .trim(),
      phone: {},
      // NEU ENDE
    };

    if (
      this.target_settings?.register?.addressRequired ||
      target_settings?.register?.addressRequired === undefined
    ) {
      schema.street = yup
        .string()
        .required(
          this.getTranslation("error_street_missing", "Straße wird benötigt"),
        )
        .trim();
      schema.zip = yup
        .string()
        .required(
          this.getTranslation(
            "error_zip_missing",
            "Postleitzahl wird benötigt",
          ),
        )
        .trim();
      schema.place = yup
        .string()
        .required(
          this.getTranslation("error_place_missing", "Stadt wird benötigt"),
        )
        .trim();
    }

    if (this.params.type === "checkout") {
      Object.assign(schema, register_schema);
    }

    return {
      schema,
      genders: {
        männlich: this.getTranslation("gender_männlich_formal", "Herr"),
        weiblich: this.getTranslation("gender_weiblich_formal", "Frau"),
        divers: this.getTranslation("gender_divers", "Divers"),
      },
      isLoading: false,
      now: moment().format("YYYY-MM-DD"),
      privacy_text:
        'Ich habe die <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere diese.',
      validate: null,
      showPhone: false,
    };
  },
  emits: ["submitData"],
  methods: {
    submitData(values) {
      this.$emit("submitData", values);
    },
    storeValidateFunction(validate) {
      this.validate = validate;
      return false;
    },
    validate() {
      this.validate();
    },
    requirePhone() {
      this.showPhone = true;
      this.addPhoneValidation();
    },
    unrequirePhone() {
      this.showPhone = false;
      this.removePhoneValidation();
    },
    addPhoneValidation() {
      if (!this.schema) {
        this.schema = this.buildValidationSchema();
      }
      this.schema.phone = yup
        .string()
        .required(
          this.getTranslation(
            "error_phone_missing",
            "Telefonnummer wird benötigt",
          ),
        )
        .trim();
    },
    removePhoneValidation() {
      if (!this.schema) {
        this.schema = this.buildValidationSchema();
      }
      delete this.schema.phone;
    },
  },
  activated() {
    let fb = Cookies.get("facebook_login");
    if (fb) {
      fb = JSON.parse(fb);
      this.initialValues = {
        gender: "männlich",
        firstname: fb.firstname,
        lastname: fb.lastname,
        email: fb.email,
      };
      Cookies.remove("facebook_login", { path: "/" });
    }
    let apple = Cookies.get("apple_login");
    if (apple) {
      apple = JSON.parse(apple);
      this.initialValues = {
        gender: "männlich",
        email: apple.email,
      };
      Cookies.remove("apple_login", { path: "/" });
    }
  },
};
</script>
