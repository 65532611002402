<template>
  <div
    :id="formItemId"
    :aria-describedby="!error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`"
    :aria-invalid="!!error"
    :class="['form-floating form-group w-100', props.class]"
  >
    <slot />
  </div>
</template>

<script setup>
import { useFormField } from "./useFormField";

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
});

const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
</script>