<template>
  <div class="text-center p-4">
    <div v-if="loginFailed" class="py-2">
      <span class="form-error">
        <span v-if="generalError">{{ generalError }}</span>
        <span v-else>{{
            getTranslation('error_invalid_credentials', 'Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.')
          }}</span>&nbsp;
        <a href="#" @click="changeComponent('forgotPassword')">
          {{ getTranslation('register_forgot_switch_link', 'Passwort vergessen?') }}
        </a>
      </span>
    </div>

    <Form @submit="submitData" :class="formClasses" id="login-form" :validation-schema="schema" v-slot="{ errors }">

      <div class="col-12 d-flex flex-wrap justify-content-center">
        <div class="col-md-6 col-9 px-lg-2">
          <div class="form-floating form-group w-100">
            <Field as="input" type="email" :placeholder="getTranslation('email_placeholder', 'E-Mail')" name="email"
                   ref="emailField" autocomplete="username" class="form-control"
                   :class="errors.email ? 'is-invalid':''"/>
            <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
            <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
          </div>
        </div>
      </div>

      <div class="col-12 d-flex flex-wrap justify-content-center">
        <div class="col-md-6 col-9 px-lg-2">
          <div class="form-floating form-group w-100">
            <Field as="input" name="password" type="password" class="form-control" id="password"
                   autocomplete="current-password" :placeholder="getTranslation('password_label', 'Passwort')"/>
            <label for="password">{{ getTranslation('password_label', 'Passwort') }}</label>
          </div>
        </div>
      </div>

      <div class="col-12">
        <button type="submit" class="btn" data-umami-event="login-button">
          <span v-if="!isLoading">Login</span>
          <span v-else class="spinner-border spinner-border-sm"></span>
        </button>
      </div>

      <div class="d-flex flex-wrap justify-content-center">
        <span class="w-100 text-center my-2">{{ getTranslation('or', 'oder') }}</span>
        <a class="btn facebook-sign-in" style="background-color: #4267b2!important;" @click="openFacebookLogin"
           href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-facebook">
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/>
          </svg>
          {{ getTranslation('login_facebook', 'Mit Facebook anmelden') }}
        </a>
      </div>

      <div class="my-4"></div>

      <div class="d-flex flex-wrap justify-content-center">

        <button class="btn apple-sign-in" @click="openAppleLogin">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 -3.552713678800501e-15 820 950"
               fill="currentColor" class="feather">
            <path
                d="M404.345 229.846c52.467 0 98.494-20.488 138.08-61.465s59.38-88.626 59.38-142.947c0-5.966-.472-14.444-1.414-25.434-6.912.942-12.096 1.727-15.552 2.355-48.383 6.908-90.954 30.615-127.713 71.12-36.758 40.506-55.137 83.838-55.137 129.996 0 5.337.785 14.13 2.356 26.375zM592.379 950c37.387 0 78.701-25.59 123.943-76.772S796.122 761.915 820 692.836c-88.912-45.844-133.368-111.626-133.368-197.348 0-71.591 35.973-132.82 107.92-183.688-49.954-62.486-115.931-93.729-197.931-93.729-34.56 0-66.134 5.181-94.724 15.543l-17.908 6.594-24.035 9.42c-15.709 5.966-30.004 8.95-42.885 8.95-10.054 0-23.25-3.455-39.586-10.363l-18.38-7.536-17.436-7.065c-25.449-10.676-52.782-16.014-82-16.014-78.23 0-141.065 26.376-188.506 79.128C23.72 349.479 0 419.03 0 505.379c0 121.517 38.015 233.772 114.046 336.763C166.828 914.047 215.054 950 258.724 950c18.537 0 36.916-3.611 55.138-10.833l23.092-9.42 18.38-6.594c25.762-9.106 49.482-13.659 71.16-13.659 22.935 0 49.326 5.81 79.173 17.427l14.609 5.652C550.75 944.191 574.786 950 592.379 950z"/>
          </svg>
          {{ getTranslation('login_apple', 'Mit Apple anmelden') }}
        </button>
      </div>
    </Form>
  </div>
</template>


<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";

import Cookies from "js-cookie";

import { getTransitionDurationFromElement } from "bootstrap/js/src/util";
import { mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useUserStore } from "../../../store/user_store";

let components;

try {
  components = require("../../../data/child_strings");
} catch (e) {
  components = require("../../../data/default_strings");
}
components = components.default;

export default {
  name: "Login",
  components: {
    Form,
    Field,
  },
  props: ["formClasses", "type", "params", "loadedFrom"],
  inject: ["getTranslation"],
  emits: ["changeComponent", "loginSuccessful"],
  data() {
    const schema = {
      email: yup
        .string()
        .nullable()
        .min(
          1,
          this.getTranslation("error_email_missing", "E-Mail wird benötigt"),
        )
        .trim()
        .email(
          this.getTranslation(
            "error_email_invalid",
            "Das ist keine gültige E-Mail Adresse.",
          ),
        ),
    };

    return {
      schema,
      components,
      isLoading: false,
      loginFailed: false,
      generalError: null,
      windowWatchInterval: null,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useUserStore),
  },
  methods: {
    getTransitionDurationFromElement,
    calcWindowPos() {
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - 1000) / 2 / 1 + dualScreenLeft;
      const top = (height - 800) / 2 / systemZoom + dualScreenTop;

      return {
        left: left,
        top: top,
      };
    },
    setRefreshInterval() {
      if (this.windowWatchInterval === null) {
        this.windowWatchInterval = setInterval(() => {
          if (Cookies.get("token")) {
            this.authStore
              .setToken(Cookies.get("token"), Cookies.get("expires"))
              .then(() => {
                new Promise((resolve) => {
                  clearInterval(this.windowWatchInterval);
                  resolve();
                }).then(() => {
                  this.windowWatchInterval = null;
                  this.userStore.refreshUserInfos().then((userInfos) => {
                    if (this.type === "auth") {
                      this.$emit("loginSuccessful");
                    } else {
                      this.changeComponent("checkout", "Shop");
                    }
                  });
                });
              });
          }

          const fb = Cookies.get("facebook_login");
          const apple = Cookies.get("apple_login");

          if (fb || apple) {
            new Promise((resolve) => {
              clearInterval(this.windowWatchInterval);
              resolve();
            }).then(() => {
              if (this.type === "auth") {
                this.changeComponent("register", "Auth", { step: 0 });
              } else {
                this.changeComponent("checkout", "Shop");
              }
            });
          }
        }, 200);
      }
    },
    openFacebookLogin() {
      const pos = this.calcWindowPos();
      window.open(
        "/auth/facebook/login",
        "_blank",
        `popup=true,left=${pos.left},top=${pos.top},width=1000,height=800,resizable=yes,scrollbars=yes,toolbar=no,location=no,directories=no,status=no,menubar=no`,
      );
      this.setRefreshInterval();
    },
    openAppleLogin() {
      const pos = this.calcWindowPos();
      window.open(
        "/auth/apple/authorize",
        "_blank",
        `popup=true,left=${pos.left},top=${pos.top},width=1000,height=800,resizable=yes,scrollbars=yes,toolbar=no,location=no,directories=no,status=no,menubar=no`,
      );
      this.setRefreshInterval();
    },
    async submitData(values) {
      this.generalError = null;

      if (!values.email || values.email.length === 0) {
        this.loginFailed = true;
        this.isLoading = false;
        this.generalError = this.getTranslation(
          "error_email_missing",
          "E-Mail wird benötigt",
        );
        return;
      }

      this.isLoading = true;

      this.authStore
        .login(values)
        .then(async (userInfos) => {
          this.isLoading = false;
          this.loginFailed = false;

          if (this.params?.redirect) {
            return this.changeComponent(
              this.params.redirect.component,
              this.params.redirect.module,
              this.params.redirect.params,
            );
          }

          if (this.type === "auth") {
            this.$emit("loginSuccessful");
          } else {
            if (this.params.returnTo) {
              this.changeComponent(
                this.params.returnTo.component,
                this.params.returnTo.module,
                this.params.returnTo.params,
              );
              return;
            }

            if (
              userInfos.unconfirmed_email === "" &&
              userInfos.phone_verified == 1
            ) {
              this.changeComponent("checkout", "Shop");
            } else {
              this.changeComponent("verify", "Auth", {
                type: !userInfos.phone_verified ? "verifyPhone" : "verifyEmail",
              });
            }
          }
        })
        .catch((error) => {
          this.loginFailed = true;
          this.isLoading = false;
          console.error(error);
        });
    },
    changeComponent(component, module = "Auth", params = {}) {
      this.$emit("changeComponent", {
        component: component,
        module: module,
        params: params,
      });
    },
  },
  activated() {
    setTimeout(() => {
      this.$refs.emailField.$el.focus();
    }, 500);
  },
};
</script>

<style scoped lang="scss">
label {
  text-align: left !important;
}

input.is-invalid {
  margin-bottom: .15rem;
}

.apple-sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 10px;
}

.apple-sign-in {
  height: 50px !important;
  width: 300px !important;
  text-align: center;

  background-image: none !important;

  background-color: white !important;
  color: black !important;
}

body.active-light-mode {
  .apple-sign-in {
    background-color: black !important;
    color: white !important;
  }
}

.facebook-sign-in {
  height: 50px !important;
  width: 300px !important;
  background-color: #4267b2 !important;
  background-image: none !important;
}
</style>