<template>
  <label
    :class="['form-label', props.class]"
    :for="formItemId"
  >
    <slot />
  </label>
</template>

<script setup>
import { useFormField } from "./useFormField";

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
});

const { error, formItemId } = useFormField();
</script>