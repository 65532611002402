import axios from "axios";

const drink_api = {
  fetchDrinkMenus() {
    return axios.get(`/api/drink-menus`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  checkForDiscounts(payload) {
    const params = new URLSearchParams(payload);
    return axios.post("/api/drinks/check/discounts", params).then((resp) => {
      return resp.data;
    });
  },
  fetchDrinkCategories() {
    return axios.get(`/api/drink-categories`).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      return resp.data;
    });
  },
  getDrinkOrdering(payload) {
    const params = new URLSearchParams(payload);
    return axios.get("/api/user/drinks/ordering", { params }).then((resp) => {
      if (resp.status === 200) {
        return resp.data;
      } else {
        throw new Error("Ein Fehler ist aufgetreten");
      }
    });
  },
};

export default drink_api;
