import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import Cookies from "js-cookie";
import { defineStore } from "pinia";
import auth_api from "../api/auth_api";
import settings_api from "../api/settings_api";
import { migrateStorage, serializer } from "../src/utils/utils";
import { useUserStore } from "./user_store";
import moment from "moment";
import { computed } from "vue";
export const useAuthStore = defineStore("auth", () => {
  const token = useLocalStorage("token", null, { serializer });

  const expires_at = useLocalStorage("expires_at", null, { serializer });

  const email_sent_at = useSessionStorage("email_sent_at", null);

  // STATE

  if (token.value) {
    Cookies.set("token", token.value);
  } else {
    Cookies.remove("token");
  }

  // VUEX Migration
  const oldAuth = migrateStorage();

  if (oldAuth && oldAuth.token.token != null) {
    console.log("restoring V1 auth data");

    token.value = oldAuth.token.token;
    expires_at.value = oldAuth.token.expires_at;

    console.log("restored V1 auth data");
  }

  //ACTIONS
  function isAuthenticated() {
    return !!token.value && moment().isBefore(moment(expires_at.value));
  }

  const isAuthenticatedRef = computed(() => {
    return !!token.value && moment().isBefore(moment(expires_at.value));
  });

  function setToken(_token, _expires) {
    return new Promise((resolve) => {
      Cookies.set("token", _token);
      token.value = _token;
      expires_at.value = moment().add(_expires, "s").toISOString();
      resolve();
    });
  }

  /**
   *
   * @param {FormData} formData
   * @returns {Promise<unknown>}
   */
  function register(formData) {
    const email = formData.get("email");
    const password = formData.get("password");
    return auth_api.register(formData).then(() => {
      console.log("Register successful. Logging in...");
      return login({ email, password });
    });
  }

  function login(payload) {
    const userStore = useUserStore();
    return auth_api
      .login(payload)
      .then((res) => {
        Cookies.set("token", res.access_token);
        token.value = res.access_token;
        expires_at.value = moment().add(res.expires_in, "s").toISOString();
        settings_api.logSession(true);
        return res.access_token;
      })
      .then((token) => {
        return userStore.refreshUserInfos(token);
      });
  }

  function resetPassword(payload) {
    return auth_api.resetPassword(payload);
  }

  function changePassword(payload) {
    return auth_api.changePassword(payload, token.value);
  }

  function deleteAccount() {
    return auth_api.deleteAccount(token.value).then((data) => {
      if (data.success) {
        return this.logout();
      }
    });
  }

  function sendVerificationEmail() {
    if (
      email_sent_at.value === null ||
      email_sent_at.value <= moment().unix() - 30
    ) {
      return auth_api
        .sendVerificationEmail(token.value)
        .then(() => {
          email_sent_at.value = moment().unix();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve) => {
        resolve();
      });
    }
  }

  function sendVerificationCode(payload) {
    return auth_api.sendVerificationCode(token.value, payload);
  }

  function verifyCode(payload) {
    return auth_api.verifyCode(token.value, payload);
  }

  function logout() {
    const userStore = useUserStore();
    return new Promise((resolve, reject) => {
      userStore.clear();
      token.value = null;
      expires_at.value = null;
      Cookies.remove("token");
      resolve();
    }).then(() => {
      document
        .getElementById("login-modal")
        .dispatchEvent(new CustomEvent("logoutSuccessful"));
    });
  }

  function setEmailSentAt(unix) {
    email_sent_at.value = unix;
  }

  return {
    token,
    expires_at: expires_at.value,
    email_sent_at: email_sent_at.value,
    setToken,
    isAuthenticated,
    isAuthenticatedRef,
    register,
    login,
    logout,
    resetPassword,
    changePassword,
    deleteAccount,
    sendVerificationEmail,
    sendVerificationCode,
    verifyCode,
    setEmailSentAt,
  };
});
