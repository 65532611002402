<template>

  <div class="container" v-if="selectedLounge">

    <div class="d-flex flex-wrap mb-4">
      <preview :hide-details="isGeneralRequestReservationAvailable"></preview>
    </div>

    <div class="d-flex flex-wrap justify-content-center mb-4">
      <span class="title">{{
          getTranslation('module_areas_lounges_select_event', 'Wähle ein Event um fortzufahren')
        }}</span>
    </div>

    <div class="d-flex flex-wrap" v-if="events">
      <div v-for="(event, index) in events"
           :class="showLounge(selectedLounge.id, event.id) ? 'col-lg-6 col-md-6 col-sm-12 col-12 event-snippet px-2 mb-5':''">
        <div class="rn-card box-card-style-default h-100 event-card" v-if="showLounge(selectedLounge.id, event.id)"
             :class="reservationRequestAvailable(selectedLounge.id, event.id) ? 'lounge-status-requests' : showAsBlocked(selectedLounge.id, event.id) ? 'lounge-status-blocked':''"
             @click="reservationRequestAvailable(selectedLounge.id, event.id) ? startReservationRequest(selectedLounge.id, event.id) : showAsBlocked(selectedLounge.id, event.id) ? dummyClick():startBooking(event.id)"
             :style="reservationRequestAvailable(selectedLounge.id, event.id) ? 'cursor: pointer;' : showAsBlocked(selectedLounge.id, event.id) ? '':'cursor: pointer;'">
          <div class="inner h-100">
            <div class="thumbnail"><a class="image"><img :src="event.image" :alt="event.name"></a></div>
            <div class="content">
              <div class="col-12 my-auto">
                <div class="row">
                  <div class="col-4 event-date-cal">
                    <span class="event-date-cal-weekday">{{ formatDate(event.start_time, 'dddd') }}</span><br/>
                    <span class="event-date-cal-day">{{ formatDate(event.start_time, 'DD') }}</span><br/>
                    <span class="event-date-cal-month">{{ formatDate(event.start_time, 'MMM') }}</span>
                  </div>
                  <div class="col-8 v-center my-auto">
                    <h4 class="title mt--0"><a href="#">{{ event.name }}</a></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import "moment/locale/de";

import { mapState } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

import preview from "../lounges/preview";

export default {
  name: "events",
  emits: [
    "startLoungeBookingFromEvent",
    "setModalTitle",
    "startReservationRequestFromEvent",
  ],
  inject: ["components", "getTranslation"],
  components: {
    preview,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useDataStore, [
      "events",
      "isLoungeBlockedForEvent",
      "isLoungeAvailableForEvent",
      "isLoungeAvailableForReservationRequestForEvent",
      "isLoungeAvailableForReservationRequest",
    ]),
    ...mapState(useCartStore, ["selectedLounge", "clearSelectedPackages"]),
    ...mapState(useDataStore, ["settings"]),
    isGeneralRequestReservationAvailable() {
      return this.isLoungeAvailableForReservationRequest(
        this.selectedLounge.id,
      );
    },
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format);
    },
    startBooking(id) {
      this.$emit("startLoungeBookingFromEvent", id);
    },
    startReservationRequest: function (id, event) {
      this.$emit("startReservationRequestFromEvent", {
        lounge: id,
        event: event,
      });
    },
    dummyClick() {
      return;
    },
    showLounge(id, event) {
      if (this.settings?.lounges.booking.show_locked_lounge) {
        return true;
      }
      return this.isLoungeAvailableForEvent(id, event);
    },
    showAsBlocked(id, event) {
      const res = this.isLoungeBlockedForEvent(id, event);
      return (
        (res.blocked && res.show_when_blocked) ||
        !this.isLoungeAvailableForEvent(id, event)
      );
    },
    reservationRequestAvailable(id, event) {
      return this.isLoungeAvailableForReservationRequestForEvent(id, event);
    },
  },
  mounted() {
    this.$emit("setModalTitle", this.selectedLounge.name);
  },
  activated() {
    this.$emit("setModalTitle", this.selectedLounge.name);
    this.clearSelectedPackages();
  },
};
</script>

<style scoped lang="scss">
.event-card {
  border: 2px solid var(--color-lounge-available) !important;

  &.lounge-status-requests {
    border: 2px solid var(--color-lounge-requests) !important;
  }

  &.lounge-status-blocked {
    border: 2px solid var(--color-lounge-blocked) !important;
  }
}

.title {
  font-size: 22px;
}

</style>