import axios from "axios";

const language_api = {
  getLanguage(lang_code) {
    const params = new URLSearchParams({ lang_code });
    return axios.get("/api/app/languages", { params }).then((resp) => {
      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }
      return resp.data.data;
    });
  },
};

export default language_api;
