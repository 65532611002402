<script setup>
import { storeToRefs } from "pinia";
import { computed, onActivated, ref } from "vue";
import { useAreasStore } from "../../../store/areas.store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useTranslation } from "../../composables/useTranslation";
import { formatCurrency, formatDate } from "../../lib/formatters";
import AreaFilter from "./AreaFilter.vue";
import StatusFilter from "./StatusFilter.vue";
import MobileFilter from "./filter/MobileFilter.vue";

const selectedAreas = ref([]);
const selectedStatuses = ref([]);

const dataStore = useDataStore();
const { event } = storeToRefs(dataStore);

const areasStore = useAreasStore();
const { areas } = storeToRefs(areasStore);

const cartStore = useCartStore();
onActivated(() => {
  cartStore.reset();
});

const emit = defineEmits(["startBooking", "startReservationRequest"]);

function startBooking(loungeId) {
  emit("startBooking", loungeId);
}

function startReservationRequest(loungeId) {
  emit("startReservationRequest", { lounge: loungeId, event: event.value.id });
}

const lounges = computed(() => {
  let filteredLounges = event.value.lounges.sort(
    (a, b) => a.area_priority - b.area_priority,
  );

  if (selectedAreas.value.length > 0) {
    filteredLounges = filteredLounges.filter((lounge) =>
      selectedAreas.value.includes(lounge.area),
    );
  }

  if (selectedStatuses.value.length > 0) {
    filteredLounges = filteredLounges.filter((lounge) => {
      if (selectedStatuses.value.includes("booked") && showAsBlocked(lounge))
        return true;
      if (
        selectedStatuses.value.includes("free") &&
        !showAsBlocked(lounge) &&
        !reservationRequestAvailable(lounge)
      )
        return true;
      if (
        selectedStatuses.value.includes("on_request") &&
        reservationRequestAvailable(lounge)
      )
        return true;
      return false;
    });
  }

  return filteredLounges;
});

function showLounge(lounge) {
  return !(lounge.blocked && !lounge.show_when_blocked);
}

function showAsBlocked(lounge) {
  return lounge.blocked || (lounge.booked && !lounge.blocked);
}

function reservationRequestAvailable(lounge) {
  return (
    lounge.blocked && lounge.show_when_blocked && lounge.reservation_requests
  );
}

function handleSelectedAreas(areas) {
  selectedAreas.value = areas;
}

function handleSelectedStatuses(statuses) {
  selectedStatuses.value = statuses;
}

function handleSelectArea(areas) {
  selectedAreas.value = areas;
}
</script>

<template>
  <div class="p-4 w-100 text-center">
    <h3 class="m-0">{{ event.name }}</h3>
    <h5 class="m-0">{{ formatDate(event.start_time, 'dddd, DD.MM.YYYY [um] HH:mm [ Uhr]') }}</h5>
  </div>
  <div class="px-4 w-100 d-flex gap-5 mt-4">
    <!-- <MobileFilter class="d-lg-none" :areas="areas" @selectArea="handleSelectArea" /> -->
    <AreaFilter :areas="areas" @update:selectedAreas="handleSelectedAreas" />
    <!--<StatusFilter @update:selectedStatuses="handleSelectedStatuses" />-->
  </div>
  <div class="lounge-container p-4 d-flex justify-content-center flex-wrap" v-if="event.lounges">
    <div v-for="lounge in lounges" :key="lounge.id" :class="showLounge(lounge) ? 'col-lg-6 col-12 mb-4 pe-4' : ''">
      <div v-if="showLounge(lounge)" class="inner h-100 lounge-card rn-card box-card-style-default" :class="{
        'lounge-status-requests': reservationRequestAvailable(lounge),
        'blocked lounge-status-blocked': showAsBlocked(lounge)
      }"
      data-umami-event="lounges-click"
      :data-umami-event-lounge-id="lounge.id"
      :data-umami-event-event-id="event.id"
      :data-umami-event-lounge-status="reservationRequestAvailable(lounge) ? 'reservation_requests' : (showAsBlocked(lounge) ? 'blocked' : 'free')"
        @click="reservationRequestAvailable(lounge) ? startReservationRequest(lounge.id) : !showAsBlocked(lounge) ? startBooking(lounge.id) : null">
        <div class="thumbnail">
          <img :src="lounge.image" :alt="lounge.name">
          <div class="lounge-name">{{ lounge.name }}</div>
          <div class="lounge-status">
            {{ reservationRequestAvailable(lounge)
              ? useTranslation('module_lounges_lounge_status_reservation_requests', "Nur auf Anfrage")
              : !showAsBlocked(lounge)
                ? useTranslation('module_lounges_lounge_status_free', "Jetzt reservieren")
                : useTranslation('module_lounges_lounge_status_blocked', "Leider schon reserviert")
            }}
          </div>
        </div>
        <div class="content">
          <div v-if="!reservationRequestAvailable(lounge)"
            class="d-flex flex-wrap align-items-center lounge-infos h-100">
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">{{ lounge.available_places }}</div>
              {{ useTranslation('module_lounges_persons', "Personen") }}
            </div>
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">{{ formatCurrency(lounge.minimum_consumption) }}</div>
              {{ useTranslation('module_lounges_minimum_consumption', "Mindestverzehr") }}
            </div>
            <div class="col-4">
              <div class="lounge-info-title h4 mb--0">
                {{ formatCurrency(lounge.minimum_consumption / lounge.available_places) }}
              </div>
              {{ useTranslation('module_lounges_per_persons', "pro Person") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
