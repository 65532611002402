<template>
  <div :class="[props.class]">
    <slot />
  </div>
</template>

<script setup>
import { useId } from "radix-vue";
import { provide } from "vue";
import { FORM_ITEM_INJECTION_KEY } from "./injectionKeys";

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
});

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);
</script>