<template>

    <div class="loading-indicator d-flex justify-content-center align-items-center" v-if="isLoading">
        <span class="spinner-grow"></span>
    </div>

    <userWidget @openLogin="openLogin"></userWidget>

    <Area @startBooking="startBooking" @startReservationRequest="startReservationRequest"></Area>

    <Teleport :to="'#shop-modal .modal-dialog'">

        <div class="modal-content bg-dark-transparent animate__animated animate__fadeIn" v-if="open">
            <div class="modal-header">
                <div
                    v-if="showBackButton"
                    @click="goBack"
                    class="back-button" aria-label="Back">
                    <i class="feather-arrow-left"></i>
                </div>

                <span class="h4 modal-title" :style="showBackButton ? 'padding-right: 2rem;' : ''">{{
                        currentTitle
                    }}</span>

                <button class="btn-close" @click="closeModal"
                        style="background:none;position:absolute;right: 2rem;top: 1rem;">
                    <i class="feather-x fs-20"></i>
                </button>

            </div>

            <div class="modal-body px-0 py-0">


                <div v-if="isLoading" class="d-flex w-100 justify-content-center align-items-center text-white ">
                    <h2 class="spinner-border mt-4"></h2>
                </div>
                <div class="h-100" v-else>
                    <keep-alive>
                        <component
                            :is="currentComponent"
                            :childComponent="childComponent"
                            :type="type"
                            :params="params"
                            @changeComponent="changeComponent"
                            @setModalTitle="setModalTitle"
                            @loginSuccessful="loginSuccessful"
                        ></component>
                    </keep-alive>
                </div>
            </div>

            <!--      <div class="modal-footer"></div>-->
        </div>

    </Teleport>

    <Teleport v-if="openTicketShop" :to="'#shop-iframe'">
        <div class="w-100" style="height: 50px;">
            <div v-if="showBackButton" @click="goBack" class="back-button" aria-label="Back">
                <i class="feather-arrow-left"></i>
            </div>
        </div>
        <div v-if="isLoading" class="d-flex w-100 justify-content-center align-items-center text-white ">
            <h2 class="spinner-border mt-4"></h2>
        </div>
        <div class="h-100" style="margin-bottom: 100px;" v-else>
            <keep-alive>
                <component
                    :is="currentComponent"
                    :childComponent="childComponent"
                    :type="type"
                    :params="params"
                    @changeComponent="changeComponent"
                    @setModalTitle="setModalTitle"
                    @loginSuccessful="loginSuccessful"
                ></component>
            </keep-alive>
        </div>
    </Teleport>


    <Teleport v-if="showUser" :to="'#user'">
        <User @openVerify="openVerify"></User>
    </Teleport>

</template>


<script>
import Swal from "sweetalert2";
import Area from "./components/lounges/areas/area";
import Auth from "./modules/Auth";
import Shop from "./modules/Shop";
import User from "./modules/User";

import userWidget from "./components/widgets/userWidget";

import {mapState, mapStores} from "pinia";
import {useAuthStore} from "../store/auth_store";
import {useCartStore} from "../store/cart_store";
import {useDataStore} from "../store/data_store";
import {useTranslationStore} from "../store/translation_store";
import {useUserStore} from "../store/user_store";
import {useCheckoutStore} from "./features/checkout/checkout.store";

let components;

try {
    components = require("../data/child_strings");
} catch (e) {
    components = require("../data/default_strings");
}
components = components.default;

export default {
    name: "Index",
    data() {
        return {
            components,
            modalId: "shop-modal",
            modal: "",
            open: false,
            isLoading: false,
            currentTitle: "",
            stack: [],
            currentComponent: "Shop",
            childComponent: "tickets",
            type: "tickets",
            params: {},
            showBackButton: false,
            openTicketShop: false,
        };
    },
    emits: ["changeComponent", "setModalTitle", "loginSuccessful"],
    provide() {
        return {
            components,
            clearStack: this.clearStack,
            addToStack: this.addToStack,
            getTranslation: this.getTranslation,
            getTranslatedTitleForComponent: this.getTranslatedTitleForComponent,
        };
    },
    components: {
        Auth,
        Shop,
        User,
        userWidget,
        Area,
    },
    watch: {
        open: {
            handler(value, oldValue) {
                if (value) {
                    // remove scroll when modal is open
                    document.body.style.top = `-${window.scrollY}px`;
                    document.body.style.position = "fixed";
                    document.body.style.left = `0`;
                    document.body.style.right = `0`;
                } else {
                    // enable scroll when modal is closed
                    const scrollY = document.body.style.top;
                    document.body.style.position = "";
                    document.body.style.top = "";
                    window.scrollTo(0, Number.parseInt(scrollY || "0") * -1);

                    if (document.querySelector(".modal-backdrop")) {
                        document.querySelector(".modal-backdrop").remove();
                    }
                }
            },
            immediate: false,
        },
    },
    computed: {
        ...mapStores(useAuthStore, useUserStore, useCartStore, useDataStore, useCheckoutStore),
        ...mapState(useTranslationStore, [
            "getTranslation",
            "getTranslatedTitleForComponent",
        ]),
        showUser: () => !!document.getElementById("user"),
    },
    methods: {
        openLogin() {
            if (!this.authStore.isAuthenticated()) {
                this.currentTitle = null;
                this.currentComponent = "Auth";
                this.childComponent = "login";
                this.type = "auth";
                this.params = {};
                this.showBackButton = false;
                this.startUpModal("shop-modal");
            } else {
                console.error("User is already authenticated");
            }
        },
        changeComponent(item) {
            if (item) {
                const newItem = {
                    module: item.module.old,
                    component: item.component.old,
                    title: item.title.old,
                    params: item.params.old,
                };
                this.stack.push(newItem);

                if (
                    item.module.old == "Auth" &&
                    item.component.old == "register" &&
                    item.component.new == "verify"
                ) {
                    if (
                        this.stack.length &&
                        this.stack.filter((item) => item.component == "checkout").length
                    ) {
                        const checkout_stack = this.stack.find(
                            (item) => item.component == "checkout",
                        );
                        this.stack = [checkout_stack];
                    } else {
                        this.stack = [];
                    }
                }

                // remove login from stack if user is logged in so that he can't go back to login
                if (
                    item.module.old == "Auth" &&
                    item.component.old == "login" &&
                    item.component.new == "checkout"
                ) {
                    const checkout_stack = this.stack.find(
                        (item) => item.component == "checkout",
                    );
                    const index = this.stack.indexOf(checkout_stack);
                    this.stack = this.stack.slice(0, index);
                }

                if (
                    item.module.old == "Auth" &&
                    item.component.old == "login" &&
                    item.component.new == "drinks"
                ) {
                    const checkout_stack = this.stack.find(
                        (item) =>
                            item.component == "drinks" &&
                            item.params?.activeComponent == "validateBooking",
                    );
                    if (checkout_stack) {
                        const index = this.stack.indexOf(checkout_stack);
                        this.stack = this.stack.slice(0, index);
                    }
                }

                if (
                    item.module.old == "Auth" &&
                    item.component.old == "login" &&
                    item.component.new == "lounges"
                ) {
                    const checkout_stack = this.stack.find(
                        (item) =>
                            item.component == "lounges" &&
                            item.params?.activeComponent == "reservationRequest",
                    );
                    if (checkout_stack) {
                        const index = this.stack.indexOf(checkout_stack);
                        this.stack = this.stack.slice(0, index);
                    }
                }

                if (
                    item.module.old == "Auth" &&
                    item.component.old == "verify" &&
                    item.component.new == "checkout"
                ) {
                    const checkout_stack = this.stack.find(
                        (item) => item.component == "checkout",
                    );
                    if (checkout_stack) {
                        const index = this.stack.indexOf(checkout_stack);
                        this.stack = this.stack.slice(0, index);
                    }
                }

                if (
                    item.module.old == "Shop" &&
                    item.component.old == "lounges" &&
                    item.params.old.activeComponent == "reservationRequest" &&
                    item.component.new == "paymentCompleted"
                ) {
                    this.stack = [];
                }

                this.currentTitle = this.getTranslatedTitleForComponent(
                    item.component.new,
                );
                this.currentComponent = item.module.new;
                this.childComponent = item.component.new;
                this.params = item.params.new;
                this.showBackButton = this.stack.length > 0;
            }
        },
        goBack() {
            if (this.stack.length) {
                const lastComponent = this.stack.pop();
                this.currentComponent = lastComponent.module;
                this.childComponent = lastComponent.component;
                this.currentTitle = this.getTranslatedTitleForComponent(
                    lastComponent.component,
                );
                this.params = lastComponent.params;
            }
            if (this.stack.length === 0) this.showBackButton = false;
        },
        closeModal() {
            this.modal.hide(document.getElementById("shop-modal"));
            this.checkoutStore.clear();
        },
        loginSuccessful: function () {
            if (
                this.stack.length &&
                this.stack.filter((item) => item.component == "checkout").length
            ) {
                const checkout_stack = this.stack.find(
                    (item) => item.component == "checkout"
                );
                this.stack = [checkout_stack];
                this.goBack();
            } else {
                if (this.type === "auth") {
                    this.closeModal();

                    if (
                        this.dataStore.settings &&
                        location.href.indexOf(this.dataStore.settings.paths.galleries) !== -1
                    ) {
                        location.reload();
                    }
                }
            }
        },
        setModalTitle(title) {
            this.currentTitle = title;
        },
        startUpModal(elementId) {
            this.modalId = elementId;
            this.modal = new bootstrap.Modal(document.getElementById(elementId), {
                keyboard: true,
            });
            this.stack = [];
            this.showBackButton = false;
            this.open = true;
            this.modal.show();
        },
        openVerify(type) {
            this.currentComponent = "Auth";
            this.type = "auth";
            this.childComponent = "verify";
            this.params = {
                verifyType: type,
            };
            this.currentTitle = this.getTranslation("verify_title");
            this.startUpModal("shop-modal");
        },
        startBooking(payload) {
            if (payload.lounge) {
                this.isLoading = true;
                // this.dataStore.loadEvents();
                // this.dataStore.loadLounges().then(() => {
                this.dataStore
                    .loadLounges()
                    .then(() => {
                        return this.cartStore.setLounge(payload.lounge);
                    })
                    .then(() => {
                        // this.dataStore.loadLoungePackages(lounge);

                        if (payload.event) {
                            new Promise((resolve) => {
                                this.currentComponent = "Shop";
                                this.type = "lounges";
                                this.childComponent = "lounges";
                                this.currentTitle =
                                    this.getTranslatedTitleForComponent("lounges");
                                this.params = {
                                    activeComponent: "persons",
                                    reservationRequest: false,
                                };
                                resolve();
                            }).then(() => {
                                this.isLoading = false;
                                return this.startUpModal("shop-modal");
                            });
                        } else {
                            this.dataStore
                                .loadEvents()
                                .then(() => {
                                    new Promise((resolve) => {
                                        this.currentComponent = "Shop";
                                        this.type = "lounges";
                                        this.childComponent = "events";
                                        this.currentTitle =
                                            this.getTranslatedTitleForComponent("events");
                                        this.params = {
                                            // activeComponent: "listLounges"
                                        };
                                        resolve();
                                    }).then(() => {
                                        this.isLoading = false;
                                        return this.startUpModal("shop-modal");
                                    });
                                })
                                .catch((error) => {
                                    this.isLoading = false;
                                    console.warn(error);
                                });
                        }
                    });
                // });
            }
        },
        async startReservationRequest(payload) {
            if (!payload.lounge) return;
            this.isLoading = true;

            await this.cartStore.setLounge(payload.lounge);

            try {
                if (payload.event) {
                    this.currentComponent = "Shop";
                    this.type = "lounges";
                    this.childComponent = "lounges";
                    this.currentTitle = this.getTranslatedTitleForComponent("lounges");
                    this.params = {
                        activeComponent: "persons",
                        reservationRequest: true,
                    };
                } else {
                    this.currentComponent = "Shop";
                    this.type = "lounges";
                    this.childComponent = "events";
                    this.params = {
                        reservationRequest: true,
                    };
                    this.currentTitle = this.getTranslatedTitleForComponent("events");
                }

                await this.startUpModal("shop-modal");
            } catch (e) {
                console.error(e);
                await Swal.fire({
                    title: this.getTranslation("error", "Fehler"),
                    text: this.getTranslation(
                        "error_occurred",
                        "Ein Fehler ist aufgetreten. Bitte versuche es erneut.",
                    ),
                    icon: "error",
                });
            } finally {
                this.isLoading = false;
            }
        },
        clearStack() {
            this.stack = [];
        },
        addToStack(item) {
            this.stack.push({
                module: item.module,
                component: item.component,
                title: item.title,
                params: item.params,
            });
        },
    },
    beforeMount() {
        if (this.cartStore.status !== "paymentInProgress") {
            this.cartStore.reset();
            // this.cartStore.setStatus('paymentFailed');
        }
        this.dataStore
            .loadEvents()
            .then(this.dataStore.loadSettings)
            .then((settings) => {
                if (!this.dataStore.config.disable_lounge_button_modal) {
                    const loungeBtns = document.querySelectorAll(".lounge-btn");
                    if (loungeBtns) {
                        loungeBtns.forEach((btn, index) => {
                            btn.addEventListener("click", (e) => {
                                e.preventDefault();
                                const event = e.currentTarget.dataset.event;
                                if (event) {
                                    this.isLoading = true;
                                    this.dataStore
                                        .loadLounges()
                                        .then(() => {
                                            return this.dataStore.loadData(event);
                                        })
                                        .then(() => {
                                            new Promise((resolve) => {
                                                this.currentComponent = "Shop";
                                                this.type = "lounges";
                                                this.childComponent = "lounges";
                                                this.currentTitle =
                                                    this.getTranslatedTitleForComponent("lounges");
                                                this.params = {
                                                    activeComponent: "listLounges",
                                                };
                                                resolve();
                                            }).then(() => {
                                                this.isLoading = false;
                                                return this.startUpModal("shop-modal");
                                            });
                                        })
                                        .catch((error) => {
                                            this.isLoading = false;
                                            console.warn(error);
                                        });
                                }
                            });
                        });
                    }
                }

                if (
                    !this.authStore.isAuthenticated() &&
                    settings &&
                    location.href.indexOf(settings.paths.galleries + "/") !== -1
                ) {
                    // öffne Login wenn Nutzer auf Galerie zugreifen möchte und nicht eingeloggt ist
                    this.openLogin();
                }

                const ticketBtns = document.querySelectorAll(".ticket-btn");
                if (ticketBtns) {
                    ticketBtns.forEach((btn, index) => {
                        btn.addEventListener("click", (e) => {
                            const event = e.currentTarget.dataset.event;
                            if (event && !event.external_ticket_link) {
                                e.preventDefault();
                                this.isLoading = true;
                                this.dataStore
                                    .loadData(event)
                                    .then(() => {
                                        new Promise((resolve) => {
                                            this.currentComponent = "Shop";
                                            this.type = "tickets";
                                            this.childComponent = "tickets";
                                            this.currentTitle =
                                                this.getTranslatedTitleForComponent("tickets");
                                            resolve();
                                        })
                                            .then(() => {
                                                if (btn.dataset.ticket) {
                                                    const ticket = btn.dataset.ticket;
                                                    this.cartStore.addTicket(ticket);
                                                }
                                            })
                                            .then(() => {
                                                this.isLoading = false;
                                                history.pushState({}, "", "#tickets");
                                                return this.startUpModal("shop-modal");
                                            });
                                    })
                                    .catch((error) => {
                                        this.isLoading = false;
                                        console.warn(error);
                                    });
                            }
                        });
                    });
                }

                // Lounge direkt buchen
                const loungeBookingBtns = document.querySelectorAll(
                    ".lounge-booking-btn",
                );
                if (loungeBookingBtns) {
                    loungeBookingBtns.forEach((btn, index) => {
                        btn.addEventListener("click", (e) => {
                            e.preventDefault();
                            const event = e.currentTarget.dataset.event;
                            const lounge = e.currentTarget.dataset.lounge;

                            if (event && lounge) {
                                this.isLoading = true;
                                this.dataStore
                                    .loadLounges()
                                    .then(() => this.dataStore.loadData(event))
                                    .then(() => {
                                        this.cartStore
                                            .setLounge(lounge)
                                            .then(() => {
                                                new Promise((resolve) => {
                                                    console.log(btn.classList, btn.children);
                                                    this.currentComponent = "Shop";
                                                    this.type = "lounges";
                                                    this.childComponent = "lounges";
                                                    this.currentTitle =
                                                        this.getTranslatedTitleForComponent("lounges");
                                                    this.params = {
                                                        activeComponent: "persons",
                                                        reservationRequest: btn.querySelector('.lounge-status-requests') !== null,
                                                    };
                                                    resolve();
                                                }).then(() => {
                                                    this.isLoading = false;
                                                    return this.startUpModal("shop-modal");
                                                });
                                            })
                                            .catch((error) => {
                                                this.isLoading = false;
                                                console.warn(error);
                                            });
                                    })
                                    .catch((error) => {
                                        this.isLoading = false;
                                        console.warn(error);
                                    });
                            }
                        });
                    });
                }

                // Event wählen
                const loungeEventBtns = document.querySelectorAll(".lounge-event-btn");
                if (loungeEventBtns) {
                    loungeEventBtns.forEach((btn, index) => {
                        btn.addEventListener("click", (e) => {
                            e.preventDefault();
                            const lounge = e.target.dataset.lounge;
                            if (lounge) {
                                this.isLoading = true;
                                this.dataStore.loadLounges().then(() => {
                                    this.cartStore
                                        .setLounge(lounge)
                                        .then(() => {
                                            // this.dataStore.loadLoungePackages(lounge);
                                            new Promise((resolve) => {
                                                this.currentComponent = "Shop";
                                                this.type = "lounges";
                                                this.childComponent = "events";
                                                this.currentTitle =
                                                    this.getTranslatedTitleForComponent("events");
                                                this.params = {
                                                    // activeComponent: "listLounges"
                                                };
                                                resolve();
                                            }).then(() => {
                                                this.isLoading = false;
                                                return this.startUpModal("shop-modal");
                                            });
                                        })
                                        .catch((error) => {
                                            this.isLoading = false;
                                            console.warn(error);
                                        });
                                });
                            }
                        });
                    });
                }

                /*const drinkBtns = document.querySelectorAll(".drink-btn");
                if (drinkBtns) {
                  drinkBtns.forEach((btn, index) => {
                    btn.addEventListener("click", (e) => {
                      e.preventDefault();
                      const booking_number = e.currentTarget.dataset.booking_number;
                      const lounge = e.currentTarget.dataset.lounge;
                      const event = e.currentTarget.dataset.event;

                      this.isLoading = true;
                      this.dataStore
                        .loadLounges()
                        .then(() => {
                          return this.dataStore.loadDrinkMenus();
                        })
                        .then(() => {
                          if (event) {
                            return this.dataStore.loadData(event);
                          }
                        })
                        .then(() => {
                          if (lounge) {
                            this.cartStore.setLounge(lounge);
                          }
                          if (booking_number) {
                            this.cartStore.setLoungeBookingNumber(booking_number);
                          }
                        })
                        .then(() => {
                          new Promise((resolve) => {
                            this.currentComponent = "Shop";
                            this.type = "drinks";
                            this.childComponent = "drinks";
                            this.currentTitle =
                              this.getTranslatedTitleForComponent("listDrinks");
                            this.params = {
                              activeComponent: "drinkMenuSelection",
                            };
                            resolve();
                          }).then(() => {
                            this.isLoading = false;
                            history.pushState({}, "", "#drinks");
                            return this.startUpModal("shop-modal");
                          });
                        })
                        .catch((error) => {
                          this.isLoading = false;
                          console.warn(error);
                        });
                    });
                  });
                }*/

                const startUpShopDiv = document.getElementById("startUpShop");
                if (this.cartStore.status === "paymentInProgress") {
                    const order_id = startUpShopDiv.dataset.id;
                    const type = startUpShopDiv.dataset.type;
                    const status = startUpShopDiv.dataset.status;
                    const user_page_url = startUpShopDiv.dataset.redirect;
                    const method = startUpShopDiv.dataset.method;
                    const pdfHash = Cookies.get("pdfHash");

                    if (order_id && type && status) {
                        if (
                            status === "SUCCEEDED" ||
                            status === "RESERVED" ||
                            type === "loungeReservationRequest"
                        ) {
                            this.cartStore.setStatus("paymentCompleted");
                            new Promise((resolve) => {
                                this.currentComponent = "Shop";
                                this.type =
                                    type === "ticket-ordering"
                                        ? "tickets"
                                        : type === "lounge-booking"
                                            ? "lounges"
                                            : "drinks";
                                this.childComponent = "paymentCompleted";
                                this.currentTitle = ""; // kein titel da der status als titel verwendet wird
                                this.params = {
                                    order: {
                                        id: order_id,
                                        status: status,
                                        type: type,
                                        user_page_url: user_page_url,
                                        method: method,
                                        pdfHash: pdfHash,
                                    },
                                };
                                resolve();
                            }).then(() => {
                                this.isLoading = false;
                                this.startUpModal("shop-modal");
                            });
                        } else {
                            this.cartStore.setStatus("paymentFailed");
                            new Promise(async (resolve) => {
                                if (type === "lounge-booking") {
                                    await this.dataStore.loadLounges();
                                }
                                this.currentComponent = "Shop";
                                this.type =
                                    type === "ticket-ordering"
                                        ? "tickets"
                                        : type === "lounge-booking"
                                            ? "lounges"
                                            : "drinks";
                                this.childComponent = "checkout";
                                this.currentTitle =
                                    this.getTranslatedTitleForComponent("checkout");
                                resolve();
                            }).then(() => {
                                return this.startUpModal("shop-modal");
                            });
                        }
                    } else {
                        this.cartStore.reset();
                    }
                }
            });

        this.dataStore.loadLounges();
    },
    mounted() {
        if (window.location.search == "?passwordChangeSucceed") {
            Swal.fire({
                title: this.getTranslation(
                    "password_change_succeeded",
                    "Dein Passwort wurde erfolgreich geändert.",
                ),
                icon: "success",
            }).then(() => {
                window.location.search = "";
            });
        }

        if (document.getElementById("userWidget")) {
            document
                .getElementById("userWidget")
                .addEventListener("click", this.openLogin);
        }

        if (document.getElementById("userWidgetMobile")) {
            document
                .getElementById("userWidgetMobile")
                .addEventListener("click", this.openLogin);
        }

        if (document.getElementById("shop-iframe")) {
            const event = document.getElementById("shop-iframe").dataset.event;
            const type = document.getElementById("shop-iframe").dataset.type;
            if (event) {
                this.openTicketShop = true;
                this.isLoading = true;
                this.dataStore
                    .loadData(event)
                    .then(() => {
                        new Promise((resolve) => {
                            this.currentComponent = "Shop";

                            if (type == "tickets") {
                                this.type = "tickets";
                                this.childComponent = "tickets";
                                this.currentTitle =
                                    this.getTranslatedTitleForComponent("tickets");
                            }

                            if (type == "lounges") {
                                this.type = "lounges";
                                this.childComponent = "lounges";
                                this.currentTitle =
                                    this.getTranslatedTitleForComponent("lounges");
                                this.params = {
                                    activeComponent: "listLounges",
                                };
                            }

                            resolve();
                        }).then(() => {
                            this.isLoading = false;
                        });
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.warn(error);
                    });
            }
        }

        document
            .getElementById("shop-modal")
            .addEventListener("hidden.bs.modal", (e) => {
                history.pushState({}, "", "#");

                this.open = false;
                if (this.status !== "paymentInProgress") {
                    this.cartStore.removeDiscountCode("*");
                    this.cartStore.reset();
                }
            });

        if (this.authStore.isAuthenticated()) {
            this.userStore.refreshUserInfos().then((userInfos) => {
                const hash = window.top.location.hash.substr(1);
                if (hash === "tickets") {
                    const ticketBtns = document.querySelectorAll(".ticket-btn");
                    if (ticketBtns.length === 1) {
                        setTimeout(() => {
                            ticketBtns[0].click();
                        }, 500);
                    }
                } else if (hash.indexOf("ticket-") !== -1) {
                    const ticket = hash.split("-")[1];

                    const ticketBtns = document.querySelectorAll(".ticket-btn");

                    if (ticketBtns.length === 1) {
                        const event = ticketBtns[0].dataset.event;

                        this.dataStore
                            .loadData(event)
                            .then(() => {
                                if (
                                    this.dataStore.tickets.length &&
                                    this.dataStore.tickets.find((t) => t.id == ticket)
                                ) {
                                    this.cartStore.addTicket(ticket).then(() => {
                                        new Promise((resolve) => {
                                            this.currentComponent = "Shop";
                                            this.type = "tickets";
                                            this.childComponent = "checkout";
                                            this.currentTitle =
                                                this.getTranslatedTitleForComponent("checkout");
                                            resolve();
                                        }).then(() => {
                                            return this.startUpModal("shop-modal");
                                        });
                                    });
                                }
                            })
                            .catch((error) => {
                                console.warn(error);
                            });
                    }
                } else if (hash.indexOf("lounges") !== -1) {
                    const ticketBtns = document.querySelectorAll(".ticket-btn");
                    if (ticketBtns.length === 1) {
                        const event = ticketBtns[0].dataset.event;
                        this.dataStore
                            .loadData(event)
                            .then(() => {
                                new Promise((resolve) => {
                                    this.currentComponent = "Shop";
                                    this.type = "lounges";
                                    this.childComponent = "lounges";
                                    this.currentTitle =
                                        this.getTranslatedTitleForComponent("lounges");
                                    this.params = {
                                        activeComponent: "listLounges",
                                    };
                                    resolve();
                                }).then(() => {
                                    return this.startUpModal("shop-modal");
                                });
                            })
                            .catch((error) => {
                                console.warn(error);
                            });
                    }
                } else if (hash.indexOf("lounge-") !== -1) {
                    const lounge = hash.split("-")[1];
                    // console.log(lounge)

                    const booking_btn = document.querySelector(
                        `.lounge-booking-btn[data-lounge="${lounge}"]`,
                    );
                    const event_btn = document.querySelector(
                        `.lounge-event-btn[data-lounge="${lounge}"]`,
                    );
                    // console.log(booking_btn)
                    // console.log(event_btn)
                    if (booking_btn) {
                        // Lounge direkt buchen
                        booking_btn.dispatchEvent(new Event("click"));
                    } else if (event_btn) {
                        // Event wählen
                        event_btn.dispatchEvent(new Event("click"));
                    }
                }
            });
        } else {
            const hash = window.top.location.hash.substr(1);

            if (hash === "tickets") {
                const ticketBtns = document.querySelectorAll(".ticket-btn");
                if (ticketBtns.length === 1) {
                    setTimeout(() => {
                        ticketBtns[0].click();
                    }, 500);
                }
            } else if (hash.indexOf("ticket-") !== -1) {
                const ticket = hash.split("-")[1];

                const ticketBtns = document.querySelectorAll(".ticket-btn");

                if (ticketBtns.length === 1) {
                    const event = ticketBtns[0].dataset.event;

                    this.dataStore
                        .loadData(event)
                        .then(() => {
                            if (
                                this.dataStore.tickets.length &&
                                this.dataStore.tickets.find((t) => t.id == ticket)
                            ) {
                                this.cartStore.addTicket(ticket).then(() => {
                                    new Promise((resolve) => {
                                        this.currentComponent = "Shop";
                                        this.type = "tickets";
                                        this.childComponent = "checkout";
                                        this.currentTitle =
                                            this.getTranslatedTitleForComponent("checkout");
                                        resolve();
                                    }).then(() => {
                                        return this.startUpModal("shop-modal");
                                    });
                                });
                            }
                        })
                        .catch((error) => {
                            console.warn(error);
                        });
                }
            } else if (hash.indexOf("lounges") !== -1) {
                const ticketBtns = document.querySelectorAll(".ticket-btn");
                if (ticketBtns.length === 1) {
                    const event = ticketBtns[0].dataset.event;
                    this.dataStore
                        .loadData(event)
                        .then(() => {
                            new Promise((resolve) => {
                                this.currentComponent = "Shop";
                                this.type = "lounges";
                                this.childComponent = "lounges";
                                this.currentTitle =
                                    this.getTranslatedTitleForComponent("lounges");
                                this.params = {
                                    activeComponent: "listLounges",
                                };
                                resolve();
                            }).then(() => {
                                return this.startUpModal("shop-modal");
                            });
                        })
                        .catch((error) => {
                            console.warn(error);
                        });
                }
            } else if (hash.indexOf("lounge-") !== -1) {
                const lounge = hash.split("-")[1];
                const booking_btn = document.querySelector(
                    `.lounge-booking-btn[data-lounge="${lounge}"]`,
                );
                const event_btn = document.querySelector(
                    `.lounge-event-btn[data-lounge="${lounge}"]`,
                );

                if (booking_btn) {
                    // Lounge direkt buchen
                    booking_btn.dispatchEvent(new Event("click"));
                } else if (event_btn) {
                    // Event wählen
                    event_btn.dispatchEvent(new Event("click"));
                }
            }
        }
    },
};
</script>

<style scoped lang="scss">
.back-button {
    height: 30px;
    width: 30px;
    font-size: 24px;
    background: none;
    color: var(--color-body);
    cursor: pointer;
}
</style>
