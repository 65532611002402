
const components = {
    'auth': {
        registerText: "Jetzt registrieren",
        loginText: "Jetzt anmelden",
        loginSwitchText: "melde dich mit deinem App-Konto an",
        registerSwitchText: "Du bist noch nicht dabei?",
        registerSwitchLinkText: "Jetzt registrieren!",
        forgotPasswordSwitchText: "Oder hast du dein",
        forgotPasswordSwitchLinkText: "Passwort vergessen?",
    },
    'login': {
        title: "Login",
        backBtn: true,
    },
    'register': {
        title: "Registrierung",
        backBtn: true,
        step1: {
            name: "Account",
            text1: "Los gehts...",
            text2: "Wie möchtest du dich bei uns anmelden?"
        },
        step2: {
            name: "Über dich",
            text1: "Weiter gehts..",
            text2: "Wie heißt du und wer bist du?"
        },
        step3: {
            name: "Profilbild",
            text1: "Dein Profilbild..",
            text2: "Möchtest du ein Foto von dir zeigen?"
        },
        step4: {
            name: "Abschluss",
            text1: "Deine Anschrift..",
            text2: "Wer ist Inhaber der Tickets, Reservierungen etc.?"
        },
    },
    'verify': {
        title: "Verifizierung",
        backBtn: true,
    },
    'missingInfos': {
        title: "Profil ergänzen",
        backBtn: true,
    },
    'forgotPassword': {
        title: "Passwort zurücksetzen",
        text: "Gib bitte deine E-Mail Adresse ein, damit wir dein Passwort zurücksetzen können.",
        backBtn: true,
    },
    //Shop
    'tickets': {
        title: "Tickets kaufen",
        backBtn: true,
    },
    'lounges': {
        title: "Lounge reservieren",
        backBtn: true,
    },
    'events': {
        title: "Event wählen",
        subTitle: "Wähle ein Event um fortzufahren",
        backBtn: true,
    },
    'checkout': {
        title: "Zusammenfassung",
        backBtn: true,
        loginText1: "Du bist bereits Mitglied?",
        loginText2: "Dann logge dich jetzt ein…",
        noteText: "Hinweise für uns...",
        paymentText: "Wie möchtest du zahlen?",
        paymentFeeText: "<span style='color: var(--color-warning)'>Die Buchungsgebühr in Höhe von <b>{price}</b> fällt sofort an.</span><br/><span>Der Rest wird von dir vor Ort in Bar erwartet.</span>",
        paymentFeeText1: "Wie möchtest du diese begleichen?",
    },
    'paymentCompleted': {
        title: "",
        failedTitle: "Zahlung fehlgeschlagen!",
        succeededTitle: "Erfolgreich!",
        pendingTitle: "Zahlung in Bearbeitung...",
        failedText: "Leider konnte deine Zahlung nicht abgeschlossen werden!",
        succeededText: "Wir haben deine Bestellung erhalten, diese erhältst du per E-Mail, in der App und in deiner Übersicht!",
        succeededFreeText: "Deine Frei-Tickets wurden freigeschaltet, diese erhältst du per E-Mail, in der App und in deiner Übersicht!",
        pendingTicketsText: "Deine Zahlung ist in bearbeitung sobald diese abgeschlossen ist findest du deine Tickets in der App und in deiner Timeline!",
        pendingLoungesText: "Deine Zahlung ist in bearbeitung sobald diese abgeschlossen ist findest du deine Reservierung in der App und in deiner Timeline! ",
        userButton: "Zu deinen Bestellungen",
        shopButton: "Zurück zum Shop",
        backBtn: true,
    },
};

export default components;