<template>

  <div class="d-flex shop-footer p-4 mt-2" v-if="type === 'tickets'">

    <div class="d-flex flex-shrink-1 pe-4 align-items-center">

      <svg width="30pt" height="30pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="#fff">
          <path d="m90.203 58.242-0.64453 0.22656c-1.5664 0.54688-3.2539 0.44922-4.7539-0.26953-1.4961-0.72266-2.6211-1.9805-3.168-3.5508-0.82422-2.3633-0.16016-4.9766 1.6953-6.6602 0.61719-0.55859 1.332-0.98438 2.1289-1.2617l0.64453-0.22656-2.6953-7.7227 8.2227-7.4609-8.7617-9.6602-0.50781 0.46094c-2.5391 2.3008-6.4805 2.1094-8.7812-0.42969-2.3047-2.5391-2.1094-6.4805 0.42969-8.7812l0.50781-0.46094-8.7617-9.6602-59.871 54.305 2.4922 2.75-2.8789 1.0039 4.3008 12.312 0.64453-0.22656c3.2344-1.1328 6.7891 0.58203 7.918 3.8164 0.82422 2.3633 0.16016 4.9805-1.6914 6.6562-0.61719 0.5625-1.332 0.98438-2.125 1.2617l-0.64453 0.22656 4.3008 12.312 76.305-26.648zm-82.391-1.0547 46.727-42.379 0.76563 0.84375 1.6836-1.5273-0.76172-0.84375 9.4336-8.5586 6.9492 7.6602c-2.6328 2.8516-2.6953 7.3047-0.039063 10.23 2.6523 2.9258 7.0898 3.2969 10.184 0.95703l6.9453 7.6602-6.7812 6.1523-2.6523 2.4023-0.76562-0.83984-1.6836 1.5273 0.76562 0.84375-46.727 42.375-6.9453-7.6602c2.6289-2.8516 2.6914-7.3008 0.039063-10.227-2.6562-2.9297-7.0938-3.2969-10.184-0.96094l-4.9883-5.4922zm72.551 15.656-2.1484 0.75 0.375 1.0742-59.555 20.801-3.4102-9.7617c0.71875-0.32422 1.3711-0.75391 1.9609-1.2852 2.2617-2.0508 3.0703-5.2383 2.0664-8.1211-1.3047-3.7305-5.2422-5.8008-9.0039-4.8555l-3.4062-9.7617 2.1367-0.74609 5.2734 5.8125 0.50391-0.45703c2.5391-2.3047 6.4805-2.1094 8.7812 0.42969 2.3047 2.5391 2.1094 6.4805-0.42969 8.7812l-0.50781 0.46094 8.7617 9.6562 50.543-45.84 2.0664 5.9141c-0.71875 0.32422-1.375 0.75391-1.9609 1.2852-2.2617 2.0508-3.0703 5.2383-2.0664 8.1211 0.66406 1.9102 2.0391 3.4453 3.8633 4.3281 1.6172 0.77734 3.418 0.96484 5.1367 0.52344l3.4141 9.7617-12.027 4.1992z" stroke="currentColor" stroke-width="1"/>
          <path d="m74.496 33.426 2.5039 2.7578-1.6836 1.5273-2.5039-2.7578z" />
          <path d="m64.488 22.395 2.5 2.7578-1.6836 1.5273-2.5-2.7578z"/>
          <path d="m59.488 16.879 2.5 2.7578-1.6836 1.5273-2.5-2.7578z"/>
          <path d="m69.492 27.914 2.5 2.7578-1.6875 1.5273-2.5-2.7578z"/>
          <path d="m35.547 69.48c1.4922 1.6484 4.0508 1.7734 5.6992 0.27734l26.484-24.02c1.6445-1.4922 1.7695-4.0508 0.27344-5.6992l-13.227-14.586c-1.4922-1.6484-4.0508-1.7734-5.6992-0.27734l-26.484 24.02c-1.6484 1.4961-1.7734 4.0508-0.27734 5.6992zm-12.035-19.273 26.484-24.02c1.0898-0.98828 2.7812-0.90625 3.7695 0.18359l13.227 14.586c0.98828 1.0898 0.90625 2.7812-0.18359 3.7695l-26.48 24.02c-1.0898 0.98828-2.7812 0.90625-3.7695-0.18359l-13.23-14.586c-0.98828-1.0898-0.90625-2.7812 0.18359-3.7695z" stroke="currentColor" stroke-width="1"/>
          <path d="m76.984 70.082-1.2266-3.5117 2.1484-0.75 1.2266 3.5117z"/>
          <path d="m73.305 59.535 2.1484-0.75 1.2266 3.5156-2.1484 0.75z"/>
          <path d="m70.848 52.504 2.1484-0.75 1.2305 3.5117-2.1484 0.75z"/>
        </g>
      </svg>
    </div>

    <div class="d-flex flex-wrap flex-grow-1">
      <div class="col-12 buy-sums">
        <div>
          <span class="buy-sums-amount">{{ countTickets ? countTickets : 0 }} {{ countTickets === 1 ? 'Ticket':'Tickets' }}</span>
        </div>
        <div>
          <span class="buy-sums-price"><b>{{ format(cart_sums.sum) }}</b> ({{ getTranslation('module_shop_vat_incl_text', 'inkl.Mwst.') }})</span>
        </div>
      </div>
      <div class="col-12">
        <small class="text-danger">{{ min_error }}</small>
      </div>
    </div>

    <div class="d-flex flex-shrink-1 align-items-center">

      <button class="btn next-btn" @click="changeComponent('checkout')" :disabled="min_error || nextBtnDisabled">
        {{ getTranslation('module_shop_next_button_text', 'Weiter') }}
      </button>
    </div>

  </div>

  <div class="d-flex shop-footer p-4 mt-2" v-if="type === 'lounges'">

    <div class="d-flex flex-shrink-1 pe-4 align-items-center">

      <svg width="30pt" height="30pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="#fff">
          <path d="m75 89.062v-28.211c11.332-0.89453 20.312-11.359 20.312-24.133 0-2.793-0.51953-15.527-1.6172-18.508-0.22656-0.61719-0.8125-1.0234-1.4688-1.0234h-7.1523l0.66406-3.4922 8.5039-2.8359-0.98828-2.9688-9.375 3.125 0.003906-0.003906c-0.53906 0.17969-0.93359 0.63281-1.043 1.1875l-0.94922 4.9883h-27.242c-0.65625 0-1.2422 0.40625-1.4688 1.0234-1.0977 2.9805-1.6172 15.715-1.6172 18.508 0 12.77 8.9805 23.238 20.312 24.133v28.211h-10.938l-3.125 6.25h31.25l-3.125-6.25zm9.375-37.5h-3.125v-3.125h3.125zm4.6875-9.375h-3.125v-3.125h3.125zm-4.5859-21.875h6.5391c0.63281 4.6641 1.0078 9.3594 1.125 14.062h-10.344zm-28.617 0h25.438l-2.6797 14.062h-23.883c0.11719-4.7031 0.49219-9.3984 1.125-14.062zm6.6406 21.875h-3.125v-3.125h3.125zm7.8125 9.375h-3.125v-3.125h3.125zm0-10.938h3.125v3.125h-3.125z"/>
          <path d="m19.07 74.637c-0.42969 0.75-0.17578 1.7031 0.57422 2.1367l8.125 4.6875c0.74609 0.42969 1.6992 0.17187 2.1328-0.57422l4.2969-7.4492h-14.434zm5.9297 0.36328h3.125v3.125h-3.125z"/>
          <path d="m37.289 74.332-4.6875 8.125c-0.61719 1.0781-1.6406 1.8672-2.8438 2.1875-0.39844 0.10547-0.80859 0.15625-1.2227 0.15625-0.82031 0-1.6289-0.21484-2.3438-0.625l-8.125-4.6875c-2.0938-1.2305-2.9062-3.8555-1.8633-6.0508h-8.2344l1.2773 14.062h31.516l1.2773-14.062h-4.3516c-0.10156 0.3125-0.23438 0.60938-0.39844 0.89453zm-23.227 8.4805h-3.125v-3.125h3.125zm25 3.125h-3.125v-3.125h3.125z"/>
          <path d="m25.113 65.734c-0.13672 0.003906-0.27344 0.019531-0.40625 0.054687-0.39844 0.10938-0.74219 0.37109-0.94922 0.73047l-2.1953 3.793h11.887l-7.5625-4.3672c-0.23438-0.13672-0.5-0.20703-0.77344-0.21094z"/>
          <path d="m7.6758 70.312h10.281l3.0938-5.3555h0.003907c0.62109-1.0781 1.6445-1.8672 2.8438-2.1875 1.2031-0.32422 2.4844-0.15625 3.5625 0.46875l8.125 4.6875c0.92187 0.53906 1.6289 1.3828 2 2.3867h4.7383l1.2773-14.062h-37.195z"/>
          <path d="m53.125 68.75c-2.8242 0-5.5625 0.96094-7.7695 2.7227l-1.6055 17.664v0.35547c2.8008 3.1953 7.0352 4.7461 11.238 4.1211s7.8008-3.3438 9.5508-7.2148c1.75-3.8711 1.4141-8.3672-0.89453-11.938-2.3086-3.5664-6.2695-5.7188-10.52-5.7109zm4.6875 11.586c-1.8945-0.003907-3.6055-1.1445-4.3281-2.8945-0.72656-1.7539-0.32422-3.7695 1.0156-5.1094l2.2109 2.2109h-0.003907c-0.60938 0.60938-0.60938 1.5977 0 2.207 0.61719 0.59375 1.5938 0.59375 2.2109 0l2.2109 2.2109c-0.875 0.88281-2.0703 1.375-3.3164 1.375z"/>
          <path d="m9.375 90.625h31.25v3.125h-31.25z"/>
        </g>
      </svg>


    </div>

    <div class="d-flex flex-wrap flex-grow-1">
      <div class="col-12 buy-sums">
        <div>
          <span class="buy-sums-amount">{{ countPackages ? countPackages : 0 }} {{ countPackages === 1 ? getTranslation('module_shop_buy_sums_amount_singular', 'Paket') : getTranslation('module_shop_buy_sums_amount_plural', 'Pakete') }}</span>
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <span class="buy-sums-price"><b>{{ format(cart_sums.sum) }}</b></span>
          <span v-if="settings.lounges.show_per_person_price && persons > 1 && cart_sums.sum > 0" style="font-size: 12px; line-height: 12px">(~{{ format(cart_sums.sum / persons) }}  ({{ getTranslation('module_lounges_per_person', 'pro Person') }}))</span>
          <span v-else style="font-size: 12px; line-height: 12px"> ({{ getTranslation('module_shop_vat_incl_text', 'inkl.Mwst.') }})</span>
        </div>
      </div>
      <div class="col-12">
        <small class="text-danger">{{ min_error }}</small>
      </div>
    </div>

    <div class="d-flex flex-shrink-1 align-items-center">
      <button class="btn next-btn" @click="changeComponent('checkout')" :disabled="nextBtnDisabled || min_error">
        {{ getTranslation('module_shop_next_button_text', 'Weiter') }}
      </button>
    </div>

  </div>
  <div class="d-flex shop-footer p-4 mt-2" v-if="type === 'drinks'">
    <div class="d-flex flex-shrink-1 pe-4 align-items-center">

      <svg width="30pt" height="30pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="#fff">
          <path d="m75 89.062v-28.211c11.332-0.89453 20.312-11.359 20.312-24.133 0-2.793-0.51953-15.527-1.6172-18.508-0.22656-0.61719-0.8125-1.0234-1.4688-1.0234h-7.1523l0.66406-3.4922 8.5039-2.8359-0.98828-2.9688-9.375 3.125 0.003906-0.003906c-0.53906 0.17969-0.93359 0.63281-1.043 1.1875l-0.94922 4.9883h-27.242c-0.65625 0-1.2422 0.40625-1.4688 1.0234-1.0977 2.9805-1.6172 15.715-1.6172 18.508 0 12.77 8.9805 23.238 20.312 24.133v28.211h-10.938l-3.125 6.25h31.25l-3.125-6.25zm9.375-37.5h-3.125v-3.125h3.125zm4.6875-9.375h-3.125v-3.125h3.125zm-4.5859-21.875h6.5391c0.63281 4.6641 1.0078 9.3594 1.125 14.062h-10.344zm-28.617 0h25.438l-2.6797 14.062h-23.883c0.11719-4.7031 0.49219-9.3984 1.125-14.062zm6.6406 21.875h-3.125v-3.125h3.125zm7.8125 9.375h-3.125v-3.125h3.125zm0-10.938h3.125v3.125h-3.125z"/>
          <path d="m19.07 74.637c-0.42969 0.75-0.17578 1.7031 0.57422 2.1367l8.125 4.6875c0.74609 0.42969 1.6992 0.17187 2.1328-0.57422l4.2969-7.4492h-14.434zm5.9297 0.36328h3.125v3.125h-3.125z"/>
          <path d="m37.289 74.332-4.6875 8.125c-0.61719 1.0781-1.6406 1.8672-2.8438 2.1875-0.39844 0.10547-0.80859 0.15625-1.2227 0.15625-0.82031 0-1.6289-0.21484-2.3438-0.625l-8.125-4.6875c-2.0938-1.2305-2.9062-3.8555-1.8633-6.0508h-8.2344l1.2773 14.062h31.516l1.2773-14.062h-4.3516c-0.10156 0.3125-0.23438 0.60938-0.39844 0.89453zm-23.227 8.4805h-3.125v-3.125h3.125zm25 3.125h-3.125v-3.125h3.125z"/>
          <path d="m25.113 65.734c-0.13672 0.003906-0.27344 0.019531-0.40625 0.054687-0.39844 0.10938-0.74219 0.37109-0.94922 0.73047l-2.1953 3.793h11.887l-7.5625-4.3672c-0.23438-0.13672-0.5-0.20703-0.77344-0.21094z"/>
          <path d="m7.6758 70.312h10.281l3.0938-5.3555h0.003907c0.62109-1.0781 1.6445-1.8672 2.8438-2.1875 1.2031-0.32422 2.4844-0.15625 3.5625 0.46875l8.125 4.6875c0.92187 0.53906 1.6289 1.3828 2 2.3867h4.7383l1.2773-14.062h-37.195z"/>
          <path d="m53.125 68.75c-2.8242 0-5.5625 0.96094-7.7695 2.7227l-1.6055 17.664v0.35547c2.8008 3.1953 7.0352 4.7461 11.238 4.1211s7.8008-3.3438 9.5508-7.2148c1.75-3.8711 1.4141-8.3672-0.89453-11.938-2.3086-3.5664-6.2695-5.7188-10.52-5.7109zm4.6875 11.586c-1.8945-0.003907-3.6055-1.1445-4.3281-2.8945-0.72656-1.7539-0.32422-3.7695 1.0156-5.1094l2.2109 2.2109h-0.003907c-0.60938 0.60938-0.60938 1.5977 0 2.207 0.61719 0.59375 1.5938 0.59375 2.2109 0l2.2109 2.2109c-0.875 0.88281-2.0703 1.375-3.3164 1.375z"/>
          <path d="m9.375 90.625h31.25v3.125h-31.25z"/>
        </g>
      </svg>


    </div>

    <div class="d-flex flex-wrap flex-grow-1">
      <div class="col-12 buy-sums">
        <div>
          <span class="buy-sums-amount">{{ countDrinks ? countDrinks : 0 }} {{ countDrinks === 1 ? 'Drink':'Drinks' }}</span>
        </div>
        <div class="d-flex flex-wrap align-items-center">
          <span class="buy-sums-price"><b>{{ format(cart_sums.sum) }}</b></span>
          <span style="font-size: 12px; line-height: 12px">({{ getTranslation('module_shop_vat_incl_text', 'inkl.Mwst.') }})</span>
        </div>
      </div>
      <div class="col-12">
        <small class="text-danger">{{ min_error }}</small>
      </div>
    </div>

    <div class="d-flex flex-shrink-1 align-items-center">
      <button class="btn next-btn" @click="changeDrinkComponent" :disabled="nextBtnDisabled || min_error">
        {{ getTranslation('module_shop_next_button_text', 'Weiter') }}
      </button>
    </div>
  </div>

</template>

<script>
import { mapState, mapStores } from "pinia";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";
import { useUserStore } from "../../../store/user_store";

export default {
  name: "shopFooter",
  props: ["type"],
  emits: ["changeComponent"],
  inject: ["getTranslation"],
  data() {
    return {
      min_error: null,
      nextBtnDisabled: true,
    };
  },
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    changeComponent: function (component, module = "Shop", params = {}) {
      document.getElementById("shop-modal").scrollTo(0, 0);
      this.$emit("changeComponent", { module, component, params });
    },
    changeDrinkComponent: function () {
      // check if user already has valid Lounge-booking then change to validateBooking else checkout
      if (this.bookingNumber) {
        // user has valid lounge booking
        return this.changeComponent("checkout", "Shop");
      } else {
        return this.changeComponent("drinks", "Shop", {
          activeComponent: "validateBooking",
        });
      }
    },
  },
  computed: {
    ...mapStores(useCartStore, useUserStore),
    ...mapState(useDataStore, ["event", "settings"]),
    ...mapState(useCartStore, [
      "selectedLounge",
      "packageCount",
      "countPackages",
      "countDrinks",
      "countTickets",
      "persons",
      "cart_sums",
      "bookingNumber",
    ]),
  },
  watch: {
    selectedLounge: {
      handler() {
        this.min_error = null;
      },
    },
    "cart_sums.total": {
      handler(newPrice, oldPrice) {
        this.nextBtnDisabled = false;
        if (this.type === "lounges" && this.selectedLounge) {
          // vip factor wird ab 2.7.7 Version direkt mit eingerechnet
          const min = this.selectedLounge.minimum_consumption;
          if (min > newPrice) {
            // this.nextBtnDisabled = true;
            this.min_error =
              this.getTranslation(
                "error_minimum_consumption",
                "Der Mindestverzehr wurde nicht erreicht.",
              ) +
              " (" +
              this.format(min) +
              ")";
          } else {
            this.min_error = null;
          }
        } else if (this.type === "drinks" && this.countDrinks > 0) {
          const min = this.settings.lounges.drink_orderings.min_consumption;
          if (min > newPrice) {
            // this.nextBtnDisabled = true;
            this.min_error =
              this.getTranslation(
                "error_minimum_consumption",
                "Der Mindestverzehr wurde nicht erreicht.",
              ) +
              " (" +
              this.format(min) +
              ")";
          } else {
            this.min_error = null;
          }
        }
      },
      immediate: false,
    },
    countTickets: {
      handler(amount, oldAmount) {
        this.nextBtnDisabled = false;
        if (this.type === "tickets") {
          if (amount <= 0) {
            // this.nextBtnDisabled = true;
            this.min_error = this.getTranslation(
              "error_select_at_least_one_ticket",
              "Du musst mindestens 1 Ticket wählen.",
            );
          } else {
            this.min_error = null;
          }
        }
      },
      immediate: false,
    },
  },
  mounted() {
    this.min_error = null;
    if (this.countTickets > 0) {
      this.nextBtnDisabled = false;
    }
  },
};
</script>

<style scoped>

</style>