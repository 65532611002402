<template>

  <div class="d-flex flex-wrap animate__animated animate__fadeIn justify-content-center" id="UserSettings">

    <div class="col-12 my-4 d-flex justify-content-center">
      <button type="button" class="btn btn-default text-center px-4" @click="showChangePassword = !showChangePassword" data-umami-event="settings-reset-password-button">
        <span>{{ getTranslation('password_change_subtitle', 'Passwort zurücksetzen') }}</span>
      </button>
    </div>

    <div class="col-8 animate__animated animate__fadeInDown" style="padding: 1rem 2rem 6rem 2rem;" v-if="showChangePassword">

      <Form @submit="submitData" id="change-password-form" :validation-schema="schema" v-slot="{ errors }">

        <div class="d-flex flex-wrap pt-2 justify-content-center">

          <div class="col-12">
            <h4 class="m-0 title">{{ getTranslation('module_user_change_password_title', 'Bitte gib dein aktuelles Passwort an') }}</h4>
          </div>

          <div class="col-12 mt-2 px-lg-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="password" name="password" class="form-control" :placeholder="getTranslation('current_password_label', 'aktuelles Passwort')"
                     :class="errors.password ? 'is-invalid':''"/>
              <label for="password">{{ getTranslation('current_password_label', 'aktuelles Passwort') }}</label>
              <small class="text-danger" v-if="errors.password">{{ errors.password }}</small>
            </div>
          </div>

          <div class="col-12">
            <h4 class="m-0 title">{{ getTranslation('module_user_change_password_title2', 'Bitte wähle dein neues Passwort') }}</h4>
          </div>

          <div class="col-sm-8 col-lg-6 col-12 px-lg-2 mt-2 ">
            <div class="form-floating form-group w-100">
              <Field as="input" type="password" name="newPassword" class="form-control" :placeholder="getTranslation('password_placeholder', 'Passwort')"
                     :class="errors.newPassword ? 'is-invalid':''"/>
              <label for="newPassword">{{ getTranslation('password_label', 'Passwort') }}</label>
              <small class="text-danger" v-if="errors.newPassword">{{ errors.newPassword }}</small>
            </div>
          </div>


          <div class="col-sm-8 col-lg-6 col-12 px-lg-2">
            <div class="form-floating form-group w-100">
              <Field as="input" type="password" name="confirmNewPassword" class="form-control" id="email"
                     :placeholder="getTranslation('password_confirm_placeholder', 'Passwort bestätigen')" :class="errors.confirmNewPassword ? 'is-invalid':''"/>
              <label for="confirmNewPassword">{{ getTranslation('password_confirm_label', 'Passwort bestätigen') }}</label>
              <small class="text-danger" v-if="errors.confirmNewPassword">{{ errors.confirmNewPassword }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap justify-content-center">
          <button type="submit" class="btn btn-default text-center px-4"
                  :disabled="Object.keys(errors).length ? true:false">
            <span v-if="!isLoading">{{ getTranslation('module_user_component_password_save_button_text', 'Passwort speichern') }}</span>
            <span v-else class="spinner-border spinner-border-sm"></span>
          </button>
        </div>

      </Form>

    </div>

    <div class="col-12 d-flex justify-content-center mt-4">
      <button type="button" class="btn btn-default text-center px-4" @click="deleteAccount">
        <span>{{ getTranslation('module_user_settings_delete_account_button_text', 'Account löschen') }}</span>
      </button>
    </div>

  </div>

</template>

<script>
import "animate.css";
import { Field, Form } from "vee-validate";
import { toRaw } from "vue";
import * as yup from "yup";

import Swal from "sweetalert2";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../../store/auth_store";

export default {
  name: "UserSettings",
  emits: [],
  components: {
    Form,
    Field,
  },
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useAuthStore),
  },
  data() {
    // NEU
    const password_message = this.getTranslation(
      "error_password_missing",
      "Passwort wird benötigt",
    );
    const password_message_2short = this.getTranslation(
      "error_password_2short",
      "Das Passwort muss mind. 6 Zeichen lang sein.",
    );
    const password_dont_matches = this.getTranslation(
      "error_password_mismatch",
      "Die Passwörter stimmen nicht überein.",
    );
    const password_length = this.getTranslation(
      "error_password_length",
      "Mindestens 8 Zeichen",
    );
    const password_spec_char = this.getTranslation(
      "error_password_spec_char",
      "Mindestens eins dieser Sonderzeichen !#$%^&*()+-=[]{}§~;':|,.<>/?",
    );
    const password_number = this.getTranslation(
      "error_password_number",
      "Mindestens eine Zahl",
    );
    const password_lowercase = this.getTranslation(
      "error_password_lowercase",
      "Mindestens ein Kleinbuchstabe",
    );
    const password_uppercase = this.getTranslation(
      "error_password_uppercase",
      "Mindestens ein Großbuchstabe",
    );
    const specialCharRegex = /[!#$%^&*()+\-=\[\]{}§~;':|,.<>\/?]+/;
    const numberRegex = /[0-9]+/;
    const lowercaseRegex = /[a-z]+/;
    const uppercaseRegex = /[A-Z]+/;
    // NEU ENDE

    const schema = {
      password: yup
        .string()
        .required(password_message)
        .min(6, password_message_2short),
      // NEU
      newPassword: (value, data) => {
        if (value === undefined || value === "") {
          return password_message;
        }
        if (!specialCharRegex.test(value)) {
          return password_spec_char;
        }
        if (!numberRegex.test(value)) {
          return password_number;
        }
        if (!lowercaseRegex.test(value)) {
          return password_lowercase;
        }
        if (!uppercaseRegex.test(value)) {
          return password_uppercase;
        }
        if (value.length < 8) {
          return password_length;
        }
        return true;
      },
      // NEU ENDE
      confirmNewPassword: function (value, data) {
        if (value === undefined || value === "") {
          return password_message;
        }

        data = toRaw(data.form);
        if (value !== data.newPassword) {
          return this.getTranslation(
            "error_password_mismatch",
            "Die Passwörter stimmen nicht überein.",
          );
        }
        return true;
      },
    };

    return {
      schema,
      showChangePassword: false,
      isLoading: false,
    };
  },
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
    submitData: function (values) {
      this.isLoading = true;

      this.authStore
        .changePassword(values)
        .then(() => {
          this.isLoading = false;
          Swal.fire({
            title: this.getTranslation("success_message_title", "Erfolgreich!"),
            icon: "success",
            text: this.getTranslation(
              "password_change_succeeded",
              "Dein Passwort wurde erfolgreich geändert.",
            ),
          }).then(() => {
            this.showChangePassword = false;
            document.getElementById("change-password-form").reset();
          });
        })
        .catch((error) => {
          this.isLoading = false;
          Swal.fire({
            title: this.getTranslation(
              "error_unknown",
              "Ein Fehler ist aufgetreten",
            ),
            icon: "error",
            text: error.message,
          });
        });
    },
    deleteAccount: function () {
      Swal.fire({
        // NEU
        icon: "warning",
        text: this.getTranslation(
          "warning_delete_account",
          "Bist du sicher, dass du deinen Account löschen möchtest?",
        ),
        // NEU ENDE
        cancelButtonText: this.getTranslation("abort_button_text", "Abbrechen"),
        showCancelButton: true,
        confirmButtonText: this.getTranslation("confirm_button_text", "Ja!"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.authStore.deleteAccount().then(() => {
            Swal.fire({
              title: "Erfolgreich!",
              text: "Dein Account wurde gelöscht.",
            }).then(() => {
              setTimeout(() => {
                window.location = "/";
              }, 2000);
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>

</style>