<template>

  <Teleport to="#userWidget">
    <template v-if="!isAuthenticatedRef">
      <div id="user-widget" class="loginBtn" @click="openLogin">
        <div class="">Login</div>
      </div>
    </template>
    <template v-else-if="isAuthenticatedRef && userInfos">
      <div class="user-card">
        <div class="user-image" :class="userImage ? '':'user-placeholder'" :style="userImage ? userImage:''"></div>
        <div class="user-info">{{ userInfos.firstname }}</div>
      </div>

      <ul class="user-submenu">
        <li class="user-submenu-item">
          <a :href="`${languagePrefix}/user/edit`">
            {{ getTranslation('module_user_profile_link_name', 'Mein Profil') }}
          </a>
        </li>
        <li class="user-submenu-item">
          <a :href="`${languagePrefix}/user/orders`">
            {{ getTranslation('module_user_timeline_link_name', 'Meine Bestellungen') }}
          </a>
        </li>
        <li class="user-submenu-item">
          <a :href="`${languagePrefix}/user/settings`">
            {{ getTranslation('module_user_settings_link_name', 'Einstellungen') }}
          </a>
        </li>
        <li class="user-submenu-item logout" @click="logout">
          <a href="#">
            Logout
          </a>
        </li>
      </ul>
    </template>
  </Teleport>

  <Teleport to="#customLogin">
    <template v-if="!isAuthenticatedRef">
      <a href="#" @click="openLogin">Login</a>
    </template>
  </Teleport>

  <!-- MOBILE -->
  <Teleport to="#userWidgetMobile">
    <div style="padding: 0 2rem">
      <template v-if="!isAuthenticatedRef">
        <div class="d-flex w-100 side-menu-user-card bg-theme-gradient" style="border-radius: var(--radius);">
          <a class="login-btn" href="#" @click="openLogin">
            Login
          </a>
        </div>
      </template>

      <template v-if="isAuthenticatedRef && userInfos">
        <div class="d-flex justify-content-center side-menu-user-card bg-theme-gradient">
          <div class="d-flex align-items-center justify-content-center">
            <div class="user-image" :class="userImage ? '':'user-placeholder'" :style="userImage ? userImage:''"></div>
          </div>
          <div class="d-flex align-items-center flex-wrap" style="height: 70px;">
            <div class="side-menu-user-name h5 ml--20 mb--0">{{ userInfos.firstname }}</div>
            <div class="side-menu-user-edit ml--20">
              <a :href="`${languagePrefix}/user/edit`">
                {{ getTranslation('module_user_profile_link_name', 'Profil bearbeiten') }}
              </a>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap w-100">
          <ul class="mainmenu nav-mobile px-0">
            <li>
              <a :href="`${languagePrefix}/user/orders`" class="nav-link">
                <i class="icon feather-shopping-cart"></i>
                {{ getTranslation('module_user_timeline_link_name', 'Meine Bestellungen') }}
              </a>
            </li>
            <li>
              <a :href="`${languagePrefix}/user/settings`" class="nav-link">
                <i class="icon feather-settings"></i>
                {{ getTranslation('module_user_settings_link_name', 'Einstellungen') }}
              </a>
            </li>
            <li>
              <a href="#" id="logout-mobile" class="nav-link" @click="logout">
                <i class="feather-log-out"></i>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </Teleport>
  <!-- END MOBILE -->
</template>


<script>
import { mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useUserStore } from "../../../store/user_store";

import Swal from "sweetalert2";
import { mapState } from "pinia";

export default {
  name: "UserWidget",
  emits: ["openLogin"],
  data() {
    return {};
  },
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useAuthStore, useUserStore),
    ...mapState(useAuthStore, ["isAuthenticatedRef"]),
    userImage() {
      if (this.userInfos?.thumbImage) {
        return `background-image: url('${this.userInfos.thumbImage}')`;
      }
      return false;
    },
    userInfos() {
      return this.userStore.userInfosRef
        ? JSON.parse(this.userStore.userInfosRef)
        : this.userStore.userInfosRef;
    },
    languagePrefix() {
      return window.disco2app.languagePrefix ?? "";
    },
  },
  methods: {
    openLogin: function () {
      document.getElementById("side-menu").classList.remove("active");
      this.$emit("openLogin");
    },
    logout: function () {
      if (window.innerWidth <= 992) {
        Swal.fire({
          text: this.getTranslation(
            "warning_logout",
            "Möchtest du dich wirklich ausloggen?",
          ),
          showCancelButton: true,
          cancelButtonText: this.getTranslation(
            "abort_button_text",
            "Abbrechen",
          ),
          submitButtonText: "Ja",
        }).then((result) => {
          if (result.isConfirmed) {
            this.authStore.logout();
          }
        });
      } else {
        this.authStore.logout();
      }
    },
  }
};
</script>


<style scoped lang="scss">
/* purgecss start ignore */
.login-btn {
  cursor: pointer;
  height: 50px;
  width: 100%;
  color: var(--color-white);
  background-color: transparent;

  background-image: linear-gradient(90deg, var(--color-primary-lightest) 10%, var(--color-primary-alt) 50%, var(--color-tertiary) 90%);
  border-radius: var(--radius);
  border-style: solid;

  border-width: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none !important;
  text-shadow: var(--shadow-light);
  line-height: 47px;

  padding: 0 28px;
  transition: 0.4s;

  &:hover {
    box-shadow: var(--shadow-primary);
    -webkit-filter: brightness(125%);
    filter: brightness(125%);
    color: var(--color-white);
    text-decoration: none;
  }
}

#userWidget, #userWidgetMobile {
  position: relative;
  cursor: pointer;

  .user-card {

    .user-image {
      float: left;
      width: 25px;
      height: 25px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: .5rem;
    }

    .user-placeholder {
      background-image: url("../../../../../public/images/user_image_placeholder.png");
    }

    .user-info {
      float: left;
      line-height: 25px;
      color: #fff !important;
    }
  }

  .side-menu-user-card {

    padding: 0px 20px;

    .user-image {
      //float: left;
      width: 55px;
      height: 55px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: .5rem;
    }

    .user-placeholder {
      background-image: url("../../../../../public/images/user_image_placeholder.png");
    }

    .user-info {
      float: left;
      line-height: 25px;
      color: #fff !important;
    }

    .side-menu-user-edit a {
      color: #fff;
      font-size: 14px;

      &:hover {
        color: var(--color-darkest);
      }
    }
  }

  .user-submenu {
    min-width: 240px;
    height: auto;
    position: absolute;
    margin-top: 35px;
    margin-left: -15px;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    padding: 12px 0;
    transition: 0.3s;
    border-radius: 10px;
    background-color: var(--color-blackest);
    box-shadow: var(--shadow-lighter);

    li {
      font-weight: 500;
      padding: 5px 20px;
      font-size: 14px;
      display: block;
      color: var(--color-body);
      margin: 0 10px;
      border-radius: 3px;
      transition: .3s;

      &:hover {
        color: var(--color-primary);
        background: var(--color-darker);
      }
    }

    .logout a {
      color: red;
    }
  }

  &:hover {
    .user-submenu {
      opacity: 1;
      visibility: visible;
    }
  }

}

/* purgecss end ignore */
</style>
