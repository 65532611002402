<template>

  <div class="w-100">


    <div class="p-4" :class="formClasses">

      <Form v-if="!formIsLoading" @submit="submitData" :validation-schema="schema" :initial-values="userInfos"
            id="register-form" v-slot="{ errors }">

        <div class="d-flex flex-wrap pt-2">

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" :placeholder="getTranslation('firstname_label', 'Vorname')" name="firstname" class="form-control" id="firstname" :class="errors.firstname ? 'is-invalid':''"/>
              <label for="firstname">{{ getTranslation('firstname_label', 'Vorname') }}</label>
              <small class="text-danger" v-if="errors.firstname">{{ errors.firstname }}</small>
            </div>
          </div>

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" :placeholder="getTranslation('lastname_label', 'Nachname')" name="lastname" class="form-control" id="lastname" :class="errors.lastname ? 'is-invalid':''"/>
              <label for="lastname">{{ getTranslation('lastname_label', 'Nachname') }}</label>
              <small class="text-danger" v-if="errors.lastname">{{ errors.lastname }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap pt-2">

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" placeholder="Username" name="username" class="form-control" id="username" :class="errors.username ? 'is-invalid':''"/>
              <label for="username">Username</label>
              <small class="text-danger" v-if="errors.username">{{ errors.username }}</small>
            </div>
          </div>

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="email" :placeholder="getTranslation('email_placeholder', 'E-Mail')" name="email" autocomplete="username" class="form-control" :class="errors.email ? 'is-invalid':''"/>
              <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
              <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap pt-2" v-if="(target_settings?.register?.addressRequired || target_settings?.register?.addressRequired === undefined)">

          <div class="col-md-4 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" :placeholder="getTranslation('street_placeholder', 'Straße & Hausnummer')" name="street" class="form-control" id="street" :class="errors.street ? 'is-invalid':''"/>
              <label for="street">{{ getTranslation('street_label', 'Straße & Hausnummer') }}</label>
              <small class="text-danger" v-if="errors.street">{{ errors.street }}</small>
            </div>
          </div>

          <div class="col-md-4 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" :placeholder="getTranslation('zip_label', 'PLZ')" name="zip" class="form-control" id="zip" :class="errors.zip ? 'is-invalid':''"/>
              <label for="zip">{{ getTranslation('zip_label', 'PLZ') }}</label>
              <small class="text-danger" v-if="errors.zip">{{ errors.zip }}</small>
            </div>
          </div>

          <div class="col-md-4 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" type="text" :placeholder="getTranslation('place_label', 'Stadt')" name="place" class="form-control" id="place" :class="errors.place ? 'is-invalid':''"/>
              <label for="place">{{ getTranslation('place_label', 'Stadt') }}</label>
              <small class="text-danger" v-if="errors.place">{{ errors.place }}</small>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap pt-2 pt-2">

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="select" name="gender" id="gender" class="form-select" :class="errors.gender ? 'is-invalid':''">
                <option value="" disabled>{{ getTranslation('gender_label', 'Geschlecht') }}</option>
                <option v-for="gender in genders" :key="gender" :value="gender" v-text="getTranslation('gender_' + gender, gender)" ></option>
              </Field>
              <label for="gender">{{ getTranslation('gender_label', 'Geschlecht') }}</label>
              <small class="text-danger" v-if="errors.gender">
                {{ getTranslation('error_gender_missing', 'Bitte wähle ein Geschlecht') }}
              </small>
            </div>
          </div>

          <div class="col-md-6 col-12 pe-md-2">
            <div class="form-group form-floating w-100">
              <Field as="input" id="birthday" :placeholder="getTranslation('birthday_placeholder', 'Geburtsdatum')" name="birthday" class="form-control"
                     type="date" :class="errors.birthday ? 'is-invalid':''" required></Field>
              <label for="birthday">{{ getTranslation('birthday_label', 'Geburtsdatum') }}</label>
              <small class="text-danger" v-if="errors.birthday">{{ errors.birthday }}</small>
            </div>
          </div>

        </div>

        <div class="d-flex flex-wrap justify-content-center pt-2">
          <div class="form-group">
            <button type="submit" class="btn text-center px-4" :class="isSaved ? 'bg-success':''" :disabled="isSaved || isLoading">
              <span v-if="!isLoading && !isSaved">{{ getTranslation('module_user_component_profile_save_button_text', 'Speichern') }}</span>
              <span v-else-if="isLoading && !isSaved" class="spinner-border spinner-border-sm"></span>
              <span v-else-if="isSaved"><i class="fa-solid fa-check"></i></span>
            </button>
          </div>
        </div>

      </Form>
      <div v-if="formIsLoading" class="col-12">
        <span class="spinner-border spinner-border"></span>
      </div>
    </div>
  </div>
</template>


<script>
import Swal from "sweetalert2";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

import { mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useUserStore } from "../../../store/user_store";

let target_settings;
try {
  target_settings = require("../../../settings/target_settings");
} catch (e) {
  target_settings = require("../../../settings/default_settings");
}
target_settings = target_settings.target_settings;

export default {
  name: "missingInfos",
  components: {
    Form,
    Field,
  },
  emits: ["changeComponent", "backToCheckout"],
  data() {
    const schema = {
      firstname: yup.string().required("Vorname wird benötigt").trim(),
      lastname: yup.string().required("Nachname wird benötigt").trim(),
      email: yup
        .string()
        .required("E-Mail Adresse wird benötigt")
        .trim()
        .email("Das ist keine gültige E-Mail Adresse."),
      username: yup
        .string()
        .required(
          this.getTranslation(
            "error_username_missing",
            "Username wird benötigt",
          ),
        )
        .trim()
        .min(3),
      birthday: (value, data) => {
        if (value === undefined || value === "") {
          return this.getTranslation(
            "error_birthday_missing",
            "Geburtsdatum wird benötigt",
          );
        }

        const date = new Date(value);
        const now = new Date();

        if (!date) {
          return this.getTranslation(
            "error_birthday_missing",
            "Geburtsdatum wird benötigt",
          );
        }

        if (now < date) {
          return this.getTranslation(
            "error_birthday_in_future",
            "Dein Geburtsdatum kann nicht in der Zukunft liegen.",
          );
        }

        if (date < new Date("1900-01-01")) {
          return this.getTranslation(
            "error_birthday_invalid",
            "Bitte gib ein valides Geburtsdatum an",
          );
        }

        return true;
      },
      gender: yup
        .string()
        .nullable()
        .required(
          this.getTranslation(
            "error_gender_missing",
            "Bitte wähle ein Geschlecht",
          ),
        ),
    };

    if (target_settings?.register?.address_required) {
      schema["street"] = yup
        .string()
        .required(
          this.getTranslation("error_street_missing", "Straße wird benötigt"),
        )
        .trim();
      schema["zip"] = yup
        .string()
        .required(
          this.getTranslation(
            "error_zip_missing",
            "Postleitzahl wird benötigt",
          ),
        )
        .trim();
      schema["place"] = yup
        .string()
        .required(
          this.getTranslation("error_place_missing", "Stadt wird benötigt"),
        )
        .trim();
    }

    return {
      schema,
      genders: ["männlich", "weiblich", "divers"],
      isLoading: false,
      formIsLoading: true,
      refreshInterval: null,
      userInfos: null,
      isSaved: false,
    };
  },
  props: ["formClasses"],
  inject: ["getTranslation"],
  computed: {
    ...mapStores(useUserStore, useAuthStore),
  },
  methods: {
    submitData(values) {
      this.isLoading = true;

      if (this.userStore.userInfos.email !== values.email) {
        values.email_unconfirmed = true;
        this.authStore.setEmailSentAt(moment().unix());

        this.refreshInterval = setInterval(() => {
          return this.userStore.refreshUserInfos().then((userInfos) => {
            if (userInfos.unconfirmed_email === "") {
              clearInterval(this.refreshInterval);
            }
          });
        }, 5000);
      }

      this.userStore
        .updateUserInfos(values)
        .then(() => {
          this.isSaved = true;
          setTimeout(() => {
            this.isSaved = false;
          }, 2000);

          return this.userStore.refreshUserInfos().then((userInfos) => {
            this.backToCheckout();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Etwas ist schiefgelaufen",
            icon: "error",
            text: error.message,
          });
          this.isLoading = false;
        });
    },
    backToCheckout() {
      this.changeComponent("checkout", "Shop");
    },
    changeComponent(component, module = "Auth") {
      this.$emit("changeComponent", { component: component, module: module });
    },
  },
  activated() {
    this.formIsLoading = true;
    this.userStore.refreshUserInfos().then((userInfos) => {
      this.formIsLoading = false;
      this.userInfos = userInfos;
    });

    if (document.querySelector(".btn-close")) {
      document.querySelector(".btn-close").style.display = "none";
    }
  },
  deactivated() {
    if (document.querySelector(".btn-close")) {
      document.querySelector(".btn-close").style.display = "inline";
    }
  },
};
</script>

<style scoped>

</style>