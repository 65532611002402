<template>

  <div>

    <div class="register-step-info">
      <h4 class="mb-1">{{ getTranslation('module_shop_component_register_step1_title', 'Los gehts...') }}</h4>
      {{ getTranslation('module_shop_component_register_step1_text', 'Wie möchtest du dich bei uns anmelden?') }}
    </div>

    <div class="d-flex flex-wrap pt-2">

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="text" name="username" class="form-control" id="username" :placeholder="getTranslation('username_label', 'Benutzername')" :class="errors.username ? 'is-invalid':''"/>
          <label for="username">{{ getTranslation('username_label', 'Benutzername') }}</label>
          <small class="text-danger" v-if="errors.username">{{ errors.username }}</small>
        </div>
      </div>

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" type="email" :placeholder="getTranslation('email_label', 'E-Mail')" name="email" autocomplete="username" class="form-control" id="email" :class="errors.email ? 'is-invalid':''"/>
          <label for="email">{{ getTranslation('email_label', 'E-Mail') }}</label>
          <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
        </div>
      </div>

    </div>

    <div class="d-flex flex-wrap pt-2 pt-2">

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" name="password" type="password" autocomplete="new-password" class="form-control"
                 id="password" :placeholder="getTranslation('password_label', 'Passwort')" :class="errors.password ? 'is-invalid':''"/>
          <label for="password">{{ getTranslation('password_label', 'Passwort') }}</label>
          <small class="text-danger" v-if="errors.password">{{ errors.password }}</small>
        </div>
      </div>

      <div class="col-md-6 col-12 px-md-2">
        <div class="form-floating form-group w-100">
          <Field as="input" name="confirmPassword" type="password" autocomplete="new-password" class="form-control"
                 id="confirmPassword" :placeholder="getTranslation('password_confirm_placeholder', 'Passwort wiederholen')" :class="errors.confirmPassword ? 'is-invalid':''"/>
          <label for="confirmPassword">{{ getTranslation('password_confirm_label', 'Passwort wiederholen') }}</label>
          <small class="text-danger" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</small>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
import { Field } from "vee-validate";
export default {
  name: "step1",
  props: ["errors"],
  inject: ["getTranslation"],
  emits: [],
  components: {
    Field,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>

</style>