const target_settings = {
    tickets: {
        codes: {
            position: 'underneath',
            open: false
        }
    },
    register: {
        addressRequired: true
    }
}

export { target_settings };
