<template>
  <div class="payment-completed-modal" v-if="params.order || params.type == 'loungeReservationRequest'">
    <div class="status-icon" :class="statusClass">
      <svg v-if="isSuccess" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
      <svg v-else-if="isPending" class="pending" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle fill="none" stroke-width="6" cx="50" cy="50" r="44" />
        <path class="pending__hand" d="M50 20 L50 50 L70 60" />
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <g fill="darkred">
          <path d="M250.37 57.34l-51.711-51.711c-2.5586-2.5586-6.1445-4.0977-9.7266-4.0977-3.5859 0-7.168 1.5352-9.7266 4.0977l-51.199 51.199-51.199-51.199c-5.1211-5.1211-14.336-5.1211-19.457 0l-51.715 51.711c-5.1211 5.6328-5.1211 14.336 0 19.457l51.199 51.199-51.199 51.199c-5.6328 5.1211-5.6328 14.336 0 19.457l51.711 51.711c2.5586 2.5586 6.1445 4.0977 9.7266 4.0977 3.5859 0 7.168-1.5352 9.7266-4.0977l51.199-51.199 51.199 51.199c2.5586 2.5586 6.1445 4.0977 9.7266 4.0977 3.5859 0 7.168-1.5352 9.7266-4.0977l51.711-51.711c5.1211-5.6328 5.1211-14.336 0-19.457l-51.199-51.199 51.199-51.199c5.6406-5.1211 5.6406-13.824 0.007813-19.457zm-80.383 60.93c-5.6328 5.1211-5.6328 14.336 0 19.457l51.199 51.199-32.258 32.258-51.199-51.199c-2.5586-2.5586-6.1445-4.0977-9.7266-4.0977-3.5859 0-7.168 1.5352-9.7266 4.0977l-51.199 51.199-32.258-32.258 51.199-51.199c5.6328-5.1211 5.6328-14.336 0-19.457l-51.199-51.199 32.258-32.258 51.199 51.199c5.1211 5.6328 14.336 5.6328 19.457 0l51.199-51.199 32.258 32.258z"/>
        </g>
      </svg>
    </div>

    <h2 class="status-title">{{ statusTitle }}</h2>
    <p class="status-message">{{ statusMessage }}</p>

    <a v-if="isSuccess && params.order?.pdfHash" class="btn-default btn-large round mb-4" target="_blank"
      :href="`/api/user/pdf/${params.order.pdfHash}`">
      <span>Download</span>
    </a>

<!--    <button v-if="showUserPageButton" @click="goToUserPage" class="action-button">
      {{ getTranslation('module_shop_component_payment_go_to_orders_text', 'Zu deinen Bestellungen') }}
    </button>
    <button v-else @click="backToShop" class="action-button">
      {{ getTranslation('module_shop_component_payment_go_back_to_shop_text', 'Zurück zum Shop') }}
    </button>-->
  </div>
</template>

<script>
import confetti from "canvas-confetti";

import { mapState, mapStores } from "pinia";
import { useAuthStore } from "../../../store/auth_store";
import { useCartStore } from "../../../store/cart_store";
import { useDataStore } from "../../../store/data_store";

let components;

try {
  components = require("../../../data/child_strings");
} catch (e) {
  components = require("../../../data/default_strings");
}
components = components.default;

export default {
  name: "paymentCompleted",
  props: ["params", "type"],
  data() {
    return {
      components,
      interval: null,
      type: null,
      user_page_url: null,
    };
  },
  inject: ["addToStack", "clearStack", "getTranslation"],
  emits: ["changeComponent", "setModalTitle"],
  computed: {
    ...mapStores(useCartStore),
    ...mapState(useCartStore, ["status"]),
    ...mapState(useDataStore, ["settings"]),
    ...mapState(useAuthStore, ["user"]),
    statusClass() {
      if (this.isSuccess) return "success";
      if (this.isPending) return "pending";
      return "failed";
    },
    isSuccess() {
      return (
        this.params.order?.status === "SUCCEEDED" ||
        this.params.order?.status === "RESERVED" ||
        this.params.type === "loungeReservationRequest"
      );
    },
    isPending() {
      return (
        this.params.order?.status === "PENDING" ||
        this.params.order?.status === "IN_EXERMINATION"
      );
    },
    bookingId() {
      return this.params.order?.id;
    },
    statusTitle() {
      if (this.isSuccess) {
        if (this.params.order?.status === "RESERVED" || this.params.type === "loungeReservationRequest") {
          return this.getTranslation(
            "module_shop_component_reservation_succeeded_title",
          "Reservierung erfolgreich!",
        );
      }
        return this.getTranslation(
          "module_shop_component_payment_succeeded_title",
          "Zahlung erfolgreich!",
        );
    }
      if (this.isPending)
        return this.getTranslation(
          "module_shop_component_payment_pending_title",
          "Zahlung in Bearbeitung!",
        );
      return this.getTranslation(
        "module_shop_component_payment_failed_title",
        "Zahlung fehlgeschlagen!",
      );
    },
    statusMessage() {
      if (this.params.order?.status === "FAILED") {
        return this.getTranslation(
          "module_shop_component_payment_failed_text",
          "Leider konnte deine Zahlung nicht abgeschlossen werden!",
        );
      } else if (
        this.params.order?.status === "SUCCEEDED" &&
        this.params.order?.method !== "Free"
      ) {
        return this.getTranslation(
          "module_shop_component_payment_succeeded_text",
          "Wir haben deine Bestellung erhalten, diese erhältst du per E-Mail, in der App und in deiner Übersicht!",
        );
      } else if (
        this.params.order?.status === "SUCCEEDED" &&
        this.params.order?.method === "Free"
      ) {
        return this.getTranslation(
          "module_shop_component_payment_succeeded_free_text",
          "Deine Frei-Tickets wurden freigeschaltet, diese erhältst du per E-Mail, in der App und in deiner Übersicht!",
        );
      } else if (this.params.order?.status === "RESERVED") {
        return this.getTranslation(
          "module_shop_component_payment_reserved_text",
          "Deine Reservierungsanfrage wurde erfolgreich entgegengenommen, ein Mitarbeiter wird sich bald bei dir melden.",
        );
      } else if (
        (this.params.order?.status === "PENDING" ||
          this.params.order?.status === "IN_EXERMINATION") &&
        this.type === "ticket-ordering"
      ) {
        return this.getTranslation(
          "module_shop_component_payment_pending_tickets_text",
          "Deine Zahlung ist in bearbeitung sobald diese abgeschlossen ist findest du deine Tickets in der App und in deiner Timeline!",
        );
      } else if (
        (this.params.order?.status === "PENDING" ||
          this.params.order?.status === "IN_EXERMINATION") &&
        this.type === "lounge-booking"
      ) {
        return this.getTranslation(
          "module_shop_component_payment_pending_lounges_text",
          "Deine Zahlung ist in bearbeitung sobald diese abgeschlossen ist findest du deine Reservierung in der App und in deiner Timeline!",
        );
      } else if (
        (this.params.order?.status === "PENDING" ||
          this.params.order?.status === "IN_EXERMINATION") &&
        this.type === "drink-ordering"
      ) {
        return this.getTranslation(
          "module_shop_component_payment_pending_drinks_text",
          "Deine Bestellung wurde aufgenommen!",
        );
      } else {
        return ""; // Default empty message if no condition is met
      }
    },
    showUserPageButton() {
      return (this.isSuccess || this.isPending) && this.user?.id;
    },
  },
  methods: {
    startConfetti: function () {
      const duration = 15 * 1000;
      const animationEnd = Date.now() + duration;
      const colors = [
        this.settings.styles.primary,
        this.settings.styles.sidebar,
        "#fff",
      ];
      const defaults = {
        startVelocity: 20,
        spread: 360,
        ticks: 30,
        zIndex: 1056,
        colors,
      };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      this.interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(this.interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          }),
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          }),
        );
      }, 250);

      const end = Date.now() + 7 * 1000;

      (function frame() {
        confetti({
          particleCount: 3,
          angle: 40,
          spread: 80,
          origin: { x: 0 },
          zIndex: 1056,
          colors: colors,
        });
        confetti({
          particleCount: 3,
          angle: 140,
          spread: 80,
          origin: { x: 1 },
          zIndex: 1056,
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    },
    goToUserPage: function () {
      this.clear().then(() => {
        window.location.pathname = "/user/orders";
      });
    },
    backToShop: function () {
      if (this.params.order.type === "ticket-ordering") {
        this.cartStore
          .restoreTicketOrdering(this.params.order.id)
          .then(() => {
            this.$emit("changeComponent", {
              component: "checkout",
              module: "Shop",
              params: {},
            });
          })
          .then(() => {
            this.clearStack().then(() => {
              this.addToStack({
                module: "Shop",
                component: "tickets",
                title: components.tickets.title,
                params: {},
              });
            });
          });
      } else if (this.params.order.type === "drink-ordering") {
        this.cartStore
          .restoreDrinkOrdering(this.params.order.id)
          .then(() => {
            this.$emit("changeComponent", {
              component: "checkout",
              module: "Shop",
              params: {},
            });
          })
          .then(() => {
            this.clearStack().then(() => {
              return this.addToStack({
                module: "Shop",
                component: "drinks",
                title: components.drinks.title,
                params: {
                  activeComponent: "drinkMenuSelection",
                },
              }).then(() => {
                this.addToStack({
                  module: "Shop",
                  component: "drinks",
                  title: components.drinks.title,
                  params: {
                    activeComponent: "listDrinks",
                  },
                });
              });
            });
          });
      } else {
        this.cartStore
          .restoreLoungeBooking(this.params.order.id)
          .then(() => {
            this.$emit("changeComponent", {
              component: "checkout",
              module: "Shop",
              params: {},
            });
          })
          .then(() => {
            this.clearStack().then(() => {
              this.addToStack({
                module: "Shop",
                component: "lounges",
                title: components.lounges.title,
                params: {
                  activeComponent: "listLounges",
                },
              }).then(() => {
                this.addToStack({
                  module: "Shop",
                  component: "lounges",
                  title: components.lounges.title,
                  params: {
                    activeComponent: "persons",
                  },
                }).then(() => {
                  this.addToStack({
                    module: "Shop",
                    component: "lounges",
                    title: components.lounges.title,
                    params: {
                      activeComponent: "listPackages",
                    },
                  });
                });
              });
            });
          });
      }
    },
    clear: function () {
      return this.cartStore.reset();
    },
  },
  beforeMount() {
    if (this.params.type === "loungeReservationRequest") {
      return this.startConfetti();
    }
    if (this.status === "paymentInProgress") {
      const ordering = this.params.order;

      if (
        ordering.status === "SUCCEEDED" ||
        ordering.status === "RESERVED" ||
        ordering.status === "PENDING" ||
        ordering.status === "IN_EXERMINATION"
      ) {
        this.user_page_url = ordering.user_page_url;
        this.type = ordering.type; //ticket-ordering || lounge-booking
        if (ordering.status === "SUCCEEDED") {
          this.startConfetti();
        }
        this.cartStore.setStatus("paymentCompleted");
      } else {
        this.cartStore.setStatus("paymentFailed");
      }
    }
  },
  mounted() {
    this.$emit("setModalTitle", "");
  },
};
</script>

<style scoped lang="scss">
.payment-completed-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  height: 100%; /* Use full height of the parent container */
  box-sizing: border-box;
}

.status-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 2rem;
}

.status-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.status-message {
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  max-width: 80%;
}

.action-button {
  padding: 1.25rem 2.5rem; /* Larger button */
  font-size: 1.25rem; /* Larger font size */
  font-weight: bold;
  color: white;
  background-color: var(--color-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  background-color: var(--color-primary-dark);
  transform: translateY(-2px);
}

/* Checkmark animation */
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: #7ac142;
  stroke-width: 2;
  fill: none;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

/* Pending animation */
.pending circle {
  stroke: #ffa500;
  stroke-width: 4;
  fill: none;
}

.pending__hand {
  stroke: #ffa500;
  stroke-width: 4;
  fill: none;
  transform-origin: 50% 50%;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
