<template>

  <div
      class="p-4 col-12 p-4 d-flex justify-content-center flex-wrap package-container animate__animated animate__fadeIn animate__fast">

    <h4>{{ getTranslation('module_shop_component_lounges_packages_title', 'Was wollt ihr trinken?') }}</h4>
    <renderPackage v-if="selectedLounge" :lounge_packages="selectedLounge.packages" :inCheckout="false"
                   :classes="'col-12 col-lg-9 py-2 mb-4  animate__animated animate__fadeInUp animate__fast'"></renderPackage>

  </div>

  <div class="d-flex w-100 justify-content-center">
      <span v-if="selectedLounge && !selectedLounge.packages">
        {{
          getTranslation('module_shop_component_lounges_0_packages_message', 'Leider wurden keine Getränke-Pakete hinterlegt.')
        }}
      </span>
  </div>

</template>

<script>
import renderPackage from "./renderPackage";

import { mapState } from "pinia";
import { useCartStore } from "../../../../store/cart_store";

export default {
  name: "listPackages",
  components: {
    renderPackage,
  },
  data() {
    return {};
  },
  inject: ["getTranslation"],
  computed: {
    ...mapState(useCartStore, ["selectedLounge"]),
  },
  emits: ["setModalTitle"],
  methods: {
    format: (price) =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price),
  },
  mounted() {
    this.$emit("setModalTitle", this.selectedLounge.name);
  },
  activated() {
    this.$emit("setModalTitle", this.selectedLounge.name);
  },
};
</script>

<style scoped>

</style>